<!-- @format -->
<template>
    <form @submit.prevent="makePayment">
        <div class="na-subscription-terms-header">
            <div class="is-flex is-justify-content-space-between">
                <p class="subtitle standout na-subscription-terms-product-title">
                    {{ productTypeFriendlyName(term.productType) }} Subscription
                </p>

                <p class="na-subscription-step">
                    <slot name="stepNumber" />
                </p>
            </div>

            <p class="subtitle standout">
                {{ publication.text }}: {{ formatCost(payLevel.value) }}/{{ termTypeFriendlyName(term.termType) }}
            </p>
        </div>
        <p v-if="needMailingAddress(term.productType)" class="subtitle na-subscription-address mb-3">
            Send To This Address
        </p>

        <mailing-address
            v-if="needMailingAddress(term.productType)"
            :errors="errors"
            :default-address="defaultAddress"
            :form-data.sync="formDataMailing"
        />

        <p class="subtitle na-subscription-address mb-3">Payment Method</p>

        <payment-method :payment.sync="formDataPayment" :payment-methods="paymentMethods" :errors="errors" />

        <p class="subtitle na-subscription-total-cost mb-3">Subscription Details</p>

        <p class="has-text-weight-bold">
            Subscription Cost: {{ formatCost(payLevel.value) }}/{{ termTypeFriendlyName(term.termType) }}
        </p>

        <div class="field">
            <div class="control" :class="hasInputError(errors, 'agreeTerms')">
                <label class="checkbox"
                    ><input
                        :checked="agreeTerms"
                        type="checkbox"
                        @change="$emit('update:agreeTerms', $event.target.checked)"
                    />
                    Agree to <a @click="showTerms = !showTerms">Subscription terms</a></label
                >
            </div>
            <input-error :field="['agreeTerms', 'general', 'publication', 'subscriptionTerm']" :errors="errors" />
        </div>

        <p v-if="showTerms" class="text mb-3">
            By clicking the PURCHASE button, you agree that your payment method will be debited by the subscription cost
            to start your subscription. Your subscription will automatically renew each {{ termType(term) }} and your payment method will
            be charged the purchase amount. The charge will be listed on your credit card statement as Zelacom NewsAtomic.
            You may cancel anytime by calling 1-845-647-9190. Please note no refunds are available for partial {{ termType(term) }}s.
        </p>

        <div class="na-subscription-terms-term buttons">
            <button
                type="submit"
                class="button is-success is-uppercase"
                :disabled="localIsBusy || !agreeTerms"
                :class="[localIsBusy ? 'is-loading' : '']"
            >
                Purchase
            </button>

            <button class="button is-uppercase is-hidden-tablet" @click="$emit('prev')">Back</button>
        </div>
    </form>
</template>

<script>
import { moneyMixin } from '@/mixins/MoneyMixin.js';
import { subscriptionHelperMixin } from '../mixins/SubscriptionHelperMixin.js';
import { dataHelperMixin } from '@/mixins/DataHelperMixin.js';
import InputError from '@/components/Common/InputError.vue';
import MailingAddress from './MailingAddress/Index.vue';
import PaymentMethod from './PaymentMethod/Index.vue';

export default {
    components: {
        InputError,
        MailingAddress,
        PaymentMethod
    },

    mixins: [moneyMixin, subscriptionHelperMixin, dataHelperMixin],

    props: {
        isBusy: {
            type: Boolean,
            default: false
        },
        payment: {
            type: Object,
            required: true
        },
        user: {
            type: Object,
            required: true
        },
        agreeTerms: {
            type: Boolean,
            required: true
        },
        term: {
            type: Object,
            required: true
        },
        payLevel: {
            type: Object,
            required: true
        },
        publication: {
            type: Object,
            required: true
        },
        // Any errors when we tried to register
        errors: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            localIsBusy: this.isBusy,
            formDataMailing: {},
            formDataPayment: this.payment,
            showTerms: false,
            paymentMethods: [],
            defaultAddress: { user: undefined, address: undefined }
        };
    },

    watch: {
        formDataMailing: {
            handler() {
                this.$emit('mailing-address-updated', this.formDataMailing);
            },
            deep: true
        },
        isBusy() {
            this.localIsBusy = this.isBusy;
        }
    },

    async created() {
        this.defaultAddress = { user: this.userCurrent, address: this.userPhysicalAddress };
        this.paymentMethods = await this.dataHelperLoadPaymentMethods(true);
    },

    methods: {
        /**
         * User wants to make payment (submit the subscription)
         */
        async makePayment() {
            this.localIsBusy = true;
            this.$emit('submit');
        },
        termType(term) {
            const result = term.termType.text.toLowerCase();
            return result === 'monthly' || result === 'month' || result === 'months'
                ? 'month'
                : (result === 'yearly' || result === 'year' || result === 'years')
                    ? 'year'
                    : result;
        }
    }
};
</script>
