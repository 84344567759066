<!-- @format -->
<template>
    <div>
        <div class="columns is-mobile">
            <div class="column">
                <div class="field" :class="hasInputError(inputErrors, 'content')">
                    <label class="label">Comment</label>
                    <div class="control">
                        <textarea v-model="comment" class="textarea" placeholder="comment" rows="5"></textarea>
                    </div>
                    <input-error field="content" :errors="inputErrors"></input-error>
                </div>
            </div>
        </div>

        <div class="columns is-mobile">
            <div class="column">
                <div class="na-post-comment-submit">
                    <a class="button is-info is-pulled-right" :disabled="isDisabled" @click="submit">Post</a>
                </div>
            </div>
        </div>
        <div class="na-reset"></div>

        <div class="columns is-mobile">
            <div v-if="errors && errors.length" class="column">
                <show-error :errors="errors"></show-error>
            </div>
        </div>

        <div v-if="messages && messages.length" class="columns is-mobile">
            <div class="column">
                <show-success :messages="messages" :auto-hide="8000" @closed="resetMessages"></show-success>
            </div>
        </div>
    </div>
</template>

<script>
import InputError from '@/components/Common/InputError.vue';
import ShowError from '@/components/Common/Error.vue';
import ShowSuccess from '@/components/Common/Success.vue';
import { createCommentPost } from '@/services/post.js';

export default {
    components: {
        InputError,
        ShowError,
        ShowSuccess
    },

    props: {
        post: {
            type: Object,
            default: function () {
                return null;
            }
        }
    },

    data() {
        return {
            isBusy: false,
            errors: [],
            inputErrors: [],
            messages: [],
            //title: null,
            comment: null
        };
    },

    computed: {
        isDisabled() {
            return this.isBusy;
        }
    },

    methods: {
        /**
         * Reset comment
         */
        resetComment() {
            //this.title = null;
            this.comment = null;
        },
        /**
         * Clear errors
         */
        resetErrors() {
            this.errors = [];
            this.inputErrors = [];
        },
        /**
         * Clear messages
         */
        resetMessages() {
            this.messages = [];
        },
        /**
         * Submit post
         */
        async submit() {
            if (this.isDisabled) {
                return;
            }
            this.isBusy = true;
            this.resetErrors();
            this.resetMessages();
            let data = {
                postId: this.post.id,
                //title: this.title,
                content: this.comment
            };

            try {
                const response = await createCommentPost(data);
                if (response.success) {
                    this.resetComment();
                    if (response.status === 'needs-moderation') {
                        this.$swal({
                            title: this.publicationGetName(this.post.publication) + ' Comments Are Moderated',
                            text:
                                'Your comment has been submitted for moderation and will be posted as soon as it is approved.',
                            type: 'success',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'Continue',
                            confirmButtonClass: 'button is-success',
                            buttonsStyling: false
                        });
                    } else {
                        this.$emit('comment-added');
                    }
                } else {
                    this.errors = response.errors ? response.errors : null;
                    this.inputErrors = response.inputErrors ? response.inputErrors : null;
                }
                this.isBusy = false;
            } catch (error) {
                this.isBusy = false;
            }
        }
    }
};
</script>
