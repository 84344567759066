/**
 * @format
 * Miscellaneous mixins
 */
import { authorDescribe } from '@/services/author';
import { issueDescribe } from '@/services/issue';

export const miscMixin = {
    computed: {
        /**
         * Return version number of NA
         */
        naVersion() {
            return window && window.naVersion ? 'v' + window.naVersion : '';
        }
    },
    methods: {
        /**
         * Sort
         *
         * @param {mixed}   prefix  Field prefix, null if none
         * @param {mixed}   field   Field name, null if none (we'll pass the whole object to the sort function)
         * @param {Boolean} reverse True if ascending, false if descending
         * @param {mixed}   primer  Function to call for comparison
         *
         * @return {Function} Sort function
         */
        sortBy(prefix, field, reverse, primer) {
            const key = function (x) {
                return primer
                    ? primer(field === null ? x : x[field])
                    : field === null
                    ? x
                    : x[field];
            };

            return function (a, b) {
                var A;
                var B;
                if (prefix !== null) {
                    A = key(a[prefix]);
                    B = key(b[prefix]);
                } else {
                    A = key(a);
                    B = key(b);
                }

                return (A < B ? -1 : A > B ? 1 : 0) * [-1, 1][+!!reverse];
            };
        },
        /**
         * Return a string describing an author
         *
         * @param {Object}  author          Author
         * @param {Boolean} includeAuthorId Include author id field with the name?  Default is false
         *
         * @return {String} Description
         */
        describeAuthor(author, includeAuthorId = false) {
            return authorDescribe(author, includeAuthorId);
        },
        /**
         * Return a string describing post type(s) (aka author types) the author is applicable to
         *
         * @param {Array} authorTypes Author types (each entry must be an object with post_type)
         *
         * @return {String} Description
         */
        describeAuthorTypes(authorTypes) {
            let name = '';
            if (authorTypes.length) {
                authorTypes.forEach((item) => {
                    if (item.post_type) {
                        name += name.length ? ', ' : '';
                        name += item.post_type.value;
                    }
                });
                name = name.replace(/^\s+/, '');
                if (!name.length) {
                    name = '(unknown!)';
                }
            } else {
                name = '(any)';
            }

            return name;
        },
        /**
         * Return a string describing an issue
         *
         * @param {Object} issue Issue
         *
         * @return {String} Description
         */
        describeIssue(issue) {
            return issueDescribe(issue);
        },
        /**
         * Return URL to public file
         *
         * @param {String} file Filename
         *
         * @return {String} Path to file
         */
        publicFileUrl(file) {
            return naRoutes.root + '/' + file;
        },
        /**
         * Truncate a string to a given maximum length and add a '...'
         *
         * @param {String} iString String
         * @param {mixed}  maxLen  Maximum length, default is 20
         *
         * @return {String} Truncate string (if needed)
         */
        dotString(iString, maxLen = 20) {
            if (typeof iString === 'string') {
                return iString.length > maxLen
                    ? iString.substring(0, maxLen) + '...'
                    : iString;
            } else {
                return iString;
            }
        },
        /**
         * Is empty?  We'll trim the string as well.
         *
         * @param {string|null} istr
         *
         * @return {Boolean}
         */
        isEmptyString(istr) {
            return (typeof istr === 'string' && !istr.trim().length) || !istr;
        }
    }
};
