<!-- @format -->
<template>
    <figure class="image na-post-publication-logo mt-1">
        <picture>
            <source v-for="set in sourceSet" :key="set.mime" :type="set.mime" :srcset="set.url" />
            <img :src="url" :alt="altText" :title="title" :width="media.width" :height="media.height" />
        </picture>
    </figure>
</template>

<script>
import { postMixin } from '@/mixins/PostMixin.js';
import { mediaMixin } from '@/mixins/MediaMixin.js';

export default {
    mixins: [postMixin, mediaMixin],

    props: {
        post: {
            type: Object,
            required: true
        }
    },

    computed: {
        media() {
            return this.getPublicationMedia(this.post, 'mediaTypeImage')[0];
        },
        url() {
            return this.mediaUrl(this.media);
        },
        sourceSet() {
            return this.getMediaSourceSet(this.media);
        },
        altText() {
            return this.mediaAlt(this.media, this.post.publication.name);
        },
        title() {
            return this.mediaTitle(this.media, this.post.publication.name);
        }
    }
};
</script>
