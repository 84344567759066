/**
 * @format
 * User related stuff
 */
import uniq from 'lodash-es/uniq';
import { readSections } from '@/services/section';

const STATUS = {
    INITIAL: 0,
    LOADING: 1,
    LOADED: 2,
    ERROR: 3
};

const state = {
    isLoggedIn: false,
    userType: null,
    canAccessAdministration: false,
    user: null,
    isImpersonating: false,
    //ignorePreferences: false,  @todo  2019-02-11  MJL  Shouldn't this be moved from viewer.js to user.js?
    prefs: {},
    balance: null,
    timeframeType: null,
    timeframeParam: {},
    loadingStatus: STATUS.INITIAL,
    customSections: {
        loading: false,
        data: [],
        errors: []
    },
    sectionsSpecial: {
        loading: false,
        data: [],
        errors: []
    },
    sections: {
        loading: false,
        data: [],
        errors: []
    },
    recentPost: {
        id: null,
        post: {}
    }
};

/**
 * Determine if a role is an admin type (admin or superadmin)
 *
 * @param {String} roleName
 *
 * @return {Boolean}
 */
const _roleIsAdminType = (roleName) => {
    return roleName === 'roleAdmin' || roleName === 'roleSuperAdmin';
};

/**
 * Return a value indicating a role type number
 *
 * @param {String} roleName
 *
 * @return {Integer}
 */
const _roleNumber = (roleName) => {
    switch (roleName) {
        case 'roleReader':
            return 1;
        case 'roleVendor':
            return 10;
        case 'roleSystem':
            return 20;
        case 'roleAuthor':
            return 25;
        case 'roleEditor':
            return 30;
        case 'roleAdminPost1':
            return 31;
        case 'roleAdmin':
            return 40;
        case 'roleSuperAdmin':
            return 50;
        default:
            return 0;
    }
};

/**
 * Compare role type with another
 *
 * @param {String} roleBase
 * @param {String} roleNew
 *
 * @return {Boolean} Returns true if roleNew is a higher role than roleBase, false if not
 */
const _roleIsHigher = (roleBase, roleNew) =>
    _roleNumber(roleNew) > _roleNumber(roleBase);

/**
 * Return the highest role object/publisher object
 *
 * @param {Object} user User
 *
 * @return {Object|null} Highest role/publisher object that the user has for any publisher
 */
const _highestRole = (user) => {
    let highestRole = null;

    if (user && user.roles) {
        user.roles.forEach((role) => {
            if (
                _roleIsHigher(
                    highestRole ? highestRole.role_type.key : null,
                    role.role_type.key
                )
            ) {
                highestRole = role;
            }
        });
    }

    return highestRole;
};

const getters = {
    isLoggedIn: (state) => state.isLoggedIn,
    canAccessAdministration: (state) => state.canAccessAdministration,
    user: (state) => state.user,
    userType: (state) => state.userType,
    isImpersonating: (state) => state.isImpersonating,
    balance: (state) => state.balance,
    loadingStatus: (state) => state.loadingStatus,
    timeframeType: (state) => state.timeframeType,
    timeframeParam: (state) => state.timeframeParam,
    physicalAddress: (state) => {
        if (state.user && state.user.addresses) {
            const address = state.user.addresses.find(
                (address) =>
                    address &&
                    address.address_type &&
                    address.address_type.key === 'adtPhysical'
            );
            return address !== undefined ? address : null;
        } else {
            return null;
        }
    },
    recentPost: (state) => state.recentPost,
    /**
     * Return the highest role object/publisher object
     *
     * @param {Object} state Store state
     *
     * @return {Object|null} Highest role/publisher object that the user has for any publisher (if they have the null publisher - that will be returned)
     */
    highestRole: (state) => {
        return _highestRole(state.user);
    },

    roles: (state) => {
        if (!state.user?.roles) {
            return [];
        }
        const roles = [...state.user.roles];
        return roles.sort((r1, r2) => {
            const r1n = _roleNumber(r1.role_type.key);
            const r2n = _roleNumber(r2.role_type.key);
            if (r1n > r2n) {
                return -1;
            }
            if (r1n < r2n) {
                return 1;
            }

            return 0;
        });
    },
    /**
     * Return the publications we're restricting to for the highest role
     * @todo  2019-02-25  MJL  Maybe allow combining multiple publications if user has multiple admin roles for different publishers?
     *
     * @return {Array|null} Array of publication ids, null if no restrictions.  Note: an empty array means they have NO publications vs. null means there are no restrictions.
     */
    restrictedToPublications: (state) => {
        const theRole = _highestRole(state.user);
        if (theRole) {
            if (theRole.publisher === null) {
                // No publisher means unrestricted
                return null;
            } else {
                return theRole.publisher && theRole.publisher.publications
                    ? theRole.publisher.publications
                    : [];
            }
        } else {
            return [];
        }
    },

    /**
     * Return the publication ids we're restricting to for the highest role
     * If user have multiple highest role e.g. mulitple admin roles, combine the
     * publication ids.
     *
     * @return {Array|null} Array of publication ids, null if no restrictions.  Note: an empty array means they have NO publications vs. null means there are no restrictions.
     */
    restrictedToPublicationIds: (state, getters) => {
        let publications = [];
        if (!state.user) {
            return publications;
        }

        for (let i = 0, len = getters.roles.length; i < len; i++) {
            let role = getters.roles[i];
            let prevRole = getters.roles[i - 1];
            if (!prevRole || prevRole.role_type_id === role.role_type_id) {
                if (role.publisher_id === null) {
                    publications = null;
                    break;
                }
                publications.push(
                    ...role.publisher.publications.map((pub) => pub.id)
                );
                continue;
            }
            if (prevRole && prevRole.role_type_id !== role.role_type_id) {
                break;
            }
        }
        return Array.isArray(publications) ? uniq(publications) : publications;
    },
    /**
     * Return the publisher ids we're restricting to for the highest role
     * @todo  2019-02-25  MJL  Maybe allow combining multiple publishers if user has multiple admin roles for each?
     *
     * @return {integer|null|undefined} Publisher id, null if no restrictions, undefined if not valid
     */
    restrictedToPublisherId: (state) => {
        const theRole = _highestRole(state.user);
        if (theRole) {
            return theRole.publisher ? theRole.publisher.id : null;
        } else {
            return null;
        }
    },
    /**
     * Determine if user has unrestricted admin access (that is, their admin privs are not tied to a specific publisher only)
     *
     * @return {Boolean}
     */
    hasUnrestrictedAdmin: (state) => {
        const theRole = _highestRole(state.user);
        return (
            theRole &&
            theRole.publisher === null &&
            theRole.role_type &&
            _roleIsAdminType(theRole.role_type.key)
        );
    },

    sections: (state) => state.sections.data,
    getSectionByRoute: (state, getters) => (route) => {
        return getters.sections.find((section) => section.route === route);
    },
    getSectionByName: (state, getters) => (name) => {
        return getters.sections.find((section) => section.name === name);
    }
};

const mutations = {
    setIsLoggedIn(state, flag) {
        state.isLoggedIn = flag;
    },
    setCanAccessAdministration(state, flag) {
        state.canAccessAdministration = flag;
    },
    setUser(state, data) {
        state.user = data || {};
    },
    setUserType(state, data) {
        state.userType = data;
    },
    setIsImpersonating(state, flag) {
        state.isImpersonating = flag;
    },
    setBalance(state, balance) {
        state.balance = balance;
    },
    setLoadingStatus(state, status) {
        state.loadingStatus = status;
    },
    setTimeframeType(state, data) {
        state.timeframeType = data;
    },
    setTimeframeParam(state, data) {
        state.timeframeParam = data;
    },
    setRecentPostId(state, data) {
        if (data && data.id) {
            state.recentPost.id = data.id;
        } else {
            state.recentPost.id = null;
            state.recentPost.post = {};
        }
    },
    setRecentPost(state, data) {
        if (data) {
            state.recentPost.post = data;
            if (data.id) {
                state.recentPost.id = data.id;
            }
        } else {
            state.recentPost.id = null;
            state.recentPost.post = {};
        }
    },
    loadSectionsRequest(state) {
        state.sections.loading = true;
        state.sections.errors = [];
    },
    loadSectionsSuccess(state, data) {
        state.sections.loading = false;
        state.customSections.data = data.customSections;
        state.sectionsSpecial.data = data.sectionsSpecial;
        state.sections.data = data.sections;
        state.sections.errors = [];
    },
    loadSectionsFailure(state, data) {
        state.sections.loading = false;
        state.sections.errors = data;
    }
};

const actions = {
    /**
     * Load user data from passed object
     *
     * @param {Object} data User object
     */
    loadFromObject({ commit }, data) {
        commit('setLoadingStatus', STATUS.LOADING);
        if (data) {
            commit('setIsLoggedIn', data.isLoggedIn);
            commit('setCanAccessAdministration', data.canAccessAdministration);
            commit('setUser', data.user);
            commit('setUserType', data.userType);
            commit('setIsImpersonating', data.isImpersonating);
            commit('setBalance', data.user ? data.user.credits : null);
            commit('setTimeframeType', data.timeframeType);
            commit('setTimeframeParam', data.timeframeParam);
            commit('setLoadingStatus', STATUS.LOADED);
        } else {
            commit('setLoadingStatus', STATUS.ERROR);
        }
    },

    async loadSections({ commit }, params) {
        commit('loadSectionsRequest');
        try {
            const response = await readSections(params);
            if (response.success) {
                commit('loadSectionsSuccess', {
                    sections: response.sections,
                    sectionsSpecial: response.sectionsSpecial,
                    customSections: response.customSections
                });
            } else {
                commit('loadSectionsFailure', response.errors);
            }
        } catch (error) {
            commit('loadSectionsFailure', [error.message]);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
