<!-- @format -->
<template>
    <standard-modal @close="$emit('close')">
        <div class="box">
            <div class="field">
                <div class="control">
                    <p>
                        A NewsAtomic account with email
                        <span class="has-text-weight-bold">{{ email }}</span>
                        already exists in our system. If you own this NewsAtomic account, you can connect your
                        {{ socialType }} account by entering your password for {{ email }}.
                    </p>
                    <p>
                        <label class="label">Password</label>
                        <input
                            :value="password"
                            class="input"
                            type="password"
                            @input="$emit('update:password', $event.target.value)"
                        />
                    </p>
                    <p v-for="error in errors" :key="error" class="help is-danger">
                        {{ error === 'Unauthorized' ? 'Please enter the correct password' : error }}
                    </p>
                </div>
            </div>
            <div class="field">
                <div class="control">
                    <button class="button is-success" :class="{ 'is-loading': isBusy }" @click="retry">Connect</button>
                </div>
            </div>
        </div>
    </standard-modal>
</template>

<script>
export default {
    props: {
        password: {
            type: String,
            required: true
        },
        email: {
            type: String,
            default: ''
        },
        socialType: {
            type: String,
            default: ''
        },
        isBusy: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        retry() {
            if (!this.password) {
                return;
            }
            this.$emit('retry');
        }
    }
};
</script>
