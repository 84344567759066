<template>
    <div>
        <div class="field na-post-event-detail-wrapper na-post-supplemental-wrapper">
            <span v-if="displayInstances()">
                <span v-for="(instance, index) in postFirstInstance(dataSupplemental.instances)" class="na-post-event-detail">
                    <span>{{ postEventInstanceDateFull(instance) }}</span>
                    <template v-if="instance.theTime">
                        <span>&bull;</span>
                        <span>{{ instance.theTime }}</span>
                    </template>
                    <br>
                </span>
            </span>

            <div v-if="postRemainingInstances(dataSupplemental.instances).length > 1">
                <span class="na-post-event-detail na-link" @click="showAdditionalDates = !showAdditionalDates">
                    Additional Event Dates&nbsp;
                    <span class="icon">
                        <fa-icon v-if="!showAdditionalDates" icon="caret-down" />
                        <fa-icon v-if="showAdditionalDates" icon="caret-up" />
                    </span>
                    <br>
                </span>
                <div v-if="showAdditionalDates">
                    <span v-for="(instance, index) in postRemainingInstances(dataSupplemental.instances)" class="na-post-event-detail">
                        <span>{{ postEventInstanceDateFull(instance) }}</span>
                        <template v-if="instance.theTime">
                            <span>&bull;</span>
                            <span>{{ instance.theTime }}</span>
                        </template>
                        <br>
                    </span>
                </div>
            </div>

            <span v-if="!displayInstances()" class="na-post-event-detail">  <!-- No instances -->
                <span>{{ postContentAtFull }}</span>
                <template v-if="dataSupplemental.eventTime">
                    <span>&bull;</span>
                    <span>{{ dataSupplemental.eventTime }}</span>
                </template>
            </span>
        </div>

        <div class="field na-post-event-detail-wrapper">
            <span class="na-post-event-detail">
                <span v-if="postVenueName(post)">{{ postVenueName(post) }}</span>
            </span>
        </div>

        <div v-if="postContactDetails(post)" class="field na-post-event-detail-wrapper">
            <span class="na-post-event-detail">
                <span>{{ postContactDetails(post) }}</span>
            </span>
        </div>

        <div class="field na-post-event-detail-wrapper">
            <span class="na-post-event-detail">
                <span v-if="dataSupplemental.admissionPrice">{{ dataSupplemental.admissionPrice }}</span>
            </span>
        </div>
    </div>
</template>

<script>
    import { editorMixin } from '@/mixins/EditorMixin.js';
    import { mediaMixin } from '@/mixins/MediaMixin.js';
    import { postMixin } from '@/mixins/PostMixin.js';

    export default {
        mixins: [editorMixin, mediaMixin, postMixin],

        props: {
            post: {
                default: function () {
                    return null;
                }
            },
            dataSupplemental: {
                default: function () {
                    return null;
                }
            }
        },

        data () {
            return {
                showAdditionalDates: false
            };
        },
        methods: {
            /**
             * Determine if event has date instances that should be displayed (e.g. a recurring event type has instances, but we wouldn't necessarily display each one)
             *
             * @return {Boolean} True if instances, false if not
             */
            displayInstances () {
                if (this.dataSupplemental.instances && this.dataSupplemental.instances.length) {
                    // @todo  2017.10.20  MJL  Need to account for recurring types here
                    return true;
                } else {
                    return false;
                }
            }
        }
    }
</script>
