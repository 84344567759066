<!-- @format -->
<template>
    <div class="na-sidebar-dock-container">
        <component :is="publicationSubdomainComponent" :year="year" />
    </div>
</template>

<script>
import { getNow } from '@/helpers/date.js';
import { publicationMixin } from '@/mixins/PublicationMixin.js';
import PublicationDefault from './Publication/Default/Index.vue';
import PublicationKingstonwire from './Publication/Kingstonwire/Index.vue';
import PublicationScDemocrat from './Publication/ScDemocrat/Index.vue';
import PublicationShawangunkJournal from './Publication/ShawangunkJournal/Index.vue';

export default {
    components: {
        PublicationDefault,
        PublicationKingstonwire,
        PublicationScDemocrat,
        PublicationShawangunkJournal
    },

    mixins: [publicationMixin],

    computed: {
        /**
         * Return current year
         *
         * @return {String}
         */
        year() {
            return getNow().format('YYYY');
        }
    }
};
</script>
