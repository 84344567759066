<!-- @format -->
<template>
    <standard-modal @close="$emit('close')">
        <div class="box">
            <div class="field">
                <div class="control">
                    <p class="has-text-weight-bold">Welcome to {{ mainPublicationName }}!</p>
                    <!--
                    <p>
                        Please accept the <a :href="terms" target="_blank">Terms and Conditions</a> to create your account.
                    </p>
                    -->
                    <p>
                        <label class="checkbox">
                            <input
                                :value="acceptTc"
                                type="checkbox"
                                @change="$emit('update:accept-tc', $event.target.checked)"
                            />
                            I Accept the
                            <a :href="terms" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
                        </label>
                    </p>
                    <p v-for="error in errors" :key="error" class="help is-danger">
                        {{ error === 'Unauthorized' ? 'Please enter the correct password' : error }}
                    </p>
                </div>
            </div>
            <div class="field">
                <div class="control">
                    <button
                        class="button is-success"
                        :class="{ 'is-loading': isBusy }"
                        :disabled="!acceptTc"
                        @click="retry"
                    >
                        Create my account
                    </button>
                </div>
            </div>
        </div>
    </standard-modal>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        acceptTc: {
            type: Boolean,
            default: false
        },
        socialType: {
            type: String,
            default: ''
        },
        isBusy: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        ...mapGetters({
            mainPublicationName: 'publication/mainPublicationName'
        }),
        /**
         * Return url to terms and conditions page
         *
         * @return {String}
         */
        terms() {
            return naRoutes.termsConditions;
        }
    },
    methods: {
        retry() {
            if (!this.acceptTc) {
                return;
            }
            this.$emit('retry');
        }
    }
};
</script>
