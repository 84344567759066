<!-- @format -->
<template>
    <standard-modal @close="$emit('close')">
        <div class="box has-text-centered">
            <video-full :video="video" autoplay loop-video show-video />
            <a
                v-if="url"
                :href="url"
                class="button is-success has-text-weight-bold"
                role="button"
                target="_blank"
                rel="noopener noreferrer"
                @click="$emit('ad-click')"
                >{{ buttonText }}</a
            >
        </div>
    </standard-modal>
</template>

<script>
import VideoFull from '@/components/Content/Videos/Full/Index.vue';

export default {
    components: {
        VideoFull
    },

    props: {
        video: {
            type: Object,
            required: true
        },
        url: {
            type: String,
            default: ''
        }
    },

    computed: {
        buttonText() {
            const caption = this.video.caption?.trim();
            if (!caption) {
                return 'Learn More';
            }
            if (caption.length > 30) {
                return caption.substring(0, 30) + '...';
            }
            return caption;
        }
    }
};
</script>
