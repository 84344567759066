<!-- @format -->
<template>
    <div>
        <content-not-found-direct v-if="renderDirect" :reason="reason" @home="goHome" @close="close" />

        <content-not-found-modal v-else :reason="reason" @home="goHome" @close="close" />
    </div>
</template>

<script>
import ContentNotFoundDirect from './Direct.vue';
import ContentNotFoundModal from './Modal.vue';

export default {
    components: {
        ContentNotFoundDirect,
        ContentNotFoundModal
    },
    props: {
        reason: {
            default: function () {
                return 'general';
            }
        },
        renderDirect: {
            // Render directly (vs. in a modal)?
            default: function () {
                return true;
            }
        }
    },
    methods: {
        /**
         * Close the modal
         */
        close() {
            window.location.href = naRoutes.home; // @todo  2018-04-12  MJL  Until functionality completely in place, just send them to home too
            //this.$emit('close');  // Emit to parent
        },
        /**
         * Redirect the user home.  I'm not assuming we're in the main component (yet), so just redirecting for now.
         */
        goHome() {
            window.location.href = naRoutes.home;
        }
    }
};
</script>
