/**
 * @format
 * Subscription helper mixins
 */
export const subscriptionHelperMixin = {
    methods: {
        /**
         * Return savings amount when going with yearly term vs monthly
         *
         * @param {Array}  subscriptionTerms Subscription terms available
         * @param {Object} productType       Product type
         *
         * @return {mixed} savings amount or null if monthly and yearly terms not defined
         */
        savingsYearly(subscriptionTerms, productType) {
            const monthly = subscriptionTerms.find((term) => {
                return (
                    term.productType.id === productType.id &&
                    term.termType.value === 'subscriptionTypeMonthly'
                );
            });

            const yearly = subscriptionTerms.find((term) => {
                return (
                    term.productType.id === productType.id &&
                    term.termType.value === 'subscriptionTypeYearly'
                );
            });

            if (monthly === undefined || yearly === undefined) {
                return null;
            } else {
                let savings =
                    Number.parseFloat(monthly.cost) * 12 -
                    Number.parseFloat(yearly.cost);
                return savings > 0 ? savings : null;
            }
        },

        /**
         * Return "friendly" product type name
         *
         * @param {Object}  productType Product type (may be null if not applicable)
         *
         * @return {String} Name (or null if we couldn't determine)
         */
        productTypeFriendlyName(productType) {
            if (productType && productType.value) {
                switch (productType.value) {
                    case 'subscriptionProductTypeDigital':
                        return 'Digital';
                    case 'subscriptionProductTypePrint':
                        return 'Print';
                    case 'subscriptionProductTypeDigitalPrint':
                        return 'Digital & Print';
                    default:
                        return null;
                }
            } else {
                return null;
            }
        },

        /**
         * Return "friendly" term type name - e.g. "month" for monthly, "year" for yearly
         *
         * @param {Object} termType Term type
         *
         * @return {String} Name (or null if we couldn't determine)
         */
        termTypeFriendlyName(termType) {
            if (termType && termType.value) {
                switch (termType.value) {
                    case 'subscriptionTypeMonthly':
                        return 'month';
                    case 'subscriptionTypeYearly':
                        return 'year';
                    default:
                        return null;
                }
            } else {
                return null;
            }
        },

        /**
         * Determine if we need the user to enter a mailing address (e.g. just for print or both subscription types)
         *
         * @param {Object} terms Terms object
         *
         * @return {Boolean}
         */
        needMailingAddress(productType) {
            switch (productType.value) {
                case 'subscriptionProductTypePrint':
                    return true;
                case 'subscriptionProductTypeDigitalPrint':
                    return true;
                default:
                    return false;
            }
        }
    }
};
