<!-- @format -->
<template>
    <div>
        <div v-if="paymentMethods && paymentMethods.length >= 1" class="columns pb-0 mb-0">
            <div class="column is-6 pb-0">
                <div class="field">
                    <div class="control">
                        <div class="select na-subscription-credit-card">
                            <select v-model="formData.paymentMethod" required>
                                <option v-for="option in availablePaymentMethods" :key="option.id" :value="option.id">
                                    {{ option.text }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template v-if="formData.paymentMethod === -1">
            <div class="columns pb-0 mb-0">
                <div class="column is-half pb-0">
                    <div class="field">
                        <div class="control na-subscription-credit-card" :class="hasInputError(errors, 'ccNumber')">
                            <input
                                v-model="formData.ccNumber"
                                class="input"
                                type="text"
                                placeholder="Credit card number"
                                inputmode="numeric"
                                pattern="[0-9\s]{13,19}"
                                autocomplete="cc-number"
                                maxlength="19"
                                required
                            />
                        </div>
                    </div>
                    <input-error field="ccNumber" :errors="errors" />
                </div>

                <div class="column is-hidden-mobile pb-0">
                    <p class="na-credit-cards">
                        <span class="icon is-medium"><fa-icon :icon="['fab', 'cc-visa']" size="2x" /></span>
                        <span class="icon is-medium"><fa-icon :icon="['fab', 'cc-mastercard']" size="2x" /></span>
                        <span class="icon is-medium"><fa-icon :icon="['fab', 'cc-discover']" size="2x" /></span>
                        <span class="icon is-medium"><fa-icon :icon="['fab', 'cc-amex']" size="2x" /></span>
                    </p>
                </div>
            </div>

            <div class="field is-grouped is-grouped-multiline">
                <div class="control" :class="hasInputError(errors, 'ccExpMonth')">
                    <div class="select">
                        <select v-model="formData.ccExpMonth" required>
                            <option
                                v-for="option in dateOptionPaymentMonths(true, 'Exp MM')"
                                :key="option.id"
                                :value="option.id"
                            >
                                {{ option.text }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="control" :class="hasInputError(errors, 'ccExpYear')">
                    <div class="select">
                        <select v-model="formData.ccExpYear" required>
                            <option
                                v-for="option in dateOptionPaymentYears(true, 'Exp YY')"
                                :key="option.id"
                                :value="option.id"
                            >
                                {{ option.text }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="control" :class="hasInputError(errors, 'ccCode')">
                    <input
                        v-model="formData.ccCode"
                        class="input na-credit-card-cvv"
                        type="text"
                        placeholder="CVV"
                        pattern="[0-9\s]{3,4}"
                        autocomplete="cc-csc"
                        maxlength="4"
                        required
                    />
                </div>
            </div>
            <input-error field="ccExpMonth" :errors="errors" />
            <input-error field="ccExpYear" :errors="errors" />
            <input-error field="ccCode" :errors="errors" />
        </template>
    </div>
</template>

<script>
import { dateMixin } from '@/mixins/DateMixin.js';
import { moneyMixin } from '@/mixins/MoneyMixin.js';
import { subscriptionHelperMixin } from '../../mixins/SubscriptionHelperMixin.js';
import InputError from '@/components/Common/InputError.vue';

export default {
    components: {
        InputError
    },

    mixins: [dateMixin, moneyMixin, subscriptionHelperMixin],

    props: {
        payment: {
            type: Object,
            required: true
        },
        paymentMethods: {
            type: Array,
            required: true
        },
        errors: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            formData: this.payment
        };
    },

    computed: {
        /**
         * Return payment method options
         *
         * @return {Array}
         */
        availablePaymentMethods() {
            const options = this.paymentMethods.map((item) => {
                return {
                    id: item.id,
                    text: `${item.ident} Expires ${item.exp_month}/${item.exp_year}`,
                    isDefault: item.is_default
                };
            });
            options.unshift({
                id: -1,
                text: 'Add new card',
                isDefault: false
            });
            return options;
        }
    },

    watch: {
        paymentMethods: {
            handler(val) {
                // Pick a default payment method
                const defaultMethod = val.find((paymentMethod) => paymentMethod.is_default);
                if (defaultMethod) {
                    this.formData.paymentMethod = defaultMethod.id;
                } else {
                    this.formData.paymentMethod = val.length ? val[0].id : -1;
                }
            },
            immediate: true
        }
    }
};
</script>
