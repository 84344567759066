<!-- @format -->
<template>
    <form class="is-flex is-align-items-center is-flex-wrap-wrap" @submit.prevent="submit">
        <p class="has-text-grey mr-2">{{ count }} matches for</p>
        <div class="field has-addons mr-1 my-1">
            <div class="control">
                <input v-model="form.term" type="search" class="input is-small" />
            </div>
            <div class="control">
                <button type="submit" class="button is-small">
                    <fa-icon icon="search" />
                </button>
            </div>
        </div>
        <div class="field my-1">
            <div class="control">
                <div class="select is-small">
                    <select v-model="form.sort">
                        <option value="newest">Sort by date</option>
                        <option value="relevance">Sort by relevance</option>
                    </select>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    props: {
        term: {
            type: String,
            required: true
        },
        sort: {
            type: String,
            default: 'newest'
        },
        count: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            form: {
                term: this.term,
                sort: this.sort
            }
        };
    },

    watch: {
        term(val) {
            this.form.term = val;
        },
        ['form.sort']() {
            this.submit();
        }
    },

    methods: {
        submit() {
            if (!this.term) {
                return;
            }
            if (this.term === this.form.term && this.sort === this.form.sort) {
                return;
            }

            this.$router.push({
                name: 'search',
                query: {
                    q: this.form.term,
                    sort: this.form.sort
                }
            });
        }
    }
};
</script>
