import Vue from 'vue';
import Vuex from 'vuex';
import adminCore from './modules/admin/admin';
import adminPost from './modules/admin/post';
import adminPublication from './modules/admin/publication';
import app from './modules/app';
import tag from './modules/tag';
import tracking from './modules/tracking';
import user from './modules/user';
import publication from './modules/publication';
import viewer from './modules/viewer';

Vue.use(Vuex);

//const debug = process.env.NODE_ENV !== 'production'
const debug = import.meta.env.MODE !== 'production'

export default new Vuex.Store({
    modules: {
        adminCore,
        adminPost,
        adminPublication,
        app,
        publication,
        tag,
        tracking,
        user,
        viewer,
    },
    strict: debug
});

