<!-- @format -->
<template>
    <div class="is-flex is-flex-direction-column">
        <template v-if="!loading">
            <div v-if="isEndTrial" class="content">
                <p class="is-size-5 has-text-centered mb-0">
                    To use the <span class="has-text-weight-bold">EZRead</span> system,<br />
                    please add credit to your account.<br />
                    <span v-if="!paymentMethods.length">Please enter your payment information here.</span>
                </p>
            </div>
            <div v-else class="content">
                <p class="is-size-5 has-text-centered mb-0">Thank you so much for reading {{ publication.name }}.</p>
                <p class="is-size-5 has-text-centered">
                    To use the <span class="has-text-weight-bold">EZRead</span> system,<br />
                    please add credit to your account.
                </p>
            </div>

            <payment-method-select
                v-if="enteringPaymentMethod"
                ref="paymentMethodSelect"
                v-model="selectedPaymentMethod"
                :payment-methods="paymentMethods"
                :charge-errors="errors"
            />

            <div class="content">
                <div class="buttons is-centered">
                    <button
                        type="button"
                        v-for="option in reloadAmountOptions"
                        :key="option.value"
                        class="button is-success"
                        :class="{ 'is-loading': isReloading }"
                        :disabled="isReloading"
                        @click="reloadAmount(option)"
                    >
                        <span>Add {{ option.text }}</span>
                    </button>
                    <button
                        type="button"
                        class="button is-danger"
                        :class="{ 'is-loading': isReloading }"
                        :disabled="isReloading"
                        @click="$emit('cancel')"
                    >
                        <span class="icon is-small"><fa-icon icon="times" /></span> <span>Cancel</span>
                    </button>
                </div>

                <template v-if="!enteringPaymentMethod">
                    <p v-if="selectedPaymentMethod" class="has-text-centered">
                        Your <span class="has-text-weight-bold">EZRead</span> balance will be replenished with the
                        credit card ending in <strong>{{ lastFourDigits }}</strong
                        >.
                    </p>

                    <p v-if="paymentMethods.length" class="has-text-centered">
                        <span class="has-text-success is-clickable" @click="enteringPaymentMethod = true"
                            >Use a different card <fa-icon icon="chevron-right"
                        /></span>
                    </p>
                </template>

                <p v-if="errors.length || inputErrors.length" class="help is-danger">
                    {{ errors[0] || inputErrors[0] }}
                </p>
            </div>
        </template>
        <p v-else class="has-text-centered">
            <busy-loader />
        </p>
    </div>
</template>

<script>
import { analyticsEventFirePurchaseCredits } from '@/services/analytics_events';
import { accountingMixin } from '@/mixins/AccountingMixin.js';
import { readPaymentMethods, addPaymentCredit } from '@/services/accounting';
import PaymentMethodSelect from './PaymentMethodSelect.vue';

export default {
    components: {
        PaymentMethodSelect
    },

    mixins: [accountingMixin],

    props: {
        isEndTrial: {
            type: Boolean,
            required: true
        },
        post: {
            type: Object,
            required: true
        },
        publication: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            loading: true,
            errors: [],
            inputErrors: [],
            isReloading: false,
            paymentMethods: [],
            enteringPaymentMethod: false,
            selectedPaymentMethod: null
        };
    },

    computed: {
        cost() {
            return this.post._cost;
        },
        reloadAmountOptions() {
            return this.doLoadOptionReloadAmounts(this.userGetBalance, this.cost);
        },
        lastFourDigits() {
            if (this.selectedPaymentMethod) {
                const ident = this.selectedPaymentMethod.ident;
                return ident.substring(ident.length - 4, ident.length);
            }
            return '';
        }
    },

    async created() {
        this.loading = true;
        await this.getPaymentMethods();
        if (!this.paymentMethods.length) {
            this.enteringPaymentMethod = true;
        }
        this.selectedPaymentMethod = this.paymentMethods.find((paymentMethod) => paymentMethod.is_default);
        // If for some reason, we couldn't find a default payment method, just use the first:
        if (this.selectedPaymentMethod === undefined){
            this.selectedPaymentMethod = this.paymentMethods[0];
        }
        this.loading = false;
    },

    methods: {
        async reloadAmount(option) {
            this.isReloading = true;
            this.errors = [];
            this.inputErrors = [];

            // If there is no payment method, we need to create it first.  That never actually gets done in PaymentMethodForm!
            let paymentMethodId = this.selectedPaymentMethod?.id;
            if (!paymentMethodId || paymentMethodId === undefined) {
                if (this.$refs.paymentMethodSelected === undefined) {
                    paymentMethodId = await this.$refs.paymentMethodSelect.submitNewPaymentMethod();
                    if (!paymentMethodId) {
                        this.isReloading = false;
                        return;
                    }
                    // Refresh payment method list
                    this.getPaymentMethods();
                } else {
                    this.errors = [ 'You do not appear to have a usable payment method on file - please check your payment methods' ];
                }
            } else {
                console.log('yep, thas');
            }

            const data = {
                paymentMethod: paymentMethodId,
                reloadAmount: option.value
            };
            const response = await addPaymentCredit(data);
            if (response.success) {
                analyticsEventFirePurchaseCredits();
                this.$emit('success');
            } else {
                this.errors = response.errors ?? [
                    'Unable to process: problem processing your request to increase credit.'
                ];
                this.inputErrors = response.inputErrors ?? [];
            }
            this.isReloading = false;
        },

        async getPaymentMethods() {
            const response = await readPaymentMethods({ activeOnly: true, not_expired: true });
            if (response.success) {
                this.paymentMethods = response.paymentMethods;
                return response.paymentMethods;
            }
        }
    }
};
</script>
