<!-- @format -->
<template>
    <span v-if="post">
        <a
            :href="theLink"
            target="_blank"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Share on Twitter"
            rel="noopener noreferrer"
            @click="trackIt"
        >
            <span class="icon na-icon-span na-icon-twitter">
                <fa-icon :icon="['fab', 'twitter-square']" />
            </span>
        </a>
    </span>
</template>

<script>
import { mapGetters } from 'vuex';
import { postMixin } from '@/mixins/PostMixin.js';

export default {
    mixins: [postMixin],

    props: {
        post: {
            default: function () {
                return null;
            }
        }
    },

    data() {
        return {};
    },

    computed: {
        ...mapGetters({
            mainPublicationName: 'publication/mainPublicationName',
            mainPublicationUrl: 'publication/mainPublicationUrl'
        }),

        theLink() {
            return this.targetLink();
        }
    },

    methods: {
        targetLink() {
            const postName = this.post.directLinkShort
                ? this.post.directLinkShort
                : this.postLinkUrl(this.postGetFirstIdentifier(this.post.post_identifiers), true);
            const text =
                'I thought you would enjoy this article from ' +
                this.mainPublicationName +
                '! (' +
                this.mainPublicationUrl +
                ')';
            const hashtags = 'newsatomic';
            return (
                'https://www.twitter.com/intent/tweet?url=' +
                encodeURI(postName) +
                '&text=' +
                encodeURI(text) +
                '&hashtags=' +
                encodeURI(hashtags)
            );
        },
        trackIt() {
            this.$emit('shared', {
                shareTo: 'shareToTypeTwitter',
                url: this.targetLink()
            });
        }
    }
};
</script>
