<!-- @format -->
<template>
    <field name="acceptTc" :errors="errors">
        <p class="control">
            <label class="checkbox">
                <input v-model="acceptTc" type="checkbox" required />
                I Accept the <a :href="terms" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
            </label>
        </p>
    </field>
</template>

<script>
import InputError from '@/components/Common/InputError.vue';

export default {
    components: {
        InputError
    },

    props: {
        formData: {
            type: Object,
            required: true
        },
        errors: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            acceptTc: false
        };
    },

    computed: {
        /**
         * Return url to terms and conditions page
         *
         * @return {String}
         */
        terms() {
            return naRoutes.termsConditions;
        }
    },
    watch: {
        acceptTc() {
            this.updateFormData();
        }
    },
    mounted() {
        this.updateFormData(); // Init
    },
    methods: {
        /**
         * Update form data for parent
         */
        updateFormData() {
            this.$emit('update:formData', {
                acceptTc: this.acceptTc
            });
        },
        /**
         * Return array of errors for given field
         *
         * @param {String} field Field name
         *
         * @return {Array}
         */
        fieldErrors(field) {
            const errors = [];
            this.errors.forEach((item) => {
                const items = item.split('|');
                if (items && items[0] === field) {
                    errors.push(items[1]);
                }
            });

            return errors;
        },
        /**
         * Determine if any errors
         *
         * @return {Boolean}
         */
        hasErrors() {
            return this.fieldErrors('acceptTc').length > 0;
        }
    }
};
</script>
