/**
 * @format
 * Subscription related API functions
 * Abstracting these out from DataMixin.js.
 */
import axios from 'axios';

/**
 * Validate and register a subscription
 *
 * @param {Object} data Signup data
 *
 * @return Promise for resolve, reject
 */
export const registerSubscription = function (data) {
    return axios
        .post(naRoutes.registerSubscription, {
            data
        })
        .then((response) => response.data);
};

/**
 * Load subscriptions for user
 *
 * @return Promise for resolve, reject
 */
export const readSubscriptions = function () {
    return axios
        .post(naRoutes.readSubscriptions, {
            max: 100 // Not implemented yet
        })
        .then((response) => response.data);
};

/**
 * Register a gift subscription
 *
 * @param {Object} data
 *
 * @return Promise for resolve, reject
 */
export const registerGiftSubscription = function (data) {
    return axios
        .post(naRoutes.registerGiftSubscription, {
            data
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Update gift subscription notification flag
 *
 * @param {Object} data
 *
 * @return Promise for resolve, reject
 */
export const notifyFlagGiftSubscription = function (data) {
    return axios
        .post(naRoutes.notifyFlagGiftSubscription, {
            data
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Activate a gift subscription
 *
 * @param {Object} data
 *
 * @return Promise for resolve, reject
 */
export const activateGiftSubscription = function (data) {
    return axios
        .post(naRoutes.activateGiftSubscription, {
            data
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Validate and register an external subscription
 *
 * @param {Object} data Signup data
 *
 * @return Promise for resolve, reject
 */
 export const registerExternalSubscription = function (data) {
    return axios
        .post(naRoutes.registerExternalSubscription, {
            data
        })
        .then((response) => response.data);
};

/**
 * Load subscriptions for user
 *
 * @return Promise for resolve, reject
 */
export const readExternalSubscriptions = function () {
    return axios
        .post(naRoutes.readExternalSubscriptions, {
            max: 100 // Not implemented yet
        })
        .then((response) => response.data);
};
