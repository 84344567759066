<!-- @format -->
<template>
    <div>
        <div class="columns is-mobile">
            <div class="column">
                <show-error :errors="errors"></show-error>
            </div>
        </div>

        <div v-if="comments && comments.length && isLoaded" id="na-comment-list">
            <template v-for="(comment, index) in comments">
                <single-comment :comment="comment" :now="timerNow"></single-comment>
                <div v-if="index < comments.length - 1">
                    <hr class="na-post-comment-divider" />
                </div>
            </template>
            <div id="na-comment-list-bottom"></div>

            <simple-pagination
                :include-per-page="false"
                :page-number="page"
                :count-per-page="limit"
                :count-total="totalCount"
                nav-class="is-small"
                @change-page="changePage"
            ></simple-pagination>
        </div>
    </div>
</template>

<script>
import { postMixin } from '@/mixins/PostMixin.js';
import ShowError from '@/components/Common/Error.vue';
import SimplePagination from '@/components/Common/SimplePagination.vue';
import SingleComment from './Comment.vue';
import { readCommentsPost } from '@/services/post.js';

export default {
    components: {
        ShowError,
        SimplePagination,
        SingleComment
    },

    mixins: [postMixin],

    props: {
        post: {
            type: Object,
            default: function () {
                return null;
            }
        },
        commentListRevision: {
            type: Number,
            default: function () {
                return 0;
            }
        }
    },

    data() {
        return {
            isBusy: false,
            isLoaded: false,
            errors: [],
            comments: [],
            page: 1,
            limit: 10,
            totalCount: 0,
            timer: null,
            timerNow: 0,
            timerWait: 1000 // Update every second or so
        };
    },

    watch: {
        /**
         * If comment list revision changes, update the list, reset page, etc.
         */
        commentListRevision(newVal) {
            this.page = 1;
            this.load();
        }
    },

    mounted() {
        this.load(() => {
            this.timer = setInterval(() => {
                this.timerNow = Date.now();
            }, this.timerWait);
        });
    },

    beforeDestroy() {
        if (this.timer) {
            try {
                clearInterval(this.timer);
            } catch (e) {
                // Ignore
            }
        }
    },

    methods: {
        /**
         * Load comments
         */
        async load(callback) {
            this.isLoaded = false;
            this.isBusy = true;
            this.errors = [];
            this.totalCount = null; // Total count is not known
            let data = {
                postId: this.post.id,
                includePrivate: false,
                excludeUnapproved: true,
                limit: 10,
                offset: (this.page - 1) * this.limit
            };

            const response = await readCommentsPost(data);
            if (response.success) {
                this.comments = response.comments;
                this.totalCount = response.totalCount;
                this.$emit('comment-count-updated', this.totalCount);
            } else {
                this.errors = response.errors;
            }
            this.isLoaded = true;
            this.isBusy = false;
            if (callback) {
                callback();
            }
        },
        /**
         * Reload
         */
        reload() {
            this.load(() => {
                this.$nextTick(() => {
                    this.scrollComments();
                });
            });
        },
        /**
         * Scroll to comment list
         */
        scrollComments() {
            const element = document.getElementById('na-comment-list-bottom');
            if (element !== null) {
                element.scrollIntoView(true);
            }
        },
        /**
         * Change page number
         *
         * @param {Integer} page Page number
         */
        changePage(page) {
            this.page = page;
            this.reload(); // Get next set
        }
    }
};
</script>
