<!-- @format -->
<template>
    <span v-if="post">
        <a
            v-clipboard:copy="theLink"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Share to Clipboard"
            @click="trackIt"
        >
            <span
                class="icon na-icon-span na-icon-clipboard is-tooltip-right"
                :class="{ 'is-tooltip-active': showCopied, tooltip: showCopied }"
                data-tooltip="Copied"
            >
                <fa-icon icon="clipboard" />
            </span>
        </a>
    </span>
</template>

<script>
import { postMixin } from '@/mixins/PostMixin.js';

export default {
    mixins: [postMixin],

    props: {
        post: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            showCopied: false,
            timer: null,
            timerLength: 2000
        };
    },

    computed: {
        theLink() {
            return this.targetLink();
        }
    },

    methods: {
        /**
         * Return url
         *
         * @return {String}
         */
        targetLink() {
            return this.post.directLinkShort
                ? this.post.directLinkShort
                : this.postLinkUrl(this.postGetFirstIdentifier(this.post.post_identifiers), false);
        },
        /**
         * Clear timer
         */
        clearTimer() {
            if (this.timer) {
                try {
                    clearTimeout(this.timer);
                } catch (exception) {
                    // Ignore any exception
                }
            }
        },
        /**
         * Set timer
         */
        setTimer() {
            this.clearTimer(); // Clear any old timer

            this.showCopied = true;
            this.timer = setTimeout(() => {
                this.showCopied = false;
            }, this.timerLength);
        },
        /**
         * Track the share
         */
        trackIt() {
            this.setTimer();
            this.$emit('shared', {
                shareTo: 'shareToTypeClipboard',
                url: this.targetLink()
            });
        }
    }
};
</script>
