<!-- @format -->
<template>
    <div v-if="getCount" class="na-post-video-count na-link" @click="clicked">
        <span>+{{ getCount }} <fa-icon icon="film" /></span>
    </div>
</template>

<script>
export default {
    props: {
        media: {
            type: Array,
            default: () => []
        },
        // Subtract one from count?  We only need to do this if (for example), we're displaying a video for preview - the count should show one less.  BUT if we're showing an image for preview, then nothing should be subtracted
        minusOne: {
            type: [Boolean, Number],
            default: false
        }
    },
    computed: {
        /**
         * Return count
         *
         * @return {Integer}
         */
        getCount() {
            if (this.media.length) {
                return this.media.length - (this.minusOne ? 1 : 0);
            } else {
                return 0;
            }
        }
    },
    methods: {
        /**
         * Item was clicked
         */
        clicked() {
            this.$emit('clicked');
        }
    }
};
</script>
