<!-- @format -->
<template>
    <div
        v-if="post && canAccessAdministration && userAdminCanAccessPublicationId(post.publication_id)"
        class="columns is-centered"
    >
        <div class="column is-8 na-viewer-admin-box">
            <p class="has-text-left is-size-4 has-text-weight-bold">
                <span>Post Administration (id {{ post.id }})</span>
                <button
                    v-if="
                        userHighestRole.role_type.key !== 'roleAdminPost1' ||
                        (post.user_id === userCurrent.id && post.post_status.key === 'postStatusReview')
                    "
                    class="button is-success is-pulled-right"
                    @click="editPost"
                >
                    Edit this post
                </button>
            </p>

            <admin-post :post="post" />
        </div>
    </div>
</template>

<script>
import AdminPost from './Post/Index.vue';

export default {
    components: {
        AdminPost
    },

    props: {
        post: {
            type: Object,
            required: true
        }
    },

    methods: {
        editPost() {
            this.clientRedirectTo(`/admin/posts/edit/${this.post.id}?init_publication_id=${this.post.publication.id}`);
        },
        close() {
            this.$emit('close');
        }
    }
};
</script>
