<!-- @format -->
<template>
    <div class="is-flex is-flex-direction-column">
        <div class="columns is-centered">
            <div class="column" :class="alignment === 'center' && 'is-two-thirds has-text-centered'">
                <p>
                    We offer monthly and yearly subscriptions to these three publications. Choose the publication and the
                    plan that is right for you.
                </p>
            </div>
        </div>

        <div class="columns" :class="alignment === 'center' && 'is-centered'">
            <div class="column is-one-third">
                <div class="sub-publication-box">
                    <img height="96" width="auto" :src="imageUrl('SJlogo-2lines.png')" alt="Shawangunk Journal" />
                    <p class="my-2 has-text-centered">Print & Digital</p>
                    <router-link class="button is-success" :to="{ name: 'sjsubscribe' }"
                        >More Subscription Info</router-link
                    >
                </div>
            </div>

            <div class="column is-one-third">
                <div class="sub-publication-box">
                    <img height="96" width="auto" :src="imageUrl('KW-gift-sub-start.png')" alt="Kingston Wire" />
                    <p class="my-2 has-text-centered">Digital Only</p>
                    <router-link class="button is-success" :to="{ name: 'kwsubscribe' }"
                        >More Subscription Info</router-link
                    >
                </div>
            </div>

            <div class="column is-one-third">
                <div class="sub-publication-box">
                    <img
                        height="96"
                        width="auto"
                        src="https://newsatomic.com/storage/publication13/0.5837740015555110805cb737288e8631.17226140/BIYBQDP6VoZ2FKvuhOl7gLjWCmhmi59yzZOGukcG.gif"
                        alt="Kingston Wire"
                    />
                    <p class="my-2 has-text-centered">Print & Digital</p>
                    <router-link class="button is-success" :to="{ name: 'misubscribe' }"
                        >More Subscription Info</router-link
                    >
                </div>
            </div>
        </div>

        <p :class="alignment === 'center' && 'has-text-centered'">
            <router-link :to="{ name: 'gift-subscription-start' }">*Gift subscriptions</router-link> are also available.
        </p>
    </div>
</template>

<script>
import { mediaMixin } from '@/mixins/MediaMixin';

export default {
    mixins: [mediaMixin],

    props: {
        alignment: {
            type: String,
            default: 'left'
        }
    }
};
</script>
