<!-- @format -->
<template>
    <div class="columns">
        <div class="column is-6-tablet">
            <input-name :default-address="defaultAddress" :errors="errors" :form-data.sync="formDataName" />

            <input-address
                :default-address="defaultAddress"
                :errors="errors"
                :form-data.sync="formDataAddress"
                :option-states="optionStates"
            />
        </div>
    </div>
</template>

<script>
import { dataHelperMixin } from '@/mixins/DataHelperMixin.js';
import InputAddress from './Address.vue';
import InputName from './Name.vue';

export default {
    components: {
        InputAddress,
        InputName
    },

    mixins: [dataHelperMixin],

    props: {
        formData: {
            type: Object,
            required: true
        },
        defaultAddress: {
            type: Object,
            required: true
        },
        // Any errors when we tried to register
        errors: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            formDataName: {
                first_name:
                    this.formData && this.formData.firstName
                        ? this.formData.firstName
                        : this.defaultAddress && this.defaultAddress.user && this.defaultAddress.user.first_name
                        ? this.defaultAddress.user.first_name
                        : null,
                last_name:
                    this.formData && this.formData.lastName
                        ? this.formData.lastName
                        : this.defaultAddress && this.defaultAddress.user && this.defaultAddress.user.last_name
                        ? this.defaultAddress.user.last_name
                        : null,
                organization:
                    this.formData && this.formData.organization
                        ? this.formData.organization
                        : this.defaultAddress && this.defaultAddress.user && this.defaultAddress.user.organization
                        ? this.defaultAddress.user.organization
                        : null
            },
            formDataAddress: {
                street_1:
                    this.formData && this.formData.street_1
                        ? this.formData.street_1
                        : this.defaultAddress && this.defaultAddress.address && this.defaultAddress.address.street_1
                        ? this.defaultAddress.address.street_1
                        : null,
                street_2:
                    this.formData && this.formData.street_2
                        ? this.formData.street_2
                        : this.defaultAddress && this.defaultAddress.address && this.defaultAddress.address.street_2
                        ? this.defaultAddress.address.street_2
                        : null,
                city:
                    this.formData && this.formData.city
                        ? this.formData.city
                        : this.defaultAddress && this.defaultAddress.address && this.defaultAddress.address.city
                        ? this.defaultAddress.address.city
                        : null,
                state:
                    this.formData && this.formData.stateId
                        ? this.formData.stateId
                        : this.defaultAddress && this.defaultAddress.address && this.defaultAddress.address.state_id
                        ? this.defaultAddress.address.state_id
                        : null,
                zipcode:
                    this.formData && this.formData.zipcode
                        ? this.formData.zipcode
                        : this.defaultAddress && this.defaultAddress.address && this.defaultAddress.address.zipcode
                        ? this.defaultAddress.address.zipcode
                        : null
            },
            optionStates: []
        };
    },

    watch: {
        formDataName: {
            handler() {
                this.updateFormData();
            },
            deep: true
        },
        formDataAddress: {
            handler() {
                this.updateFormData();
            },
            deep: true
        }
    },

    async created() {
        this.optionStates = await this.dataHelperLoadLocationStates({ id: null, value: null, text: 'State' });
        this.updateFormData(); // Init
    },

    methods: {
        /**
         * Update form data for parent
         */
        updateFormData() {
            this.$emit('update:formData', {
                name: this.formDataName,
                address: this.formDataAddress
            });
        }
    }
};
</script>
