<!-- @format -->
<template>
    <address>
        <div v-if="hasMedia && isShowAuthorMedia(post)" class="na-post-author-headshot-wrapper">
            <figure class="image">
                <picture>
                    <source v-for="set in sourceSet" :key="set.mime" :type="set.mime" :srcset="set.url" />
                    <img
                        class="na-post-author-headshot"
                        :src="authorMediaUrl"
                        :alt="altText"
                        :title="authorMediaTitle"
                        :width="authorMedia.width"
                        :height="authorMedia.height"
                    />
                </picture>
            </figure>
        </div>
        <h3
            class="na-post-author"
            :class="{ 'na-sponsored': isPostSponsored(post) }"
        >
            {{ `${showBy ? 'By ' : ''}${postAuthorFormattedList(post.post_authors)}` }}
        </h3>
    </address>
</template>

<script>
import { postMixin } from '@/mixins/PostMixin.js';
import { mediaMixin } from '@/mixins/MediaMixin.js';

export default {
    mixins: [postMixin, mediaMixin],

    props: {
        post: {
            type: Object,
            required: true
        }
    },

    computed: {
        showBy() {
            return this.isShowAuthorBy(this.post);
        },
        hasMedia() {
            return this.doesAuthorHaveMedia(this.post);
        },
        author() {
            return this.post.post_authors[0]?.author;
        },
        authorMedia() {
            if (!this.author) {
                return;
            }
            return this.author.media[0];
        },
        authorMediaUrl() {
            if (!this.authorMedia) {
                return;
            }
            return this.mediaUrl(this.authorMedia);
        },
        sourceSet() {
            return this.getMediaSourceSet(this.authorMedia);
        },
        altText() {
            if (!this.authorMedia) {
                return;
            }
            return this.mediaAlt(this.authorMedia, `A photo of the post author ${this.author.first_name}.`);
        },
        authorMediaTitle() {
            if (!this.authorMedia) {
                return;
            }
            return this.mediaTitle(this.authorMedia);
        }
    }
};
</script>
