<!-- @format -->
<template>
    <div class="columns">
        <div class="column">
            <div id="na-post-video-container" class="has-text-centered"></div>
            <div v-if="isShowCaption" class="na-post-video-caption-card">
                <span v-if="mediaCaption(video)" class="na-post-video-caption">{{ mediaCaption(video) }}</span>
                <span class="na-post-video-credit">{{ mediaCredit(video) }}</span>
            </div>

            <div class="na-post-video-caption-card">
                <div class="na-post-video-meta">
                    <video-meta :video-player="videoPlayer" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mediaMixin } from '@/mixins/MediaMixin.js';
import { postMixin } from '@/mixins/PostMixin.js';
import Player from '@vimeo/player';
import VideoMeta from '../Meta/Index.vue';

export default {
    components: {
        VideoMeta
    },

    mixins: [mediaMixin, postMixin],

    props: {
        video: {
            // This is the video object, e.g. post.post_media_videos[0]
            default: function () {
                return null;
            }
        },
        showVideo: {
            default: function () {
                return false;
            }
        },
        autoplay: {
            // Autoplay?
            default: function () {
                return false;
            }
        },
        loopVideo: {
            // Loop?
            default: function () {
                return false;
            }
        },
        isShowCaption: {
            default: function () {
                return false;
            }
        }
    },

    data() {
        return {
            videoPlayer: null
        };
    },

    watch: {
        showVideo() {
            this.handleShowVideo();
        }
    },

    mounted() {
        if (this.showVideo) {
            this.handleShowVideo();
        }
    },

    methods: {
        /**
         * Unload video player
         */
        unloadPlayer(callback) {
            if (this.videoPlayer) {
                this.videoPlayer
                    .unload()
                    .then(() => {
                        callback();
                    })
                    .catch((error) => {
                        callback();
                    });
            } else {
                // Nothing to unload...
                callback();
            }
        },
        /**
         * Destroy video player
         */
        destroyPlayer(callback) {
            if (this.videoPlayer) {
                this.unloadPlayer(() => {
                    this.videoPlayer
                        .destroy()
                        .then(() => {
                            this.videoPlayer = null;
                            callback();
                        })
                        .catch((error) => {
                            this.videoPlayer = null;
                            callback();
                        });
                });
            } else {
                // Nothing to destroy...
                callback();
            }
        },
        /**
         * Handle showing video or not.  Called when showVideo flag is toggled and upon initial mount.
         */
        handleShowVideo() {
            if (this.showVideo) {
                this.$nextTick(() => {
                    // Make sure DOM is updated first
                    if (this.video) {
                        this.doShowVideo(this.video);
                    }
                });
            } else {
                // Hide video if shown
                this.destroyPlayer();
            }
        },
        /**
         * Show video
         */
        doShowVideo(video) {
            const previewDuration = 5; // Seconds
            const self = this;
            const options = {
                id: video.ident,
                //width: video.width ? video.width : 640,
                //height: video.height ? video.height : 320,
                loop: this.loopVideo,
                title: false,
                byline: false,
                portrait: false,
                autoplay: this.autoplay,
                responsive: true
            };

            this.videoPlayer = new Player('na-post-video-container', options);
            this.videoPlayer
                .addCuePoint(previewDuration, {
                    //customKey: 'customValue'
                })
                .catch(function (error) {
                    switch (error.name) {
                        case 'UnsupportedError':
                            // cue points are not supported with the current player or browser
                            break;
                        case 'RangeError':
                            // the time was less than 0 or greater than the video’s duration
                            break;
                        default:
                            // some other error occurred
                            break;
                    }
                });

            //player.setVolume(0);

            this.videoPlayer.on('play', function () {});

            this.videoPlayer.on('cuepoint', function () {
                self.videoPlayer.pause();
                self.videoPlayer.loadVideo(video.ident);
                self.tellVideoPreview();
            });
        },
        /**
         * Tell the user that this is just a preview of the video
         */
        tellVideoPreview() {
            this.$swal({
                title: 'Preview Over',
                text: 'Please read the full article to view the entire video',
                type: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Continue',
                confirmButtonClass: 'button is-success',
                buttonsStyling: false
            }).then(
                () => {
                    // Reload video
                    //self.doShowVideo(self.video);
                },
                () => {
                    // Reload video
                    //self.doShowVideo(self.video);
                }
            );
        }
    }
};
</script>
