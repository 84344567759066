/**
 * @format
 * Vimeo video related API functions
 */
import axios from 'axios';

/**
 * Check if a given vimeo video exists
 *
 * @param {Object} data
 *
 * @return Promise for resolve, reject
 */
export function vimeoIsVideoExists(id) {
    return vimeoGetMeta(id);
}

/**
 * Get a given thumbnail url from vimeo meta data.  Sizes don't have a type, so we're going to determine what qualifies as small, medium, large
 *
 * @param {Object} meta Vimeo meta data
 * @param {String} size Size desired.  'small', 'medium', 'large'
 *
 * @return {String} URL to thumbnail - null if we couldn't determine
 */
export function vimeoGetMetaThumbnail(meta, size) {
    let url = null;
    if (
        meta &&
        meta.pictures &&
        meta.pictures.sizes &&
        meta.pictures.sizes.length
    ) {
        const block = meta.pictures.sizes;
        const match = block.find((thumb) => {
            switch (size) {
                case 'small':
                    return thumb.width >= 100;
                case 'medium':
                    return thumb.width >= 200;
                case 'large':
                    return thumb.width >= 640;
            }
        });
        if (match !== undefined) {
            url = match.link ? match.link : null;
        }
    }

    return url;
}

/**
 * Get video duration from vimeo meta data.
 *
 * @param {Object} meta Vimeo meta data
 *
 * @return {Integer} length in seconds
 */
export function vimeoGetMetaDuration(meta) {
    return meta && meta.duration ? meta.duration : null;
}

/**
 * Grab meta for given image (contains stuff like video title, thumbnail)
 *
 * @param {Object} data
 *
 * @return Promise for resolve, reject
 */
export function vimeoGetMeta(id) {
    return axios.get('/vimeo/meta/' + id).then((response) => response.data);
}
