<!-- @format -->
<template>
    <div>
        <p class="na-sidebar-quicklinks-text">
            Contact us if you are a publisher, small or not-so-small, and want to provide your readers something new and
            exciting.
        </p>
        <div class="na-sidebar-quicklinks-address">
            <p class="na-sidebar-quicklinks-text">122 Canal Street / P.O. Box 669</p>
            <p class="na-sidebar-quicklinks-text">Ellenville, NY 12428</p>
            <p class="na-sidebar-quicklinks-text">(845) 647-9190</p>
            <p class="na-sidebar-quicklinks-text">
                <a href="mailto:info@newsatomic.com">info@gunkjournal.com</a>
            </p>
            <social-media-icons-group class="na-sidebar-quicklinks-text has-background-white"
            ></social-media-icons-group>
        </div>
    </div>
</template>

<script>
import SocialMediaIconsGroup from '@/components/Content/Social-Media/Icons/Group.vue';

export default {
    components: {
        SocialMediaIconsGroup
    }
};
</script>
