/**
 * @format
 * Post related mixins
 *
 * @todo 2018-02-15  MJL  Some of these were specifically related to posts, but now have expanded to other functionality.  Should be moved to their own mixin.  e.g. getOptionValue().
 */
import moment from 'moment';
import { getNow, toDate } from '@/helpers/date.js';
import { formatDuration } from '@/helpers/media';

export const postMixin = {
    computed: {
        /**
         * Return formatted content date for display
         *
         * @return {String} Date string
         */
        postContentAt() {
            if (!this.post) {
                return '';
            }
            // If date is today, show it in # hours/minutes ago - otherwise, just show the date
            moment.updateLocale('en', {
                // Update locale because we want to alter the text displayed
                relativeTime: {
                    s: '1 sec',
                    ss: '%d secs',
                    m: '1 min',
                    mm: '%d mins',
                    h: '1 hr',
                    hh: '%d hrs'
                }
            });
            //return this.post.content_at ? (moment(this.post.content_at).fromNow()) : '';

            // Special case - if date is today and time is exactly midnight, just say today
            if (
                this.post.content_at &&
                toDate(this.post.content_at).format('YYYY-MM-DD HH:mm:ss') ===
                    getNow().format('YYYY-MM-DD' + ' 00:00:00') &&
                this.isMorphDateToToday(this.post)
            ) {
                // Revert locale setting
                moment.updateLocale('en', null);
                return 'today';
            }

            // Don't use fromNow if we shouldn't for this post (e.g. don't do for events)
            const text = this.post.content_at
                ? toDate(this.post.content_at).format('YYYY-MM-DD') ===
                      getNow().format('YYYY-MM-DD') &&
                  this.isMorphDateToToday(this.post)
                    ? toDate(this.post.content_at).fromNow()
                    : toDate(this.post.content_at).format('M/D/YYYY')
                : '';
            moment.updateLocale('en', null);
            return text;
        },
        /**
         * Return formatted content date (including year) for display
         *
         * @return {String} Date string
         */
        postContentAtFull() {
            if (!this.post) {
                return '';
            }
            return this.post.content_at
                ? toDate(this.post.content_at).format('dddd, MMMM Do Y')
                : '';
        },

        isShowPostTypeLabel() {
            const postTypes = [
                'postTypeArticle',
                'postTypeNewsShort',
                'postTypeOpEd',
                'postTypeColumn',
                'postTypeLetter'
            ];
            if (this.post && postTypes.includes(this.post.post_type.key)) {
                return true;
            }
            return false;
        },
    },

    methods: {
        /**
         * Return value field (from the options table) of the post type for the given Id; null if none or unknown
         *
         * @param {Array}   optionPostTypes Post types
         * @param {Integer} postTypeId      Post type Id to be found
         *
         * @return {mixed} Value or null if none/not found
         */
        getPostTypeValue(optionPostTypes, postTypeId) {
            if (optionPostTypes) {
                const result = optionPostTypes.find((item) => {
                    return item.id === postTypeId;
                });
                return result ? result.value : null;
            } else {
                return null;
            }
        },
        /**
         * Return ID field (from the options table) of the post type for the given post type value; null if none or unknown
         *
         * @param {Array}  optionPostTypes Post types
         * @param {String} postTypeValue   Post type value (e.g. 'postTypeAd')
         *
         * @return {mixed} Name or null if none/not found
         */
        getPostTypeId(optionPostTypes, postTypeValue) {
            if (optionPostTypes) {
                const result = optionPostTypes.find((item) => {
                    return item.value === postTypeValue;
                });
                return result ? result.id : null;
            } else {
                return null;
            }
        },
        /**
         * Return ID field (from the options table) of the post status for the given post type value; null if none or unknown
         *
         * @param {Array}  optionPostStatuses Post statuses
         *
         * @return {mixed} ID or null if none/not found
         */
        getPostStatusId(optionPostStatuses, value = 'postStatusPublished') {
            if (optionPostStatuses) {
                const result = optionPostStatuses.find((item) => {
                    return item.value === value;
                });
                return result ? result.id : null;
            } else {
                return null;
            }
        },
        /**
         * Return ID field (from the options table) of the post category for the given post category value; null if none or unknown
         *
         * @param {Array}  optionPostCategories Post categories
         * @param {String} postCategoryValue    Post category value (e.g. 'postCategoryCrime')
         *
         * @return {mixed} ID or null if none/not found
         */
        getPostCategoryId(optionPostCategories, postCategoryValue) {
            if (optionPostCategories) {
                const result = optionPostCategories.find((item) => {
                    return item.value === postCategoryValue;
                });
                return result ? result.id : null;
            } else {
                return null;
            }
        },
        /**
         * Return ID field (from the options table) of the post publication for the given post publication name; null if none or unknown
         *
         * @param {Array}  optionPublications Publications
         * @param {String} publicationName    Publication name
         *
         * @return {mixed} ID or null if none/not found
         */
        getPublicationId(optionPublications, publicationName) {
            if (optionPublications) {
                const result = optionPublications.find((item) => {
                    return item.text === publicationName;
                });
                return result ? result.id : null;
            } else {
                return null;
            }
        },
        /**
         * General function to return the text for a given option type.  e.g. optionPostTypes has a text field we may want.
         *
         * @param {Array}   options Options table
         * @param {Integer} id      Id we're looking for
         *
         * @return {mixed} Text or null if none/not found
         */
        getOptionText(options, id) {
            if (options) {
                const result = options.find((item) => {
                    return item.id === id;
                });
                return result ? result.text : null;
            } else {
                return null;
            }
        },
        /**
         * General function to return the value for a given option type.
         *
         * @param {Array}   options Options table
         * @param {Integer} id      id we're looking for
         *
         * @return {mixed} Value or null if none/not found
         */
        getOptionValue(options, id) {
            if (options) {
                const result = options.find((item) => {
                    return item.id === id;
                });
                return result ? result.value : null;
            } else {
                return null;
            }
        },
        /**
         * General function to return the id for a given option value.
         *
         * @param {Array}  options Options table
         * @param {String} value   Value we're looking for
         *
         * @return {mixed} Value or null if none/not found
         */
        getOptionIdFromValue(options, value) {
            if (options) {
                const result = options.find((item) => {
                    return item.value === value;
                });
                return result ? result.id : null;
            } else {
                return null;
            }
        },
        /**
         * Find next "issue" ID (based on issue on date)
         *
         * @param {Array}   optionIssues Issues
         * @param {String}  issueDate    Issue date to match on (default is today) - should be in YYYY-MM-DD format
         * @param {Integer} issueId      Current issue id (may be null if not yet assigned)
         * @param {Boolean} forceReset   Force reset to next issue id even if current is already set and valid?
         *
         * @return {mixed} ID or null if none/not found and current issueId is null
         */
        getNextIssueId(
            optionIssues,
            issueDate = null,
            issueId = null,
            forceReset = false
        ) {
            const matchDate =
                issueDate === null ? getNow().format('YYYY-MM-DD') : issueDate;

            // Only get the next issue id if the current issue id is null or not valid
            if (!forceReset) {
                const lookupIssueId = optionIssues.find((element) => {
                    return element.id && element.id === issueId;
                });
                if (issueId && lookupIssueId !== undefined) {
                    return issueId;
                }
            }

            // First get only issues on or after the issue date we're looking for
            const issues = optionIssues.filter((item) => {
                if (item.issueOn) {
                    return item.issueOn >= matchDate;
                } else {
                    return false;
                }
            });

            // Sort that array by issueOn date ascending
            if (issues.length) {
                issues.sort((a, b) => {
                    if (a.issueOn < b.issueOn) {
                        return -1;
                    }

                    if (a.issueOn > b.issueOn) {
                        return 1;
                    }

                    return 0;
                });

                // Now just select first one (if any)
                return issues.length ? issues[0].id : issueId;
            } else {
                // Nothing found
                return issueId;
            }
        },
        /**
         * Given an author object, return a formatted name/organization (however applicable)
         *
         * @param {Object}  author          Author object
         * @param {Boolean} includeAuthorId Include author id field?  Default is false
         *
         * @return {String} Name
         */
        postAuthorFormatted(author, includeAuthorId = false) {
            let result = '';
            if (author) {
                result += author.first_name ? ' ' + author.first_name : '';
                result += author.last_name ? ' ' + author.last_name : '';
                if (result.length) {
                    // If we already have part of name, then comma before organization
                    result += author.organization
                        ? ', ' + author.organization
                        : '';
                } else {
                    result += author.organization
                        ? ' ' + author.organization
                        : '';
                }
                if (includeAuthorId) {
                    result += ' (' + author.id + ')';
                }
            }

            return result.replace(/^\s+/, '');
        },
        /**
         * Given an post authors array, return the formatted name/organization list
         *
         * @param {Array}   authors         Array of authors
         * @param {Boolean} includeAuthorId Include author id field?  Default is false
         *
         * @return {String} Name
         */
        postAuthorFormattedList(authors, includeAuthorId = false) {
            let result = '';
            authors.forEach((postAuthor, index) => {
                const author = postAuthor.author;
                let single = '';
                if (author) {
                    single += author.first_name ? ' ' + author.first_name : '';
                    single += author.last_name ? ' ' + author.last_name : '';
                    if (single.length) {
                        // If we already have part of name, then comma before organization
                        single += author.organization
                            ? ', ' + author.organization
                            : '';
                    } else {
                        single += author.organization
                            ? ' ' + author.organization
                            : '';
                    }
                    if (includeAuthorId) {
                        single += ' (' + author.id + ')';
                    }
                    if (result.length) {
                        result += index === authors.length - 1 ? ' and ' : ', ';
                    }
                    result += single.replace(/^\s+/, '');
                }
            });

            return result;
        },
        /**
         * Return the author bio
         *
         * @param {Object} author Author object
         *
         * @return {mixed} Bio or null if none
         */
        postAuthorBio(author) {
            if (
                author &&
                author.bio &&
                typeof author.bio === 'string' &&
                author.bio.trim().length
            ) {
                return author.bio.trim();
            } else {
                return null;
            }
        },
        /**
         * Given a publication object, return a formatted name
         *
         * @param {Object} publication Publication object
         *
         * @return {String} Name
         */
        postPublicationFormatted(publication) {
            let result = '';
            if (publication) {
                result += publication.name ? ' ' + publication.name : '';
            }

            return result.replace(/^\s+/, '');
        },
        /**
         * Return an empty Blotter post type supplemental data object
         * This is typically used to pass data to post add/edit in admin to gather supplemental data for Police Blotter
         *
         * @return {Object}
         */
        postTypeBlotterInit() {
            return {
                suspectName: null,
                suspectAge: null,
                suspectResides: null,
                suspectCrime: null,
                statusType: null,
                crimeDate: {
                    date: getNow({ isServerTime: false }).format('YYYY-MM-DD')
                }
            };
        },
        /**
         * Return an empty Classified post type supplemental data object
         *
         * @return {Object}
         */
        postTypeClassifiedInit() {
            return {
                classifiedType: null,
                contactPhone: null,
                contactEmail: null
            };
        },
        /**
         * Return an empty Event post type supplemental data object
         * This is typically used to pass data to post add/edit in admin to gather supplemental data for Events
         *
         * @return {Object}
         */
        postTypeEventInit() {
            /*
            const response = await loadOptionPostTypeEventSchedule();
            let optionEventSchedule = response.success
                ? response.postTypeEventSchedule
                : [];
            */
            return {
                scheduleType: null, //this.getOptionIdFromValue(optionEventSchedule, 'postTypeEventScheduleTypeOnce'),
                eventType: null,
                venue: null,
                venueAddress: null,
                admissionPrice: null,
                contact: null,
                startDate: null,
                endDate: null,
                eventTime: null,
                // startTime: null,  2017.07.18  MJL  Not using - just will be a single event time text field
                // endTime: null,
                dayOfWeek: null, // For recurring events  @todo  2017.10.03  MJL  Soon to be obsoleted by daysOfWeek
                recurringType: null,
                recurDailyEvery: 0, // Daily - recur every (n) days
                recurWeeklyEvery: 0, // Weekly - recur every (n) weeks
                daysOfWeek: [], // Multiple days of week
                months: [], // Multiple months
                recurMonthlyEvery: 0, // Monthly - recur every (n) months
                monthlyType: null, // Monthly type (e.g. days/on)
                days: [], // Multiple event days
                sequences: [], // Multiple event sequences (e.g. First/Second/Last)
                instances: [], // Multiple event instances (e.g. used when specific dates are entered)
                venueAuthor: null // Venue author (aka contact) id
            };
        },
        /**
         * Return an empty Native Ad post type supplemental data object
         *
         * @return {Object}
         */
        postTypeNativeAdInit() {
            return {
                url: null,
                nativeAdType: 'nativeAdTypeRegular'
            };
        },
        /**
         * Initialize supplemental post data depending on post type
         *
         * @param {String} postType
         *
         * @return {Object} Supplemental data
         */
        postTypeSupplementalInit(postType = null) {
            switch (postType) {
                case 'postTypeBlotter':
                    return this.postTypeBlotterInit();
                case 'postTypeClassified':
                    return this.postTypeClassifiedInit();
                case 'postTypeEvent':
                    return this.postTypeEventInit();
                case 'postTypeNativeAd':
                    return this.postTypeNativeAdInit();
                default:
                    return {};
            }
        },
        /**
         * Return post sub type (which depends on post type)
         *
         * @param {String} postType         Post type
         * @param {Object} dataSupplemental Supplemental data
         * @return {mixed} Sub type or null if not applicable
         */
        getPostSubtype(postType, dataSupplemental) {
            let result = null;

            if (dataSupplemental) {
                switch (postType) {
                    // Blotter not applicable?
                    case 'postTypeClassified':
                        result = dataSupplemental.classifiedType
                            ? dataSupplemental.classifiedType
                            : null;
                        break;
                    case 'postTypeEvent':
                        result = dataSupplemental.eventType
                            ? dataSupplemental.eventType
                            : null;
                        break;
                }
            }

            return result;
        },
        /**
         * Return a string describing post categories
         *
         * @param {Array} postCategories Post categories (each entry must be an object with category)
         * @param {mixed} itemSeparator  Separator to use when there are multiple.  Default is a comma.
         *
         * @return {String} Description - may be null if none
         */
        describePostCategories(postCategories, itemSeparator = ', ') {
            let name = '';
            if (postCategories && postCategories.length) {
                postCategories.forEach((item) => {
                    if (item.category) {
                        name += name.length ? itemSeparator : '';
                        name += item.category.value;
                    }
                });
                name = name.replace(/^\s+/, '');
                if (!name.length) {
                    name = '(unknown!)';
                }
            } else {
                name = ''; // Shouldn't be an all case, must be at least one category - just show blank
            }

            return name.length ? name : null;
        },
        /**
         * Return a string describing post classified categories
         *
         * @param {Array} postCategories Post classified categories (each entry must be an object with type)
         * @param {mixed} itemSeparator  Separator to use when there are multiple.  Default is a comma.
         *
         * @return {String} Description
         */
        describePostClassifiedCategories(postCategories, itemSeparator = ', ') {
            let name = '';
            if (postCategories && postCategories.length) {
                postCategories.forEach((item) => {
                    if (item.type) {
                        name += name.length ? itemSeparator : '';
                        name += item.type.value;
                    }
                });
                name = name.replace(/^\s+/, '');
                if (!name.length) {
                    name = '(unknown!)';
                }
            } else {
                name = ''; // Shouldn't be an all case, must be at least one category - just show blank
            }

            return name.length ? name : '';
        },
        /**
         * Return a string describing post event categories
         *
         * @param {Array} postEvents     Post event categories (each entry must be an object with type)
         * @param {mixed} itemSeparator  Separator to use when there are multiple.  Default is a comma.
         *
         * @return {String} Description
         */
        describePostEventCategories(postEvents, itemSeparator = ', ') {
            let name = '';
            if (postEvents && postEvents.length) {
                postEvents.forEach((item) => {
                    if (item.type) {
                        name += name.length ? itemSeparator : '';
                        name += item.type.value;
                    }
                });
                name = name.replace(/^\s+/, '');
                if (!name.length) {
                    name = '';  // No longer return this: '(unknown!)';
                }
            } else {
                name = ''; // Shouldn't be an all case, must be at least one category - just show blank
            }

            return name.length ? name : '';
        },
        /**
         * Return a string describing post identifiers
         *
         * @param {Array} postIdentifiers Post identifiers
         * @param {mixed} itemSeparator   Separator to use when there are multiple.  Default is a comma.
         *
         * @return {String} Description
         */
        describePostIdentifiers(postIdentifiers, itemSeparator = ', ') {
            let name = '';
            if (postIdentifiers && postIdentifiers.length) {
                postIdentifiers.forEach((item) => {
                    name += name.length ? itemSeparator : '';
                    name += item.identifier;
                });
                name = name.replace(/^\s+/, '');
                if (!name.length) {
                    name = '(unknown!)';
                }
            } else {
                name = ''; // Shouldn't be an all case, must be at least one category - just show blank
            }

            return name.length ? name : '';
        },
        /**
         * Set supplemental data on load
         * Note: I set it up so that there could theoretically be multiple supplemental data records for a single post.  That isn't yet implemented (and may not be) - but the supplemental data may be
         *       an array.  We're only looking at the first element.
         * Note: With the new event form, there are some fields that no longer apply (e.g. venueAddress, contact)
         *
         * @param {Object} post     Post object
         * @param {String} postType Post type value (e.g. 'postTypeBlotter')
         *
         * @return {mixed} Supplmental data object, null if no supplemental data or we had a problem
         */
        setSupplementalData(post, postType) {
            let dataSupplemental = null;
            let supplementalData = null;
            switch (postType) {
                case 'postTypeBlotter':
                    dataSupplemental = this.postTypeBlotterInit();
                    supplementalData =
                        post.post_blotters && post.post_blotters[0]
                            ? post.post_blotters[0]
                            : null;
                    if (supplementalData !== null) {
                        dataSupplemental.suspectName =
                            supplementalData.perp_name;
                        dataSupplemental.suspectAge = supplementalData.perp_age;
                        dataSupplemental.suspectResides =
                            supplementalData.perp_resides;
                        dataSupplemental.suspectCrime = supplementalData.crime;
                        dataSupplemental.crimeDate =
                            supplementalData.crime_date;
                        dataSupplemental.statusType =
                            supplementalData.status_id;
                        dataSupplemental.status = supplementalData.status;
                    }
                    break;
                case 'postTypeClassified':
                    dataSupplemental = this.postTypeClassifiedInit();
                    supplementalData =
                        post.post_classifieds && post.post_classifieds[0]
                            ? post.post_classifieds[0]
                            : null;
                    if (supplementalData !== null) {
                        dataSupplemental.classifiedType =
                            supplementalData.type_id;
                        dataSupplemental.contactPhone =
                            supplementalData.contact_phone;
                        dataSupplemental.contactEmail =
                            supplementalData.contact_email;
                    }
                    break;
                case 'postTypeEvent':
                    dataSupplemental = this.postTypeEventInit();
                    supplementalData =
                        post.post_events && post.post_events[0]
                            ? post.post_events[0]
                            : null;
                    if (supplementalData !== null) {
                        dataSupplemental.scheduleType =
                            supplementalData.schedule_type_id;
                        dataSupplemental.eventType = supplementalData.type_id;
                        dataSupplemental.startDate =
                            supplementalData.event_date;
                        dataSupplemental.endDate = supplementalData.end_date;
                        dataSupplemental.eventTime =
                            supplementalData.start_time;
                        dataSupplemental.venue = supplementalData.venue_id;
                        dataSupplemental.venueAddress =
                            supplementalData.venue_address;
                        dataSupplemental.admissionPrice =
                            supplementalData.admission_price;
                        dataSupplemental.contact = supplementalData.contact;
                        dataSupplemental.venueAuthor =
                            supplementalData.venue_author
                                ? supplementalData.venue_author.author_id
                                : null;
                        //dataSupplemental.venueAuthor = supplementalData.venue_author_id;  @todo  2017.11.08  MJL  Venue author is currently just the author ID for purposes of editing
                        dataSupplemental.recurringType =
                            supplementalData.recurring_type_id;
                        dataSupplemental.recurDailyEvery =
                            supplementalData.recur_days;
                        dataSupplemental.recurWeeklyEvery =
                            supplementalData.recur_weeks;
                        dataSupplemental.monthlyType =
                            supplementalData.monthly_type_id;

                        if (
                            post.post_event_recurs &&
                            post.post_event_recurs.length
                        ) {
                            // Load recurring sequence types as needed
                            post.post_event_recurs.forEach((instance) => {
                                if (
                                    instance.recurs_type &&
                                    instance.recurs_type.key ===
                                        'eventRecurSequenceTypeDaysOfWeek'
                                ) {
                                    // Day(s) of week
                                    dataSupplemental.daysOfWeek.push(
                                        instance.option_id
                                    );
                                }

                                if (
                                    instance.recurs_type &&
                                    instance.recurs_type.key ===
                                        'eventRecurSequenceTypeDays'
                                ) {
                                    // Day(s)
                                    dataSupplemental.days.push(
                                        instance.option_id
                                    );
                                }

                                if (
                                    instance.recurs_type &&
                                    instance.recurs_type.key ===
                                        'eventRecurSequenceTypeMonths'
                                ) {
                                    // Month(s)
                                    dataSupplemental.months.push(
                                        instance.option_id
                                    );
                                }

                                if (
                                    instance.recurs_type &&
                                    instance.recurs_type.key ===
                                        'eventRecurSequenceTypeSequences'
                                ) {
                                    // Sequence(s)
                                    dataSupplemental.sequences.push(
                                        instance.option_id
                                    );
                                }
                            });
                        }

                        if (
                            post.post_event_instances &&
                            post.post_event_instances.length
                        ) {
                            post.post_event_instances.forEach((instance) => {
                                dataSupplemental.instances.push({
                                    theDate: instance.instance_date,
                                    theTime: instance.instance_time
                                });
                            });
                        }
                    }
                    break;
                case 'postTypeNativeAd':
                    dataSupplemental = this.postTypeNativeAdInit();
                    supplementalData =
                        post.post_native_ads && post.post_native_ads[0]
                            ? post.post_native_ads[0]
                            : null;
                    if (supplementalData !== null) {
                        dataSupplemental.url = supplementalData.url;
                        dataSupplemental.nativeAdType =
                            supplementalData.ad_type;
                    }
                    break;
            }
            return dataSupplemental;
        },
        /**
         * Determine if data is fundamentally considered "empty".  This is used to determine if we should present a warning to the user if they are
         * going to overwrite some data.
         *
         * @param {Object} data     Data
         * @param {String} postType Post type value (e.g. 'postTypeBlotter')
         *
         * @return {Boolean} True if data exists already, false if not
         */
        dataHasDataForMirror(data, postType) {
            let hasData = false;
            if (data !== null && typeof data !== 'undefined') {
                switch (postType) {
                    case 'postTypeBlotter':
                        break;
                    case 'postTypeEvent':
                        hasData =
                            data.whatIsThis ||
                            data.headline ||
                            data.subhead ||
                            data.reportContent;
                        break;
                }
            }
            return hasData;
        },
        /**
         * Determine if supplemental data is fundamentally considered "empty".  This is used to determine if we should present a warning to the user if they are
         * going to overwrite some data.
         * Note: I set it up so that there could theoretically be multiple supplemental data records for a single post.  That isn't yet implemented (and may not be) - but the supplemental data may be
         *       an array.  We're only looking at the first element.
         *
         * @param {Object} dataSupplemental Supplemental data
         * @param {String} postType         Post type value (e.g. 'postTypeBlotter')
         *
         * @return {Boolean} True if data exists already, false if not
         */
        supplementalDataHasDataForMirror(dataSupplemental, postType) {
            let hasData = false;
            if (
                dataSupplemental !== null &&
                typeof dataSupplemental !== 'undefined'
            ) {
                switch (postType) {
                    case 'postTypeBlotter':
                        hasData =
                            dataSupplemental.suspectName ||
                            dataSupplemental.suspectAge ||
                            dataSupplemental.suspectResides ||
                            dataSupplemental.suspectCrime ||
                            dataSupplemental.crimeDate ||
                            dataSupplemental.statusType ||
                            dataSupplemental.status;
                        break;
                    case 'postTypeEvent':
                        hasData =
                            dataSupplemental.scheduleType ||
                            dataSupplemental.eventType ||
                            dataSupplemental.startDate ||
                            dataSupplemental.endDate ||
                            dataSupplemental.eventTime ||
                            dataSupplemental.venue ||
                            dataSupplemental.venueAuthor ||
                            dataSupplemental.venueAddress ||
                            dataSupplemental.admissionPrice ||
                            dataSupplemental.contact;
                        break;
                }
            }
            return hasData;
        },
        /**
         * A post is ready to save.  Last chance to alter data for any special cases.  e.g. For an event, the content date should be set as the event date
         *
         * @param {String} postType Post type (e.g. postTypeEvent)
         * @param {Object} data     Data object
         *
         * @return {Object} Updated data object
         */
        postReadyToSave(postType, data) {
            let oData = {};
            Object.assign(oData, data);
            if (postType === 'postTypeEvent') {
                if (data.supplemental.startDate) {
                    // Content date is actually event start date (maybe - depends on event schedule type too) - content date is now expecting stamp, but time in event is text, set to 00:00:00
                    oData.contentDate =
                        data.supplemental.startDate + ' 00:00:00';
                }

                if (oData.contentDate) {
                    oData.contentDate = toDate(oData.contentDate).format(
                        'YYYY-MM-DD HH:mm:ss'
                    );
                }
            }
            return oData;
        },
        /**
         * A post is ready to update.  Last chance to alter data for any special cases.  e.g. For an event, the content date should be set as the event date
         *
         * @param {String} postType Post type (e.g. postTypeEvent)
         * @param {Object} data     Data object
         *
         * @return {Object} Updated data object
         */
        postReadyToUpdate(postType, data) {
            let oData = {};
            Object.assign(oData, data);
            if (postType === 'postTypeEvent') {
                if (data.supplemental.startDate) {
                    // Content date is actually event start date (maybe - depends on event schedule type too) - content date is now expecting stamp, but time in event is text, set to 00:00:00
                    oData.contentDate =
                        data.supplemental.startDate + ' 00:00:00';
                }

                if (oData.contentDate) {
                    oData.contentDate = toDate(oData.contentDate).format(
                        'YYYY-MM-DD HH:mm:ss'
                    );
                }
            }
            return oData;
        },
        /**
         * Check if post type is a given type
         *
         * @param {Object}        post     Post object
         * @param {String|Array}  postType Post type to check - may be a string or an array of post types
         *
         * @return {Boolean} True if is given type, false if not
         */
        isPostType(post, postType) {
            if (post && post.post_type) {
                return Array.isArray(postType)
                    ? postType.includes(post.post_type.key)
                    : post.post_type.key === postType;
            } else {
                return false;
            }
        },
        /**
         * Determine if post is purchased.  Note that this only checks the post record - the backend should still be consulted as the final arbiter.
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True if purchase, false if not
         */
        isPostPurchased(post) {
            return post && post.consumed && post.consumed.length;
        },
        /**
         * Determine if publication has any media
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True if has media, false if not
         */
        doesPublicationHaveMedia(post) {
            return (
                post &&
                post.publication &&
                post.publication.media &&
                post.publication.media.length
            );
        },
        /**
         * Determine if publication has logo
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True if has media (logo), false if not
         */
        doesPublicationHaveMediaLogo(post) {
            if (
                post &&
                post.publication &&
                post.publication.media &&
                post.publication.media.length
            ) {
                const hasLogo = post.publication.media.find((item) => {
                    return (
                        item &&
                        item.media_type &&
                        item.media_type.key === 'mediaTypeImage'
                    );
                });
                return hasLogo !== undefined;
            } else {
                return false;
            }
        },
        /**
         * Determine if publication has icon
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True if has media (icon), false if not
         */
        doesPublicationHaveMediaIcon(post) {
            if (
                post &&
                post.publication &&
                post.publication.media &&
                post.publication.media.length
            ) {
                const hasLogo = post.publication.media.find((item) => {
                    return (
                        item &&
                        item.media_type &&
                        item.media_type.key === 'mediaTypeIcon'
                    );
                });
                return hasLogo !== undefined;
            } else {
                return false;
            }
        },
        /**
         * Return publication media matching the requested media type key
         *
         * @param {Object} post      Post object
         * @param {String} mediaType Media type key (e.g. 'mediaTypeIcon')
         *
         * @return {Array}
         */
        getPublicationMedia(post, mediaType) {
            if (
                post &&
                post.publication &&
                post.publication.media &&
                post.publication.media.length
            ) {
                return post.publication.media.filter((item) => {
                    return (
                        item &&
                        item.media_type &&
                        item.media_type.key === mediaType
                    );
                });
            } else {
                return [];
            }
        },
        /**
         * Determine if author has headshot
         * If there are multiple authors, we will currently return false
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True if has media (logo), false if not
         */
        doesAuthorHaveMedia(post) {
            return (
                post &&
                post.post_authors &&
                post.post_authors.length === 1 &&
                post.post_authors[0].author.media &&
                post.post_authors[0].author.media.length
            );
        },
        /**
         * Determine if post type is an advertisement
         *
         * @param {String} postType Post object
         *
         * @return {Boolean}
         */
        isPostTypeAdvertisement(postType) {
            return (
                postType === 'postTypeAd' ||
                postType === 'postTypeDisplayAd' ||
                postType === 'postTypeNativeAd'
            );
        },
        /**
         * Determine if post type is a news article
         *
         * @param {String} postType Post object
         *
         * @return {Boolean}
         */
        isPostTypeNews(postType) {
            return (
                postType === 'postTypeArticle' ||
                postType === 'postTypeNewsShort'
            );
        },
        /**
         * Determine if post type is a sponsored
         *
         * @param {String} postType Post object
         *
         * @return {Boolean}
         */
        isPostTypeSponsored(postType) {
            return (
                postType === 'postTypeSponsored'
            );
        },
        /**
         * Determine if post type is sponsored
         *
         * @param {Object} post Post object
         *
         * @return {Boolean}
         */
        isPostSponsored(post) {
            return post.post_type.key
                ? this.isPostTypeSponsored(post.post_type.key)
                : false;
        },
        /**
         * Determine if post is an advertisement
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/false
         */
        isPostAdvertisement(post) {
            return post.post_type.key
                ? this.isPostTypeAdvertisement(post.post_type.key)
                : false;
        },
        /**
         * Determine if post is an native advertisement
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/false
         */
        isPostNativeAdvertisement(post) {
            return post && post.post_type && post.post_type.key
                ? post.post_type.key === 'postTypeNativeAd'
                : false;
        },
        /**
         * Determine if post is a podcast/newscast
         *
         * @param {Object} post
         *
         * @return {Boolean}
         */
        isPostCast(post) {
            return post && post.post_type && post.post_type.key
                ? post.post_type.key === 'postTypeNewscast' ||
                      post.post_type.key === 'postTypePodcast'
                : false;
        },
        /**
         * Determine if we should show the publication banner for a post
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/false
         */
        isShowPublicationBanner(post) {
            return post && !this.isPostNativeAdvertisement(post) && !this.isPostSponsored(post);
        },
        /**
         * Determine if related posts should be shown for post
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/false
         */
        isShowRelatedPosts(post) {
            return !this.isPostAdvertisement(post);
        },
        /**
         * Determine if post headline should be shown
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/false
         */
        isShowHeadline(post) {
            return !this.isPostAdvertisement(post);
        },
        /**
         * Determine if post media count should be shown
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/false
         */
        isShowMediaCount(post) {
            return !this.isPostAdvertisement(post);
        },
        /**
         * Determine if we should show author image (if available)
         *
         * @param {Object} post
         * @returns {Boolean}
         */
        isShowAuthorMedia(post) {
            return !this.isPostCast(post) && !this.isPostSponsored(post);
        },
        /**
         * Determine if post comments should be shown
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/false
         */
        isAllowPostComments(post) {
            return !this.isPostAdvertisement(post);
        },
        /**
         * Determine if post comments should be allowed
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/false
         */
        isShowPostComments(post) {
            return !this.isPostAdvertisement(post);
        },
        /**
         * Determine if text content field is empty
         *
         * @param {Object} post Post object
         *
         * @return {Boolean}
         */
        isTextContentEmpty(post) {
            return (
                post.textContent === null ||
                (typeof post.textContent === 'string' &&
                    !post.textContent.length)
            );
        },
        /**
         * Determine if media should be obfuscated in preview
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/false
         */
        isShowMediaObfuscated(post) {
            if (
                post &&
                post.publication &&
                this.publicationIsDevilsAdvocate(post.publication.id)
            ) {
                // If it's a Devil's Advocate post, then obfuscate the image if we're not showing a snippet
                return !this.isShowPostSnippet(post);
            } else {
                return (
                    this.isPostType(post, 'postTypeCartoon') ||
                    this.isPostType(post, 'postTypeImage') ||
                    (this.isPostTypeNews(post) && this.isTextContentEmpty(post))
                );
            }
        },
        /**
         * Determine if share icons should be shown
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/false
         */
        isShowShareIcons(post) {
            return !this.isPostAdvertisement(post);
        },
        /**
         * Determine if post subhead should be shown
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/false
         */
        isShowSubhead(post) {
            return !this.isPostType(post, 'postTypeClassified') &&
                !this.isPostType(post, 'postTypeLegal') &&
                !this.isPostAdvertisement(post)
                ? true
                : false;
        },
        /**
         * Determine if post author should be shown
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/false
         */
        isShowAuthor(post) {
            return !this.isPostType(post, 'postTypeClassified') &&
                !this.isPostAdvertisement(post) &&
                !this.isPostType(post, 'postTypeSystem') &&
                !this.isPostType(post, 'postTypeObit')
                ? true
                : false;
        },
        /**
         * Determine if "By " should proceed the post author (i.e. we don't really want to say it's "By XYZ Police Department" for a blotter)
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/false
         */
        isShowAuthorBy(post) {
            if (
                this.isPostType(post, 'postTypeBlotter') ||
                this.isPostType(post, 'postTypeEvent') ||
                this.isPostType(post, 'postTypeSponsored')
            ) {
                // Never for certain post types
                return false;
            }

            // @todo  2019-06-06  MJL  No longer a single post author, how to deal with this?
            // If the post author has a first or last name, then assume to include the "By" - if not, then it's just an organization and should
            //return (post.author.first_name !== null || post.author.last_name !== null) ? true : false;
            return true;
        },
        isShowAuthorImage(post) {
            return !this.isPostType(post, [
                'postTypeNewscast',
                'postTypePodcast'
            ]);
        },
        /**
         * Determine if post content date should be shown
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/false
         */
        isShowContentAt(post) {
            return !this.isPostAdvertisement(post);
        },
        /**
         * Determine if post content should be shown
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/false
         */
        isShowContent(post) {
            return !this.isPostAdvertisement(post);
        },
        /**
         * Determine if post caption card (caption and possibly image credit)
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/false
         */
        isShowCaptionCard(post) {
            return !this.isPostAdvertisement(post);
        },
        /**
         * Determine if a post type should be shown
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/false
         */
        isShowPostType(post) {
            if (
                post &&
                post.post_type &&
                post.post_type.key === 'postTypeSystem'
            ) {
                return true;
            }

            if (post && post.type) {
                // @todo  2018-12-18  Hmmm... what was .type - not .post_type? I guess if no one has complained, then leave it alone
                return this.isPostType(post, 'postTypeEvent') ? false : true;
            } else {
                return false;
            }
        },
        /**
         * Determine if a post category should be shown
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/false
         */
        isShowPostCategory(post) {
            return this.isPostAdvertisement(post) ||
                this.isPostType(post, 'postTypeSystem')
                ? false
                : true;
        },
        /**
         * Determine if cost corner should be shown
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/false
         */
        isShowCostCorner(post) {
            return this.isPostType(post, 'postTypeSystem') ||
                this.isPostAdvertisement(post)
                ? false
                : true;
        },
        /**
         * Determine if the post has a location
         * @todo  2017.08.03  MJL  We allow for multiple locations in the back-end, but only one in the front-end.  So, we're only looking at the
         *                         first location.
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/false
         */
        hasPostLocation(post) {
            return post && post.post_locations && post.post_locations.length;
        },
        /**
         * Determine if the post has "what is this" text
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/false
         */
        hasPostWhatIsThis(post) {
            return post && post.what_is_this && post.what_is_this.length;
        },
        /**
         * Determine if we should prefix the location of a post to content (e.g. for an article, we should prefix the location)
         * @todo  2017.08.03  MJL  We allow for multiple locations in the back-end, but only one in the front-end.  So, we're only looking at the
         *                         first location.
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/false
         */
        isShowLocationInContent(post) {
            if (post && post.post_locations && post.post_locations.length) {
                return this.isPostType(post, [
                    'postTypeArticle',
                    'postTypeBlotter',
                    'postTypeNewsShort',
                    'postTypePressRelease'
                ]);
            } else {
                return false;
            }
        },
        /**
         * Return a string describing the location of a post.
         * @todo  2017.08.03  MJL  We allow for multiple locations in the back-end, but only one in the front-end.  So, we're only looking at the
         *                         first location.
         *
         * @param {Object}  post        Post object
         * @param {mixed}   showLocType Show location type?  e.g. "Town of (town)".  If false, we omit the "Town of" portion.  Default is false.
         *
         * @return {mixed} Location description; null if not applicable
         */
        describePostLocation(post, showLocType = false) {
            // @todo  2017.08.03  MJL  We may need to be able to handle when location doesn't have a specific location, but only a county or state
            if (post && post.post_locations && post.post_locations.length) {
                if (
                    this.isPostType(post, 'postTypeSystem') &&
                    post.publication &&
                    post.publication.name
                ) {
                    return post.publication.name.toUpperCase();
                }
                // If we have a location with town/hamlet/village/city, so show that
                if (
                    post.post_locations[0].location &&
                    post.post_locations[0].location.location_type
                ) {
                    const location = post.post_locations[0].location;
                    return showLocType
                        ? location.location_type.value + ' of ' + location.name
                        : location.name;
                }

                // If we have a county, show that:
                if (post.post_locations[0].county) {
                    return post.post_locations[0].county.value; // + ' County';
                }

                // If we have a county, show that:
                if (post.post_locations[0].state) {
                    return post.post_locations[0].state.value;
                }

                // We've got nothing
                return null;
            } else {
                return null;
            }
        },
        /**
         * Return a string describing the "read more" text (generally used in preview)
         *
         * @param {Object}  post     Post object
         * @param {Boolean} isMobile Display meant for mobile?
         *
         * @return {String}
         */
        describePostReadMore(post, isMobile = false) {
            let result = '';

            if (post) {
                if (isMobile) {
                    // Default for all mobile indicators
                    result = 'Read';
                }

                const postType = post.post_type ? post.post_type.key : '';
                const audio = post.media.find(
                    (audio) => audio.audio_type === 'castFull'
                );
                switch (postType) {
                    case 'postTypeArticle':
                    case 'postTypeNewsShort':
                        if (!isMobile) {
                            result = 'Read the story';
                        }
                        break;
                    case 'postTypeBlotter':
                        if (!isMobile) {
                            result = 'See the charges';
                        }
                        break;
                    case 'postTypeCartoon':
                        if (!isMobile) {
                            result = 'See the full view';
                        }
                        break;
                    case 'postTypeImage': // @todo  2018-07-10  MJL  Obsolete as of today, so remove eventually
                        if (!isMobile) {
                            result = 'See the full view';
                        }
                        break;
                    case 'postTypeEvent':
                        if (!isMobile) {
                            result = "See what's happening";
                        }
                        break;
                    case 'postTypeColumn':
                        if (!isMobile) {
                            if (post.post_authors && post.post_authors.length) {
                                // Show something like "Read Joe Blow's column" or if multiple, "Read Joe Blow's (et al) column"
                                result =
                                    'Read ' +
                                    this.postAuthorFormatted(
                                        post.post_authors[0].author
                                    ) +
                                    "'s" +
                                    (post.post_authors.length > 1
                                        ? ' (et al)'
                                        : '') +
                                    ' column';
                            } else {
                                result = 'Read the column';
                            }
                        }
                        break;
                    case 'postTypeNewscast':
                        if (!audio) {
                            result = 'Listen to NewsCast';
                        } else {
                            const duration = formatDuration(
                                audio.duration / 1000
                            );
                            result = `Listen to NewsCast (${duration})`;
                        }
                        break;
                    case 'postTypePodcast':
                        if (!audio) {
                            result = 'Listen to Podcast';
                        } else {
                            const duration = formatDuration(
                                audio.duration / 1000
                            );
                            result = `Listen to Podcast (${duration})`;
                        }
                        break;
                    default:
                        if (!isMobile) {
                            result = 'Read the full version';
                        }
                        break;
                }
            }

            return result;
        },
        /**
         * Determine if a post should have a preview or not (e.g. an Ad should not have a preview)
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/Flase
         */
        hasPostPreview(post) {
            let result = true;

            if (post) {
                const postType = post.post_type ? post.post_type.key : '';
                result =
                    this.isPostAdvertisement(post) ||
                    postType === 'postTypeSystem'
                        ? false
                        : true;
            }

            return result;
        },
        /**
         * Return a string describing post word count
         *
         * @param {Object} post Post object
         *
         * @return {mixed} Description - may be null if not known
         */
        getPostWordCount(post) {
            return post && post.content_word_count
                ? post.content_word_count +
                      ' word' +
                      (post.content_word_count > 1 ? 's' : '')
                : null;
        },
        /**
         * Return a string describing post image count
         *
         * @param {Object} post Post object
         *
         * @return {mixed} Description - may be null if not known
         */
        getPostImageCount(post) {
            const count = post?.media?.filter(
                (media) => media.media_type_id === 31
            ).length;
            if (!count) {
                return null;
            }
            return count + ' photo' + (count > 1 ? 's' : '');
        },
        /**
         * Return a string describing post counts (word count and image count)
         * @todo  2018-01-08  MJL  Obsolete now?  Replaced by the getPostWordCount() and getPostImageCount()
         *
         * @param {Object} post           Post object
         * @param {mixed}  itemSeparator  Separator to use when there are multiple.  Default is a comma.
         *
         * @return {String} Description
         */
        getPostCounts(post, itemSeparator = ', ') {
            let result = '';
            if (post) {
                let wordCount = post.content_word_count
                    ? post.content_word_count +
                      ' word' +
                      (post.content_word_count > 1 ? 's' : '')
                    : null;
                // @todo 2017.08.01  MJL  Might need to cycle through media to check only image types (e.g. when we allow for attachments)
                let imageCount =
                    post.media && post.media.length
                        ? post.media.length +
                          ' photo' +
                          (post.media.length > 1 ? 's' : '')
                        : null;
                result += wordCount ? wordCount : '';
                if (result.length && imageCount) {
                    result += itemSeparator;
                }
                result += imageCount ? imageCount : '';
            }

            return result.length ? '(' + result + ')' : '';
        },
        /**
         * Find a given user address type in an array of user addresses
         *
         * @param {Array}  addresses   Array of user address objects
         * @param {String} addressType Address type to find (e.g. 'adtPhysical')
         *
         * @return {mixed} address Matched address; null if type wasn't found
         */
        getUserAddress(addresses, addressType) {
            const result = addresses.find((item) => {
                if (item.address_type) {
                    return item.address_type.value === addressType;
                } else {
                    return false;
                }
            });
            return result ? result : null;
        },
        /**
         * Return the content comment for a given post comment
         *
         * @param {Object} comment Comment object
         *
         * @return {String} Comment
         */
        postComment(comment) {
            if (comment && comment.content) {
                return comment.content;
                // return comment.content.replace("\n", "<br>");
            } else {
                return '';
            }
        },
        /**
         * Return the content excerpt comment for a given post comment
         *
         * @param {Object}  comment   Comment object
         * @param {Integer} maxWords  Maximum number of words, defaults to 15
         * @param {Integer} maxLength Maximum overall length, defaults to null (which means it doesn't matter)
         * @param {String}  dot       Include after excerpt if it was in fact truncated
         *
         * @return {String} Comment
         */
        postCommentExcerpt(
            comment,
            maxWords = 15,
            maxLength = null,
            dot = null
        ) {
            if (comment && comment.content) {
                const output = comment.content
                    .split(/\s+/)
                    .slice(0, maxWords)
                    .join(' ');
                let dotted = '';
                if (
                    (dot !== null &&
                        comment.content.split(/\s+/).slice(0).join(' ') !==
                            output) ||
                    (maxLength && output.length > maxLength)
                ) {
                    dotted = dot;
                }
                return maxLength
                    ? output.substr(0, maxLength) + dotted
                    : output + dotted;
                // return comment.content.replace("\n", "<br>");
            } else {
                return '';
            }
        },
        /**
         * Return the user name and location for a given post comment
         *
         * @param {Object} comment     Comment object
         * @param {mixed}  addressType Address type to return - defaults to 'adtPhysical'
         *
         * @return {String} Name and address - if we couldn't determine, we just return "NewsAtomic User"
         */
        postCommentUser(comment, addressType = 'adtPhysical') {
            if (comment && comment.user) {
                const userFirst = comment.user.first_name
                    ? comment.user.first_name.trim().substring(0, 1) + '. '
                    : '';
                const userLast = comment.user.last_name
                    ? comment.user.last_name.trim()
                    : '';
                const userName = (userFirst + userLast).trim().length
                    ? userFirst + userLast
                    : 'NewsAtomic User';
                let userAddress = '';
                if (comment.user.addresses) {
                    userAddress = this.getUserAddress(
                        comment.user.addresses,
                        addressType
                    );
                    userAddress = userAddress ? userAddress.city : '';
                }
                return (
                    userName + (userAddress.length ? ', ' + userAddress : '')
                );
            } else {
                return 'NewsAtomic User';
            }
        },
        /**
         * Return the title for a given post comment
         *
         * @param {Object} comment     Comment object
         *
         * @return {String} Title
         */
        postCommentTitle(comment) {
            if (comment && comment.subject) {
                return comment.subject;
            } else {
                return null;
            }
        },
        /**
         * Return the comment time
         *
         * @param {Object}  comment Comment object
         * @param {Boolean} dynamic Dynamic?  If true, we calculate on the fly.  If false (default), we use the createdAtDiff expected from the model
         *
         * @return {String} Time
         */
        postCommentTime(comment, dynamic = false) {
            if (comment) {
                if (dynamic) {
                    moment.updateLocale('en', {
                        // Update locale because we want to alter the text displayed
                        relativeTime: {
                            s: '%d sec',
                            ss: '%d secs',
                            m: '%d min',
                            mm: '%d mins',
                            h: '%d hr',
                            hh: '%d hrs'
                        }
                    });
                    const text = comment.created_at
                        ? toDate(comment.created_at).fromNow()
                        : '';
                    moment.updateLocale('en', null);
                    return text;
                } else {
                    return comment.createdAtDiff ? comment.createdAtDiff : '';
                }
            }
        },
        /**
         * Return formatted content date for display of event instance
         *
         * @param {Object} instance Instance
         *
         * @return {String} Date string
         */
        postEventInstanceDate(instance) {
            return instance && instance.theDate
                ? toDate(instance.theDate).format('M/D/YYYY')
                : '';
        },
        /**
         * Return formatted content date (including year) for display
         *
         * @param {Object} instance Instance
         *
         * @return {String} Date string
         */
        postEventInstanceDateFull(instance) {
            return instance && instance.theDate
                ? toDate(instance.theDate).format('dddd, MMMM Do Y')
                : '';
        },
        /**
         * Determine if post has date instances (currently only applies to events)
         *
         * @param {Object} post Post
         *
         * @return {Boolean} True if so, false if not
         */
        hasDateInstances(post) {
            return (
                post &&
                post.post_event_instances &&
                post.post_event_instances.length
            );
        },
        /**
         * Return only date instances we want to show (e.g. don't show a past date).  Note that we exclude posts that don't have *any* dates
         * in the future on the server side, but we do still return all instances.  We can choose to include or exclude them here for display.
         *
         * @param {Array} Array of date instances (from dataSupplemental)
         *
         * @return {Array} Array of instances
         */
        upcomingDateInstances(instances) {
            if (instances) {
                return instances.filter((instance) => {
                    if (instance.theDate) {
                        let days = getNow().diff(
                            toDate(instance.theDate),
                            'days'
                        );
                        return days <= 0;
                    } else {
                        return false;
                    }
                });
            } else {
                return [];
            }
        },
        /**
         * Return the first qualified date instance
         *
         * @param {Array} Array of date instances (from dataSupplemental)
         *
         * @return {mixed} Instance or null if no upcoming instances
         */
        firstUpcomingDateInstance(instances) {
            const first = this.upcomingDateInstances(instances);
            return first.length ? first[0] : null;
        },
        /**
         * Return the remaining qualified date instances (doesn't include the first)
         *
         * @param {Array} Array of date instances (from dataSupplemental)
         *
         * @return {mixed} Instance or null if no upcoming instances
         */
        remainingUpcomingDateInstances(instances) {
            const list = this.upcomingDateInstances(instances);
            return list.length && list.length > 1
                ? list.slice(list.length - 1)
                : null;
        },
        /**
         * Given a list of items, return a new list.  e.g. Take a list of publications, prefix a "Please Select" item and return the new list
         *
         * @param {Array}    items Items array
         * @param {Function} func  Function to call
         *
         * @return {Array} New list
         */
        addToArray(items, func) {
            if (items) {
                let newItems = items.slice(); // Copy array
                func(newItems);
                return newItems;
            } else {
                return [];
            }
        },
        /**
         * Determine if a post snippet should be shown depending on the post type.
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/false
         */
        isShowPostSnippet(post) {
            if (
                post &&
                post.publication &&
                this.publicationIsDevilsAdvocate(post.publication.id)
            ) {
                // For Devil's Advocate, we only show snippet if there is no image or video
                if (!post.media.length && !post.post_media_videos.length) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return this.isPostType(post, 'postTypeBlotter') ? false : true;
            }
        },
        /**
         * Return the post preview snippet HTML.
         *
         * @param {Object}      post    Post object
         * @param {Object|null} options Options
         *
         * @return {mixed} Snippet (null if no post)
         */
        postPreviewSnippet(post, options = {}) {
            if (post) {
                let result = '';
                let postLocation = this.isShowLocationInContent(post)
                    ? this.describePostLocation(
                          post,
                          options && options.showLocType
                      )
                    : null;
                result +=
                    postLocation !== null ? postLocation.toUpperCase() : '';
                result += result && result.length ? ' - ' : '';
                if (post.content_match) {
                    result += `...${post.content_match.trim()}`;
                } else {
                    if (options && options.snippet10) {
                        result += post.snippet10;
                    } else {
                        result += post.snippet;
                    }
                }
                if (!result) {
                    return null;
                }
                result += '...';
                return result;
            } else {
                return null;
            }
        },
        /**
         * Given a post identifier, return the direct link to it
         *
         * @param {String}  identifier Identifier
         * @param {Boolean} short      Use short url?
         *
         * @return {mixed} Direct link (null if we couldn't determine)
         */
        postLinkUrl(identifier, short = false) {
            if (short) {
                return identifier
                    ? naRoutes.rootShort + '/' + identifier
                    : null;
            } else {
                return identifier
                    ? naRoutes.root + '/post/link/' + identifier
                    : null;
            }
        },
        /**
         * Determine if post is already paid for
         *
         * @param {Object} post Post
         *
         * @return {Boolean}
         */
        postCostIsPaid(post) {
            if (post) {
                return Number.parseInt(post.purchasedCount) > 0;
            } else {
                return false;
            }
        },
        /**
         * Determine if post cost would be a debit (whether paid for or not)
         *
         * @param {Object} post Post
         *
         * @return {Boolean}
         */
        postCostIsDebit(post) {
            if (post) {
                return Number.parseFloat(post.cost) > 0;
            } else {
                return false;
            }
        },
        /**
         * Determine if post cost would be a credit (whether paid for or not)
         *
         * @param {Object} post Post
         *
         * @return {Boolean}
         */
        postCostIsCredit(post) {
            if (post) {
                return Number.parseFloat(post.cost) < 0; // @todo  2018-03-13  MJL  Different post cost types need to come into play!  This hasn't been fully implemented yet, so not correct here yet
            } else {
                return false;
            }
        },
        /**
         * Determine if post cost would be free (whether paid for or not - this just checks if the post cost is 0)
         *
         * @param {Object} post Post
         *
         * @return {Boolean}
         */
        postCostIsFree(post) {
            if (post) {
                return post.cost === null || post.cost === '0'; // Yes, string (it's that way from the PostController)
            } else {
                return false;
            }
        },
        /**
         * Determine if post cost is free due to a subscription
         *
         * @param {Object} post Post
         *
         * @return {Boolean}
         */
        postCostIsSubscription(post) {
            //return true;
            if (post) {
                return post._has_subscription;
            } else {
                return false;
            }
        },
        /**
         * Determine if we can switch the post date depending on post type (e.g. should not do this for events)
         *
         * @param {Object} post Post object
         *
         * @return {Boolean} True/false
         */
        isMorphDateToToday(post) {
            return this.isPostType(post, 'postTypeEvent') ? false : true;
        },
        /**
         * Given an array of post identifiers, pick the first one that's not empty (should be the first one, but just in case)
         *
         * @param {Array} postIdentifiers Array of post identifiers
         *
         * @return {String} identifier (or null if none)
         */
        postGetFirstIdentifier(postIdentifiers) {
            let identifier = postIdentifiers.find((item) => {
                return item.identifier;
            });
            return identifier === undefined ? null : identifier.identifier;
        },
        /**
         * Return formatted content date for display
         * @todo  2018-07-12  MJL  Should probably replace all calls to computed postContentAt() with this method.
         *
         * @param {Object} post Post object
         * @param {String} oFormat Output format, default is 'M/D/YYYY'
         *
         * @return {String} Date string
         */
        postContentAtFormat(post, oFormat = 'M/D/YYYY') {
            // If date is today, show it in # hours/minutes ago - otherwise, just show the date
            moment.updateLocale('en', {
                // Update locale because we want to alter the text displayed
                relativeTime: {
                    s: '1 sec',
                    ss: '%d secs',
                    m: '1 min',
                    mm: '%d mins',
                    h: '1 hr',
                    hh: '%d hrs'
                }
            });

            const content_at = post.content_at_calculated ?? post.content_at;

            // Special case - if date is today and time is exactly midnight, just say today
            if (
                content_at &&
                toDate(content_at).format('YYYY-MM-DD HH:mm:ss') ===
                    getNow().format('YYYY-MM-DD' + ' 00:00:00') &&
                this.isMorphDateToToday(post)
            ) {
                moment.updateLocale('en', null);
                return 'today';
            }

            // Don't use fromNow if we shouldn't for this post (e.g. don't do for events)
            const text = content_at
                ? toDate(content_at).format('YYYY-MM-DD') ===
                      getNow().format('YYYY-MM-DD') &&
                  this.isMorphDateToToday(post)
                    ? toDate(content_at).fromNow()
                    : toDate(content_at).format(oFormat)
                : '';
            moment.updateLocale('en', null);
            return text;
        },
        /**
         * Return first date instance as an array
         *
         * @param {Array} instances
         *
         * @return {Array} Instance (returned as an array)
         */
        postFirstInstance(instances) {
            const instance = this.upcomingDateInstances(instances);
            return instance ? [instance[0]] : [];
        },
        /**
         * Return remaining date instances (excludes the first)
         *
         * @param {Array} instances
         *
         * @return {Array} Instances
         */
        postRemainingInstances(instances) {
            const instance = this.upcomingDateInstances(instances);
            return instance ? instance.slice(-(instance.length - 1)) : [];
        },
        /**
         * Return event contact details.
         *
         * @param {Object} post Post object
         *
         * @return {mixed} Contact details - null if none available
         */
        postContactDetails(post) {
            let result = null;
            if (post.post_events && post.post_events.length) {
                const venueContact = post.post_events[0].venue_author;
                if (
                    venueContact &&
                    venueContact.author &&
                    (venueContact.author.organization ||
                        (venueContact.author.contacts &&
                            venueContact.author.contacts.length))
                ) {
                    if (
                        venueContact.author.contacts &&
                        venueContact.author.contacts.length
                    ) {
                        // Has details
                        const contact = venueContact.author.contacts[0].contact;
                        if (contact) {
                            result = '';
                            result += venueContact.author.organization
                                ? ' ' + venueContact.author.organization
                                : '';
                            result += venueContact.author.first_name
                                ? ' ' + venueContact.author.first_name
                                : '';
                            result += venueContact.author.last_name
                                ? ' ' + venueContact.author.last_name
                                : '';
                            result += contact.email ? ' ' + contact.email : '';
                            result += contact.phone ? ' ' + contact.phone : '';
                            result += contact.url ? ' ' + contact.url : '';
                        }
                    } else {
                        // Only have organization name
                        result = '';
                        result += venueContact.author.organization
                            ? ' ' + venueContact.author.organization
                            : '';
                    }

                    result = result.replace(/^\s+/, '');
                    result = result.replace(/\s+$/, '');
                    result = result.length ? result : null;
                }
            }
            return result;
        },
        /**
         * Return venue name and location.  Depends on if we have a venue id (new style event form) or just a plain old venue name
         *
         * @param {Object} post Post object
         *
         * @return {String}
         */
        postVenueName(post) {
            if (post.post_events && post.post_events.length) {
                // We have post events data, so it's the new style
                const postEvent = post.post_events[0];
                if (
                    postEvent.venueName &&
                    typeof postEvent.venueName === 'string' &&
                    postEvent.venueName.length
                ) {
                    return (
                        postEvent.venueName +
                        (postEvent.venue_address
                            ? ', ' + postEvent.venue_address
                            : '')
                    ); // Plain old venue name - old style (now obsolete - just needed for old event types)
                } else {
                    const venue = postEvent.venue;
                    let location = null;
                    if (venue && venue.locations && venue.locations.length) {
                        location = venue.locations[0];
                    }
                    let result =
                        location && location.name
                            ? location.name
                            : venue && venue.name
                            ? venue.name
                            : '';
                    if (location) {
                        result += location.street_1
                            ? ', ' + location.street_1
                            : '';
                        result +=
                            location.location && location.location.name
                                ? ', ' + location.location.name
                                : ''; // City/Town/Village
                        result +=
                            location.county && location.county.key
                                ? ', ' + location.county.key
                                : ''; // State
                    }
                    return result.replace(/^,\s+/, '');
                }
            }
        },
        /**
         * Determine if post has images
         *
         * @param {Object} post Post object
         *
         * @param {Boolean}
         */
        postHasMediaImages(post) {
            return !!post?.media?.find((media) => media.media_type_id === 31);
        },
        /**
         * Determine if post has videos
         *
         * @param {Object} post Post object
         *
         * @param {Boolean}
         */
        postHasMediaVideos(post) {
            return (
                post && post.post_media_videos && post.post_media_videos.length
            );
        },
        /**
         * Determine if post should show video instead of image for preview
         *
         * @param {Object} post Post object
         *
         * @param {Boolean}
         */
        postShowVideoInsteadOfImage(post) {
            const isOverride = post && post.use_media_preview_video;
            if (this.postHasMediaVideos(post)) {
                // At least has video...
                if (this.postHasMediaImages(post)) {
                    // Also has at least an image, so it depends on the override flag
                    return isOverride;
                } else {
                    // Doesn't have an image, so yes, override by default
                    return true;
                }
            } else {
                // No video, so no need to override
                return false;
            }
        },
        /**
         * Determine if post image should be shown as media preview.
         * Only if media is supposed to be shown, we of course have an image and video doesn't override
         *
         * @param {Object}  post      Post object
         * @param {Boolean} showMedia Should media be shown at all?  Default is true.
         *
         * @return {Boolean}
         */
        postShowImage(post, showMedia = true) {
            return (
                post &&
                showMedia &&
                !this.isPostCast(post) &&
                this.postHasMediaImages(post) &&
                (!post.use_media_preview_video ||
                    (post.use_media_preview_video &&
                        !this.postHasMediaVideos(post)))
            );
        },
        /**
         * Determine if video should be shown as media preview.  In this case, it really means the video thumbnail.  We're not showing video yet.
         * Only if media is supposed to be shown, we have video and the video meta, and we're supposed to override image (or we don't have images)
         *
         * @param {Object}  post          Post object
         * @param {Boolean} showMedia     Should media be shown at all?  Default is true.
         * @param {Boolean} needVideoMeta Need video meta to be shown?  Default is false.
         * @param {Object}  videoMeta     Video meta data.  Default is null.
         *
         * @return {Boolean}
         */
        postShowVideo(
            post,
            showMedia = true,
            needVideoMeta = false,
            videoMeta = null
        ) {
            return (
                post &&
                showMedia &&
                this.postHasMediaVideos(post) &&
                (!needVideoMeta || (needVideoMeta && videoMeta)) &&
                (post.use_media_preview_video || !this.postHasMediaImages(post))
            );
        },
        /**
         * Return post type content class (so we can style some post type content differently)
         *
         * @param {Object} post Post object
         *
         * @return {String}
         */
        postTypeContentClass(post) {
            return post && post.post_type && post.post_type.key
                ? 'na-content-' + post.post_type.key
                : null;
        },
        /**
         * Return list of Twitter hashtags based on post tags
         *
         * @param {Array} postTags Array of post tag objects
         *
         * @return {String}
         */
        postTagsToTwitterHashtags(postTags) {
            let oString = '';
            if (postTags) {
                // Strip all punctuation and whitespace from tags - they don't work properly on Twitter
                postTags.forEach(
                    (postTag) =>
                        (oString +=
                            postTag &&
                            postTag.tag !== null &&
                            postTag.tag.length
                                ? ' #' + postTag.tag.replace(/[^A-Za-z09]/g, '')
                                : '')
                );
            }
            return oString.replace(/^\s+|\s+$/g, '');
        },
        /**
         * Return the paywall content - generally for Google News
         *
         * @param {Object} post Post object
         *
         * @return {String|null}
         */
        postPaywallContent(post) {
            return post.content_html ?? post.content_text;
        },
        /**
         * Determine if we should show paywall content - generally for Google News
         *
         * @param {Object} post Post object
         *
         * @return {Boolean}
         */
        postIsShowPaywallContent(post) {
            return (
                toDate(this.post.created_at).format('YYYYMMDD') >= '20190414' &&
                this.postPaywallContent(post)
            );
            //return false;  // 2019-04-16  MJL  Disabled for now
            //return toDate(this.post.created_at).format('YYYYMMDD') >= '20190414' && (!(this.post.id % 2) && this.postPaywallContent(post));
        },
        postGetPostVideos(postVideos) {
            if (postVideos && postVideos.length) {
                return postVideos.map((postVideo) => {
                    return {
                        caption:
                            postVideo && postVideo.caption
                                ? postVideo.caption
                                : null,
                        creditId:
                            postVideo && postVideo.creditId
                                ? postVideo.creditId
                                : null,
                        id: postVideo && postVideo.id ? postVideo.id : null,
                        privateUrl:
                            postVideo && postVideo.privateUrl
                                ? postVideo.privateUrl
                                : null,
                        videoId:
                            postVideo && postVideo.videoId
                                ? postVideo.videoId
                                : null,
                        videoTypeId:
                            postVideo && postVideo.videoTypeId
                                ? postVideo.videoTypeId
                                : null
                    };
                });
            } else {
                return [];
            }
        }
    }
};
