<!-- @format -->
<template>
    <div class="is-flex is-justify-content-center">
        <a :href="link">
            <div class="na-info-message-button">
                <span>{{ message }}</span>
            </div>
            <button type="button" v-if="includeButton" class="button is-small has-text-weight-bold" :class="buttonStyle">
                {{ buttonText }}
            </button>
        </a>
    </div>
</template>

<script>
export default {
    props: {
        link: {
            type: String,
            default: ''
        },
        message: {
            type: String,
            required: true
        },
        buttonText: {
            type: String,
            default: ''
        },
        buttonStyle: {
            type: String,
            default: 'is-success'
        },
        includeButton: {
            type: Boolean,
            default: true
        }
    }
};
</script>
