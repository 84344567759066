/** @format */
import axios from 'axios';

/**
 * Create a new post
 *
 * @param {Object} data Post data to be saved
 *
 * @return Promise for resolve, reject
 */
export const createPost = function (data) {
    return axios
        .post(naRoutes.createPost, {
            data
        })
        .then((response) => response.data);
};

/**
 * Create a new post for contest submission
 *
 * @param {Object} data Post data to be saved
 *
 * @return Promise for resolve, reject
 */
export const createPostContestSubmission = function (data) {
    return axios
        .post(naRoutes.createPostContestSubmission, {
            data
        })
        .then((response) => response.data);
};

/**
 * Load a post
 *
 * @param {Integer} postId            Post ID to be loaded
 * @param {Boolean} ignorePreferences Hack because we'll be returning most recent comments from the read call and we need to know if user wants to ignore prefs or not
 * @param {Boolean} isLogPostView     Is log post view?  If false, we'll still record it, but set it to inactive
 * @param {Boolean} includePostStats  Include post stats (e.g. reads/views)?
 *
 * @return Promise for resolve, reject
 */
export const readPost = function (
    postId,
    ignorePreferences = false,
    isLogPostView = true,
    includePostStats = false
) {
    return axios
        .post(naRoutes.readPost, {
            data: {
                id: postId,
                options: {
                    ignorePreferences,
                    isLogPostView,
                    includePostStats
                }
            }
        })
        .then((response) => response.data);
};

/**
 * Load a post by identifier
 *
 * @param {Integer} postId              Post ID to be loaded
 * @param {Boolean} ignorePreferences   Hack because we'll be returning most recent comments from the read call and we need to know if user wants to ignore prefs or not
 * @param {Boolean} isLogPostView       Is log post view?  If false, we'll still record it, but set it to inactive
 * @param {Boolean} isIncludePrivatePub Include even if publication is private
 *
 * @return Promise for resolve, reject
 */
export const readPostByIdentifier = function (
    identifier,
    ignorePreferences = false,
    isLogPostView = true,
    isIncludePrivatePub = false
) {
    return axios
        .post(naRoutes.readPostByIdentifier, {
            data: {
                identifier,
                options: {
                    ignorePreferences,
                    isLogPostView,
                    isIncludePrivatePub
                }
            }
        })
        .then((response) => response.data);
};

/**
 * Update a post
 *
 * @param {Object} data Post data to be saved
 *
 * @return Promise for resolve, reject
 */
export const updatePost = function (data) {
    return axios
        .post(naRoutes.updatePost, {
            data
        })
        .then((response) => response.data);
};

/**
 * Update post tags
 *
 * @param {Object} data Post data to be saved
 *
 * @return Promise for resolve, reject
 */
export const updatePostTags = function (data) {
    return axios
        .post(naRoutes.updatePostTags, {
            data
        })
        .then((response) => response.data);
};

/**
 * Delete a post
 *
 * @param {Integer} postId Post ID to be deleted
 *
 * @return Promise for resolve, reject
 */
export const deletePost = function (postId) {
    return axios
        .post(naRoutes.deletePost, {
            data: {
                id: postId
            }
        })
        .then((response) => response.data);
};

/**
 * Purchase a post
 *
 * @param {Integer} postId ID of post to be purchased
 *
 * @return Promise for resolve, reject
 */
export const purchasePost = function (postId) {
    return axios
        .post(naRoutes.purchasePost, {
            data: {
                id: postId
            }
        })
        .then((response) => response.data);
};

/**
 * Load posts
 *
 * @param {Object} data Data to pass - e.g. limit, offset
 *
 * @return Promise for resolve, reject
 */
export const readPosts = function (data) {
    return axios
        .post(naRoutes.readPosts, {
            data
        })
        .then((response) => response.data);
};

/**
 * Load posts in preview mode (limited fields returned)
 *
 * @param {Object} data Data to pass - e.g. limit, offset
 *
 * @return Promise for resolve, reject
 */
export const readPreviewPosts = function (data) {
    return axios
        .post(naRoutes.readPreviewPosts, {
            data
        })
        .then((response) => response.data);
};

/**
 * Load breaking posts
 *
 * @param {Object} data Data to pass
 *
 * @return Promise for resolve, reject
 */
export const readPostsBreaking = function (data) {
    return axios
        .post(naRoutes.readPostsBreaking, {
            data
        })
        .then((response) => response.data);
};

/**
 * Load native ad posts
 *
 * @param {Object} data Data to pass
 *
 * @return Promise for resolve, reject
 */
export const readPostsNativeAd = function (data) {
    return axios
        .post(naRoutes.readPostsNativeAd, {
            data
        })
        .then((response) => response.data);
};

/**
 * Send a post comment
 *
 * @param {Object} data Comment data
 *
 * @return Promise for resolve, reject
 */
export const createCommentPost = function (data) {
    return axios
        .post(naRoutes.createCommentPost, {
            data
        })
        .then((response) => response.data);
};

/**
 * Update a post comment(s)
 *
 * @param {Object} data Comment data
 *
 * @return Promise for resolve, reject
 */
export const updateCommentPost = function (data) {
    return axios
        .post(naRoutes.updateCommentPost, {
            data
        })
        .then((response) => response.data);
};

/**
 * Read comments for a post
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const readCommentsPost = function (data) {
    return axios
        .post(naRoutes.readCommentsPost, {
            data
        })
        .then((response) => response.data);
};

/**
 * Read comments for a post
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const readRecentComments = function (data) {
    return axios
        .post(naRoutes.readRecentComments, {
            data
        })
        .then((response) => response.data);
};
/**
 * Get default post price
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const getDefaultPostPrice = function (data) {
    return axios
        .post(naRoutes.getDefaultPostPrice, {
            data
        })
        .then((response) => response.data);
};

/**
 * Record a miscellaneous post hit
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const recordPostHit = function (data) {
    return axios
        .post(naRoutes.recordPostHit, {
            data
        })
        .then((response) => response.data);
};

/**
 * Read posting requirements
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const readPostRequirements = function (data) {
    return axios
        .post(naRoutes.readPostRequirements, {
            data
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};
