<!-- @format -->
<template>
    <div v-if="isShowStandardSub || isGiftSub" class="na-ad-box-dock has-text-centered">
        <img
            v-if="isGiftSub"
            class="is-clickable"
            :src="imageUrl('SJ-KWGiftSubs-Ad.png')"
            alt="Give the gift of local news!"
            title="Gift the gift of local news!"
            width="250"
            height="250"
            loading="lazy"
            @click="clicked"
        />
        <img
            v-if="!isGiftSub && isShowStandardSub"
            class="is-clickable is-hidden-mobile"
            :src="imageUrl('NewsAtomic-SubscribeToSJ-250.jpg')"
            alt="Subscribe to the Shawangunk Journal"
            title="Subscribe to the Shawangunk Journal"
            width="250"
            height="250"
            loading="lazy"
            @click="clicked"
        />
        <img
            v-if="!isGiftSub && isShowStandardSub"
            class="is-clickable is-hidden-tablet"
            :src="imageUrl('NewsAtomic-SubscribeToSJ-250.jpg')"
            alt="Subscribe to the Shawangunk Journal"
            title="Subscribe to the Shawangunk Journal"
            width="250"
            height="250"
            loading="lazy"
            @click="clicked"
        />
    </div>
</template>

<script>
import { mediaMixin } from '@/mixins/MediaMixin.js';

export default {
    mixins: [mediaMixin],

    data() {
        return {
            isGiftSub: false,
            isShowStandardSub: true
        };
    },

    methods: {
        /**
         * Ad clicked
         */
        clicked() {
            if (this.isGiftSub) {
                this.$router.push({ name: 'gift-subscription-start' });
            } else {
                this.$router.push({ name: 'sjsubscribe' });
            }
        }
    }
};
</script>
