/**
 * Analytic event functions
 */
import { event } from 'vue-gtag';

 /**
  * Fire a registration
  */
export const analyticsEventFireNewReader = function () {
    event(
        'New Reader', {
            'event_category': 'Registrations',
            'event_label': 'Sign-up',
            'value': 20
        }
    );
};

/**
 * Fire a credits purchase.  This is meant to only be for credit purchase to read an article - not just for credits needed when purchasing a subscription for example.
 */
export const analyticsEventFirePurchaseCredits = function () {
    event(
        'Purchase Credits', {
            'event_category': 'Purchases',
            'event_label': 'Article Credits',
            'value': 25
        }
    );
};
 
/**
 * Fire a monthly subscription purchase.  This is meant for a first time purchase - NOT renewals.
 */
export const analyticsEventFirePurchaseMonthlySubscription = function () {
    event(
        'New Subscriber', {
            'event_category': 'Subscriptions',
            'event_label': 'Monthly',
            'value': 60
        }
    );
};
  
/**
 * Fire a yearly subscription purchase.  This is meant for a first time purchase - NOT renewals.
 */
export const analyticsEventFirePurchaseYearlySubscription = function () {
    event(
        'New Subscriber', {
            'event_category': 'Subscriptions',
            'event_label': 'Yearly',
            'value': 110
        }
    );
};
   
/**
 * Fire a newsletter sign-up.
 */
export const analyticsEventFireNewsletter = function () {
    event(
        'Sign-up', {
            'event_category': 'Registrations',
            'event_label': 'Newsletter',
            'value': 10
        }
    );
};

/**
 * Fire a test event.
 */
export const analyticsEventFireTest = function () {
    event(
        'New Subscriber', {
            'event_category': 'Testing',
            'event_label': 'Monthly',
            'value': 60
        }
    );
};
