<!-- @format -->
<template>
    <div class="na-register-what-is-this">
        <div v-if="mainPublication" class="columns is-centered">
            <div class="column is-two-thirds is-paddingless">
                <figure class="image">
                    <img
                        :src="mediaUrl(getPublicationMedia({ publication: mainPublication }, 'mediaTypeImage')[0])"
                        :alt="mainPublication.name"
                    />
                </figure>
            </div>
        </div>

        <div class="columns is-mobile">
            <div class="column">
                <p>
                    NewsAtomic is an innovative way to read news about your community, and provides access to a wide
                    range of material from the region's writers, publications and organizations.
                </p>
            </div>
        </div>

        <div class="columns is-mobile">
            <div class="column na-register-what-is-this-cta">
                <p class="has-text-centered has-text-weight-bold">How It Works</p>
                <p>
                    When you sign up, you get a free $1 reading account, good for 30 days. As you read articles, your
                    account balance will be decreased by the 'cost' of the article, typically 25&cent; or less. There
                    are no payments to make during the free period. When your balance gets low, it's easy to add more so
                    you can continue to read the publications you love.
                </p>
            </div>
        </div>

        <div class="columns is-mobile">
            <div class="column">
                <p>Ready to try it out? Just click Sign Me Up!</p>
            </div>
        </div>

        <div class="columns is-mobile">
            <div class="column">
                <a class="button is-success" @click="$emit('signup')">
                    <span class="icon is-small"><fa-icon icon="chevron-left" /></span>
                    <span>Sign Me Up!</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { postMixin } from '@/mixins/PostMixin.js';
import { mediaMixin } from '@/mixins/MediaMixin.js';
import { mapState } from 'vuex';

export default {
    mixins: [mediaMixin, postMixin],
    computed: {
        ...mapState({
            mainPublication: (state) => state.publication.mainPublication
        })
    }
};
</script>
