<!-- @format -->
<template>
    <div v-if="show">
        <div v-if="message && !dismissed[0]" class="notification is-success" :class="notificationClass">
            <button class="delete" @click="dismiss(0)"></button>
            {{ message }}
        </div>

        <template v-if="messages && messages.length">
            <template v-for="(m, index) in messages">
                <div v-if="!dismissed[index]" :key="index" class="notification is-success" :class="notificationClass">
                    <button class="delete" @click="dismiss(index)"></button>
                    {{ m }}
                </div>
            </template>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        message: {
            type: String,
            default: ''
        },
        messages: {
            type: Array,
            default: () => []
        },
        notificationClass: {
            type: String,
            default: ''
        },
        // Auto remove all messages after (n) milliseconds - null means not to fade out
        autoHide: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            dismissed: [],
            timer: null,
            show: true
        };
    },
    watch: {
        /**
         * Reset dismissed array when message changes
         */
        message() {
            this.dismissed = [];
            this.show = true;
        },
        /**
         * Reset dismissed array when messages changes
         */
        messages() {
            this.dismissed = [];
            this.show = true;
        }
    },
    mounted() {
        // Auto hide
        if (this.autoHide) {
            this.timer = setTimeout(() => {
                this.hideAll();
            }, this.autoHide);
        }
    },
    methods: {
        /**
         * Dismiss a message
         *
         * @param {Integer} index Array index
         */
        dismiss(index) {
            this.$set(this.dismissed, index, 1);

            // If there are no messages being displayed, tell the caller in case they want to do something:
            if (this.message) {
                if (this.dismissed.length === 1) {
                    this.$emit('closed');
                }
            } else {
                if (this.dismissed.length === this.messages.length) {
                    this.$emit('closed');
                }
            }
        },
        /**
         * Auto hide all messages
         */
        hideAll() {
            this.timer = null;
            this.dismissed = [];
            this.show = false;
            this.$emit('closed');
        }
    }
};
</script>
