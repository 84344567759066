<!-- @format -->
<template>
    <div class="columns">
        <div class="column">
            <div ref="playerContainer" class="has-text-centered video-container" :style="{ width: containerWidth, height: containerHeight }">
                <div class="iframe-wrapper">
                    <!-- Vimeo player iframe will be inserted here -->
                </div>
            </div>
            <div v-if="isShowCaption" class="na-post-video-caption-card">
                <span v-if="mediaCaption(video)" class="na-post-video-caption">{{ mediaCaption(video) }}</span>
                <span class="na-post-video-credit">{{ mediaCredit(video) }}</span>
            </div>

            <div class="na-post-video-caption-card">
                <div class="na-post-video-meta">
                    <video-meta :video-player="videoPlayer" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mediaMixin } from '@/mixins/MediaMixin.js';
import { postMixin } from '@/mixins/PostMixin.js';
import Player from '@vimeo/player';
import VideoMeta from '../Meta/Index.vue';

export default {
    components: {
        VideoMeta
    },

    mixins: [mediaMixin, postMixin],

    props: {
        // This is the video object, e.g. post.post_media_videos[0]
        video: {
            type: Object,
            required: true
        },
        showVideo: {
            type: Boolean,
            default: false
        },
        autoplay: {
            type: Boolean,
            default: false
        },
        loopVideo: {
            type: Boolean,
            default: false
        },
        isShowCaption: {
            type: Boolean,
            default: false
        },
        allowFullscreen: {
            type: Boolean,
            default: false
        },
        allowPip: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            videoPlayer: null,
            videoWidth: 0,
            videoHeight: 0,
            containerWidth: '100vw',
            containerHeight: '100vh'
        };
    },

    watch: {
        video() {
            //this.updateVideoDimensions();
            this.handleShowVideo();
        },
        showVideo() {
            //this.updateVideoDimensions();
            this.handleShowVideo();
        }
    },

    mounted() {
        if (this.showVideo) {
            this.handleShowVideo();
        }
    },

    methods: {
        /**
         * Destroy video player
         */
        async destroyPlayer() {
            await this.videoPlayer.unload();
            await this.videoPlayer.destroy();
            this.videoPlayer = null;
        },

        /**
         * Handle showing video or not.  Called when showVideo flag is toggled and upon initial mount.
         */
        handleShowVideo() {
            if (this.showVideo) {
                this.$nextTick(() => {
                    // Make sure DOM is updated first
                    if (this.video) {
                        this.doShowVideo(this.video);
                    }
                });
            } else {
                // Hide video if shown
                this.destroyPlayer();
            }
        },

        /**
         * Show video
         */
         doShowVideo(video) {
            // Destroy the existing player if it exists
            if (this.videoPlayer) {
                this.videoPlayer.destroy().then(() => {
                    this.videoPlayer = null;
                    this.createPlayer(video);
                });
            } else {
                this.createPlayer(video);
            }
        },
        updateVideoDimensions() {
            if (this.videoPlayer) {
                this.$nextTick(() => {
                    this.videoPlayer.getVideoWidth().then(width => {
                        this.videoWidth = width;
                        this.videoPlayer.getVideoHeight().then(height => {
                            this.videoHeight = height;

                            const aspectRatio = this.videoWidth / this.videoHeight;
                            const isMobile = window.innerWidth <= 768;

                            if (aspectRatio >= 1 && aspectRatio <= 1.2) {
                                // More of a square video
                                this.containerWidth = isMobile ? '80vw' : '60vw';
                                this.containerHeight = 'auto';
                            } else if (aspectRatio > 1.2) {
                                // Landscape video
                                this.containerWidth = isMobile ? '90vw' : '85vw';
                                this.containerHeight = 'auto';
                            } else {
                                // Portrait video
                                this.containerWidth = isMobile ? '65vw' : '35vw';
                                this.containerHeight = 'auto';
                            }
                        });
                    });
                });
            }
        },

        async createPlayer(video) {
            const options = {
                id: video.ident,
                loop: this.loopVideo,
                autoplay: this.autoplay,
                title: false,
                byline: false,
                portrait: false,
                responsive: true,
                pip: this.allowPip,
                fullscreen: this.allowFullscreen,
                //height: '640',
                //width: '320'
            };

            // Create a new player
            this.videoPlayer = new Player(this.$refs.playerContainer, options);
            await this.videoPlayer.ready();

            // Update video dimensions after creating the player
            this.updateVideoDimensions();
        }
    }
};
</script>
