<!-- @format -->
<template>
    <div class="is-flex is-flex-direction-column">
        <signup
            v-if="view === 'signup'"
            :header-text="headerText"
            :show-header="showHeader"
            :show-social="showSocial"
            :show-extra-info="showExtraInfo"
            :initial-email="initialEmail"
            :button-text="signupButtonText"
            @close="$emit('close')"
            @loggedIn="loggedIn"
        />
        <forgot-password
            v-if="view === 'forgot-password'"
            :email="email"
            :show-header="showHeader"
            @close-reset="$emit('close')"
            @close="view = 'login'"
        />
        <login
            v-if="view === 'login'"
            :header-text="headerText"
            :show-header="showHeader"
            :show-social="showSocial"
            :initial-email="initialEmail"
            @close="$emit('close')"
            @loggedIn="loggedIn"
            @signup="view = 'signup'"
            @forgotPassword="view = 'forgot-password'"
        />
    </div>
</template>

<script>
import Signup from '../Register/Index.vue';
import ForgotPassword from '../ForgotPassword/Index.vue';
import Login from './Login.vue';
import { mapMutations } from 'vuex';

export default {
    components: {
        Signup,
        ForgotPassword,
        Login
    },

    props: {
        headerText: {
            type: String,
            default: ''
        },
        initialEmail: {
            type: String,
            default: ''
        },
        initialView: {
            type: String,
            default: 'login'
        },
        showSocial: {
            type: Boolean,
            default: true
        },
        showHeader: {
            type: Boolean,
            default: true
        },
        signupButtonText: {
            type: String,
            default: ''
        },
        showExtraInfo: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            view: this.$route.query.auth_view ?? this.initialView,
            email: ''
        };
    },

    created() {
        this.setupGoogleLib();
        this.setupAppleSignInLib();
        this.setupFacebookSdk();
    },

    methods: {
        ...mapMutations({
            setAppleSignInLoaded: 'app/setAppleSignInLoaded',
            setFacebookSdkLoaded: 'app/setFacebookSdkLoaded'
        }),

        setupGoogleLib() {
            const src = 'https://apis.google.com/js/platform.js?onload=loadGoogleApi';
            const existing = document.head.querySelector(`script[src="${src}"]`);
            if (!existing) {
                const script = document.createElement('script');
                script.src = src;
                document.head.appendChild(script);
            }
        },
        setupAppleSignInLib() {
            const src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
            const existing = document.head.querySelector(`script[src="${src}"]`);
            if (!existing) {
                const script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', () => {
                    this.setAppleSignInLoaded(true);
                });
                document.head.appendChild(script);
            }
        },
        setupFacebookSdk() {
            const src = 'https://connect.facebook.net/en_US/sdk.js';
            const existing = document.head.querySelector(`script[src="${src}"]`);
            if (!existing) {
                const script = document.createElement('script');
                script.src = src;
                script.crossorigin = 'anonymous';
                script.addEventListener('load', () => {
                    window.FB.init({
                        appId: document.head.children['facebook-app_id'].content,
                        autoLogAppEvents: true,
                        xfbml: false,
                        version: 'v12.0'
                    });
                    this.setFacebookSdkLoaded(true);
                });
                document.head.appendChild(script);
            }
        },

        close() {
            this.$emit('close');
        },
        loggedIn() {
            this.$emit('loggedIn');
        }
    }
};
</script>
