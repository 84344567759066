<!-- @format -->
<template>
    <div class="na-sidebar-dock-container">
        <p class="na-sidebar-quicklinks">Quicklinks</p>
        <template v-if="userIsLoggedIn">
            <p class="na-sidebar-quicklinks-item">
                <router-link :to="{ name: 'account' }">MY ACCOUNT</router-link>
            </p>
            <p class="na-sidebar-quicklinks-text">Manage your account and preferences</p>
        </template>

        <p class="na-sidebar-quicklinks-item">
            <router-link :to="{ name: 'about' }">ABOUT US</router-link>
        </p>

        <p class="na-sidebar-quicklinks-item">
            <router-link :to="{ name: 'faq' }">FAQ</router-link>
        </p>

        <p class="na-sidebar-quicklinks-item">
            <router-link :to="{ name: 'terms' }">PRIVACY / TERMS OF SERVICE</router-link>
        </p>

        <template v-if="!userIsLoggedIn">
            <p class="na-sidebar-quicklinks-item">
                <router-link :to="{ name: 'home', query: { auth_view: 'login' } }">REGISTER &amp; READ</router-link>
            </p>
            <p class="na-sidebar-quicklinks-text">Get a FREE one month trial account</p>
        </template>

        <p class="na-sidebar-quicklinks-item">
            <router-link :to="{ name: 'contact' }">LEARN MORE</router-link>
        </p>

        <component :is="publicationSubdomainComponent" />
    </div>
</template>

<script>
import { publicationMixin } from '@/mixins/PublicationMixin.js';
import PublicationDefault from './Publication/Default/Index.vue';
import PublicationKingstonwire from './Publication/Kingstonwire/Index.vue';
import PublicationScDemocrat from './Publication/ScDemocrat/Index.vue';
import PublicationShawangunkJournal from './Publication/ShawangunkJournal/Index.vue';

export default {
    components: {
        PublicationDefault,
        PublicationKingstonwire,
        PublicationScDemocrat,
        PublicationShawangunkJournal
    },

    mixins: [publicationMixin]
};
</script>
