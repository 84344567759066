<!-- @format -->
<template>
    <span>
        <share-to-email v-if="shareTo === 'email'" :post="post" @shared="trackIt"></share-to-email>

        <share-to-facebook v-if="shareTo === 'facebook'" :post="post" @shared="trackIt"></share-to-facebook>

        <share-to-twitter v-if="shareTo === 'twitter'" :post="post" @shared="trackIt"></share-to-twitter>

        <share-to-clipboard v-if="shareTo === 'clipboard'" :post="post" @shared="trackIt"></share-to-clipboard>
    </span>
</template>

<script>
import { postShareLog } from '@/services/share.js';
import ShareToClipboard from './Clipboard.vue';
import ShareToEmail from './Email.vue';
import ShareToFacebook from './Facebook.vue';
import ShareToTwitter from './Twitter.vue';

export default {
    components: {
        ShareToClipboard,
        ShareToEmail,
        ShareToFacebook,
        ShareToTwitter
    },

    props: {
        post: {
            type: Object,
            required: true
        },
        shareTo: {
            type: String,
            required: true
        }
    },
    methods: {
        /**
         * Track the share
         *
         * @param {String} shared Data we shared
         */
        trackIt(shared) {
            postShareLog({
                postId: this.post.id,
                shareTo: shared.shareTo,
                url: shared.url
            });
        }
    }
};
</script>
