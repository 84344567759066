/**
 * @format
 * User mixins
 */
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('user');
import { impersonateUserOff } from '@/services/adminUser.js';
import { userDataLoadInfo } from '@/services/user';

export const userMixin = {
    computed: {
        ...mapGetters({
            userIsLoggedIn: 'isLoggedIn',
            userIsImpersonating: 'isImpersonating',
            userGetBalance: 'balance',
            userCurrent: 'user',
            canAccessAdministration: 'canAccessAdministration',
            userCanAccessAdministration: 'canAccessAdministration',
            userType: 'userType',
            userPhysicalAddress: 'physicalAddress',
            userRestrictedToPublicationIds: 'restrictedToPublicationIds',
            userHasUnrestrictedAdminAccess: 'hasUnrestrictedAdmin',
            userHighestRole: 'highestRole',
            userRecentPost: 'recentPost'
        }),
        /**
         * Return the publisher that the user belongs to
         *
         * @return String
         */
        userPublisher() {
            return this.userHighestRole && this.userHighestRole.publisher
                ? this.userHighestRole.publisher.name
                : '(Master)';
        }
    },
    methods: {
        /**
         * Load user info
         *
         * @param {Function} callback Function to call when complete, null if you don't want one called
         */
        async loadUserInfo() {
            const response = await userDataLoadInfo();
            if (response.success) {
                console.log('loadUserInfo, response=', response.userData);
                this.$store.dispatch('user/loadFromObject', response.userData);
                return response.userData;
            }
        },

        /**
         * Return current user info
         *
         * @return {Object} User object or null if none
         */
        /*
        userCurrent () {
            return (window && window.naGlobal && window.naGlobal.user && window.naGlobal.user.user ? window.naGlobal.user.user : null);
        },
        */
        /**
         * Determine if admin user has access to given function.  This is VERY basic right now - will expand as we go.
         * @todo  2019-06-27  MJL  This should be broken out to it's own store/mixin - maybe we need to consider granular permissions vs. strictly role based too
         *
         * @param {String} access Access request
         * @param {Object} user   If null, we'll assume the current user
         *
         * @return {Boolean}
         */
        userAdminHasAccessTo(access, user = null) {
            let result = false; // Assume no access
            const userCheck = user ? user : this.userCurrent;

            switch (access) {
                case 'authors':
                    result =
                        userCheck &&
                        (this.userHasRole(userCheck, 'roleAdmin') ||
                            this.userHasRole(userCheck, 'roleSuperAdmin'));
                    break;
                case 'post-comments':
                    result =
                        userCheck &&
                        (this.userHasRole(userCheck, 'roleAdmin') ||
                            this.userHasRole(userCheck, 'roleSuperAdmin'));
                    break;
                case 'feed-import':
                    result =
                        userCheck &&
                        (this.userHasRole(userCheck, 'roleAdmin') ||
                            this.userHasRole(userCheck, 'roleSuperAdmin'));
                    break;
                case 'mailings-home':
                    result =
                        userCheck &&
                        this.userHasRole(userCheck, 'roleSuperAdmin');
                    break;
                case 'publication-pay-to-publish-minimums':
                    result =
                        userCheck &&
                        (this.userHasRole(userCheck, 'roleAdmin') ||
                            this.userHasRole(userCheck, 'roleSuperAdmin')) &&
                        this.userHasUnrestrictedAdminAccess;
                    //result = (userCheck && this.userHasRole(userCheck, 'roleSuperAdmin') && this.userHasUnrestrictedAdminAccess);
                    break;
            }

            return result;
        },
        /**
         * Check if user has current role.  We're just checking locally, so check on server side as well!
         *
         * @param {Object} user User object
         * @param {String} role Role name (e.g. roleAdmin)
         *
         * @return {Boolean}
         */
        userHasRole(user, role) {
            if (user && user.roles) {
                let findRole = user.roles.find((item) => {
                    return (
                        item && item.role_type && item.role_type.key === role
                    );
                });
                return findRole !== undefined;
            } else {
                return false;
            }
        },
        /**
         * Determine if user can access a particular publication for admin
         *
         * @param {Integer} publicationId
         *
         * @return {Boolean}
         */
        userAdminCanAccessPublicationId(publicationId) {
            return (
                this.userRestrictedToPublicationIds === null ||
                this.userRestrictedToPublicationIds.includes(publicationId)
            );
        },
        /**
         * Disable user impersonation
         */
        async userImpersonateOff() {
            const response = await impersonateUserOff({});
            if (response.success) {
                this.$swal({
                    title: 'Impersonate user disabled',
                    text: 'Welcome back!',
                    type: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Continue',
                    confirmButtonClass: 'button is-success',
                    buttonsStyling: false
                }).then(
                    () => {
                        this.clientRedirectToHome();
                    },
                    () => {
                        this.clientRedirectToHome();
                    }
                );
            } else {
                this.$swal({
                    title: 'Could not disable impersonation',
                    text: 'Unable to disable impersonation',
                    type: 'error',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Continue',
                    confirmButtonClass: 'button is-error',
                    buttonsStyling: false
                }).then(
                    () => {
                        this.clientRedirectToHome();
                    },
                    () => {
                        this.clientRedirectToHome();
                    }
                );
            }
        },
        /**
         * Check that user can access administration and if not, redirect them home
         *
         * @return {Boolean} True if can access, false if not
         */
        checkAdministration() {
            if (this.userCanAccessAdministration) {
                this.clientRedirectToHome();
                return false;
            } else {
                return true;
            }
        }
    }
};
