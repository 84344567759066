<!-- @format -->
<template>
    <div>
        <p v-if="error" class="help is-danger">{{ error }}</p>
        <template v-else>
            <p v-for="(inputError, index) in inputErrors" :key="index" class="help is-danger">{{ inputError }}</p>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        field: {
            // May be a single field name to match or an array of field names
            type: [Array, String],
            default: function () {
                return null;
            }
        },
        error: {
            type: String,
            default: ''
        },
        errors: {
            type: [Array, Object],
            default: function () {
                return [];
            }
        },
        errorDelimiter: {
            type: String,
            default: '|'
        }
    },
    computed: {
        /**
         * Return array of input errors for given field name
         *
         * @return {Array} Array of error messages
         */
        inputErrors() {
            let result = [];
            const fields = Array.isArray(this.field) ? this.field : [this.field]; // Field(s) to check into an array if not already

            if (this.errors && Array.isArray(this.errors)) {
                this.errors.forEach((item) => {
                    if (typeof item === 'string') {
                        const parts = item.split(this.errorDelimiter);
                        if (fields.includes(parts[0])) {
                            result.push(parts[1]);
                        }
                    }
                });
            } else if (this.errors) {
                result = this.errors[this.field];
            }
            return result;
        }
    }
};
</script>
