/**
 * @format
 * Video related mixins.
 */
import moment from 'moment';

import {
    vimeoIsVideoExists,
    vimeoGetMeta,
    vimeoGetMetaThumbnail,
    vimeoGetMetaDuration
} from '@/services/video/providers/vimeo.js';

export const videoMixin = {
    methods: {
        /**
         * Check if vimeo video exists
         *
         * @param {Integer} id Video id
         *
         * @return Promise, resolve with object that contains response, reject with error message and error response
         */
        isVideoExists(id) {
            return vimeoIsVideoExists(id);
        },
        /**
         * Return formatted video duration
         *
         * @param {Integer} iDuration In seconds
         *
         * @return {String} Formatted duration, or null if none
         */
        videoFormattedDuration(iDuration) {
            if (iDuration) {
                const duration = parseInt(iDuration);
                let minutes = moment.duration(duration, 'seconds').minutes();
                minutes += minutes === 1 ? ' minute' : ' minutes';
                let seconds = moment.duration(duration, 'seconds').seconds();
                seconds += seconds === 1 ? ' second' : ' seconds';

                return minutes + ', ' + seconds;
            } else {
                return null;
            }
        },
        /**
         * Get video meta data
         *
         * @param {Integer} id Video id
         *
         * @return Promise, resolve with object that contains response, reject with error message and error response
         */
        videoGetMeta(id) {
            return vimeoGetMeta(id);
        },
        /**
         * Get a thumbnail url from video meta data
         *
         * @param {Object} meta Video meta data
         * @param {String} size Image size wanted (small, medium, large)
         *
         * @return {String} url (or null if we couldn't determine)
         */
        videoGetMetaThumbnail(meta, size) {
            return vimeoGetMetaThumbnail(meta, size);
        },
        /**
         * Get duration of video from video meta data
         *
         * @param {Object} meta Video meta data
         *
         * @return {Integer} Length in seconds (or null if we couldn't determine)
         */
        videoGetMetaDuration(meta) {
            return vimeoGetMetaDuration(meta);
        }
    }
};
