<!-- @format -->
<template>
    <div class="is-flex is-flex-direction-column">
        <div class="na-subscription-terms-header">
            <div class="columns is-gapless mb-0">
                <div class="column is-8-tablet">
                    <p class="subtitle standout na-subscription-terms-product-title">
                        {{ productTypeFriendlyName(term.productType) }} Subscription
                    </p>
                </div>

                <div class="column is-4-tablet is-hidden-mobile">
                    <p class="na-subscription-step">
                        <slot name="stepNumber" />
                    </p>
                </div>
            </div>

            <div class="columns is-gapless">
                <div class="column">
                    <p class="subtitle standout">
                        {{ publication.text }}
                    </p>
                </div>
            </div>
        </div>

        <form @submit.prevent="next">
            <div class="na-subscription-cta">
                <div class="columns is-gapless is-mobile">
                    <div class="column">
                        <publication-sj v-if="publicationIsShawangunkJournal(publication.id)" />
                        <publication-da v-if="publicationIsDevilsAdvocate(publication.id)" />
                        <publication-mi v-if="publicationIsManorInk(publication.id)" />
                        <publication-dhc v-if="publicationIsDhCanvas(publication.id)" />
                        <publication-bkaa v-if="publicationIsBashaKill(publication.id)" />
                        <publication-kw v-if="publicationIsKingstonwire(publication.id)" />
                    </div>
                </div>
            </div>

            <pay-level
                :pay-level="payLevel"
                :publication="publication"
                :term="term"
                @pay-level-selected="payLevelSelected"
            />

            <div class="columns na-subscription-terms-termtype-pay-select">
                <div class="column">
                    <button type="submit" class="button is-success active">
                        <span v-if="!termCost">CHOOSE AMOUNT</span>
                        <span v-else>
                            PAY {{ formatCost(termCost) }}
                            {{ term.termType.value ? term.termType.text.toUpperCase() : '' }}
                        </span>
                    </button>

                    <button class="button is-hidden-tablet" @click="$emit('prev')">BACK</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { mediaMixin } from '@/mixins/MediaMixin.js';
import { moneyMixin } from '@/mixins/MoneyMixin.js';
import { publicationMixin } from '@/mixins/PublicationMixin.js';
import { subscriptionHelperMixin } from '../mixins/SubscriptionHelperMixin.js';
import PayLevel from './PayLevel.vue';
import PublicationBkaa from './Publications/BashaKill.vue';
import PublicationDa from './Publications/DevilsAdvocate.vue';
import PublicationDhc from './Publications/DHCanvas.vue';
import PublicationKw from './Publications/Kingstonwire.vue';
import PublicationMi from './Publications/ManorInk.vue';
import PublicationSj from './Publications/ShawangunkJournal.vue';

export default {
    components: {
        PayLevel,
        PublicationBkaa,
        PublicationDa,
        PublicationDhc,
        PublicationKw,
        PublicationMi,
        PublicationSj
    },

    mixins: [mediaMixin, moneyMixin, publicationMixin, subscriptionHelperMixin],

    props: {
        publication: {
            type: Object,
            required: true
        },
        term: {
            type: Object,
            required: true
        },
        payLevel: {
            type: Object,
            required: true
        },
        subscriptionTerms: {
            type: Array,
            required: true
        }
    },

    computed: {
        /**
         * Return savings from going with yearly vs monthly
         *
         * @return {mixed} savings amount; null if both monthly and yearly not defined
         */
        savings() {
            return this.savingsYearly(this.subscriptionTerms, this.term.productType);
        },
        /**
         * Return term type name
         *
         * @return {String} null if we couldn't determine
         */
        termTypeName() {
            return this.term.termType && this.term.termType.text ? this.termTypeFriendlyName(this.term.termType) : null;
        },
        termCost() {
            return this.payLevel.value;
        }
    },

    methods: {
        /**
         * Pay level selected
         *
         * @param {Object} payLevel
         */
        payLevelSelected(payLevel) {
            this.$emit('update:payLevel', payLevel);
        },

        /**
         * User chose level and clicked the pay button
         */
        next() {
            this.$emit('pay-level-accepted');
        }
    }
};
</script>
