<!-- @format -->
<template>
    <base-layout :group="group" group-title="LEGAL NOTICES" :show-subtitle="false" show-authors>
        <template slot="groupImage">
            <figure class="image">
                <img :src="imageUrl('sections/section-default-gavel.jpg')" alt="Public notice image" />
            </figure>
        </template>
    </base-layout>
</template>

<script>
import { mediaMixin } from '@/mixins/MediaMixin.js';
import BaseLayout from './BaseLayout.vue';

export default {
    components: {
        BaseLayout
    },

    mixins: [mediaMixin],

    props: {
        group: {
            type: Array,
            required: true
        }
    }
};
</script>
