<!-- @format -->
<template>
    <div class="na-sidebar-dock-container">
        <div class="mt-1">
            <router-link
                v-if="userIsLoggedIn"
                class="has-text-primary is-uppercase has-text-weight-bold is-size-7"
                :to="{ name: 'section', params: { sectionRoute: route } }"
                >{{ sectionTitle }}</router-link
            >
            <span v-else class="has-text-primary is-uppercase has-text-weight-bold is-size-7">{{ sectionTitle }}</span>
        </div>

        <article v-for="post in posts" :key="post.id" class="is-flex is-flex-direction-column is-flex-grow-1 mb-1">
            <h1 v-if="post.title">
                <router-link
                    class="is-family-secondary has-text-black has-text-weight-bold is-size-7"
                    :to="buildRouteParams(post)"
                    >{{ post.title }}</router-link
                >
            </h1>
            <publication-line :post="post" />
        </article>

        <p v-if="loadMoreEnabled" class="na-intro-more">
            <button
                class="button is-ghost is-small pl-0"
                :class="{
                    'is-loading': isBusy
                }"
                :disabled="isBusy"
                @click="$emit('fetch-more', { type, limit: loadMoreBatch, offset: posts.length })"
            >
                <span>Load More <fa-icon icon="chevron-right" /></span>
            </button>
        </p>
    </div>
</template>

<script>
import { buildRouteParams } from '@/helpers/post.js';
import PublicationLine from '@/components/Common/PublicationLine.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'SidebarSectionBase',
    components: {
        PublicationLine
    },

    props: {
        title: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            required: true
        },
        route: {
            type: String,
            required: true
        },
        posts: {
            type: Array,
            required: true
        },
        isBusy: {
            type: Boolean,
            default: false
        },
        loadMoreEnabled: {
            type: Boolean,
            default: false
        },
        loadMoreBatch: {
            type: Number,
            default: 3
        }
    },

    computed: {
        ...mapGetters({
            getSectionByRoute: 'user/getSectionByRoute'
        }),
        section() {
            return this.getSectionByRoute(this.route);
        },
        sectionTitle() {
            return this.title ? this.title : this.section.name;
        }
    },

    methods: {
        buildRouteParams
    }
};
</script>
