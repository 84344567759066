<!-- @format -->
<template>
    <div class="box">
        <template v-if="!loading">
            <intro
                v-if="view === 'intro'"
                :publication="publication"
                :is-end-trial="isEndTrial"
                @subscribe-click="$emit('subscribe')"
                @ez-read-click="view = 'ez-read'"
                @ez-read-description-click="view = 'ez-read-description'"
            />
            <ez-read
                v-else-if="view === 'ez-read'"
                :post="post"
                :publication="publication"
                :is-end-trial="isEndTrial"
                @success="view = 'success'"
                @cancel="cancel"
            />
            <ez-read-description
                v-else-if="view === 'ez-read-description'"
                :publication="publication"
                @back="view = 'intro'"
            />

            <transaction-success v-else-if="view === 'success'" @close="$emit('added')" />
        </template>
        <p v-else class="has-text-centered">
            <busy-loader />
        </p>
    </div>
</template>

<script>
import { readPublication } from '@/services/publication';
import Intro from './Intro.vue';
import EzRead from './EzRead.vue';
import EzReadDescription from './EzReadDescription.vue';
import TransactionSuccess from './TransactionSuccess.vue';
import moment from 'moment';

export default {
    components: {
        Intro,
        EzRead,
        EzReadDescription,
        TransactionSuccess
    },

    props: {
        post: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            loading: true,
            view: 'intro',
            isEndTrial: false,
            reloadAmount: 10,
            publication: null
        };
    },

    computed: {
        activeSubscriptionTerms() {
            return this.publication?.subscription_terms?.filter((term) => term.is_active) ?? [];
        }
    },

    async created() {
        this.loading = true;

        const userCreatedAt = moment(this.userCurrent.created_at);
        const diff = moment.duration(moment().diff(userCreatedAt));
        // 2 months or less
        this.isEndTrial = diff.months() < 2;

        const response = await readPublication({ id: this.post.publication_id });
        this.publication = response.publication;

        if (!this.activeSubscriptionTerms.length) {
            this.view = 'ez-read';
        }
        this.loading = false;
    },

    methods: {
        /**
         * Cancel ezread - either this closes completely - or if there is a subscription option, just show the intro again
         */
        cancel() {
            if (this.activeSubscriptionTerms.length) {
                this.view = 'intro';
            } else {
                this.$emit('close');
            }
        }
    }
};
</script>
