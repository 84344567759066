<!-- @format -->
<template>
    <div class="is-flex" :class="align === 'center' && 'is-justify-content-center'">
        <button
            type="button"
            class="button na-bg-apple"
            :title="initText + ' Apple'"
            :disabled="!appleSignInLoaded"
            @click="$emit('click', 'apple')"
        >
            <span class="icon is-small m-0"><fa-icon :icon="['fab', 'apple']" /></span
            ><span :class="hideTextOnMobile ? 'is-hidden-mobile' : ''">{{ initText }} Apple</span>
        </button>
        <button
            type="button"
            class="button na-bg-facebook"
            :title="initText + ' Facebook'"
            :disabled="!facebookSdkLoaded"
            @click="$emit('click', 'facebook')"
        >
            <span class="icon is-small m-0"><fa-icon :icon="['fab', 'facebook-f']" /></span
            ><span :class="hideTextOnMobile ? 'is-hidden-mobile' : ''">{{ initText }} Facebook</span>
        </button>
        <button
            type="button"
            class="button na-bg-google"
            :title="initText + ' Google'"
            :disabled="!googleAuthClientLoaded"
            @click="$emit('click', 'google')"
        >
            <span class="icon is-small m-0"><fa-icon :icon="['fab', 'google']" /></span
            ><span :class="hideTextOnMobile ? 'is-hidden-mobile' : ''">{{ initText }} Google</span>
        </button>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: {
        initText: {
            type: String,
            default: 'Log in with'
        },
        align: {
            type: String,
            default: 'center'
        },
        hideTextOnMobile: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapState({
            googleAuthClientLoaded: (state) => state.app.googleAuthClientLoaded,
            appleSignInLoaded: (state) => state.app.appleSignInLoaded,
            facebookSdkLoaded: (state) => state.app.facebookSdkLoaded
        })
    }
};
</script>
