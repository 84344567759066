<!-- @format -->
<template>
    <div class="columns">
        <div class="column">
            <div class="columns">
                <div class="column">
                    <h1 class="has-text-centered">Reset Password</h1>
                </div>
            </div>

            <div class="columns is-mobile">
                <div class="column">
                    <login-email :email.sync="email" :errors="inputErrors" />
                </div>
            </div>

            <div class="my-2">
                <div class="columns is-mobile">
                    <div class="column">
                        <a class="button is-success" :class="{ 'is-loading': isBusy }" @click="doSend">
                            <span class="icon is-small">
                                <fa-icon icon="check" />
                            </span>
                            <span> Reset </span>
                        </a>

                        <a class="button is-danger" @click="doCancel">
                            <span class="icon is-small">
                                <fa-icon icon="times" />
                            </span>
                            <span> Cancel </span>
                        </a>
                    </div>
                </div>
            </div>
            <div v-if="errors && errors.length" class="columns is-mobile">
                <div class="column">
                    <show-error :errors="errors" @closed="errors = []"></show-error>
                </div>
            </div>

            <div v-if="messages && messages.length" class="columns is-mobile">
                <div class="column">
                    <show-success :messages="messages"></show-success>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ShowError from '@/components/Common/Error.vue';
import ShowSuccess from '@/components/Common/Success.vue';
import LoginEmail from './Email.vue';
import { userForgotPassword } from '@/services/user';

export default {
    components: {
        LoginEmail,
        ShowError,
        ShowSuccess
    },

    props: {
        redirectHome: {
            type: Boolean,
            // Redirect to homepage?  Only needed if we're using this component as a stand-alone route
            default: false
        }
    },

    data() {
        return {
            isBusy: false,
            messages: [],
            errors: [],
            inputErrors: [],
            email: '',
            isShow: true
        };
    },

    methods: {
        /**
         * Try to send link
         */
        async doSend() {
            this.isBusy = true;
            this.messages = [];
            this.errors = [];

            try {
                const response = await userForgotPassword(this.email);
                this.isBusy = false;
                if (response.success) {
                    this.closeReset();
                    this.showSuccess(response.url);
                } else {
                    this.inputErrors = response.inputErrors ? response.inputErrors : [];
                    this.errors = response.errors;
                }
            } catch (error) {
                this.isBusy = false;
            }
        },
        /**
         * Cancel
         */
        doCancel() {
            this.messages = [];
            this.errors = [];
            this.$emit('close'); // Emit to parent
            if (this.redirectHome) {
                this.doRedirectHome();
            }
        },
        /**
         * Redirect to home
         */
        doRedirectHome() {
            this.clientRedirectToHome();
        },
        /**
         * Close the modal
         */
        close() {
            this.messages = [];
            this.errors = [];
            this.$emit('close'); // Emit to parent
            if (this.redirectHome) {
                this.doRedirectHome();
            }
        },
        /**
         * Close the modal - user asked for reset request
         */
        closeReset() {
            this.messages = [];
            this.errors = [];
            this.$emit('close-reset'); // Emit to parent
            if (this.redirectHome) {
                this.doRedirectHome();
            }
        },
        /**
         * Show the thank you popup
         *
         * @param {String} redirectTo URL to redirect to
         */
        showSuccess() {
            this.isShow = false;
            const self = this;
            this.$swal({
                title: 'Reset Password',
                text: 'Please check your email for instructions to complete the password reset.',
                type: 'success',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Continue',
                confirmButtonClass: 'button is-success',
                buttonsStyling: false
            }).then(
                () => {
                    self.closeReset();
                    //this.clientRedirectTo(redirectTo);
                },
                () => {
                    self.closeReset();
                    // There is no cancel...
                    //this.clientRedirectTo(redirectTo);
                }
            );
        }
    }
};
</script>
