<!-- @format -->
<template>
    <div class="is-flex is-flex-direction-column mb-2">
        <h2 v-if="showTitle && post.title" class="is-family-secondary is-size-6 has-text-weight-bold">
            <router-link class="has-text-black" :to="toRoute">{{ post.title }}</router-link>
        </h2>

        <h3 v-if="showSubtitle && post.subtitle" class="is-family-secondary has-text-weight-bold is-size-6 is-italic">
            <router-link class="has-text-black" :to="toRoute">{{ post.subtitle }}</router-link>
        </h3>

        <h4
            v-if="showAuthors && Array.isArray(post.post_authors) && post.post_authors.length"
            class="has-text-grey-dark is-size-8"
        >
            By {{ postAuthorFormattedList(post.post_authors) }}
        </h4>

        <publication-line
            v-if="showPublicationIcon || showPublicationName || showContentAt"
            :post="post"
            :show-icon="showPublicationIcon"
            :show-name="showPublicationName"
            :show-date="showContentAt"
        />

        <router-link v-if="showSnippet10" :to="toRoute">
            <div class="is-family-secondary has-text-black is-size-6" v-html="postPreview"></div>
        </router-link>

        <slot :post="post" />
    </div>
</template>

<script>
import { mediaMixin } from '@/mixins/MediaMixin.js';
import { postMixin } from '@/mixins/PostMixin.js';
import { buildRouteParams } from '@/helpers/post.js';
import PublicationLine from '@/components/Common/PublicationLine.vue';

export default {
    components: {
        PublicationLine
    },

    mixins: [mediaMixin, postMixin],

    props: {
        post: {
            type: Object,
            required: true
        },
        showTitle: {
            type: Boolean,
            default: true
        },
        showSubtitle: {
            type: Boolean,
            default: true
        },
        showAuthors: {
            type: Boolean,
            default: false
        },
        showPublicationIcon: {
            type: Boolean,
            default: true
        },
        showPublicationName: {
            type: Boolean,
            default: true
        },
        showContentAt: {
            type: Boolean,
            default: true
        },
        showSnippet10: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        publicationText() {
            return this.postPublicationFormatted(this.post.publication);
        },
        postDate() {
            return this.postContentAtFormat(this.post, 'MMMM D');
        },
        postPreview() {
            return this.postPreviewSnippet(this.post, { snippet10: true });
        },
        publicationIcon() {
            return this.getPublicationMedia(this.post, 'mediaTypeIcon')[0];
        },
        toRoute() {
            return buildRouteParams(this.post);
        }
    }
};
</script>
