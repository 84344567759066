<!-- @format -->
<template>
    <div class="na-ad-box-dock has-text-centered">
        <router-link class="is-hidden-mobile" :to="{ name: 'ad' }"
            ><img
                class="na-link na-ad-image"
                :src="imageUrl('NewsAtomic-AdvertiseNA2020.jpg')"
                alt="Advertise on NewsAtomic"
                title="Advertise on NewsAtomic"
                width="250"
                height="250"
                loading="lazy"
        /></router-link>
        <router-link class="is-hidden-tablet" :to="{ name: 'ad' }"
            ><img
                class="na-link na-ad-image"
                :src="imageUrl('NewsAtomic-AdvertiseNA2020.jpg')"
                alt="Advertise on NewsAtomic"
                title="Advertise on NewsAtomic"
                width="250"
                height="250"
                loading="lazy"
        /></router-link>
    </div>
</template>

<script>
import { mediaMixin } from '@/mixins/MediaMixin.js';

export default {
    mixins: [mediaMixin]
};
</script>
