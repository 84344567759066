<!-- @format -->
<template>
    <section-base
        type="blotter"
        route="police-blotter"
        :posts="posts"
        :is-busy="isBusy"
        load-more-enabled
        @fetch-more="$emit('fetch-more', $event)"
    />
</template>

<script>
import SectionBase from './SectionBase.vue';

export default {
    components: {
        SectionBase
    },

    props: {
        posts: {
            type: Array,
            required: true
        },
        isBusy: {
            type: Boolean,
            required: true
        }
    }
};
</script>
