<!-- @format -->
<template>
    <span class="na-subscription-step-number is-hidden-mobile">
        <span v-for="(step, index) in steps" :key="step.name">
            <fa-icon v-if="index > 0" icon="minus" size="2x" />
            <fa-layers
                class="fa-2x"
                :class="[index < currentStepOrder ? 'is-clickable' : null]"
                @click="linkToStep(index)"
            >
                <fa-icon :icon="[index === currentStepOrder ? 'fas' : 'far', 'circle']" />
                <fa-layers-text
                    :class="[
                        index === currentStepOrder ? 'na-subscription-step-active' : 'na-subscription-step-inactive'
                    ]"
                    :value="index + 1"
                />
            </fa-layers>
        </span>
    </span>
</template>

<script>
export default {
    props: {
        steps: {
            type: Array,
            required: true
        },
        currentStep: {
            type: String,
            required: true
        }
    },

    computed: {
        currentStepOrder() {
            return this.steps.indexOf(this.currentStep);
        }
    },

    methods: {
        /**
         * User clicked on a step link
         *
         * @param {Integer} step Step number
         */
        linkToStep(index) {
            if (index < this.currentStepOrder) {
                this.$emit('step-selected', this.steps[index]);
            }
        }
    }
};
</script>
