<!-- @format -->
<template>
    <div class="field is-grouped">
        <div class="control is-expanded" :class="hasInputError(errors, 'first_name')">
            <input
                ref="firstName"
                v-model.trim="first_name"
                class="input"
                type="text"
                placeholder="First name"
                required
            />
            <input-error field="first_name" :errors="errors" />
        </div>
        <div class="control is-expanded" :class="hasInputError(errors, 'last_name')">
            <input v-model.trim="last_name" class="input" type="text" placeholder="Last name" required />
            <input-error field="last_name" :errors="errors" />
        </div>
    </div>
</template>

<script>
import InputError from '@/components/Common/InputError.vue';

export default {
    components: {
        InputError
    },

    props: {
        formData: {
            type: Object,
            required: true
        },
        errors: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            first_name: null,
            last_name: null
        };
    },

    watch: {
        first_name() {
            this.updateFormData();
        },
        last_name() {
            this.updateFormData();
        }
    },

    mounted() {
        this.updateFormData(); // Init
        this.$refs.firstName.focus();
    },

    methods: {
        /**
         * Update form data for parent
         */
        updateFormData() {
            this.$emit('update:formData', {
                firstName: this.first_name,
                lastName: this.last_name
            });
        }
    }
};
</script>
