<!-- @format -->
<template>
    <base-layout :group="group" group-title="OBITUARIES" :show-subtitle="false">
        <template slot="groupImage">
            <figure class="image">
                <img :src="imageUrl('sections/obit.png')" alt="Obituary image" />
            </figure>
        </template>
    </base-layout>
</template>

<script>
import { mediaMixin } from '@/mixins/MediaMixin.js';
import BaseLayout from './BaseLayout.vue';

export default {
    components: {
        BaseLayout
    },

    mixins: [mediaMixin],

    props: {
        group: {
            type: Array,
            required: true
        }
    }
};
</script>
