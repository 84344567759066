/**
 * Publication related API functions
 * Abstracting these out from DataMixin.js.
 */
import axios from 'axios';

/**
 * Create a new publication
 *
 * @param {Object} data Publication data to be saved
 *
 * @return Promise for resolve, reject
 */
export const createPublication = function (data) {
    return axios.post(naRoutes.createPublication,
        {
            data
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Load a publication
 *
 * @param {Integer} publicationId Publication ID to be loaded
 *
 * @return Promise for resolve, reject
 */
export const readPublication = function ({ id, name, slug }) {
    return axios.post(naRoutes.readPublication,
        {
            data: {
                id,
                name,
                slug
            }
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Update a publication
 *
 * @param {Object} data Publication data to be saved
 *
 * @return Promise for resolve, reject
 */
export const updatePublication = function (data) {
    return axios.post(naRoutes.updatePublication,
        {
            data
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Delete a publication
 *
 * @param {Integer} publicationId Publication ID to be deleted
 *
 * @return Promise for resolve, reject
 */
export const deletePublication = function (publicationId) {
    return axios.post(naRoutes.deletePublication,
        {
            data: {
                id: publicationId
            }
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Load publications
 *
 * @param {Object} data
 *
 * @return Promise for resolve, reject
 */
export const readPublications = function (data) {
    return axios.post(naRoutes.readPublications,
        {
            data
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};
