<!-- @format -->
<template>
    <base-layout :group="group" show-snippet10 group-title="COLUMNS" />
</template>

<script>
import BaseLayout from './BaseLayout.vue';

export default {
    components: {
        BaseLayout
    },
    props: {
        group: {
            type: Array,
            required: true
        }
    }
};
</script>
