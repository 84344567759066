<!-- @format -->
<template>
    <div class="modal is-active na-modal-general">
        <div class="modal-background na-modal-general-background"></div>
        <div
            class="
                modal-content
                na-modal-general-content na-modal-padded-content na-modal-640px-content na-subscription-added
            "
        >
            <div class="columns">
                <div class="column py-0">
                    <p class="has-text-centered na-subscription-added-check">
                        <span class="icon">
                            <fa-icon icon="check" size="4x" />
                        </span>
                    </p>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <p class="title has-text-centered has-text-weight-bold">
                        Thank you for subscribing to the<br />
                        {{ publication.text }}.
                    </p>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <p class="has-text-centered">
                        Cost: <span class="has-text-weight-bold">{{ formatCost(cost) }}/{{ termName }}</span>
                    </p>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <p class="has-text-centered">
                        Start Date: <span class="has-text-weight-bold">{{ theStartDate }}</span>
                    </p>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <p class="has-text-centered">
                        Please manage your subscription through {{ mainPublicationName }}. For questions or assistance,
                        please call the {{ publication.text }} at {{ phoneNumber }}.
                    </p>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <div class="buttons is-centered">
                        <button class="button is-success" @click="close">THANK YOU</button>
                    </div>
                </div>
            </div>
        </div>
        <button class="modal-close is-large" aria-label="close" @click="close"></button>
    </div>
</template>

<script>
import { toDate } from '@/helpers/date.js';
import { accountingMixin } from '@/mixins/AccountingMixin.js';
import { dateMixin } from '@/mixins/DateMixin.js';
import { moneyMixin } from '@/mixins/MoneyMixin.js';
import { mapGetters } from 'vuex';

export default {
    mixins: [accountingMixin, dateMixin, moneyMixin],

    props: {
        publication: {
            type: Object,
            required: true
        },
        // Term name (e.g. month)
        termName: {
            type: String,
            required: true
        },
        // Cost of subscription paid
        cost: {
            type: [Number, String],
            required: true
        },
        startDate: {
            type: [Object, Number],
            required: true
        }
    },

    computed: {
        ...mapGetters({
            mainPublicationName: 'publication/mainPublicationName'
        }),
        theStartDate() {
            return toDate(this.startDate).format('MMMM D, YYYY');
        },
        phoneNumber() {
            // @todo we must get this detail from the API instead of being hardcoded
            let phoneNumber = '845.647.9190';
            if (this.publication && this.publication.slug === 'kingston-wire') {
                phoneNumber = '845-481-3431';
            }
            return phoneNumber;
        }
    },

    methods: {
        /**
         * User wants to close
         */
        close() {
            this.$emit('close');
        }
    }
};
</script>
