/**
 * @format
 * Editor (Quill) mixins
 */

export const editorMixin = {
    methods: {
        /**
         * Return all insert data
         *
         * @param {String} item   Source JSON string
         * @param {mixed}  simple Simple content?  If true (default), we only load insert string data (so no images, video, etc.)
         *
         * @return {Array} Array of insert objects
         */
        loadQuill(item, simple = true) {
            if (
                item === null ||
                typeof item !== 'string' ||
                (typeof item === 'string' && !item.length)
            ) {
                return [];
            }

            try {
                const json = JSON.parse(item);

                const items = json.ops.filter((item) => {
                    if (simple) {
                        if (item.insert && typeof item.insert === 'string') {
                            return 1;
                        } else {
                            return 0;
                        }
                    } else {
                        return 1;
                    }
                });

                return items;
            } catch (e) {
                // @todo Would be nice to log this!
                return []; // Any error, just empty array
            }
        },
        /**
         * Append given text string to quill insert data - will be appended on the last "line" of given content
         * Note: Even if there is no content, quill will still have one insert as "\n".
         */
        appendQuillText(html, text, prefixText = ' ') {
            const index = html.lastIndexOf('</p>');
            return (
                html.substring(0, index) +
                prefixText +
                text +
                html.substring(index)
            );
        },

        /**
         * Prefix given text string to quill insert data - will be prefixed on the first "line" of given content
         */
        prefixQuillText(html, text) {
            const match = /^(<div>)?<p>/.exec(html);
            if (!match) {
                return html;
            }
            const index = match[0].length;
            return html.substring(0, index) + text + html.substring(index);
        }
    }
};
