<!-- @format -->
<template>
    <div>
        <img :src="imageUrl('MInewspapers-trans.png')" alt="Manor Ink" title="Manor Ink" />
        <p class="title">Help underwrite a unique publication</p>

        <div>
            <p>
                As a youth-driven community newspaper, Manor Ink is an informative news source. But the paper is also a
                teaching publication, giving students from eighth through twelfth grades an opportunity to learn about
                journalism, reporting, photography, print and digital design, editing and news production. The paper
                currently has a staff of 10 students; its editor-in-chief, Marlee Madison, is a junior at Livingston
                Manor High School.
            </p>
            <p class="shim">
                Though it's free on newsstands, Manor Ink is supported by its advertisers and by generous grants from
                community organizations. It's also funded by the generosity of its readers - people like you. If you
                would like to help further Manor Ink's mission, please select one of the contributor options below. We
                thank you for your donation!
                <!--
                <p class="has-text-right">
                    &mdash; The Shawangunk Journal Team
                </p>
                -->
            </p>
        </div>
    </div>
</template>

<script>
import { mediaMixin } from '@/mixins/MediaMixin.js';

export default {
    mixins: [mediaMixin]
};
</script>
