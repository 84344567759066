/**
 * Crawler related functions
 */
import axios from 'axios';

/**
 * Check if visitor is a crawler
 *
 * @return Promise for resolve, reject
 */
export const checkIfCrawler = function () {
    return axios.get('/check-if-crawler')
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};
