/**
 * @format
 * General app related stuff
 */

function generateMessagePayload(data) {
    const id =
        new Date().getTime().toString() +
        '_' +
        Math.floor(Math.random() * Math.floor(50000)).toString();
    if (typeof data === 'string') {
        return {
            id,
            text: data,
            isHtml: false
        };
    } else if (typeof data === 'object') {
        if (data.html) {
            return {
                id,
                text: data.html,
                isHtml: true
            };
        } else {
            return {
                id,
                text: data.text,
                isHtml: false
            };
        }
    }
}
const state = {
    recaptchaLoaded: false,
    googleAuthClientLoaded: false,
    appleSignInLoaded: false,
    facebookSdkLoaded: false,
    prevRoute: null,
    mobileAuthToken: null,
    messageBar: {
        messages: []
    }
};

const mutations = {
    setMobileAuthToken(state, data) {
        state.mobileAuthToken = data.token;
    },
    setMessages(state, data) {
        state.messageBar.messages = [];
        const payload = generateMessagePayload(data);
        if (payload) {
            state.messageBar.messages.push(payload);
        }
    },
    addMessage(state, data) {
        const payload = generateMessagePayload(data);
        if (payload) {
            state.messageBar.messages.push(payload);
        }
    },
    clearMessages(state) {
        state.messageBar.messages = [];
    },
    setPrevRoute(state, data) {
        state.prevRoute = data;
    },
    setRecaptchaLoaded(state, loaded) {
        state.recaptchaLoaded = loaded;
    },
    setGoogleAuthClientLoaded(state, loaded) {
        state.googleAuthClientLoaded = loaded;
    },
    setAppleSignInLoaded(state, loaded) {
        state.appleSignInLoaded = loaded;
    },
    setFacebookSdkLoaded(state, loaded) {
        state.facebookSdkLoaded = loaded;
    }
};

export default {
    namespaced: true,
    state,
    mutations
};
