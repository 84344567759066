/**
 * @format
 * Admin user related API functions
 * Abstracting these out from DataMixin.js.
 */
import axios from 'axios';

/**
 * Impersonate user
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const impersonateUser = function (data) {
    return axios
        .post(naRoutes.impersonateUser, {
            data
        })
        .then((response) => response.data);
};

/**
 * Impersonate user off
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const impersonateUserOff = function (data) {
    return axios
        .post(naRoutes.impersonateUserOff, {
            data
        })
        .then((response) => response.data);
};

/**
 * Read user subscriptions
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const readUserSubscriptions = function (data) {
    return axios
        .post(naRoutes.readUserSubscriptions, {
            data
        })
        .then((response) => response.data);
};

/**
 * Update user subscription
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export async function updateUserSubscription(data) {
    return axios
        .post(naRoutes.updateUserSubscription, data)
        .then((response) => response.data);
}


/**
 * Refund user subscription
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export async function refundUserSubscription(data) {
    return axios
        .post('/user/subscriptions/refund', data)
        .then((response) => response.data);
}

/**
 * Update user credit
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const updateUserCredit = function (data) {
    return axios
        .post(naRoutes.updateUserCredit, {
            data
        })
        .then((response) => response.data);
};

/**
 * Deactivate user
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const deactivateUser = function (data) {
    return axios
        .post(naRoutes.deactivateUser, {
            data
        })
        .then((response) => response.data);
};

/**
 * Activate user
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const activateUser = function (data) {
    return axios
        .post(naRoutes.activateUser, {
            data
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Update user features
 */
export async function updateUserFeatures(data) {
    return axios.post('/user/features', data).then((response) => response.data);
}

/**
 * Read user transactions
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const readUserTransactions = function (data) {
    return axios
        .post(naRoutes.readUserTransactions, {
            data
        })
        .then((response) => response.data);
};

/**
 * Create a user transactions
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const createUserTransaction = function (data) {
    return axios
        .post(naRoutes.createUserTransaction, {
            data
        })
        .then((response) => response.data);
};

/**
 * Update a user transactions
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const updateUserTransaction = function (data) {
    return axios
        .post(naRoutes.updateUserTransaction, {
            data
        })
        .then((response) => response.data);
};

/**
 * Delete Update a user transactions
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const deleteUserTransaction = function (data) {
    return axios
        .post(naRoutes.deleteUserTransaction, {
            data
        })
        .then((response) => response.data);
};

/**
 * Refund transaction
 *
 */
export async function refundUserTransaction(id, options = {}) {
    return axios
        .post('/user/accounting/transactions/refund', {
            id,
            ...options
        })
        .then((response) => response.data);
}

/**
 * Read user read/view activity
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const readUserReadActivity = function (data) {
    return axios
        .post(naRoutes.readUserReadActivity, {
            data
        })
        .then((response) => response.data);
};
