/**
 * Option related API functions
 * Abstracting these out from DataMixin.js.
 */
import axios from 'axios';

/**
 * US states
 *
 * @return Promise for resolve, reject
 */
export const loadOptionStates = function () {
    return axios.get(naRoutes.loadOptionStates)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Credit card expiration months
 *
 * @return Promise for resolve, reject
 */
export const loadOptionCreditCardExpMonths = function () {
    return axios.get(naRoutes.loadOptionCreditCardExpMonths)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Credit card expiration years
 *
 * @return Promise for resolve, reject
 */
export const loadOptionCreditCardExpYears = function () {
    return axios.get(naRoutes.loadOptionCreditCardExpYears)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Post statuses
 *
 * @return Promise for resolve, reject
 */
export const loadOptionPostStatuses = function () {
    return axios.get(naRoutes.loadOptionPostStatuses)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Post types
 *
 * @return Promise for resolve, reject
 */
export const loadOptionPostTypes = function () {
    return axios.get(naRoutes.loadOptionPostTypes)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Post categories
 *
 * @return Promise for resolve, reject
 */
export const loadOptionPostCategories = function () {
    return axios.get(naRoutes.loadOptionPostCategories)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Post type: blotter: status
 *
 * @return Promise for resolve, reject
 */
export const loadOptionPostTypeBlotterStatus = function () {
    return axios.get(naRoutes.loadOptionPostTypeBlotterStatus)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Post type: event: schedule type
 *
 * @return Promise for resolve, reject
 */
export const loadOptionPostTypeEventSchedule = function () {
    return axios.get(naRoutes.loadOptionPostTypeEventSchedule)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Post type: event: recurring schedule type
 *
 * @return Promise for resolve, reject
 */
export const loadOptionPostTypeEventRecurring = function () {
    return axios.get(naRoutes.loadOptionPostTypeEventRecurring)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Post type: event: event type
 *
 * @return Promise for resolve, reject
 */
export const loadOptionPostTypeEventType = function () {
    return axios.get(naRoutes.loadOptionPostTypeEventType)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Location type: state
 *
 * @return Promise for resolve, reject
 */
export const loadOptionLocationStates = function () {
    return axios.get(naRoutes.loadOptionLocationStates)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Location type: county
 *
 * @param {mixed} stateId The state ID to get counties for.  This should be the option ID of the keycat 'divisionToLong'.  null (default) means all counties regardless of state.
 *
 * @return Promise for resolve, reject
 */
export const loadOptionLocationCounties = function (stateId = null) {
    return axios.get(naRoutes.loadOptionLocationCounties + (stateId === null ? '' : '/' + stateId))
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Location type: types
 *
 * @return Promise for resolve, reject
 */
export const loadOptionLocationTypes = function () {
    return axios.get(naRoutes.loadOptionLocationTypes)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Location type: county id to divisionToLong id
 *
 * @param {Integer} countyId The county ID.  We'll try to return the matching 'divisionToLong' record.
 *
 * @return Promise for resolve, reject
 */
export const lookupOptionCountyToDivisionLong = function (countyId) {
    return axios.get(naRoutes.lookupOptionCountyToDivisionLong + '/' + countyId)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Days of week
 *
 * @return Promise for resolve, reject
 */
export const loadOptionDaysOfWeek = function () {
    return axios.get(naRoutes.loadOptionDaysOfWeek)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Months
 *
 * @return Promise for resolve, reject
 */
export const loadOptionMonths = function () {
    return axios.get(naRoutes.loadOptionMonths)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Event sequences (e.g. first, second, last, etc.)
 *
 * @return Promise for resolve, reject
 */
export const loadOptionEventSequences = function () {
    return axios.get(naRoutes.loadOptionEventSequences)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Event days
 *
 * @return Promise for resolve, reject
 */
export const loadOptionEventDays = function () {
    return axios.get(naRoutes.loadOptionEventDays)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Event monthly types
 *
 * @return Promise for resolve, reject
 */
export const loadOptionEventMonthlyTypes = function () {
    return axios.get(naRoutes.loadOptionEventMonthlyTypes)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Post type: classified: type
 *
 * @return Promise for resolve, reject
 */
export const loadOptionPostTypeClassifiedType = function () {
    return axios.get(naRoutes.loadOptionPostTypeClassifiedType)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Post timeframe types
 *
 * @return Promise for resolve, reject
 */
export const loadOptionPostTimeframeTypes = function () {
    return axios.get(naRoutes.loadOptionPostTimeframeTypes)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Post timeframe types for events only
 *
 * @return Promise for resolve, reject
 */
export const loadOptionPostTimeframeEventTypes = function () {
    return axios.get(naRoutes.loadOptionPostTimeframeEventTypes)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Subscription types
 *
 * @return Promise for resolve, reject
 */
export const loadOptionSubscriptionTypes = function () {
    return axios.get(naRoutes.loadOptionSubscriptionTypes)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Subscription product types
 *
 * @return Promise for resolve, reject
 */
export const loadOptionSubscriptionProductTypes = function () {
    return axios.get(naRoutes.loadOptionSubscriptionProductTypes)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Section post types (e.g. a section may be news or events)
 *
 * @return Promise for resolve, reject
 */
export const loadOptionSectionPostTypes = function () {
    return axios.get(naRoutes.loadOptionSectionPostTypes)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Publish pricing periods
 *
 * @return Promise for resolve, reject
 */
export const loadOptionPublishPricingPeriods = function () {
    return axios.get(naRoutes.loadOptionPublishPricingPeriods)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Publication status types
 *
 * @return Promise for resolve, reject
 */
export const loadOptionPublicationStatusTypes = function () {
    return axios.get(naRoutes.loadOptionPublicationStatusTypes)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};
