<!-- @format -->
<template>
    <add-subscription
        v-if="isLoaded"
        :add-only="true"
        caller="content"
        :selected-publication-id="thePublicationId"
        :use-only-selected-publication="true"
        :option-publications="optionPublications"
        @cancelled="cancelled"
        @added-subscription="$emit('added-subscription', $event)"
    />
</template>

<script>
import { readPublications } from '@/services/publication.js';
import { postMixin } from '@/mixins/PostMixin.js';
import AddSubscription from '@/components/User/Account/Subscriptions/Index.vue';

export default {
    components: {
        AddSubscription
    },

    mixins: [postMixin],

    props: {
        post: {
            type: Object,
            default: function () {
                return null;
            }
        },
        publicationId: {
            default: function () {
                return null;
            }
        }
    },

    data() {
        return {
            isLoaded: false,
            optionPublications: []
        };
    },

    computed: {
        thePublicationId() {
            return this.publicationId
                ? this.publicationId
                : this.post && this.post.publication
                ? this.post.publication.id
                : null;
        }
    },

    async created() {
        await this.load();
        this.isLoaded = true;
    },

    methods: {
        async load() {
            this.errors = [];
            const response = await readPublications();
            if (!response.success) {
                if (response.errors) {
                    this.errors = response.errors;
                }
            } else {
                this.optionPublications = [];
                response.publications.forEach((item) => {
                    this.optionPublications.push({
                        id: item.id,
                        value: item.id,
                        slug: item.slug,
                        text: item.name
                    });
                });
            }
        },
        cancelled() {
            this.$emit('cancelled');
        }
    }
};
</script>
