/** @format */
import {
    FontAwesomeIcon,
    FontAwesomeLayers,
    FontAwesomeLayersText
} from '@fortawesome/vue-fontawesome/index.js';

import { library } from '@fortawesome/fontawesome-svg-core/index.js';

import { faUser } from '@fortawesome/free-solid-svg-icons/faUser.js';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons/faUserCircle.js';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus.js';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars.js';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch.js';
import { faNewspaper } from '@fortawesome/free-regular-svg-icons/faNewspaper.js';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck.js';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare.js';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes.js';
import { faComment } from '@fortawesome/free-solid-svg-icons/faComment.js';
import { faClipboard } from '@fortawesome/free-solid-svg-icons/faClipboard.js';
import { faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons/faEnvelopeOpen.js';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare.js';
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons/faTwitterSquare.js';
import { faInstagramSquare } from '@fortawesome/free-brands-svg-icons/faInstagramSquare.js';
import { faApple } from '@fortawesome/free-brands-svg-icons/faApple.js';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF.js';
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle.js';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog.js';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons/faCaretLeft.js';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons/faCaretRight';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp.js';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown.js';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle.js';
import { faImages } from '@fortawesome/free-solid-svg-icons/faImages.js';
import { faBolt } from '@fortawesome/free-solid-svg-icons/faBolt.js';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons/faArrowCircleLeft.js';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons/faArrowCircleRight.js';
import { faArrowCircleUp } from '@fortawesome/free-solid-svg-icons/faArrowCircleUp.js';
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons/faArrowCircleDown.js';
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar.js';
import { faFilm } from '@fortawesome/free-solid-svg-icons/faFilm.js';
import { faQuestion } from '@fortawesome/free-solid-svg-icons/faQuestion.js';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle.js';
import { faSmile } from '@fortawesome/free-regular-svg-icons/faSmile.js';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock.js';
import { faUnlockAlt } from '@fortawesome/free-solid-svg-icons/faUnlockAlt.js';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus.js';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft.js';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight.js';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp.js';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown.js';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft.js';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight.js';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons/faAngleUp.js';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown.js';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons/faCircleNotch.js';
import { faRedo } from '@fortawesome/free-solid-svg-icons/faRedo.js';
import { faStepForward } from '@fortawesome/free-solid-svg-icons/faStepForward.js';
import { faStepBackward } from '@fortawesome/free-solid-svg-icons/faStepBackward.js';
import { faCcVisa } from '@fortawesome/free-brands-svg-icons/faCcVisa.js';
import { faCcMastercard } from '@fortawesome/free-brands-svg-icons/faCcMastercard.js';
import { faCcDiscover } from '@fortawesome/free-brands-svg-icons/faCcDiscover.js';
import { faCcAmex } from '@fortawesome/free-brands-svg-icons/faCcAmex.js';
import { faThList } from '@fortawesome/free-solid-svg-icons/faThList.js';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye.js';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash.js';
import { faParagraph } from '@fortawesome/free-solid-svg-icons/faParagraph.js';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons/faLocationArrow.js';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt.js';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons/faTachometerAlt.js';
import { faMap } from '@fortawesome/free-solid-svg-icons/faMap.js';
import { faBuilding } from '@fortawesome/free-solid-svg-icons/faBuilding.js';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook.js';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane.js';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard.js';
import { faListOl } from '@fortawesome/free-solid-svg-icons/faListOl.js';
import { faCircle as farCircle } from '@fortawesome/free-regular-svg-icons/faCircle.js';
import { faCircle as fasCircle } from '@fortawesome/free-solid-svg-icons/faCircle.js';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus.js';
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort.js';
import { faSortUp } from '@fortawesome/free-solid-svg-icons/faSortUp.js';
import { faSortDown } from '@fortawesome/free-solid-svg-icons/faSortDown.js';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt.js';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle.js';
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation.js';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons/faDollarSign.js';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome.js';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay.js';
import { faPause } from '@fortawesome/free-solid-svg-icons/faPause.js';
import { faUndo } from '@fortawesome/free-solid-svg-icons/faUndo.js';
import { faHandPointRight } from '@fortawesome/free-solid-svg-icons/faHandPointRight.js';
import { faRss } from '@fortawesome/free-solid-svg-icons/faRss.js';
import { faPodcast } from '@fortawesome/free-solid-svg-icons/faPodcast.js';
import { faHeadphones } from '@fortawesome/free-solid-svg-icons/faHeadphones.js';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy.js';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt.js';

// It's possible to add custom font awesome icons. For example adding the old v4
// style
// https://github.com/FortAwesome/Font-Awesome/wiki/Customize-Font-Awesome#svg-framework
// v4 svg icons are found here: https://github.com/Rush/Font-Awesome-SVG-PNG

const faNewspaperOld = {
    prefix: 'fac',
    iconName: 'newspaper-old',
    icon: [
        2048,
        1792,
        [],
        'e001',
        'M1024 512h-384v384h384v-384zm128 640v128h-640v-128h640zm0-768v640h-640v-640h640zm640 768v128h-512v-128h512zm0-256v128h-512v-128h512zm0-256v128h-512v-128h512zm0-256v128h-512v-128h512zm-1536 960v-960h-128v960q0 26 19 45t45 19 45-19 19-45zm1664 0v-1088h-1536v1088q0 33-11 64h1483q26 0 45-19t19-45zm128-1216v1216q0 80-56 136t-136 56h-1664q-80 0-136-56t-56-136v-1088h256v-128h1792z'
    ]
};

const faCommentOld = {
    prefix: 'fac',
    iconName: 'comment-old',
    icon: [
        1792,
        1792,
        [],
        'e002',
        'M1792 896q0 174-120 321.5t-326 233-450 85.5q-70 0-145-8-198 175-460 242-49 14-114 22-17 2-30.5-9t-17.5-29v-1q-3-4-.5-12t2-10 4.5-9.5l6-9 7-8.5 8-9q7-8 31-34.5t34.5-38 31-39.5 32.5-51 27-59 26-76q-157-89-247.5-220t-90.5-281q0-130 71-248.5t191-204.5 286-136.5 348-50.5q244 0 450 85.5t326 233 120 321.5z'
    ]
};

const faTicketOld = {
    prefix: 'fac',
    iconName: 'ticket-old',
    icon: [
        1792,
        1792,
        [],
        'e003',
        'M1024 452l316 316-572 572-316-316zm-211 979l618-618q19-19 19-45t-19-45l-362-362q-18-18-45-18t-45 18l-618 618q-19 19-19 45t19 45l362 362q18 18 45 18t45-18zm889-637l-907 908q-37 37-90.5 37t-90.5-37l-126-126q56-56 56-136t-56-136-136-56-136 56l-125-126q-37-37-37-90.5t37-90.5l907-906q37-37 90.5-37t90.5 37l125 125q-56 56-56 136t56 136 136 56 136-56l126 125q37 37 37 90.5t-37 90.5z'
    ]
};

library.add(
    faUser,
    faUserCircle,
    faUserPlus,
    faBars,
    faSearch,
    faNewspaper,
    faCheck,
    faCheckSquare,
    faTimes,
    faComment,
    faClipboard,
    faEnvelopeOpen,
    faFacebookSquare,
    faTwitterSquare,
    faInstagramSquare,
    faApple,
    faFacebookF,
    faGoogle,
    faCog,
    faCaretLeft,
    faCaretRight,
    faCaretUp,
    faCaretDown,
    faTimesCircle,
    faImages,
    faBolt,
    faArrowCircleLeft,
    faArrowCircleRight,
    faArrowCircleUp,
    faArrowCircleDown,
    faCalendar,
    faFilm,
    faQuestion,
    faQuestionCircle,
    faSmile,
    faLock,
    faUnlockAlt,
    faPlus,
    faChevronLeft,
    faChevronRight,
    faChevronUp,
    faChevronDown,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faAngleDown,
    faCircleNotch,
    faRedo,
    faStepForward,
    faStepBackward,
    faCcVisa,
    faCcMastercard,
    faCcDiscover,
    faCcAmex,
    faThList,
    faEye,
    faEyeSlash,
    faParagraph,
    faLocationArrow,
    faPencilAlt,
    faTachometerAlt,
    faMap,
    faBuilding,
    faBook,
    faPaperPlane,
    faCreditCard,
    faListOl,
    farCircle,
    fasCircle,
    faMinus,
    faSort,
    faSortUp,
    faSortDown,
    faSignInAlt,
    faExclamationTriangle,
    faExclamation,
    faDollarSign,
    faHome,
    faPlay,
    faPause,
    faUndo,
    faHandPointRight,
    faRss,
    faPodcast,
    faHeadphones,
    faCopy,
    faNewspaperOld,
    faCommentOld,
    faTicketOld,
    faExternalLinkAlt
);

export { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText };
