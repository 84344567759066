<!-- @format -->
<template>
    <div class="is-inline-flex is-align-items-center my-1">
        <publication-icon
            v-if="showIcon && publicationIcon"
            :icon="publicationIcon"
            :alt-text-fallback="publicationName"
        />
        <span class="has-text-grey is-size-8"
            ><span v-if="showName && post.publication">{{ publicationName }}</span>
            <time v-if="showContentAt" :datetime="post.content_at">&bull; {{ contentAt }}</time></span
        >
    </div>
</template>

<script>
import { postMixin } from '@/mixins/PostMixin';
import { getNow, toDate } from '@/helpers/date';
import PublicationIcon from './PublicationIcon.vue';

export default {
    components: {
        PublicationIcon
    },

    mixins: [postMixin],

    props: {
        post: {
            type: Object,
            required: true
        },
        showIcon: {
            type: Boolean,
            default: true
        },
        showName: {
            type: Boolean,
            default: true
        },
        showDate: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        publicationName() {
            return this.postPublicationFormatted(this.post.publication);
        },
        publicationIcon() {
            return this.getPublicationMedia(this.post, 'mediaTypeIcon')[0];
        },
        year() {
            return getNow().year();
        },
        showContentAt() {
            return this.showDate && this.isShowContentAt(this.post);
        },
        contentAt() {
            const contentAtYear = toDate(this.post.content_at_calculated ?? this.post.content_at).year();
            const format = this.year === contentAtYear ? 'MMMM D' : 'MMMM D, YYYY';
            return this.postContentAtFormat(this.post, format);
        }
    }
};
</script>
