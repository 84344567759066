<!-- @format -->
<template>
    <div class="is-flex">
        <span
            v-if="isValid && isMatched"
            class="icon password-match-icon"
            :class="iconPositionClass"
            data-toggle="tooltip"
            data-placement="top"
            title="Passwords match"
            ><fa-icon icon="check"
        /></span>
        <span
            v-if="isValid && !isMatched"
            class="icon password-mismatch-icon"
            :class="iconPositionClass"
            data-toggle="tooltip"
            data-placement="top"
            title="Passwords do not match"
            ><fa-icon icon="times-circle"
        /></span>
    </div>
</template>

<script>
export default {
    props: {
        password: {
            type: String,
            default: ''
        },
        passwordConfirmation: {
            type: String,
            default: ''
        },
        iconPositionClass: {
            type: [String, Array, Object],
            default: 'icon-in-input-right'
        }
    },
    computed: {
        /**
         * Determine if password and confirmation match
         *
         * @return {Boolean} True if matched, false if not
         */
        isMatched() {
            if (
                this.password &&
                this.passwordConfirmation &&
                this.password.length &&
                this.passwordConfirmation.length
            ) {
                return this.password === this.passwordConfirmation;
            } else {
                return false;
            }
        },
        /**
         * Determine if password and confirmation match are "valid" (as in they are not null and have some length) - we don't want to display a bad message if
         * both passwords are empty
         *
         * @return {Boolean} True if matched, false if not
         */
        isValid() {
            if (this.password && (this.password.length || this.passwordConfirmation.length)) {
                return true;
            } else {
                return false;
            }
        }
    }
};
</script>
