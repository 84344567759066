<!-- @format -->
<template>
    <div v-if="adCount" class="na-sidebar-dock-container py-1">
        <ad-kw v-if="mainPublication && mainPublication.slug && mainPublication.slug === 'kingston-wire'" />
        <ad-sj v-else-if="mainPublication && mainPublication.slug && mainPublication.slug === 'shawangunk-journal'" />
        <div v-else-if="mainPublication && mainPublication.slug && mainPublication.slug === 'sc-democrat'">
            <!-- nothing for now -->
        </div>
        <div v-else-if="mainPublication && mainPublication.slug && mainPublication.slug === 'dundalk-herald'">
            <!-- nothing for now -->
        </div>
        <ad-generic v-else />
    </div>
</template>

<script>
// @todo  2018-11-28  MJL  Eventually these ads will probably need to come from some external source, but hard-coding for now since there are almost no specifics on this
import { mapState } from 'vuex';
import AdGeneric from './Ads/Generic.vue';
import AdKw from './Ads/Kw.vue';
import AdSj from './Ads/Sj.vue';

export default {
    components: {
        AdGeneric,
        AdKw,
        AdSj
    },

    data() {
        return {
            isBusy: false,
            isLoaded: false
        };
    },

    computed: {
        ...mapState({
            mainPublication: (state) => state.publication.mainPublication
        }),

        /**
         * Return count of ads
         *
         * @return {Integer} Count of ads
         */
        adCount() {
            return 1;
        }
    }
};
</script>
