<!-- @format -->
<template>
    <div class="content">
        <p>
            <span class="has-text-success is-clickable" @click="$emit('back')"
                ><fa-icon icon="chevron-left" /> Back</span
            >
        </p>
        <p>
            <span class="has-text-weight-bold">EZRead</span> is a simple and cost effective way to read the articles we
            publish.
        </p>
        <p>
            <span class="has-text-weight-bold">{{ publication.name }}</span> is part of NewsAtomic, a growing collective
            of over 20 local publications, produced by independent publishers right here in the Hudson Valley.
        </p>
        <p>
            Each publisher sets the rates to read their material, usually between $0.05 and $0.25 per article, and you
            can use your
            <span class="has-text-weight-bold">EZRead</span> account to read them.
        </p>
        <p>
            By keeping a few dollars in your <span class="has-text-weight-bold">EZRead</span> account, you can enjoy all
            of our independent publications without having to subscribe to each one.
        </p>
    </div>
</template>

<script>
export default {
    props: {
        publication: {
            type: Object,
            required: true
        }
    }
};
</script>
