<!-- @format -->
<template>
    <div class="is-flex is-flex-direction-column">
        <div class="na-subscription-terms-header mb-4">
            <div class="is-flex is-justify-content-space-between">
                <p class="subtitle standout na-subscription-terms-product-title">
                    {{ productTypeFriendlyName(term.productType) }} Subscription
                </p>

                <p class="na-subscription-step">
                    <slot name="stepNumber" />
                </p>
            </div>
        </div>

        <div class="columns">
            <div v-if="!userIsLoggedIn" class="column is-two-thirds">
                <template v-if="!showLogin">
                    <h2 class="has-text-weight-bold" style="font-size: 1.3rem">Create an account</h2>
                    <h3 class="is-size-6 mb-1">
                        (Already have an account? <a @click="switchToLogin('')">Please log in.</a>)
                    </h3>
                    <signup
                        ref="first_name"
                        class="mb-6"
                        :show-header="false"
                        :show-divider="false"
                        :show-extra-info="false"
                        align-social="left"
                        button-text="Create account"
                        @loggedIn="$emit('loggedIn')"
                        @email-in-use="switchToLogin"
                    />
                </template>

                <template v-if="showLogin">
                    <h2 class="has-text-weight-bold" style="font-size: 1.3rem">Log in</h2>
                    <h3 class="is-size-6 mb-1">
                        (Don't have an account? <a @click="switchToRegister">Create Account.</a>)
                    </h3>
                    <login
                        ref="login"
                        :show-header="false"
                        :show-forgot-password="false"
                        :show-divider="false"
                        :show-signup-link="false"
                        align-social="left"
                        button-text="Create account"
                        @loggedIn="$emit('loggedIn')"
                    />
                </template>
            </div>
            <div v-else class="column">
                <p class="content">
                    Logged in as: <b>{{ userCurrent.email }}</b>
                </p>
                <button class="button is-success" @click="$emit('continue')">Continue</button>
            </div>
        </div>
    </div>
</template>

<script>
import { subscriptionHelperMixin } from './mixins/SubscriptionHelperMixin.js';
import Signup from '@/components/User/Register/Index.vue';
import Login from '@/components/User/Auth/Login.vue';

export default {
    components: {
        Signup,
        Login
    },

    mixins: [subscriptionHelperMixin],

    props: {
        term: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            showLogin: false,
            errors: []
        };
    },

    methods: {
        switchToLogin(email) {
            this.showLogin = true;
            this.$nextTick(() => {
                this.$refs.login.focusEmail(email);
            });
        },
        switchToRegister() {
            this.showLogin = false;
        }
    }
};
</script>
