<!-- @format -->
<template>
    <div class="field" :class="[wrapperClass, hasInputError(errors, errorFieldName)]">
        <label v-if="label" class="label">{{ label }}</label>
        <div class="control has-icons-right is-expanded">
            <input
                ref="passwordInput"
                v-model="lPassword"
                class="input"
                :class="inputClass"
                :type="lShowPassword ? 'text' : 'password'"
                :placeholder="placeholder"
                :autocomplete="autocomplete"
                :disabled="isDisabled ? 'disabled' : null"
                :required="required"
                :minlength="minlength"
            />
            <span v-if="showPasswordToggle" class="icon is-small is-right is-clickable" @click="showToggled">
                <fa-icon :icon="['fas', lShowPassword ? 'eye' : 'eye-slash']" />
            </span>
            <password-confirmation
                v-if="showConfirmation"
                :password="lPassword"
                :password-confirmation="passwordConfirmation"
                icon-position-class="is-right"
            />
            <password-strength
                v-if="showStrength"
                :min-score="0"
                :max-score="4"
                :min-length="8"
                :password="lPassword"
                :show-icons="false"
            />
        </div>
        <input-error :field="errorFieldName" :errors="errors" />
    </div>
</template>

<script>
import InputError from '@/components/Common/InputError.vue';
import PasswordConfirmation from '@/components/Common/PasswordConfirmation.vue';
import PasswordStrength from '@/components/Common/PasswordStrength.vue';

export default {
    components: {
        InputError,
        PasswordConfirmation,
        PasswordStrength
    },

    props: {
        errors: {
            type: Array,
            default: () => []
        },
        // Name of field we're looking for in errors array
        errorFieldName: {
            type: String,
            default: 'password'
        },
        // The password field
        password: {
            type: String,
            default: ''
        },
        // Label (null if not desired)
        label: {
            type: String,
            default: ''
        },
        // Placeholder (null if not desired)
        placeholder: {
            type: String,
            default: 'password'
        },
        // Password to confirm against if 'showConfirmation' is used
        passwordConfirmation: {
            type: String,
            default: ''
        },
        showPassword: {
            type: Boolean,
            default: false
        },
        // Show the password hide/show toggle?
        showPasswordToggle: {
            type: Boolean,
            default: true
        },
        // Show password confirmation?
        showConfirmation: {
            type: Boolean,
            default: false
        },
        // Show password strength meter?
        showStrength: {
            type: Boolean,
            default: false
        },
        // Autocomplete for password field
        autocomplete: {
            type: String,
            default: 'off'
        },
        // Wrapper class
        wrapperClass: {
            type: String,
            default: ''
        },
        // Additional input class
        inputClass: {
            type: [String, Array, Object],
            default: ''
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        minlength: {
            type: Number,
            default: 8
        }
    },

    data() {
        return {
            lShowPassword: this.showPassword,
            lPassword: this.password
        };
    },

    watch: {
        lPassword() {
            this.updateFormData();
        },
        showPassword() {
            this.lShowPassword = this.showPassword;
        }
    },

    methods: {
        updateFormData() {
            this.$emit('update:password', this.lPassword);
        },
        showToggled() {
            this.lShowPassword = !this.lShowPassword;
            this.$refs.passwordInput.focus();
            this.$emit('show-toggled', this.lShowPassword);
        }
    }
};
</script>
