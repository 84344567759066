<!-- @format -->
<template>
    <div class="na-ad-box-dock has-text-centered">
        <router-link class="is-hidden-mobile" :to="{ name: 'post-viewer-short', params: { identifier: '543HSS' } }"
            ><img
                class="na-link na-ad-image"
                :src="imageUrl('DAnewpub-v2.png')"
                alt="Subscribe to the Devil's Advocate"
                title="Subscribe to the Devil's Advocate"
                width="250"
                height="250"
                loading="lazy"
        /></router-link>
        <router-link class="is-hidden-tablet" :to="{ name: 'post-viewer-short', params: { identifier: '543HSS' } }"
            ><img
                class="na-link na-ad-image"
                :src="imageUrl('newpub-da-18131-mobile.png')"
                alt="Subscribe to the Devil's Advocate"
                title="Subscribe to the Devil's Advocate"
                width="250"
                height="250"
                loading="lazy"
        /></router-link>
    </div>
</template>

<script>
// @todo  2018-11-28  MJL  Eventually these ads/notifications will probably need to come from some external source, but hard-coding for now since there are almost no specifics on this
import { mediaMixin } from '@/mixins/MediaMixin.js';

export default {
    mixins: [mediaMixin]
};
</script>
