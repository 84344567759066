import moment from 'moment-timezone';

const timeZone = 'America/New_York';

/**
 * Format a standard date in the yyyy-mm-dd hh:mm:ss format to mm/dd/yyyy hh:mm (or other given format)
 *
 * @param {String} dateIn        Date input
 * @param {String} dateOutFormat Date output format.  'basic' for mm/dd/yyyy hh:mm (default).  'human' for simple human format
 *
 * @return {mixed} Date output; null if none/not valid
 */
export const dateFormatStandardDateTime = function (dateIn = null, dateOutFormat = 'basic') {
    let dateOut = null;
    if (dateIn !== null) {
        switch (dateOutFormat) {
            case 'human':
                // Human format not including day of week
                dateOut = moment.tz(dateIn, timeZone).format("MMMM Do YYYY, h:mm:ss a");
                break;
            case 'human-noseconds':
                // Human format not including day of week or seconds
                dateOut = moment.tz(dateIn, timeZone).format("MMMM Do YYYY, h:mm a");
                break;
            case 'human-day':
                // Human format including day of week
                dateOut = moment.tz(dateIn, timeZone).format("dddd, MMMM Do YYYY, h:mm:ss a");
                break;
            case 'human-date':
                // Human format not including time
                 dateOut = moment.tz(dateIn, timeZone).format("MMMM Do YYYY");
                break;
            case 'human-date2':
                // Human format not including time
                 dateOut = moment.tz(dateIn, timeZone).format("MMMM D YYYY");
                break;
            case 'basic-detail':
                dateOut = moment.tz(dateIn, timeZone).format("MM/DD/YYYY h:mm a");
                break;
            case 'date-mmddyy':
                dateOut = moment.tz(dateIn, timeZone).format("MM/DD/YY");
                break;
            case 'date-mdyy':
                dateOut = moment.tz(dateIn, timeZone).format("M/D/YY");
                break;
             default:
                 if (dateIn !== null) {
                    dateOut = dateIn.substr(5, 2) + '/' + dateIn.substr(8, 2) + '/' + dateIn.substr(0, 4) + ' ' + dateIn.substr(11, 5);
                 } else {
                     dateOut = null;
                 }
                break;
        }
    }

    return dateOut;
};
