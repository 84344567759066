<!-- @format -->
<template>
    <div class="is-flex">
        <template v-if="postCostIsPaid(post)">
            <span v-if="postCostIsFree(post)" class="na-post-cost-tag na-post-cost-tag-paid na-link">
                <span class="icon">
                    <fa-icon icon="check" />
                </span>
            </span>

            <span
                v-if="!postCostIsSubscription(post) && !postCostIsFree(post)"
                class="na-post-cost-tag na-post-cost-tag-paid na-link"
                data-toggle="tooltip"
                data-placement="top"
                title="Paid For"
                @click="purchase"
            >
                PAID
            </span>
            <span
                v-if="postCostIsSubscription(post) && !postCostIsFree(post)"
                class="na-post-cost-tag na-post-cost-tag-subscription na-link"
                data-toggle="tooltip"
                data-placement="top"
                title="Included as part of your subscription"
                @click="purchase"
            >
                <!-- <p>paid</p> -->
                SUB
            </span>
        </template>

        <template v-else>
            <span
                v-if="postCostIsDebit(post) && !postCostIsSubscription(post)"
                class="na-post-cost-tag na-post-cost-tag-debit na-link"
                data-toggle="tooltip"
                data-placement="top"
                title="Purchase"
                @click="purchase"
                v-html="formatCost(post.cost)"
            ></span>
            <span
                v-if="postCostIsSubscription(post) && !isPostSponsored(post)"
                class="na-post-cost-tag na-post-cost-tag-subscription na-link"
                data-toggle="tooltip"
                data-placement="top"
                title="Included as part of your subscription"
                @click="purchase"
                >SUB</span
            >

            <span
                v-if="postCostIsFree(post) && isPostSponsored(post)"
                class="na-post-cost-tag na-post-cost-tag-free na-link"
                data-toggle="tooltip"
                data-placement="top"
                title="Free!"
                @click="purchase"
            >
                FREE
            </span>
        </template>
    </div>
</template>

<script>
import { moneyMixin } from '@/mixins/MoneyMixin.js';
import { postMixin } from '@/mixins/PostMixin.js';

export default {
    mixins: [moneyMixin, postMixin],

    props: {
        post: {
            type: Object,
            default: function () {
                return null;
            }
        },
        // When we show the cost corner in Full.vue, we don't reload the post - so we should set this flag to assume the post was purchased (otherwise, we wouldn't have gotten to the Full view!)
        isFull: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        /**
         * User wants to purchase this post (or in the case of an already owned post, simply show it)
         *
         * @param {mixed} optionalArg Optional argument to emit
         */
        purchase(optionalArg = null) {
            this.$emit('purchase', optionalArg);
        }
    }
};
</script>
