<!-- @format -->
<template>
    <div class="columns">
        <div class="column">
            <div v-if="error && !dismissed[0]">
                <div class="content is-danger">
                    <span v-if="allowHtml" v-html="error"></span>
                    <span v-if="!allowHtml" class="tag is-medium is-white">
                        <span class="has-text-danger">
                            {{ error }}
                        </span>
                        <button class="delete is-small" @click="dismiss(0)"></button>
                    </span>
                </div>
            </div>
            <template v-if="generalErrors && generalErrors.length">
                <template v-for="(error, index) in errors">
                    <div v-if="!dismissed[index]" class="help is-danger">
                        <span v-if="allowHtml" v-html="error"></span>
                        <span v-if="!allowHtml" class="tag is-medium is-white">
                            <span class="has-text-danger">
                                {{ error }}
                            </span>
                            <button class="delete is-small" @click="dismiss(index)"></button>
                        </span>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        error: {
            default: function () {
                return null;
            }
        },
        errors: {
            default: function () {
                return [];
            }
        },
        allowHtml: {
            // Allow HTML in errors?
            default: function () {
                return false;
            }
        }
    },
    data() {
        return {
            dismissed: []
        };
    },
    computed: {
        generalErrors() {
            return this.errors ? this.errors.filter((error) => !error.includes('|')) : null;
        }
    },
    watch: {
        /**
         * Reset dismissed array when error changes
         */
        error(newVal) {
            this.dismissed = [];
        },
        /**
         * Reset dismissed array when errors changes
         */
        errors(newVal) {
            this.dismissed = [];
        }
    },
    methods: {
        /**
         * Dismiss a message
         *
         * @param {Integer} index Array index
         */
        dismiss(index) {
            this.$set(this.dismissed, index, 1);

            // If there are no errors being displayed, tell the caller in case they want to do something:
            if (this.message) {
                if (this.dismissed.length === 1) {
                    this.$emit('closed');
                }
            } else {
                if (this.dismissed.length === this.errors.length) {
                    this.$emit('closed');
                }
            }
        }
    }
};
</script>
