/**
 * Publication admin
 */
import { loadOptionPostTypes } from '@/services/option';

const state = {
    postTypes: {
        data: [],
        isLoading: false,
        errors: []
    }
};

const getters = {
    postTypes: (state) => state.postTypes,
};

const mutations = {
    setPostTypes (state, payload) {
        state.postTypes[payload.item] = payload.data;
    }
};
  
const actions = {
    /**
     * Load post types from server
     */
    async loadPostTypes ({ commit }, payload) {
        commit('setPostTypes', { item: 'isLoading', data: true });
        commit('setPostTypes', { item: 'errors', data: [] });
        loadOptionPostTypes()
            .then((response) => {
                if (!response.success) {
                    if (response.errors) {
                        commit('setPostTypes', { item: 'errors', data: response.errors });
                        commit('setPostTypes', { item: 'isLoading', data: false });
                    }
                } else {
                    const data = response.postTypes;
                    if (payload && payload.options && payload.options.addPicker) {
                        data.unshift({
                            id: null,
                            value: null,
                            text: 'Please Select'
                        });
                    }
                    commit('setPostTypes', { item: 'data', data });
                    commit('setPostTypes', { item: 'isLoading', data: false });
                }
            }, (error) => {
                commit('setPostTypes', { item: 'errors', data: [ error ] });
                commit('setPostTypes', { item: 'isLoading', data: false });
            });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
