<!-- @format -->
<template>
    <div>
        <div v-if="stepName !== 'paid'" class="columns">
            <div class="column na-subscription-terms-subscribe">
                <h1
                    class="title is-3"
                    :class="{ 'has-text-centered has-text-white has-background-black py-4': caller !== 'my-account' }"
                >
                    Subscribe to the {{ publication.text }}
                </h1>
            </div>
        </div>

        <div class="columns na-subscription-terms-container">
            <div class="column na-subscription-terms">
                <template v-if="stepName === 'productType'">
                    <step-product-type
                        :selected-term-id.sync="selectedTermId"
                        :terms="subscriptionTerms"
                        @continue="productTypeSelected"
                    >
                        <template slot="stepNumber">
                            <step-number :steps="steps" :current-step="stepName" @step-selected="stepSelected" />
                        </template>
                    </step-product-type>

                    <div v-if="publicationHasGiftSubscription && showGiftLink" class="column">
                        <p class="has-text-centered">
                            <router-link
                                :to="{
                                    name: 'gift-subscription',
                                    params: { slug: publication.slug }
                                }"
                                >*Gift subscriptions</router-link
                            >
                            are also available.
                        </p>
                    </div>
                </template>

                <step-pay-level
                    v-else-if="stepName === 'payLevel'"
                    :pay-level.sync="payLevel"
                    :term="selectedTerm"
                    :subscription-terms="subscriptionTerms"
                    :publication="publication"
                    @pay-level-accepted="payLevelAccepted"
                    @prev="stepName = 'productType'"
                >
                    <template slot="stepNumber">
                        <step-number :steps="steps" :current-step="stepName" @step-selected="stepSelected" />
                    </template>
                </step-pay-level>

                <step-auth v-else-if="stepName === 'auth'" :term="selectedTerm" @loggedIn="loggedIn" @continue="stepName = 'payFinish'">
                    <template slot="stepNumber">
                        <step-number :steps="steps" :current-step="stepName" @step-selected="stepSelected" />
                    </template>
                </step-auth>

                <step-pay-finish
                    v-else-if="stepName === 'payFinish'"
                    :payment.sync="payment"
                    :agree-terms.sync="agreeTerms"
                    :pay-level="payLevel"
                    :user="user"
                    :term="selectedTerm"
                    :publication="publication"
                    :errors="errors"
                    :is-busy="isBusy"
                    @mailing-address-updated="mailingAddressUpdated"
                    @prev="stepName = isAuth ? 'payLevel' : 'auth'"
                    @submit="register"
                >
                    <template slot="stepNumber">
                        <step-number :steps="steps" :current-step="stepName" @step-selected="stepSelected" />
                    </template>
                </step-pay-finish>
            </div>
        </div>

        <div v-if="stepName === 'productType'" class="columns">
            <div class="column">
                <template v-if="!publicationIsDevilsAdvocate(publication.id)">
                    <p class="subtitle na-profile-subtitle na-subscription-who-what">The Basics: Who We Are</p>
                    <p class="na-pre na-subscription-who-what-detail">{{ subscriptionTermsDescription }}</p>
                </template>

                <p class="subtitle na-profile-subtitle na-subscription-who-what">Digital Subscription Features</p>
                <p class="na-pre na-subscription-who-what-detail">{{ subscriptionTermsWhatYouGet }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { getNow } from '@/helpers/date';
import { accountingMixin } from '@/mixins/AccountingMixin';
import { moneyMixin } from '@/mixins/MoneyMixin';
import { postMixin } from '@/mixins/PostMixin';
import { publicationMixin } from '@/mixins/PublicationMixin';
import { subscriptionHelperMixin } from './mixins/SubscriptionHelperMixin';
import { registerSubscription } from '@/services/subscription';
import StepNumber from './StepNumber.vue';
import StepPayFinish from './PayFinish/Index.vue';
import StepProductType from './ProductType/Index.vue';
import StepAuth from './StepAuth.vue';
import StepPayLevel from './PayLevel/Index.vue';

export default {
    components: {
        StepNumber,
        StepPayFinish,
        StepPayLevel,
        StepAuth,
        StepProductType
    },

    mixins: [accountingMixin, moneyMixin, postMixin, publicationMixin, subscriptionHelperMixin],

    props: {
        subscriptionTermsDescription: {
            type: String,
            default: ''
        },
        subscriptionTermsWhatYouGet: {
            type: String,
            default: ''
        },
        subscriptionTerms: {
            type: Array,
            default: () => []
        },
        publication: {
            type: Object,
            required: true
        },
        // A way of altering behavior slightly depending on "caller" - e.g. We want to center "Subscribe to the Shawangunk Journal" and add a background if being called from 'non-my account'
        caller: {
            type: String,
            default: 'my-account'
        }
    },

    stepsUnauth: ['productType', 'payLevel', 'auth', 'payFinish'],
    stepsAuth: ['productType', 'payLevel', 'payFinish'],

    data() {
        const showGiftLink = new URLSearchParams(window.location.search).get('show_gift_link') || '1';
        return {
            isBusy: false,
            errors: [],
            agreeTerms: false,
            availableSubscriptionTerms: this.subscriptionTerms ? this.subscriptionTerms : null,
            stepName: 'productType',
            productType: null, // Product type selected
            selectedTermId: 0,
            payLevel: {
                name: '',
                value: 0
            },
            mailingAddress: {},
            payment: {
                paymentType: 'card',
                paymentMethod: -1,
                ccNumber: '',
                ccExpMonth: null,
                ccExpYear: null,
                ccCode: ''
            },
            isShowingSuccess: false,
            showGiftLink: showGiftLink === '1',
            isAuth: false,
            user: null
        };
    },

    computed: {
        publicationHasGiftSubscription() {
            return this.publication.slug === 'kingston-wire' || this.publication.slug === 'shawangunk-journal';
        },

        selectedTerm() {
            return this.subscriptionTerms.find((term) => term.id === this.selectedTermId);
        },

        steps() {
            return this.isAuth ? this.$options.stepsAuth : this.$options.stepsUnauth;
        }
    },

    watch: {
        /**
         * When subscription terms change, set to local and select the only term (which should be monthly)
         */
        subscriptionTerms() {
            this.availableSubscriptionTerms = this.subscriptionTerms;
            this.subscriptionTerm =
                this.availableSubscriptionTerms && this.availableSubscriptionTerms.length
                    ? this.availableSubscriptionTerms[0].id
                    : null;
        }
    },

    created() {
        if (this.userIsLoggedIn) {
            this.isAuth = true;
            this.user = this.userCurrent;
        }
    },

    methods: {
        productTypeSelected() {
            this.stepName = 'payLevel';
        },

        /**
         * User selected the pay level and clicked the pay button
         */
        payLevelAccepted() {
            this.stepName = this.isAuth ? 'payFinish' : 'auth';
        },

        /**
         * User has updated the mailing address
         */
        mailingAddressUpdated(address) {
            this.mailingAddress = address;
        },

        stepSelected(step) {
            this.stepName = step;
        },

        /**
         * Check if credit card details were given
         *
         * @return {Boolean}
         */
        hasCreditCardDetails() {
            return this.payment.ccNumber && this.payment.ccExpMonth && this.payment.ccExpYear && this.payment.ccCode;
        },

        /**
         * Make sure that we have some basic needed info before submitting
         *
         * @return {Boolean}
         */
        validateRegister() {
            // If user wants to add a new card, make sure they've given card details
            if (this.payment.paymentType === 'card' && this.payment.paymentMethod === -1) {
                if (!this.hasCreditCardDetails()) {
                    this.errors = ['general|To add a new credit card, please provide all card information'];
                    return false;
                }
            }
            return true;
        },

        /**
         * Try to register a new subscription
         */
        async register() {
            this.isBusy = true;
            this.errors = [];

            // Do a sanity check
            if (!this.validateRegister()) {
                this.isBusy = false;
                return;
            }

            if (this.payLevel && this.payLevel.name === 'other') {
                // Make sure to format any values to a proper amount
                const value = this.payLevel.value;
                this.payLevel.value =
                    value && !Number.isNaN(Number.parseFloat(value))
                        ? Number.parseFloat(value).toFixed(2).toString().replace(/\.00$/, '')
                        : value.toString();
            }

            let data = {
                publication: this.publication.id,
                subscriptionTerm:
                    this.selectedTerm !== undefined && this.selectedTerm && this.selectedTerm.id
                        ? this.selectedTerm.id
                        : null,
                ...this.payment,
                paymentType: this.payment.paymentType ?? 'balance',
                paymentMethod: this.payment.paymentMethod
                    ? this.payment.paymentMethod === -1
                        ? 'new'
                        : this.payment.paymentMethod
                    : null,
                payLevel: this.payLevel,
                agreeTerms: this.agreeTerms,
                mailingAddress: this.needMailingAddress(this.selectedTerm.productType) ? this.mailingAddress : null
            };
            try {
                const response = await registerSubscription(data);
                this.isBusy = false;
                if (!response.success) {
                    this.errors = response.errors;
                } else {
                    this.isBusy = false;
                    const registerResults = {
                        publicationName: this.publication.text,
                        publication: this.publication,
                        cost: this.payLevel.value,
                        termTypeFriendlyName: this.termTypeFriendlyName(this.selectedTerm.termType),
                        selectedTerm: this.selectedTerm,
                        startDate: getNow({ isServerTime: false })
                    };
                    this.$emit('added', registerResults);
                }
            } catch (error) {
                this.errors = ['general|There was a problem processing your subscription.'];
                this.isBusy = false;
            }
        },

        async loggedIn() {
            await this.loadUserInfo();
            this.$nextTick(() => {
                this.user = this.userCurrent;
                this.stepName = 'payFinish';
            });
        }
    }
};
</script>
