<!-- @format -->
<template>
    <form class="field has-addons" @submit.prevent="submit">
        <div class="control">
            <input
                ref="searchInput"
                v-model="text"
                type="search"
                class="input"
                :class="inputClass"
                :placeholder="placeholder"
                :autofocus="autofocus"
            />
        </div>
        <div v-if="includeButton" class="control">
            <button type="submit" class="button is-success">
                <fa-icon icon="search" />
            </button>
        </div>
    </form>
</template>

<script>
export default {
    props: {
        inputClass: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: 'Search'
        },
        includeButton: {
            type: Boolean,
            default: true
        },
        autofocus: {
            type: Boolean,
            default: false
        }
    },

    data() {
        const storedQuery = window.sessionStorage.getItem('search_query');
        return {
            text: storedQuery ?? ''
        };
    },

    watch: {
        '$route.query.q': {
            handler(val) {
                if (val) {
                    this.text = val;
                }
            },
            immediate: true
        }
    },

    mounted() {
        if (this.autofocus) {
            this.$refs.searchInput.focus();
        }
    },

    methods: {
        submit() {
            this.$router.push({
                name: 'search',
                query: {
                    q: this.text
                }
            });
            this.$emit('close');
        }
    }
};
</script>
