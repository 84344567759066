<!-- @format -->
<template>
    <div class="columns">
        <div class="column">
            <p>
                <span class="has-text-weight-bold">Poster: </span>
                <span v-if="post.user && post.user.email">{{ post.user.email }}</span>
                <span v-else>(user email unknown)</span>
                <span v-if="post.user && post.user.id">({{ post.user.id }})</span>
                <span v-else>(user id unknown)</span>
            </p>
            <p>
                <span class="has-text-weight-bold">Creation Date:</span>
                {{ formatStandardDateTime(post.created_at, 'basic-detail') }}
            </p>
            <p>
                <span class="has-text-weight-bold">Last Updated:</span>
                {{ formatStandardDateTime(post.updated_at, 'basic-detail') }}
            </p>
            <p><span class="has-text-weight-bold">Status:</span> {{ post.post_status.value }}</p>
            <p v-if="post.post_identifiers && post.post_identifiers.length">
                <span class="has-text-weight-bold">ShortCode:</span>
                {{ describePostIdentifiers(post.post_identifiers) }}
            </p>
            <p v-if="post.post_identifiers && post.post_identifiers.length">{{ post.directLink }}</p>
            <p v-if="post.post_identifiers && post.post_identifiers.length">{{ post.directLinkShort }}</p>

            <tag-editor :post="post" />
        </div>
    </div>
</template>

<script>
import { dateMixin } from '@/mixins/DateMixin.js';
import { postMixin } from '@/mixins/PostMixin.js';
import TagEditor from './Tag/Index.vue';

export default {
    components: {
        TagEditor
    },

    mixins: [dateMixin, postMixin],

    props: {
        post: {
            type: Object,
            required: true
        }
    }
};
</script>
