<!-- @format -->
<template>
    <div class="my-2" data-test="post_group">
        <template v-if="group.items.length === 1">
            <article-post
                v-if="isPostTypeNews(group.items[0].post_type.key)"
                :is-top-post="group.isTopPost"
                :post="group.items[0]"
                @single-post-view="singlePostView"
            />
            <cartoon-post
                v-else-if="isPostType(group.items[0], 'postTypeCartoon')"
                :is-top-post="group.isTopPost"
                :post="group.items[0]"
                @single-post-view="singlePostView"
            />
            <classified-post
                v-else-if="isPostType(group.items[0], 'postTypeClassified')"
                :is-top-post="group.isTopPost"
                :show-post-type="showSectionPostType"
                :post="group.items[0]"
                @single-post-view="singlePostView"
            />
            <column-post
                v-else-if="isPostType(group.items[0], 'postTypeColumn')"
                :is-top-post="group.isTopPost"
                :post="group.items[0]"
                @single-post-view="singlePostView"
            />
            <event-post
                v-else-if="isPostType(group.items[0], 'postTypeEvent')"
                :show-post-type="showSectionPostType"
                :post="group.items[0]"
                :key="group.items[0].id"
                @single-post-view="singlePostView"
            />
            <legal-notice-post
                v-else-if="isPostType(group.items[0], 'postTypeLegal')"
                :is-top-post="group.isTopPost"
                :show-post-type="showSectionPostType"
                :post="group.items[0]"
                @single-post-view="singlePostView"
            />
            <letter-post
                v-else-if="isPostType(group.items[0], 'postTypeLetter')"
                :is-top-post="group.isTopPost"
                :post="group.items[0]"
                @single-post-view="singlePostView"
            />
            <obituary-post
                v-else-if="isPostType(group.items[0], 'postTypeObit')"
                :is-top-post="group.isTopPost"
                :show-post-type="showSectionPostType"
                :post="group.items[0]"
                @single-post-view="singlePostView"
            />
            <blotter-post
                v-else-if="isPostType(group.items[0], 'postTypeBlotter')"
                :is-top-post="group.isTopPost"
                :show-post-type="showSectionPostType"
                :post="group.items[0]"
                @single-post-view="singlePostView"
            />
            <opinion-post
                v-else-if="isPostType(group.items[0], 'postTypeOpEd')"
                :is-top-post="group.isTopPost"
                :post="group.items[0]"
                @single-post-view="singlePostView"
            />
            <press-release-post
                v-else-if="isPostType(group.items[0], 'postTypePressRelease')"
                :post="group.items[0]"
                @single-post-view="singlePostView"
            />
            <newscast-post v-else-if="isPostType(group.items[0], 'postTypeNewscast')" :post="group.items[0]" />
            <podcast-post v-else-if="isPostType(group.items[0], 'postTypePodcast')" :post="group.items[0]" />
            <sports-post
                v-else-if="isPostType(group.items[0], 'postTypeSports')"
                :is-top-post="group.isTopPost"
                :show-post-type="showSectionPostType"
                :post="group.items[0]"
                @single-post-view="singlePostView"
            />
            <sponsored-post
                v-else-if="isPostType(group.items[0], 'postTypeSponsored')"
                :is-top-post="group.isTopPost"
                :show-post-type="showSectionPostType"
                :post="group.items[0]"
                @single-post-view="singlePostView"
            />
            <!-- Fallback to Article type when type is not handled -->
            <article-post v-else :post="group.items[0]" :is-top-post="group.isTopPost" />
        </template>
        <template v-else-if="group.items.length > 1">
            <classified-group v-if="isPostType(group.items[0], 'postTypeClassified')" :group="group.items" />
            <column-group v-else-if="isPostType(group.items[0], 'postTypeColumn')" :group="group.items" />
            <event-group v-else-if="isPostType(group.items[0], 'postTypeEvent')" :group="group.items" />
            <legal-notice-group v-else-if="isPostType(group.items[0], 'postTypeLegal')" :group="group.items" />
            <letter-group v-else-if="isPostType(group.items[0], 'postTypeLetter')" :group="group.items" />
            <obituary-group v-else-if="isPostType(group.items[0], 'postTypeObit')" :group="group.items" />
            <blotter-group v-else-if="isPostType(group.items[0], 'postTypeBlotter')" :group="group.items" />
            <opinion-group v-else-if="isPostType(group.items[0], 'postTypeOpEd')" :group="group.items" />
            <!-- Fallback to classified type of grouping when unkown group type -->
            <classified-group v-else :group="group.items" />
        </template>
    </div>
</template>

<script>
import { postMixin } from '@/mixins/PostMixin.js';
import ArticlePost from './PostTypes/Main/Article.vue';
import CartoonPost from './PostTypes/Main/Cartoon.vue';
import ClassifiedPost from './PostTypes/Main/Classified.vue';
import ColumnPost from './PostTypes/Main/Column.vue';
import EventPost from './PostTypes/Main/Event.vue';
import LegalNoticePost from './PostTypes/Main/LegalNotice.vue';
import LetterPost from './PostTypes/Main/Letter.vue';
import ObituaryPost from './PostTypes/Main/Obituary.vue';
import BlotterPost from './PostTypes/Main/Blotter.vue';
import OpinionPost from './PostTypes/Main/Opinion.vue';
import PressReleasePost from './PostTypes/Main/PressRelease.vue';
import NewscastPost from './PostTypes/Main/Newscast.vue';
import PodcastPost from './PostTypes/Main/Podcast.vue';
import SponsoredPost from './PostTypes/Main/Sponsored.vue';
import SportsPost from './PostTypes/Main/Sports.vue';
import ClassifiedGroup from './PostTypes/Group/Classified.vue';
import ColumnGroup from './PostTypes/Group/Column.vue';
import EventGroup from './PostTypes/Group/Event.vue';
import LegalNoticeGroup from './PostTypes/Group/LegalNotice.vue';
import LetterGroup from './PostTypes/Group/Letter.vue';
import ObituaryGroup from './PostTypes/Group/Obituary.vue';
import BlotterGroup from './PostTypes/Group/Blotter.vue';
import OpinionGroup from './PostTypes/Group/Opinion.vue';

export default {
    components: {
        ArticlePost,
        CartoonPost,
        ClassifiedPost,
        ColumnPost,
        EventPost,
        LegalNoticePost,
        LetterPost,
        ObituaryPost,
        BlotterPost,
        OpinionPost,
        PressReleasePost,
        NewscastPost,
        PodcastPost,
        SponsoredPost,
        SportsPost,
        ClassifiedGroup,
        ColumnGroup,
        EventGroup,
        LegalNoticeGroup,
        LetterGroup,
        ObituaryGroup,
        BlotterGroup,
        OpinionGroup
    },

    mixins: [postMixin],

    props: {
        group: {
            type: Object,
            required: true
        },
        section: {
            type: Object,
            default: null
        },
        isEmit: {  // Emit instead of routing
            type: Boolean,
            default: false
        }
    },

    computed: {
        showSectionPostType() {
            if (!this.section) {
                return true;
            }
            return this.section.should_group_post_type;
        }
    },

    methods: {
        singlePostView(data) {
            //console.log('SinglePostView in GroupLayout!, data=', data);
            this.$emit('single-post-view', data);
        }
    }
};
</script>
