/**
 * @format
 * Date mixins
 */

import { dateFormatStandardDateTime } from '@/services/date'; // @todo  2019-03-22  MJL  Start standardizing functions here instead of defining in mixin, mixin can call them instead

export const dateMixin = {
    methods: {
        /**
         * Format a standard date in the yyyy-mm-dd hh:mm:ss format to mm/dd/yyyy.
         *
         * @param {String} dateIn Date input
         *
         * @return {mixed} Date output; null if none/not valid
         */
        formatStandardDate(dateIn = null) {
            let dateOut = null;
            if (dateIn !== null) {
                dateOut =
                    dateIn.substr(5, 2) +
                    '/' +
                    dateIn.substr(8, 2) +
                    '/' +
                    dateIn.substr(0, 4);
            }

            return dateOut;
        },
        /**
         * Format a standard date in the yyyy-mm-dd hh:mm:ss format to mm/dd/yyyy hh:mm (or other given format)
         *
         * @param {String} dateIn        Date input
         * @param {String} dateOutFormat Date output format.  'basic' for mm/dd/yyyy hh:mm (default).  'human' for simple human format
         *
         * @return {mixed} Date output; null if none/not valid
         */
        formatStandardDateTime(dateIn = null, dateOutFormat = 'basic') {
            return dateFormatStandardDateTime(dateIn, dateOutFormat);
        },
        /**
         * Return credit card expiration months
         *
         * @param {Boolean} includeSelect Include 'please select' entry?  Default is true.
         * @param {String}  selectText    Select text when includeSelect  Default is 'Please Select'
         *
         * @return {Array}
         */
        dateOptionPaymentMonths(
            includeSelect = true,
            selectText = 'Please Select'
        ) {
            let months = [];
            if (includeSelect) {
                months.push({
                    id: null,
                    text: selectText
                });
            }
            for (let index = 1; index <= 12; index++) {
                months.push({
                    id: index.toString().padStart(2, '0'),
                    text: index.toString().padStart(2, '0')
                });
            }

            return months;
        },
        /**
         * Return credit card expiration years
         *
         * @param {Boolean} includeSelect Include 'please select' entry?  Default is true.
         * @param {String}  selectText    Select text when includeSelect  Default is 'Please Select'
         *
         * @return {Array}
         */
        dateOptionPaymentYears(
            includeSelect = true,
            selectText = 'Please Select'
        ) {
            let years = [];
            if (includeSelect) {
                years.push({
                    id: null,
                    text: selectText
                });
            }
            let year = Number.parseInt(new Date().getFullYear());
            for (let index = year; index < year + 15; index++) {
                years.push({
                    id: index,
                    text: index
                });
            }

            return years;
        }
    }
};
