/**
 * @format
 * Client mixins
 */
import router from '../router';

export const clientMixin = {
    computed: {
        /**
         * Return client screen width
         *
         * @return {Integer} Could be 0 if no window
         */
        clientWidth() {
            return window.screen && window.screen.width
                ? window.screen.width
                : 0;
        },
        /**
         * Return client screen height
         *
         * @return {Integer} Could be 0 if no window
         */
        clientHeight() {
            return window.screen && window.screen.height
                ? window.screen.height
                : 0;
        },
        /**
         * Determine if client width is "mobile"
         *
         * @return {Boolean}
         */
        clientIsMobileWidth() {
            return window.screen && window.screen.width <= 768 ? true : false;
        },
        /**
         * Determine if client is Android based on user agent
         *
         * @return {Array} Match or null if not matched
         */
        clientIsAndroid() {
            return navigator.userAgent.match(/android/i);
        },
        /**
         * Determine if client is Blackberry based on user agent
         *
         * @return {Array} Match or null if not matched
         */
        clientIsBlackBerry() {
            return navigator.userAgent.match(/blackberry/i);
        },
        /**
         * Determine if client is iOS based on user agent
         *
         * @return {Array} Match or null if not matched
         */
        clientIsIos() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        /**
         * Determine if client is Opera Mini based on user agent
         *
         * @return {Array} Match or null if not matched
         */
        clientIsOperaMini() {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        /**
         * Determine if client is Window mobile based on user agent
         *
         * @return {Array} Match or null if not matched
         */
        clientIsWindowsMobile: function () {
            return (
                navigator.userAgent.match(/IEMobile/i) ||
                navigator.userAgent.match(/WPDesktop/i)
            );
        },
        /**
         * Determine if client is mobile based on user agent
         *
         * @return {Boolean}
         */
        clientIsMobile() {
            const result =
                this.clientIsAndroid ||
                this.clientIsBlackBerry ||
                this.clientIsIos ||
                this.clientIsOperaMini ||
                this.clientIsWindowsMobile;
            return result === null ? false : true;
        },
        /**
         *  Return user agent
         *
         * @return {String}
         */
        clientUserAgent() {
            return navigator.userAgent;
        },
        /**
         * Determine if swipe is allowed
         *
         * @return {Boolean}
         */
        clientAllowSwipe() {
            return this.clientIsMobile;
        }
    },

    methods: {
        /**
         * Return URL
         *
         * @param {String} url    Url to redirect to
         * @param {String} params Any parameters to pass - default is none
         */
        clientRedirectUrl(url, params = null) {
            return url + (params !== null ? '/' + params : '');
        },
        /**
         * Redirect to URL
         *
         * @param {String} url    Url to redirect to
         * @param {String} params Any parameters to pass - default is none
         */
        clientRedirectTo(url, params = null) {
            window.location.href = url + (params !== null ? '/' + params : '');
        },
        /**
         * Redirect to home.  Shortcut for above.
         *
         * @param {String} params Any parameters to pass - default is none
         */
        clientRedirectToHome(params = null) {
            window.location.href =
                naRoutes.home + (params !== null ? '/' + params : '');
        },
        /**
         * Scroll to top of window
         */
        clientScrollTop() {
            window.scrollTo(0, 0);
        },
        /**
         * Change URL
         *
         * @param {String} url
         */
        async clientReplaceState(url = null) {
            try {
                window.history.replaceState(null, null, url ? url : '/');
                // Temporary work around to sync vue-router with the current
                // custom route handling
                await router.replace(url ? url : '/');
            } catch (error) {
                if (error.name === 'NavigationDuplicated') {
                    return;
                }
                throw error;
            }
        }
    }
};
