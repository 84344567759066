<!-- @format -->
<template>
    <div
        v-if="
            !postCostIsFree(post) && !postCostIsPaid(post) && !postCostIsCredit(post) && !postCostIsSubscription(post)
        "
        class="na-subscribe-to"
    >
        <span>
            <span class="na-subscribe-to-slogan">Tired of nickels &amp; dimes?</span>
            <span class="ml-1 na-subscribe-to-publication"
                ><a class="na-link" @click="subscribe"
                    >Subscribe to the {{ postPublicationFormatted(post.publication) }}</a
                ></span
            >
        </span>
    </div>
</template>

<script>
import { postMixin } from '@/mixins/PostMixin.js';

export default {
    mixins: [postMixin],

    props: {
        post: {
            default: function () {
                return null;
            }
        }
    },

    methods: {
        /**
         * User clicked subscribe
         */
        subscribe() {
            this.$router.push({
                name: 'post-viewer',
                params: {
                    ...this.$route.params
                },
                query: {
                    subscribe: 1
                }
            });
        }
    }
};
</script>
