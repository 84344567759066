/**
 * Tag related API functions
 * Abstracting these out from DataMixin.js.
 */
import axios from 'axios';

/**
 * Load tags
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const readTags = function (data) {
    return axios.post(naRoutes.readTags,
        {
            data
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};
