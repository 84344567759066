/**
 * @format
 * Accounting mixins
 */
export const accountingMixin = {
    methods: {
        /**
         * Return array of reload amounts.  This is not stored in the back-end because it's dynamic.  We'll only show appropriate amounts.
         *
         * @param {Integer} balance Current account balance.  If not null, we will add balance and amt and only show reload options that are applicable (always at least the $100 top amount though)
         * @param {Integer} amt     Amount of purchase
         *
         * @return {Array}
         */
        doLoadOptionReloadAmounts(balance = null, amt = null) {
            let optionReloadAmounts = [];

            const minThresh = balance !== null && amt !== null;
            const maxCount = 3;
            let counter = 0;

            if (window.ReactNativeWebView) {
                if (
                    !minThresh ||
                    (minThresh &&
                        counter < maxCount &&
                        Number.parseFloat(10) + Number.parseFloat(balance) >=
                            Number.parseFloat(amt))
                ) {
                    optionReloadAmounts.push({
                        text: '$5.00',
                        value: 5
                    });
                    counter++;
                }
            }

            if (
                !minThresh ||
                (minThresh &&
                    counter < maxCount &&
                    Number.parseFloat(10) + Number.parseFloat(balance) >=
                        Number.parseFloat(amt))
            ) {
                optionReloadAmounts.push({
                    text: '$10.00',
                    value: 10
                });
                counter++;
            }

            if (
                !minThresh ||
                (minThresh &&
                    counter < maxCount &&
                    Number.parseFloat(25) + Number.parseFloat(balance) >=
                        Number.parseFloat(amt))
            ) {
                optionReloadAmounts.push({
                    text: '$25.00',
                    value: 25
                });
                counter++;
            }

            /*
            2020-05-01  MJL  Removed $50 and $100
            if (!minThresh || (minThresh && counter < maxCount && (Number.parseFloat(50) + Number.parseFloat(balance) >= Number.parseFloat(amt)))) {
                optionReloadAmounts.push({
                    text: '$50',
                    value: 50
                });
                counter ++;
            }

            // Also need to make sure we have at least 1 button option, so include $100 if none
            if (!minThresh || (minThresh && !counter || (minThresh && counter < maxCount && (Number.parseFloat(100) + Number.parseFloat(balance) >= Number.parseFloat(amt))))) {
               optionReloadAmounts.push({
                    text: '$100',
                    value: 100
                });
                counter ++;
            }
            */

            return optionReloadAmounts;
        },
        /**
         * Return payment types (currently only one - so just hard-coded).  If we add more, this should pull from the database (options table)
         *
         * @return {Array}
         */
        doLoadOptionPaymentTypes() {
            return [
                {
                    id: null,
                    text: 'Please Select',
                    value: null
                },
                {
                    id: -20,
                    text: 'Credit Card',
                    value: 'paytypeCreditCard'
                }
            ];
        },
        /**
         * Helper where given a customer balance and a cost, indicates the needed amount to be charged.  Could be 0 if customer has enough balance.
         *
         * @param {mixed} balance Balance
         * @param {mixed} cost    Cost
         *
         * @return {Number} charge.  May be 0 if additional amount needed, null if any of the numbers were not valid
         */
        accountingNewCharge(balance, cost) {
            const _balance = isNaN(balance) ? null : Number.parseFloat(balance);
            const _cost = isNaN(cost) ? null : Number.parseFloat(cost);

            if (_balance !== null && _cost !== null) {
                return _balance >= _cost ? 0 : _cost - _balance;
            } else {
                return null;
            }
        },
        /**
         * Helper where given a customer balance and a cost, indicates the balance after.
         *
         * @param {mixed} balance Balance
         * @param {mixed} cost    Cost
         *
         * @return {Number} balance.  May be null if any of the numbers were not valid
         */
        accountingNewBalance(balance, cost) {
            const _balance = isNaN(balance) ? null : Number.parseFloat(balance);
            const _cost = isNaN(cost) ? null : Number.parseFloat(cost);

            if (_balance !== null && _cost !== null) {
                return _balance - _cost;
            } else {
                return null;
            }
        }
    }
};
