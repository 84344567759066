<!-- @format -->
<template>
    <div v-if="getCount" class="na-post-image-count na-link" @click="clicked">
        <span>+{{ getCount }} <fa-icon icon="images" /></span>
    </div>
</template>

<script>
export default {
    props: {
        media: {
            type: Array,
            default: () => []
        },
        // Subtract one from count?  We only need to do this if (for example), we're displaying a video for preview - the count should show one less.  BUT if we're showing an image for preview, then nothing should be subtracted
        minusOne: {
            type: [Boolean, Number],
            default: false
        }
    },

    computed: {
        getCount() {
            const count = this.media?.filter((media) => !!media.url_med).length;
            if (count) {
                return count - (this.minusOne ? 1 : 0);
            } else {
                return 0;
            }
        }
    },
    methods: {
        /**
         * Item was clicked
         */
        clicked() {
            this.$emit('clicked');
        }
    }
};
</script>
