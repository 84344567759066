/**
 * @format
 * Error mixins
 */

import some from 'lodash-es/some';

export const errorMixin = {
    methods: {
        /**
         * Determine if given array has an error for a given field.
         *
         * @param {Array}        errors         Array of input errors
         * @param {Array|String} field          Field name to check - may be a single string or an array of strings
         * @param {mixed}        errorDelimiter Field which delimits error name and message - defaults to |
         *
         * @return {Object|bool} Class object (or bool if 'returnBool' is true)
         */
        hasInputError(errors, field, errorDelimiter = '|', returnBool = false) {
            let result = false; // Assume no match

            if (errors && Array.isArray(errors)) {
                errors.forEach((item) => {
                    if (typeof item === 'string') {
                        const fields = item.split(errorDelimiter);
                        if (
                            (Array.isArray(field) &&
                                field.includes(fields[0])) ||
                            (typeof field === 'string' && fields[0] === field)
                        ) {
                            result = true; // Found a match!
                        }
                    }
                });
            } else if (errors) {
                result = errors[field];
            }

            if (returnBool) {
                return result;
            } else {
                return {
                    'na-input-error': result
                };
            }
        }
    }
};

export function makeHasErrorEventMixin(errorLookUp) {
    return {
        watch: {
            errors(val, oldVal) {
                if (val.length && !oldVal.length) {
                    for (let i = 0, len = val.length; i < len; i++) {
                        const error = val[i];
                        if (
                            some(errorLookUp, (errorPrefix) =>
                                error.startsWith(errorPrefix)
                            )
                        ) {
                            this.$emit('onError');
                            return;
                        }
                    }
                }
            }
        }
    };
}
