<!-- @format -->
<template>
    <div class="is-flex is-flex-direction-column mb-2">
        <div v-if="paymentMethods.length" class="is-flex is-flex-direction-column">
            <field name="paymentMethod">
                <label class="label">Choose your payment method:</label>
                <div class="control">
                    <div class="select">
                        <select v-model="selectedPaymentMethodId" name="paymentMethod">
                            <option v-for="option in optionPaymentMethods" :key="option.value" :value="option.value">
                                {{ option.text }}
                            </option>
                        </select>
                    </div>
                </div>
            </field>
        </div>
        <payment-method-form
            v-if="selectedPaymentMethodId === null"
            ref="paymentMethodForm"
            :charge-errors="chargeErrors"
        />
    </div>
</template>

<script>
import PaymentMethodForm from './PaymentMethodForm.vue';

export default {
    components: {
        PaymentMethodForm
    },

    props: {
        value: {
            type: Object,
            default: null,
            validator(val) {
                return val === null || typeof val === 'object';
            }
        },
        paymentMethods: {
            type: Array,
            required: true
        },
        chargeErrors: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            selectedPaymentMethodId: this.value?.id ?? null
        };
    },

    computed: {
        optionPaymentMethods() {
            return [
                {
                    value: null,
                    text: 'Add new card'
                },
                ...this.paymentMethods.map((paymentMethod) => ({
                    value: paymentMethod.id,
                    text: `${paymentMethod.ident} Expires ${paymentMethod.exp_month}/${paymentMethod.exp_year}`
                }))
            ];
        }
    },

    watch: {
        selectedPaymentMethodId(val) {
            if (!val) {
                this.$emit('input', null);
            } else {
                this.$emit('input', this.paymentMethods.find((pm) => pm.id === val) ?? null);
            }
        }
    },

    methods: {
        submitNewPaymentMethod() {
            return this.$refs.paymentMethodForm.submit();
        }
    }
};
</script>
