/** @format */
import { dateMixin } from '@/mixins/DateMixin.js';
import { mediaMixin } from '@/mixins/MediaMixin.js';
import { postMixin } from '@/mixins/PostMixin.js';

export default {
    mixins: [dateMixin, mediaMixin, postMixin],

    computed: {
        selectAllowed() {
            if (!this.userCurrent) {
                return false;
            }
            return (
                this.canAccessAdministration ||
                this.userCurrent.features.some(
                    (feature) =>
                        feature.is_active &&
                        feature.feature === 'post_content_select'
                )
            );
        }
    },

    methods: {
        setStructureData() {
            const structuredData = {
                '@context': 'https://schema.org',
                '@type': 'NewsArticle',
                mainEntityOfPage: {
                    '@type': 'WebPage',
                    '@id':
                        this.post.directLink ??
                        this.postLinkUrl(
                            this.postGetFirstIdentifier(
                                this.post.post_identifiers
                            )
                        )
                },
                headline: this.post.title,
                image: this.post.media
                    ? this.postMediaUrl(
                          this.post.media[0],
                          this.isShowMediaObfuscated(this.post) ? 'pixs' : 'med'
                      )
                    : '',
                datePublished: this.formatStandardDateTime(
                    this.post.content_at,
                    'iso'
                ),
                dateModified: this.formatStandardDateTime(
                    this.post.updated_at,
                    'iso'
                ),
                author: {
                    '@type': 'Person',
                    name: this.postAuthorFormattedList(this.post.post_authors)
                },
                publisher: {
                    name: this.post.publication.name,
                    '@type': 'Organization',
                    logo: {
                        '@type': 'ImageObject',
                        url: this.post.media
                            ? this.mediaUrl(
                                  this.getPublicationMedia(
                                      this.post,
                                      'mediaTypeImage'
                                  )[0]
                              )
                            : ''
                    }
                },
                description: this.post.snippet,
                isAccessibleForFree: !!this.postCostIsFree(this.post),
                hasPart: [
                    {
                        '@type': 'WebPageElement',
                        isAccessibleForFree: !!this.postCostIsFree(this.post),
                        cssSelector: '.na-paywall-content'
                    }
                ]
            };
            const scriptTag = document.createElement('script');
            scriptTag.setAttribute('type', 'application/ld+json');
            scriptTag.appendChild(
                document.createTextNode(JSON.stringify(structuredData))
            );
            document.head.appendChild(scriptTag);
        },

        isVisitorCrawler(data) {
            if (data) {
                return data?.isGoogleBot || data?.isGoogleOther;
            } else {
                return false;
            }
        }
    }
};
