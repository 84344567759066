<!-- @format -->
<template>
    <span v-if="post">
        <a
            :href="theLink"
            target="_blank"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Share on Facebook"
            rel="noopener noreferrer"
            @click="trackIt"
        >
            <span class="icon na-icon-span na-icon-facebook">
                <fa-icon :icon="['fab', 'facebook-square']" />
            </span>
        </a>
    </span>
</template>

<script>
import { postMixin } from '@/mixins/PostMixin.js';

export default {
    mixins: [postMixin],

    props: {
        post: {
            type: Object,
            required: true
        }
    },
    computed: {
        theLink() {
            return this.targetLink();
        }
    },
    methods: {
        /**
         * Return url
         *
         * @return {String}
         */
        targetLink() {
            const postName = this.post.directLinkShort
                ? this.post.directLinkShort
                : this.postLinkUrl(this.postGetFirstIdentifier(this.post.post_identifiers), true);
            return 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURI(postName); //developers.facebook.com%2Fdocs%2Fplugins%2F&amp;src=sdkpreparse';
        },
        /**
         * Track the share
         */
        trackIt() {
            this.$emit('shared', {
                shareTo: 'shareToTypeFacebook',
                url: this.targetLink()
            });
        }
    }
};
</script>
