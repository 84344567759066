/**
 * @format
 * Data helper mixins
 * Break out simple helpers - e.g. load US states.
 */

import {
    loadOptionLocationStates,
    loadOptionSubscriptionTypes,
    loadOptionSubscriptionProductTypes
} from '@/services/option';
import { readPaymentMethods } from '@/services/accounting.js';
import { readPublication } from '@/services/publication.js';

export const dataHelperMixin = {
    methods: {
        /**
         * Load states
         *
         * @param {mixed}    addPicker Add picker text?  Null (default) is none - otherwise, this is the object.
         * @param {Function} callback  Function to call when done.  We pass a Boolean result and the Array
         *
         * @return Void
         */
        async dataHelperLoadLocationStates(addPicker = null) {
            let results = [];
            const response = await loadOptionLocationStates();
            results = response.locationStates;
            if (addPicker) {
                results.unshift({
                    id: addPicker.id ? addPicker.id : null,
                    value: addPicker.value ? addPicker.value : null,
                    text: addPicker.text ? addPicker.text : null
                });
            }
            return results;
        },

        /**
         * Load subscription types
         *
         * @param {mixed}    addPicker Add picker text?  Null (default) is none - otherwise, this is the object.
         * @param {Function} callback  Function to call when done.  We pass a Boolean result, the Array and errors (or null if none)
         *
         * @return Void
         */
        async dataHelperLoadOptionSubscriptionTypes(addPicker = null) {
            let results = [];
            const response = await loadOptionSubscriptionTypes();
            if (response.success) {
                results = response.subscriptionTypes;
            } else {
                throw new Error('internal error');
            }
            if (addPicker) {
                results.unshift({
                    id: addPicker.id ? addPicker.id : null,
                    value: addPicker.value ? addPicker.value : null,
                    text: addPicker.text ? addPicker.text : null
                });
            }
            return results;
        },

        /**
         * Load subscription product types
         *
         * @param {mixed}    addPicker Add picker text?  Null (default) is none - otherwise, this is the object.
         * @param {Function} callback  Function to call when done.  We pass a Boolean result, the Array and errors (or null if none)
         *
         * @return Void
         */
        async dataHelperLoadOptionSubscriptionProductTypes(addPicker = null) {
            let results = [];
            const response = await loadOptionSubscriptionProductTypes();
            if (response.success) {
                results = response.subscriptionProductTypes;
            } else {
                throw new Error('internal error');
            }
            if (addPicker) {
                results.unshift({
                    id: addPicker.id ? addPicker.id : null,
                    value: addPicker.value ? addPicker.value : null,
                    text: addPicker.text ? addPicker.text : null
                });
            }
            return results;
        },

        /**
         * Load subscription terms for publication
         *
         * @param {Integer}  publicationId Publication id
         * @param {Function} callback      Function to call when done.  We pass a Boolean result, the Array and errors (or null if none)
         *
         * @return Void
         */
        async dataHelperLoadPublicationSubscriptionTerms(publicationId) {
            let results = null;
            const response = await readPublication({ id: publicationId });
            if (response.success) {
                results = {
                    description: response.publication.subscription_description,
                    whatYouGet: response.publication.subscription_what_you_get,
                    terms: response.publication.subscription_terms
                };
            } else {
                throw new Error('internal error');
            }
            return results;
        },

        /**
         * Load payment methods for user
         *
         * @param {Boolean}  activeOnly Include only active methods?  Default is false.
         * @param {Function} callback   Function to call when done.  We pass a Boolean result, the Array and errors (or null if none)
         *
         * @return Void
         */
        async dataHelperLoadPaymentMethods(activeOnly = true) {
            let results = null;
            const data = {
                activeOnly
            };
            const response = await readPaymentMethods(data);
            if (response.success) {
                results = response.paymentMethods;
            } else {
                throw new Error('internal error');
            }
            return results;
        }
    }
};
