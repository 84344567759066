<!-- @format -->
<template>
    <div>
        <p class="na-sidebar-quicklinks-text">
            Contact us if you are excited about what you see and want to contribute to Kingston Wire.
        </p>
        <div class="na-sidebar-quicklinks-address">
            <p class="na-sidebar-quicklinks-text">516 Broadway, Studio #2</p>
            <p class="na-sidebar-quicklinks-text">Kingston, NY 12401</p>
            <!-- <p class="na-sidebar-quicklinks-text">(845) 647-9190</p> -->
            <p class="na-sidebar-quicklinks-text">
                <a href="mailto:info@kingstonwire.com">info@kingstonwire.com</a>
            </p>
            <social-media-icons-group class="na-sidebar-quicklinks-text has-background-white"
            ></social-media-icons-group>
        </div>
    </div>
</template>

<script>
import SocialMediaIconsGroup from '@/components/Content/Social-Media/Icons/Group.vue';

export default {
    components: {
        SocialMediaIconsGroup
    }
};
</script>
