<!-- @format -->
<template>
    <figure>
        <picture :class="[isFullView && 'is-clickable']" @click="$emit('click')">
            <source
                v-for="set in imageSet"
                :key="set.mime"
                :type="set.mime"
                :srcset="set.srcset"
                sizes="(max-width: 768px) calc(100vw - 4rem), 1024px"
            />
            <img :src="media.url" :alt="altText" :title="titleText" :width="media.width" :height="media.height" />
        </picture>
        <figcaption v-if="showCaption" class="na-post-image-caption-card">
            <span v-if="caption" class="na-post-image-caption">{{ caption }}&nbsp;</span>
            <span class="na-post-image-credit">{{ credit }}</span>
        </figcaption>
    </figure>
</template>

<script>
import { postMixin } from '@/mixins/PostMixin.js';
import { mediaMixin } from '@/mixins/MediaMixin.js';

export default {
    mixins: [postMixin, mediaMixin],

    props: {
        post: {
            type: Object,
            required: true
        },
        isFullView: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        isNativeAd() {
            return !!this.post?.post_native_ads;
        },
        isPremiumAd() {
            return this.isNativeAd && this.post.post_native_ads[0]?.ad_type === 'nativeAdTypePremium';
        },
        postImage() {
            if (this.isPremiumAd) {
                const adImage = this.post.media.find((media) => media.image_type === 'premiumSquare');
                if (adImage) {
                    return adImage;
                }
                return this.post.media.find((media) => media.image_type === 'premiumLong');
            }
            return this.post.media[0];
        },
        media() {
            if (!this.postImage) {
                return;
            }
            let imageType = 'med';
            if (this.isShowMediaObfuscated(this.post) && !this.isFullView) {
                imageType = 'pixs';
            }
            return this.getPostMedia(this.postImage, imageType);
        },
        imageSet() {
            return this.postMediaUrlSet(this.postImage, {
                pixelated: this.isShowMediaObfuscated(this.post) && !this.isFullView
            });
        },
        altText() {
            return this.mediaAlt(this.postImage, `A photo from "${this.post.title}"`);
        },
        titleText() {
            return this.mediaTitle(this.postImage);
        },
        showCaption() {
            return this.isShowCaptionCard(this.post);
        },
        caption() {
            return this.mediaCaption(this.postImage);
        },
        credit() {
            return this.mediaCredit(this.postImage);
        }
    }
};
</script>
