<!-- @format -->
<template>
    <div v-if="isLoaded" class="is-flex is-flex-direction-column">
        <list-subscriptions v-if="step === null" :level="level" @add-subscription="step = 'add'" />

        <added-subscription
            v-if="isAddedSubscription"
            :cost="addSubscriptionResults.cost"
            :publication="addSubscriptionResults.publication"
            :start-date="addSubscriptionResults.startDate"
            :term-name="addSubscriptionResults.termTypeFriendlyName"
            @close="isAddedSubscription = false"
        />

        <add-subscription
            v-if="step === 'add'"
            :caller="caller"
            :option-publications="optionPublications"
            :subscription-terms-description="subscriptionTermsDescription"
            :subscription-terms-what-you-get="subscriptionTermsWhatYouGet"
            :subscription-terms="subscriptionTerms"
            :selected-publication-id="selectedPublicationId"
            :use-only-selected-publication="useOnlySelectedPublication"
            @publication-changed="loadSubscriptionTerms"
            @registering="step = 'register'"
            @added-subscription="addedSubscription"
            @cancelled="cancelledAddSubscription"
        />
    </div>
</template>

<script>
import { dataHelperMixin } from '@/mixins/DataHelperMixin.js';
import AddSubscription from './Add/Index.vue';
import AddedSubscription from '@/components/User/Account/Subscriptions/AddedSubscription/Index.vue';
import ListSubscriptions from './List.vue';

export default {
    components: {
        AddSubscription,
        AddedSubscription,
        ListSubscriptions
    },

    mixins: [dataHelperMixin],

    props: {
        optionPublications: {
            type: Array,
            default: () => []
        },
        // Can be used to cause add only step - useful for embedding add subscription functionality in content view
        addOnly: {
            type: Boolean,
            default: false
        },
        // Preselected publication id (will cause add to be assumed - so user doesn't have to click the 'add subscription button')
        selectedPublicationId: {
            default: function () {
                return null;
            }
        },
        // Use ONLY the preselected publication id above?
        useOnlySelectedPublication: {
            type: Boolean,
            default: false
        },
        // A way of altering behavior slightly depending on "caller" - e.g. We want to center "Subscribe to the Shawangunk Journal" and add a background if being called from 'non-my account'
        caller: {
            type: String,
            default: 'my-account'
        }
    },

    data() {
        return {
            isLoaded: false,
            errors: [],
            messages: [],
            level: 0,
            step: this.addOnly ? 'add' : null,
            subscriptionTermsDescription: null,
            subscriptionTermsWhatYouGet: null,
            subscriptionTerms: [],
            optionSubscriptionProductTypes: [],
            optionSubscriptionTypes: [],
            addSubscriptionResults: null,
            isAddedSubscription: false
        };
    },

    mounted() {
        this.init();
    },

    methods: {
        /**
         * Load initial
         */
        async init() {
            this.isLoaded = false;
            this.errors = [];
            this.messages = [];
            this.optionSubscriptionProductTypes = []; // Product types (e.g. digital, print)
            this.optionSubscriptionTypes = []; // Term types (e.g. monthly, yearly)
            const [productTypeResults, subTypesResults] = await Promise.all([
                this.dataHelperLoadOptionSubscriptionProductTypes(null),
                this.dataHelperLoadOptionSubscriptionTypes(null)
            ]);
            this.optionSubscriptionProductTypes = productTypeResults;
            this.optionSubscriptionTypes = subTypesResults;
            // If we have a pre-selected publication id, we need to load subscription terms (because otherwise, they wouldn't change in the add component)
            if (this.selectedPublicationId) {
                await this.loadSubscriptionTerms(this.selectedPublicationId);
            }
            this.isLoaded = true;
        },
        /**
         * Lookup terms for subscription type id
         *
         * @param {Array}   subscriptionTerms Terms array to be searched
         * @param {Integer} pTypeId           Product type id
         * @param {Integer} sTypeId           Subscription terms id
         *
         * @return {mixed} Terms object if found, null if not found
         */
        getTerms(subscriptionTerms, pTypeId, sTypeId) {
            if (subscriptionTerms) {
                const result = subscriptionTerms.find((item) => {
                    return (
                        item.is_active &&
                        item.subscription_product_type_id === pTypeId &&
                        item.subscription_term_id === sTypeId
                    );
                });

                return typeof result === 'undefined' ? null : result;
            }
            return null; // Not found
        },
        /**
         * Load subscription terms for publication
         *
         * @param {Integer}  publicationId
         */
        async loadSubscriptionTerms(publicationId) {
            this.errors = [];
            this.messages = [];
            this.subscriptionTermsDescription = null;
            this.subscriptionTermsWhatYouGet = null;
            const subscriptionTerms = []; // Done like this because we want to set all at once - if we clear the array passed to a child component, it would be notified before we want it to be and the child may temporarily think there are no subscription terms
            const allSubscriptionTerms = await this.dataHelperLoadPublicationSubscriptionTerms(publicationId);
            this.subscriptionTermsDescription = allSubscriptionTerms.description;
            this.subscriptionTermsWhatYouGet = allSubscriptionTerms.whatYouGet;

            // Get only active subscription terms
            let activeSubscriptionTerms = [];
            allSubscriptionTerms.terms.forEach((item) => {
                if (item.is_active) {
                    activeSubscriptionTerms.push(item);
                }
            });

            // Now we have to only add terms that actually exist and that this publication has
            this.optionSubscriptionProductTypes.forEach((productType) => {
                this.optionSubscriptionTypes.forEach((termType) => {
                    const terms = this.getTerms(activeSubscriptionTerms, productType.id, termType.id); // Do we have terms for this subscription type?
                    if (terms !== null) {
                        subscriptionTerms.push({
                            id: terms.id,
                            cost: terms.amt, // Cost
                            productType: {
                                id: productType.id, // Product type id
                                text: productType.text, // e.g. "Digital"
                                value: productType.value // e.g. "subscriptionProductTypeDigital"
                            },
                            termType: {
                                id: termType.id, // Terms type id
                                text: termType.text, // e.g. "Monthly"
                                value: termType.value // e.g. "subscriptionTypeSubscriptionTypeMonthly"
                            }
                        });
                    }
                });
            });
            this.subscriptionTerms = subscriptionTerms;
        },
        /**
         * A subscription was added
         *
         * @param {Object} registerResults Results of adding subscription
         */
        addedSubscription(registerResults) {
            if (this.addOnly) {
                // Adding only, so just let caller handle
                this.$emit('added-subscription', registerResults);
            } else {
                this.step = null;
                this.level++; // Force list refresh
                this.$nextTick(() => {
                    this.addSubscriptionResults = registerResults;
                    this.isAddedSubscription = true;
                });
            }
        },
        /**
         * Cancelled add subscription
         */
        cancelledAddSubscription() {
            if (this.addOnly) {
                this.$emit('cancelled');
            } else {
                this.step = null;
            }
        },
        /**
         * A new payment method was added
         *
         * @param {Object} method
         */
        addedPaymentMethod(method) {
            this.$emit('added-payment-method', method);
        }
    }
};
</script>
