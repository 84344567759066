<!-- @format -->
<template>
    <base-layout
        :group="group"
        group-title="EVENTS"
        :show-publication-icon="false"
        :show-publication-name="false"
        :show-content-at="false"
    >
        <template #default="{ post }">
            <div
                v-for="(instance, index) in postFirstInstance(
                    (setSupplementalData(post, 'postTypeEvent') || {}).instances
                )"
                :key="index"
            >
                <span class="na-intro-post-event-detail">{{ postEventInstanceDateFull(instance) }}</span>
                <template v-if="instance && instance.theTime">
                    <span class="na-intro-post-event-detail">
                        <span>&bull;</span>
                        <span>{{ instance.theTime }}</span>
                    </span>
                </template>
            </div>
        </template>
    </base-layout>
</template>

<script>
import BaseLayout from './BaseLayout.vue';
import { postMixin } from '@/mixins/PostMixin.js';

export default {
    components: {
        BaseLayout
    },
    mixins: [postMixin],
    props: {
        group: {
            type: Array,
            required: true
        }
    }
};
</script>
