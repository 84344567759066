<!-- @format -->
<template>
    <div class="is-flex is-flex-direction-column">
        <div v-if="showHeader" class="mb-6">
            <div class="has-text-centered">
                <h1 class="is-size-4 has-text-weight-bold has-text-centered">
                    {{ headerText || `Welcome to ${mainPublicationName}!` }}
                </h1>
                <p class="is-size-5 is-italic">
                    <span v-if="mainPublication && mainPublication.slug === 'kingston-wire'"
                        >Independent News & Views</span
                    >
                    <span v-else>Actual News, Locally grown.</span>
                </p>
            </div>

            <div v-if="publicationMediaUrl" class="is-flex is-justify-content-center">
                <figure class="image">
                    <img :src="publicationMediaUrl" :alt="mainPublication.name" style="height: 64px" height="64" />
                </figure>
            </div>
        </div>

        <social-buttons
            v-if="showSocial"
            class="mb-2"
            :align="alignSocial"
            hide-text-on-mobile
            @click="doLoginSocial"
        />

        <template v-if="showSocial && showDivider">
            <hr class="divider mb-2" />
            <p class="is-size-6">Or log in with email</p>
        </template>

        <retry-with-email
            v-if="retryPrompt === 'email'"
            :email.sync="retryData.email"
            :is-busy="isLoggingIn"
            :social-type="interimSocialType"
            :errors="retryErrors"
            @retry="retryLogin"
            @close="retryPrompt = null"
        />

        <retry-with-password
            v-else-if="retryPrompt === 'password'"
            :password.sync="retryData.password"
            :is-busy="isLoggingIn"
            :email="interimEmail"
            :social-type="interimSocialType"
            :errors="retryErrors"
            @retry="retryLogin"
            @close="retryPrompt = null"
        />

        <retry-with-accept-tc
            v-else-if="retryPrompt === 'acceptTc'"
            :accept-tc.sync="retryData.acceptTc"
            :is-busy="isLoggingIn"
            :errors="retryErrors"
            @retry="retryLogin"
            @close="retryPrompt = null"
        />

        <form @submit.prevent="doLogin">
            <field name="email" :errors="inputErrors">
                <p class="control">
                    <input
                        ref="email"
                        v-model.trim="email"
                        class="input"
                        type="email"
                        placeholder="email address"
                        name="email"
                        autocomplete="email"
                        autofocus
                        required
                    />
                </p>
            </field>

            <password-input :errors="inputErrors" :password.sync="password" autocomplete="on" required :minlength="1" />

            <a v-if="showForgotPassword" class="na-not-logged-in-forgot" @click="forgotPassword">Forgot Password?</a>

            <div class="my-2">
                <button
                    type="submit"
                    class="button is-success"
                    :class="{ 'is-loading': isLoggingIn }"
                    :disabled="isLoggingIn"
                >
                    <span class="icon"><fa-icon icon="sign-in-alt" /></span>
                    <span>LOG IN</span>
                </button>
                <span v-if="showSignupLink" class="na-not-logged-in-signup na-link" @click="signup">
                    &nbsp;...or Sign Up today.</span
                >
            </div>

            <label v-if="showRememberMe" class="checkbox"
                ><input v-model="remember" type="checkbox" /> Remember Me</label
            >
        </form>

        <show-error :errors="errors" />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { postMixin } from '@/mixins/PostMixin.js';
import { mediaMixin } from '@/mixins/MediaMixin.js';
import { userLogin } from '@/services/user.js';
import { userLoginSocial } from '@/services/user.js';
import ShowError from '@/components/Common/Error.vue';
import PasswordInput from '@/components/Common/Password.vue';
import RetryWithEmail from '../Register/RetryWithEmail.vue';
import RetryWithPassword from '../Register/RetryWithPassword.vue';
import RetryWithAcceptTc from '../Register/RetryWithAcceptTc.vue';
import SocialButtons from './SocialButtons.vue';

export default {
    components: {
        PasswordInput,
        RetryWithEmail,
        RetryWithPassword,
        RetryWithAcceptTc,
        ShowError,
        SocialButtons
    },

    mixins: [mediaMixin, postMixin],

    props: {
        headerText: {
            type: String,
            default: ''
        },
        initialEmail: {
            type: String,
            default: ''
        },
        showForgotPassword: {
            type: Boolean,
            default: true
        },
        showRememberMe: {
            type: Boolean,
            default: true
        },
        showHeader: {
            type: Boolean,
            default: true
        },
        showSocial: {
            type: Boolean,
            default: true
        },
        alignSocial: {
            type: String,
            default: 'center'
        },
        showSignupLink: {
            type: Boolean,
            default: true
        },
        showDivider: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            isLoggingIn: false,
            errors: [],
            inputErrors: [],
            showPassword: false,
            email: this.initialEmail,
            password: null,
            remember: true,
            retryData: {
                email: '',
                password: '',
                acceptTc: false
            },
            retryPrompt: '',
            retryCallback: null,
            interimEmail: null,
            interimSocialType: null,
            retryErrors: []
        };
    },

    computed: {
        ...mapGetters({
            mainPublicationName: 'publication/mainPublicationName'
        }),
        ...mapState({
            mainPublication: (state) => state.publication.mainPublication
        }),
        publicationMediaUrl() {
            if (this.mainPublication && this.mainPublication.media && this.mainPublication.media.length) {
                return this.mediaUrl(
                    this.getPublicationMedia({ publication: this.mainPublication }, 'mediaTypeImage')[0]
                );
            }
            return null;
        }
    },

    methods: {
        close() {
            this.$emit('close');
        },

        async doLogin() {
            this.isLoggingIn = true;
            this.errors = [];
            this.inputErrors = [];
            try {
                const response = await userLogin(this.email.toLowerCase(), this.password, this.remember);
                this.isLoggingIn = false;
                if (!response.success) {
                    this.errors = response.errors ? response.errors : null;
                    this.inputErrors = response.inputErrors ? response.inputErrors : null;
                } else {
                    this.$emit('loggedIn');
                }
            } catch (error) {
                this.isLoggingIn = false;
            }
        },

        doCancel() {
            this.errors = [];
            this.inputErrors = [];
            this.clientRedirectTo(naRoutes.home);
        },

        forgotPassword() {
            this.errors = [];
            this.inputErrors = [];
            this.$emit('forgotPassword');
        },

        signup() {
            this.errors = [];
            this.inputErrors = [];
            this.$emit('signup');
        },

        justRegisteredLoggedIn() {
            this.$emit('loggedIn');
        },

        async doLoginSocial(socialType) {
            this.isLoggingIn = true;
            const data = {
                registerPublicationId:
                    this.userRecentPost && this.userRecentPost.post ? this.userRecentPost.post.publication_id : null
            };
            try {
                const response = await userLoginSocial(socialType, data);
                if (response.passwordRequired) {
                    this.retryCallback = response.retryCallback;
                    this.interimSocialType = response.socialType;
                    this.interimEmail = response.email;
                    this.retryPrompt = 'password';
                    this.isLoggingIn = false;
                    return;
                }
                if (response.acceptTcRequired) {
                    this.retryCallback = response.retryCallback;
                    this.interimSocialType = response.socialType;
                    this.retryPrompt = 'acceptTc';
                    this.isLoggingIn = false;
                    return;
                }
                if (response.emailRequired) {
                    this.retryCallback = response.retryCallback;
                    this.interimSocialType = response.socialType;
                    this.retryPrompt = 'email';
                    this.isLoggingIn = false;
                    return;
                }
                if (!response.success) {
                    this.errors = response.errors ? response.errors : null;
                    this.inputErrors = response.inputErrors ? response.inputErrors : null;
                } else {
                    this.$emit('loggedIn');
                }
                this.isLoggingIn = false;
            } catch (error) {
                if (error.message === 'cancelled') {
                    this.isLoggingIn = false;
                } else {
                    this.errors = ['There was a problem with the log in process.'];
                    this.isLoggingIn = false;
                    throw error;
                }
            }
        },

        async retryLogin() {
            this.isLoggingIn = true;
            this.retryErrors = [];
            try {
                const response = await this.retryCallback(this.retryData);
                if (response.passwordRequired) {
                    this.retryCallback = response.retryCallback;
                    this.interimSocialType = response.socialType;
                    this.interimEmail = response.email;
                    this.retryPrompt = 'password';
                    this.isLoggingIn = false;
                    return;
                }
                if (response.acceptTcRequired) {
                    this.retryCallback = response.retryCallback;
                    this.interimSocialType = response.socialType;
                    this.retryPrompt = 'acceptTc';
                    this.isLoggingIn = false;
                    return;
                }
                if (response.emailRequired) {
                    this.retryCallback = response.retryCallback;
                    this.interimSocialType = response.socialType;
                    this.retryPrompt = 'email';
                    this.isLoggingIn = false;
                    return;
                }
                if (!response.success) {
                    if (response.inputErrors) {
                        this.retryErrors = response.inputErrors;
                    } else {
                        this.retryErrors = response.errors || [];
                    }
                } else {
                    this.$emit('loggedIn');
                }
                this.isLoggingIn = false;
            } catch (error) {
                this.isLoggingIn = false;
            }
        },

        focusEmail(email) {
            this.email = email;
            this.$refs.email.focus();
            this.$refs.email.scrollIntoView({ inline: 'center' });
        }
    }
};
</script>
