/** @format */
import { readPublication } from '@/services/publication.js';

const state = {
    mainPublication: null,
    mainPublicationIsLoading: true
};

const getters = {
    mainPublicationId: (state) =>
        state.mainPublication && state.mainPublication.id
            ? state.mainPublication.id
            : null,
    mainPublicationHasWelcomeSection: (state) =>
        state.mainPublication?.slug !== 'kingston-wire',
    mainPublicationHasCovid19: (state) => { return false; },
        //state.mainPublication?.slug !== 'kingston-wire',
    mainPublicationName: (state) =>
        state.mainPublication && state.mainPublication.name
            ? state.mainPublication.name
            : 'NewsAtomic',
    mainPublicationUrl: (state) =>
        state.mainPublication && state.mainPublication._base_url
            ? state.mainPublication._base_url
            : 'https://newsatomic.com/',
    mainPublicationTitle: (state) =>
        state.mainPublication && state.mainPublication._title
            ? state.mainPublication._title
            : 'Actual News, Locally grown.',
    //mainPublication: (state) => state.mainPublication,
    mainPublicationIsLoading: (state) => state.mainPublicationIsLoading
};

const mutations = {
    setMainPublication(state, data) {
        state.mainPublication = data;
    },
    setMainPublicationIsLoading(state, flag) {
        state.mainPublicationIsLoading = flag;
    }
};

const actions = {
    async loadMainPublication({ commit }, payload) {
        const response = await readPublication(payload);
        if (response.success) {
            commit('setMainPublication', response.publication);
            commit('setMainPublicationIsLoading', false);
        } else {
            commit('setMainPublicationIsLoading', false);
        }
    }
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
