<!-- @format -->
<template>
    <div class="na-sidebar-dock-container">
        <p class="na-sidebar-search">Search {{ mainPublicationName }}</p>
        <simple-search :autofocus="false" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SimpleSearch from '../SimpleSearch.vue';

export default {
    components: {
        SimpleSearch
    },

    computed: {
        ...mapGetters({
            mainPublicationName: 'publication/mainPublicationName'
        })
    }
};
</script>
