<!-- @format -->
<template>
    <div v-show="hasResult && showIcon">
        <span
            v-if="!isValid"
            class="icon invalid-username-icon"
            :class="iconInInputClass"
            data-toggle="tooltip"
            data-placement="top"
            title="Email is not Valid"
            ><fa-icon icon="times-circle"
        /></span>
        <span
            v-if="isValid"
            class="icon valid-username-icon"
            :class="iconInInputClass"
            data-toggle="tooltip"
            data-placement="top"
            title="Email is Valid"
            ><fa-icon icon="check"
        /></span>
    </div>
</template>

<script>
import { userIsUsernameValid } from '@/services/user';
import debounce from 'lodash-es/debounce';

export default {
    props: {
        username: {
            type: String,
            default: ''
        },
        // Type of lookup to perform, 0 = username must be free, 1 = just validate that it's an email, 2 = username must be used
        lookupType: {
            type: Number,
            default: 0
        },
        iconInInputClass: {
            type: String,
            default: 'icon-in-input-right'
        },
        showIcon: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            hasResult: false,
            isValid: false,
            timer: null
        };
    },

    watch: {
        username: debounce(function (val) {
            if (val) {
                this.validateUsername(val);
            } else {
                this.hasResult = false;
            }
        }, 500)
    },

    methods: {
        /**
         * Validate username
         *
         * @param {String} username Username to be validated
         */
        async validateUsername(username) {
            if (!username) {
                // Nothing to validate
                return;
            }
            this.isBusy = true;
            try {
                const response = await userIsUsernameValid(username, this.lookupType);
                this.isBusy = false;
                this.hasResult = true;
                this.isValid = response.success;
                if (this.isValid) {
                    this.$emit('valid', { user: response.user });
                } else {
                    this.$emit('invalid', {
                        isValidEmailAddress: response.isValidEmailAddress,
                        error: response.error
                    });
                }
            } catch (error) {
                this.isBusy = false;
                this.hasResult = true;
                this.isValid = false;
                this.$emit('invalid', { isValidEmailAddress: false, error: 'internal' });
            }
        }
    }
};
</script>
