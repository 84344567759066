/**
 * @format
 * Publication related mixins
 */

export const publicationMixin = {
    computed: {
        publicationSubdomainComponent() {
            const mainPublication =
                this.$store.state.publication.mainPublication;
            switch (
                mainPublication && mainPublication.ident
                    ? mainPublication.ident
                    : null
            ) {
                case 'kingston-wire':
                    return 'publication-kingstonwire';
                case 'sc-democrat':
                    return 'publication-sc-democrat';
                case 'shawangunk-journal':
                    return 'publication-shawangunk-journal';
            }
            return 'publication-default';
        }
    },
    methods: {
        /**
         * Determine if publication id is for Shawangunk Journal
         * @todo  2018-12-11  MJL  Need to redo this somehow - I don't like static publication ID!
         *
         * @param {Integer} publicationId
         *
         * @return {Boolean}
         */
        publicationIsShawangunkJournal(publicationId) {
            return publicationId === 1;
        },
        /**
         * Determine if publication id is for Devil's Advocate
         * @todo  2018-12-11  MJL  Need to redo this somehow - I don't like static publication ID!
         *
         * @param {Integer} publicationId
         *
         * @return {Boolean}
         */
        publicationIsDevilsAdvocate(publicationId) {
            return publicationId === 10;
        },
        /**
         * Determine if publication id is for Manor Ink
         * @todo  2018-12-11  MJL  Need to redo this somehow - I don't like static publication ID!
         *
         * @param {Integer} publicationId
         *
         * @return {Boolean}
         */
        publicationIsManorInk(publicationId) {
            return publicationId === 13;
            //return publicationId === 32 || publicationId === 13;  // Ugh (32 on my test site, 13 on live)
        },
        /**
         * Determine if publication id is for D&H Canvas
         * @todo  2018-12-11  MJL  Need to redo this somehow - I don't like static publication ID!
         *
         * @param {Integer} publicationId
         *
         * @return {Boolean}
         */
        publicationIsDhCanvas(publicationId) {
            return publicationId === 15;
        },
        /**
         * Determine if publication id is for Basha Kill
         * @todo  2018-12-11  MJL  Need to redo this somehow - I don't like static publication ID!
         *
         * @param {Integer} publicationId
         *
         * @return {Boolean}
         */
        publicationIsBashaKill(publicationId) {
            return publicationId === 16;
        },
        /**
         * Determine if publication id is for Kingston Wire
         *
         * @param {Integer} publicationId
         *
         * @return {Boolean}
         */
        publicationIsKingstonwire(publicationId) {
            return publicationId === 27;
        },
        /**
         * Return publication name
         *
         * @param {Object} publication Publication object
         *
         * @return {String}
         */
        publicationGetName(publication) {
            return publication && publication.name ? publication.name : '';
        },
        /**
         * Return combined list of publications allowed - based on user's restricted publications and another list
         *
         * @param {Array|null} list1
         * @param {Array|null} list2
         *
         * @return {Array|null} Combined list or null if both lists are null.  Note that empty array means the user has no publications to access, null means they have no restrictions on what they can access.  VERY important distinction.
         */
        publicationConcatPublicationIds(list1, list2) {
            if (!list1 && !list2) {
                return null;
            }

            const concatList1 = !list1 ? [] : list1;
            const concatList2 = !list2 ? [] : list2;

            const concat = concatList1.concat(concatList2);
            return concat;
            //return concat.length ? concat : []);
        },
        /**
         * Exclude publications from list
         *
         * @param {Array|null} list             Array of publication ids
         * @param {Boolean}    listIsObjects    Indicates if list is a simple array or an array of objects
         * @param {Array|null} listRestrictedTo Array of allowed publication ids or objects with id parameter
         *
         * @return {Array}
         */
        publicationFilterForRestricted(
            list,
            listIsObjects = false,
            listRestrictedTo
        ) {
            const listCheck = !list ? [] : list;
            const restrictedTo = !list ? [] : listRestrictedTo;

            if (listIsObjects) {
                return listCheck.filter((item) =>
                    restrictedTo.includes(item.id)
                );
            } else {
                return listCheck.filter((id) => restrictedTo.includes(id));
            }
        }
    }
};
