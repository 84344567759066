<!-- @format -->
<template>
    <article class="columns" :class="{ 'is-gapless': clientIsMobile }">
        <div
            v-if="!forceHideMedia && showMedia && !!media && (!isTopPost || clientIsMobile)"
            class="column is-one-third"
        >
            <router-link class="is-block" :to="routeParams">
                <picture>
                    <source
                        v-for="set in imageSet"
                        :key="set.mime"
                        :type="set.mime"
                        :srcset="set.srcset"
                        sizes="(max-width: 768px) calc(100vw - 4rem), 268px"
                    />
                    <img :src="media.url" :alt="imageAlt" loading="lazy" :width="media.width" :height="media.height" />
                </picture>
            </router-link>
        </div>

        <div class="column">
            <publication-line
                v-if="
                    publicationLineLocation === 'top' &&
                    !omitPublicationLine &&
                    (showPublicationIcon || showPublicationName || showContentAt)
                "
                :post="post"
                :show-icon="showPublicationIcon"
                :show-name="showPublicationName"
                :show-date="showContentAt"
            />
            <p v-if="!!$slots.mainLabel" class="is-uppercase is-size-7 has-text-weight-normal">
                <router-link class="na-link" :to="routeParams"><slot name="mainLabel" /></router-link>
            </p>
            <p v-else-if="showLocation && hasPostLocation(post)" class="is-uppercase is-size-7 has-text-weight-normal">
                <router-link class="has-text-grey" :to="routeParams">{{ describePostLocation(post) }}</router-link>
            </p>
            <p v-else-if="showPostType" class="is-uppercase is-size-7 has-text-weight-normal">
                <router-link :class="[ maybeTextGrey ]" :to="routeParams">{{ post.post_type.value }}</router-link>
            </p>

            <h2 v-if="showTitle && post.title" class="is-family-secondary is-size-5 has-text-weight-bold">
                <router-link :class="[ maybeTextBlack ]" :to="routeParams">{{ title }}</router-link>
            </h2>

            <h3
                v-if="showSubtitle && post.subtitle"
                class="is-family-secondary is-size-5 has-text-weight-bold is-italic"
            >
                <router-link
                    :class="[ maybeTextBlack ]"
                    class="is-italic"
                    :to="routeParams">
                    {{ subtitle }}
                </router-link>
            </h3>

            <h4
                v-if="showAuthors && Array.isArray(post.post_authors) && post.post_authors.length"
                :class="[ maybeTextGreyDark ]"
                class="is-size-8"
            >
                <span v-if="isShowAuthorBy(post)">By&nbsp;</span>
                <span>{{ postAuthorFormattedList(post.post_authors) }}</span>
            </h4>

            <publication-line
                v-if="
                    publicationLineLocation === 'middle' &&
                    !omitPublicationLine &&
                    (showPublicationIcon || showPublicationName || showContentAt)
                "
                :post="post"
                :show-icon="showPublicationIcon"
                :show-name="showPublicationName"
                :show-date="showContentAt"
            />

            <router-link v-if="showSnippet" :to="routeParams">
                <div class="is-family-secondary has-text-black is-size-6" v-html="snippet"></div>
            </router-link>

            <slot />
        </div>

        <div
            v-if="!forceHideMedia && showMedia && !!media && isTopPost && !clientIsMobile"
            class="column is-two-thirds"
        >
            <router-link class="is-block" :to="routeParams">
                <picture>
                    <source
                        v-for="set in imageSet"
                        :key="set.mime"
                        :type="set.mime"
                        :srcset="set.srcset"
                        sizes="(max-width: 768px) calc(100vw - 4rem), 560px"
                    />
                    <img :src="media.url" :alt="imageAlt" loading="lazy" :width="media.width" :height="media.height" />
                </picture>
            </router-link>
        </div>
    </article>
</template>

<script>
import { mediaMixin } from '@/mixins/MediaMixin.js';
import { postMixin } from '@/mixins/PostMixin.js';
import { buildRouteParams } from '@/helpers/post.js';
import PublicationLine from '@/components/Common/PublicationLine.vue';

export default {
    components: {
        PublicationLine
    },

    mixins: [mediaMixin, postMixin],

    props: {
        post: {
            type: Object,
            required: true
        },
        isTopPost: {
            type: Boolean,
            default: false
        },
        showMedia: {
            type: Boolean,
            default: true
        },
        pixelatedMedia: {
            type: Boolean,
            default: false
        },
        showLocation: {
            type: Boolean,
            default: false
        },
        showPostType: {
            type: Boolean,
            default: true
        },
        showTitle: {
            type: Boolean,
            default: true
        },
        showSubtitle: {
            type: Boolean,
            default: true
        },
        showAuthors: {
            type: Boolean,
            default: true
        },
        showPublicationIcon: {
            type: Boolean,
            default: true
        },
        showPublicationName: {
            type: Boolean,
            default: true
        },
        showContentAt: {
            type: Boolean,
            default: true
        },
        showSnippet: {
            type: Boolean,
            default: true
        },
        defaultTopPostImagePath: {
            type: String,
            default: ''
        },
        omitPublicationLine: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        forceHideMedia() {
            return this.$route.name === 'search';
        },
        publicationLineLocation() {
            return this.$route.name === 'search' ? 'top' : 'middle';
        },
        title() {
            return this.post.title;
        },
        subtitle() {
            return this.post.subtitle;
        },
        snippet() {
            if (!this.showSnippet) {
                return null;
            }
            return this.postPreviewSnippet(this.post);
        },
        postImage() {
            return this.post?.media?.find((media) => media.media_type_id === 31);
        },
        media() {
            if (!this.postImage) {
                return;
            }
            return this.getPostMedia(this.postImage, this.pixelatedMedia ? 'pixs' : 'med');
        },
        imageSet() {
            return this.postMediaUrlSet(this.postImage, {
                pixelated: this.pixelatedMedia
            });
        },
        imageAlt() {
            const defaultAlt = `A photo from "${this.post.title} post."`;
            if (this.postImage) {
                return this.mediaAlt(this.postImage, defaultAlt);
            }
            return defaultAlt;
        },
        routeParams() {
            /*
            if (this.$route && this.$route.name === 'hvnews') {
                console.log('in routeParams, route=', this.$route);
                return buildRouteParams(this.post, null, { isHvNews: true });
            } else {
                */
                return buildRouteParams(this.post);
            //}
        },
        publicationIcon() {
            return this.getPublicationMedia(this.post, 'mediaTypeIcon')[0];
        },
        maybeTextBlack() {
            return this.isPostSponsored(this.post)
                ? 'na-sponsored'
                : 'has-text-black';
        },
        maybeTextGrey() {
            return this.isPostSponsored(this.post)
                ? 'na-sponsored'
                : 'has-text-grey';
        },
        maybeTextGreyDark() {
            return this.isPostSponsored(this.post)
                ? 'na-sponsored'
                : 'has-text-grey-dark';
        }
    }
};
</script>
