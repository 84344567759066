<!-- @format -->
<template>
    <div v-if="adCount" class="na-ad-animation">
        <ad-native-ad v-if="ads[adIndex].isNative && canShowNativeAds" :post="ads[adIndex].ad" @showVideo="showVideo" />
        <!-- Not sure what ads will be in here for non native, so it's ugly for now -->
        <ad-manor-ink v-if="!ads[adIndex].isNative && ads[adIndex].type === 'manorInk'" @subscribe="subscribe" />
        <ad-da v-if="!ads[adIndex].isNative && ads[adIndex].type === 'devilsAdvocate'" @subscribe="subscribe" />
        <component
            :is="publicationSubdomainComponent"
            v-if="(!ads[adIndex].isNative && ads[adIndex].type === 'housead') || (!canShowNativeAds)"
        />
        <ad-kingstonwire v-if="!ads[adIndex].isNative && ads[adIndex].type === 'kingstonwire-subscribe'" />
        <ad-shawangunk-journal v-if="!ads[adIndex].isNative && ads[adIndex].type === 'shawangunk-journal-subscribe'" />
        <ad-kingstonwire-gift v-if="!ads[adIndex].isNative && ads[adIndex].type === 'kingstonwire-gift-subscribe'" />
        <ad-shawangunk-journal-gift v-if="!ads[adIndex].isNative && ads[adIndex].type === 'shawangunk-journal-gift-subscribe'" />
        <video-modal v-if="postVideo" v-bind="postVideo" @close="closeVideo" @ad-click="videoClicked" />
    </div>
</template>

<script>
import shuffle from 'lodash-es/shuffle';
import { mapGetters } from 'vuex';
import { publicationMixin } from '@/mixins/PublicationMixin.js';
import { recordPostHit } from '@/services/post.js';
import AdDa from './Ads/Da.vue';
import AdKingstonwire from './Ads/Kingstonwire-subscribe.vue';
import AdKingstonwireGift from './Ads/Kingstonwire-gift-subscribe.vue';
import AdManorInk from './Ads/ManorInk.vue';
import AdNativeAd from './Ads/NativeAd.vue';
import AdShawangunkJournal from './Ads/ShawangunkJournal-subscribe.vue';
import AdShawangunkJournalGift from './Ads/ShawangunkJournal-gift-subscribe.vue';
import PublicationDefault from './Ads/NewsAtomic.vue';
import PublicationKingstonwire from './Ads/Kingstonwire.vue';
import PublicationScDemocrat from './Ads/ScDemocrat.vue';
import PublicationShawangunkJournal from './Ads/ShawangunkJournal.vue';
import VideoModal from './VideoModal.vue';

export default {
    components: {
        AdDa,
        AdKingstonwire,
        AdKingstonwireGift,
        AdManorInk,
        AdNativeAd,
        AdShawangunkJournal,
        AdShawangunkJournalGift,
        PublicationDefault,
        PublicationKingstonwire,
        PublicationScDemocrat,
        PublicationShawangunkJournal,
        VideoModal
    },

    mixins: [publicationMixin],

    props: {
        shuffleTime: {
            type: Number,
            default: 10000
        }
    },

    data() {
        return {
            isIncludeSubscription: true,
            adIndex: 0,
            postVideo: null,
            timer: null,
            isGiftSubs: false
        };
    },

    computed: {
        ...mapGetters({
            currentPost: 'viewer/currentPost',
            nativeAds: 'viewer/squareNativeAds'
        }),

        /**
         * We don't want to show native ads when currently displaying a native ad in the feed - should only show non-native
         *
         * @return {Booelan}
         */
        canShowNativeAds() {
            return !(
                this.$route.name === 'post-viewer' &&
                this.currentPost &&
                this.currentPost.post_type &&
                this.currentPost.post_type.key === 'postTypeNativeAd'
            );
        },

        ads() {
            const newAds = this.nativeAds.map((ad) => ({ isNative: true, ad }));
            if (this.nativeAdCount < 15) {
                const mainPublication = this.$store.state.publication.mainPublication;
                if (mainPublication && mainPublication.ident !== 'sc-democrat') {
                    // No house ads for sc-democrat yet (in demo)
                    //newAds.push({ isNative: false, type: 'housead' });
                }
                if (this.isIncludeSubscription) {
                    switch (mainPublication && mainPublication.ident ? mainPublication.ident : null) {
                        case 'kingston-wire':
                            //newAds.push({ isNative: false, type: 'kingstonwire-subscribe' });  2022-04-29  MJL  Disable as per Sharon
                            if (this.isGiftSubs) {
                                newAds.push({ isNative: false, type: 'kingstonwire-gift-subscribe' });
                            }
                            break;
                        default:
                            //newAds.push({ isNative: false, type: 'shawangunk-journal-subscribe' });  2022-04-29  MJL  Disable as per Sharon
                            if (this.isGiftSubs) {
                                newAds.push({ isNative: false, type: 'shawangunk-journal-gift-subscribe' });
                            }
                            break;
                    }
                }
            }
            return shuffle(newAds);
        },

        /**
         * Return count of native ads
         *
         * @return {Integer}
         */
        nativeAdCount() {
            return this.nativeAds.length;
        },
        /**
         * Return count of ads total
         *
         * @return Integer
         */
        adCount() {
            return this.ads.length;
        }
    },

    created() {
        this.resetTimer();
    },

    methods: {
        /**
         * Clicked subscribe link
         *
         * @param {Object} block Data block
         */
        subscribe(block) {
            this.$emit('subscribe', block);
        },

        /**
         * Reset ad timer
         */
        resetTimer() {
            this.clearTimer();
            if (this.timer === null) {
                this.timer = setTimeout(() => {
                    this.nextAd();
                }, this.shuffleTime);
            }
        },

        /**
         * Clear ad timer
         */
        clearTimer() {
            if (this.timer !== null) {
                clearTimeout(this.timer);
                this.timer = null;
            }
        },

        /**
         * Shuffle to next ad
         */
        nextAd() {
            if (this.adCount) {
                if (this.adIndex + 1 === this.adCount) {
                    this.adIndex = 0;
                } else {
                    this.adIndex = this.adIndex + 1;
                }
                this.resetTimer();
            }
        },
        showVideo(postVideo) {
            this.clearTimer();
            this.postVideo = postVideo;
        },
        closeVideo() {
            this.resetTimer();
            this.postVideo = null;
        },
        videoClicked() {
            recordPostHit({
                post_id: this.postVideo.video.post_id,
                subsource: 'NativeAd',
                url: this.postVideo.url
            });
        }
    }
};
</script>
