/** @format */
import { readPostsNativeAd } from '@/services/post';

const state = {
    ignorePreferences: true,
    nativeAds: {
        data: [],
        isLoading: false,
        errors: []
    },
    nowPlaying: {
        isActive: false,
        url: '',
        title: ''
    },
    currentPost: null, // Note to self that this is somewhat redundant with the recent post in the user store (though that one is used mainly for tracking and can also just save the post id)
    postCommentCount: null, // Current post comment count
    instantiator: null, // Instantiator of viewer (may be used to help deal with fixing multiple posthit views)
    isLogPostView: true // Ditto
};

const getters = {
    ignorePreferences: (state) => state.ignorePreferences,
    getMostRecentComments: (state) => state.mostRecentComments,
    allNativeAds: (state) => state.nativeAds.data,
    regularNativeAds: (state) =>
        state.nativeAds.data.filter(
            (ad) =>
                ad.post_native_ads[0] &&
                ad.post_native_ads[0].ad_type === 'nativeAdTypeRegular'
        ),
    squareNativeAds: (state, getters) => [
        ...getters.regularNativeAds,
        ...getters.premiumNativeAds.filter(
            (ad) =>
                !!ad.media.find((media) => media.image_type === 'premiumSquare')
        )
    ],
    premiumNativeAds: (state) =>
        state.nativeAds.data.filter(
            (ad) =>
                ad.post_native_ads[0] &&
                ad.post_native_ads[0].ad_type === 'nativeAdTypePremium'
        ),
    currentPost: (state) => state.currentPost,
    postCommentCount: (state) => state.postCommentCount,
    instantiator: (state) => state.instantiator,
    getIsLogPostView: (state) => state.isLogPostView
};

const mutations = {
    setPreferences(state, flag) {
        state.ignorePreferences = flag;
    },
    togglePreferences(state) {
        state.ignorePreferences = !state.ignorePreferences;
    },
    setNativeAds(state, data) {
        state.nativeAds[data.item] = data.data;
    },
    setCurrentPost(state, data) {
        state.currentPost = data ? data : null;
    },
    setPostCommentCount(state, data) {
        state.postCommentCount = data;
    },
    setInstantiator(state, data) {
        state.instantiator = data ? data : 'root';
        state.isLogPostView = state.instantiator !== 'post-direct';
    },
    setIsLogPostView(state, data) {
        state.isLogPostView = data;
    },
    playPodcast(state, data) {
        state.nowPlaying.isActive = true;
        state.nowPlaying.url = data.url;
        state.nowPlaying.title = data.title;
    },
    stopPodcast(state) {
        state.nowPlaying.isActive = false;
    }
};

const actions = {
    /**
     * Load native ads from server
     */
    async loadNativeAds({ commit }, payload) {
        commit('setNativeAds', { item: 'isLoading', data: true });
        commit('setNativeAds', { item: 'errors', data: [] });
        await readPostsNativeAd(payload.request).then(
            (response) => {
                //response.success = false;
                //response.errors = [ 'test error' ];
                if (!response.success) {
                    if (response.errors) {
                        commit('setNativeAds', {
                            item: 'errors',
                            data: response.errors
                        });
                        commit('setNativeAds', {
                            item: 'isLoading',
                            data: false
                        });
                    }
                } else {
                    const data = response.posts.filter(
                        (post) => post && post.media && post.media.length
                    ); // If anomalies that don't have an image, there's no point in including those (I suppose the back-end should handle, but just in case)
                    commit('setNativeAds', { item: 'data', data });
                    commit('setNativeAds', { item: 'isLoading', data: false });
                }
            },
            (error) => {
                commit('setNativeAds', { item: 'errors', data: [error] });
                commit('setNativeAds', { item: 'isLoading', data: false });
            }
        );
    },

    async setIgnorePreferences({ commit }, ignorePreferences) {
        commit('setPreferences', ignorePreferences);
        window.naShared.storage.setItem(
            'ignorePreferences',
            JSON.stringify(ignorePreferences)
        );
    }
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
