/**
 * @format
 * Corporate related API functions
 * Abstracting these out from DataMixin.js.
 */
import axios from 'axios';

/**
 * Load intro featured articles
 *
 * @param {mixed} data Data
 *
 * @return Promise for resolve, reject
 */
export const readPostsIntro = function (data) {
    return axios
        .post(naRoutes.readPostsIntro, {
            data
        })
        .then((response) => response.data);
};

/**
 * Create contact us request
 *
 * @param {mixed} data Data
 *
 * @return Promise for resolve, reject
 */
export const createContactUs = function (data) {
    return axios
        .post(naRoutes.createContactUs, {
            data
        })
        .then((response) => response.data);
};

/**
 * Make a contribution
 *
 * @param {Object} data
 *
 * @return Promise for resolve, reject
 */
export async function makeContribution(data) {
    return axios
        .post('/contribution/add', {
            data
        })
        .then((response) => response.data);
}
