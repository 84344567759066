<!-- @format -->
<template>
    <div class="na-not-found-direct">
        <div>
            <div class="columns">
                <div class="column">
                    <p class="has-text-centered">I'm sorry, there's nothing here.</p>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <p v-if="!reason || reason === 'general'" class="has-text-centered">
                        The section is empty. Try a different one, or adjust your
                        <router-link class="na-link" :to="{ name: 'account', query: { step: 'preferences' } }"
                            >reader settings.</router-link
                        >
                    </p>

                    <p v-if="reason === 'search'" class="has-text-centered">There are no matches for your search.</p>

                    <p v-if="reason === 'event'" class="has-text-centered">
                        Try different events or dates, or adjust your
                        <router-link class="na-link" :to="{ name: 'account', query: { step: 'preferences' } }"
                            >reader settings.</router-link
                        >
                    </p>

                    <p v-if="reason === 'directPostNotFound'" class="has-text-centered">Post not available.</p>

                    <p v-if="reason === 'pageNotFound'" class="has-text-centered">That page is not available.</p>
                </div>
            </div>

            <div class="columns">
                <div class="column">
                    <p class="has-text-centered">But you can always come <a class="na-link" href="/">HOME</a></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        reason: {
            type: String,
            default: 'general'
        }
    }
};
</script>
