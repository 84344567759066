<!-- @format -->
<template>
    <div class="modal is-active na-modal-card" :class="classMain">
        <div class="modal-background" :class="classBackground" @click="$emit('close')"></div>
        <div class="modal-card" :class="classContent">
            <header class="modal-card-head">
                <slot name="head"></slot>
            </header>
            <section class="modal-card-body">
                <slot></slot>
            </section>
            <footer class="modal-card-foot">
                <slot name="foot"></slot>
            </footer>
        </div>
        <button class="modal-close" :class="classButton" @click="$emit('close')"></button>
    </div>
</template>

<script>
export default {
    props: {
        // Additional main class
        classMain: {
            type: [String, Object, Array],
            default: function () {
                return null;
            }
        },
        // Additional background class
        classBackground: {
            type: [String, Object, Array],
            default: function () {
                return null;
            }
        },
        // Additional content class
        classContent: {
            type: [String, Object, Array],
            default: function () {
                return null;
            }
        },
        // Additional button class
        classButton: {
            type: [String, Object, Array],
            default: function () {
                return null;
            }
        }
    }
};
</script>
