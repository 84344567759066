/** @format */
import { authorDescribe, readAuthors } from '@/services/author';
import { issueDescribe, readIssues } from '@/services/issue';
import {
    loadOptionPostCategories,
    loadOptionPostStatuses,
    loadOptionPostTypes
} from '@/services/option';
import { readPublication, readPublications } from '@/services/publication';
import { loadOptionPublishPricingPeriods } from '@/services/option';

const state = {
    isBusy: 0, // isBusy is not just a flag - multiple places may set/clear busy.  Only when we're at 0, do we consider it as not busy
    isMinimalEditor: false,
    publicationReload: 0,
    authors: {
        data: [],
        isLoading: false,
        errors: []
    },
    issues: {
        data: [],
        isLoading: false,
        errors: []
    },
    postCategories: {
        data: [],
        isLoading: false,
        errors: []
    },
    postStatuses: {
        data: [],
        isLoading: false,
        errors: []
    },
    postTypes: {
        data: [],
        isLoading: false,
        errors: []
    },
    publications: {
        data: [],
        isLoading: false,
        errors: []
    },
    publication: {
        data: [],
        isLoading: false,
        errors: []
    },
    publishPricingPeriodOptions: {
        data: [],
        isLoading: false,
        errors: []
    }
};

const getters = {
    isBusy: (state) => state.isBusy > 0,
    isMinimalEditor: (state) => state.isMinimalEditor,
    publicationReload: (state) => state.publicationReload,
    authors: (state) => state.authors,
    issues: (state) => state.issues,
    postCategories: (state) => state.postCategories,
    postStatuses: (state) => state.postStatuses,
    postTypes: (state) => state.postTypes,
    publications: (state) => state.publications,
    publication: (state) => state.publication,
    publishPricingPeriodOptions: (state) => state.publishPricingPeriodOptions
    
};
const mutations = {
    setIsBusy(state, data) {
        const { flag = false, force = false } = data;  // Force set's it to off no matter what if flag is false - if flag is true, we just increment in use
        if (flag) {
            state.isBusy++;
        } else {
            if (force) {
                state.isBusy = 0;
            } else {
                state.isBusy--;
            }
        }

        if (state.isBusy < 0) {
            state.isBusy = 0;
        }
    },
    setIsMinimalEditor(state, flag = true) {
        state.isMinimalEditor = flag;
    },
    setPublicationReload(state) {
        state.publicationReload++;
    },
    setAuthors(state, payload) {
        state.authors[payload.item] = payload.data;
    },
    setIssues(state, payload) {
        state.issues[payload.item] = payload.data;
    },
    setPostCategories(state, payload) {
        state.postCategories[payload.item] = payload.data;
    },
    setPostStatuses(state, payload) {
        state.postStatuses[payload.item] = payload.data;
    },
    setPostTypes(state, payload) {
        state.postTypes[payload.item] = payload.data;
    },
    setPublications(state, payload) {
        state.publications[payload.item] = payload.data;
    },
    setPublication(state, payload) {
        state.publication[payload.item] = payload.data;
    },
    setPublishPricingPeriodOptions(state, payload) {
        state.publishPricingPeriodOptions[payload.item] = payload.data;
    }
};

const actions = {
    /**
     * Load authors from server
     */
    async loadAuthors({ commit }, payload) {
        commit('setAuthors', { item: 'isLoading', data: true });
        commit('setAuthors', { item: 'errors', data: [] });
        try {
            const response = await readAuthors(payload.request);
            if (!response.success) {
                commit('setAuthors', {
                    item: 'errors',
                    data: response.errors
                });
                commit('setAuthors', {
                    item: 'isLoading',
                    data: false
                });
            } else {
                const data = response.authors.map((item) => {
                    return {
                        id: item.id,
                        value: item.id,
                        postTypes: item.post_types,
                        text: authorDescribe(item),
                        defaults: {
                            default_venue_id: item.default_venue_id,
                            default_event_type_id: item.default_event_type_id
                        }
                    };
                });
                if (payload && payload.options && payload.options.addPicker) {
                    data.unshift({
                        id: null,
                        value: null,
                        postTypes: null,
                        text: 'Please Select'
                    });
                }
                commit('setAuthors', { item: 'data', data });
                commit('setAuthors', { item: 'isLoading', data: false });
            }
        } catch (error) {
            commit('setAuthors', { item: 'errors', data: [error] });
            commit('setAuthors', { item: 'isLoading', data: false });
        }
    },

    /**
     * Load issues from server
     */
    async loadIssues({ commit }, payload) {
        commit('setIssues', { item: 'isLoading', data: true });
        commit('setIssues', { item: 'errors', data: [] });
        await readIssues(payload.request.publicationId).then(
            (response) => {
                //response.success = false;
                //response.errors = [ 'test error' ];
                if (!response.success) {
                    if (response.errors) {
                        commit('setIssues', {
                            item: 'errors',
                            data: response.errors
                        });
                        commit('setIssues', { item: 'isLoading', data: false });
                    }
                } else {
                    const data = response.issues.map((item) => {
                        return {
                            id: item.id,
                            value: item.id,
                            issueOn: item.issue_on,
                            volume: item.volume,
                            edition: item.edition,
                            text: issueDescribe(item)
                        };
                    });
                    if (
                        payload &&
                        payload.options &&
                        payload.options.addPicker
                    ) {
                        data.unshift({
                            id: null,
                            value: null,
                            issueOn: null,
                            volume: null,
                            edition: null,
                            text: 'No Issue'
                        });
                    }
                    commit('setIssues', { item: 'data', data });
                    commit('setIssues', { item: 'isLoading', data: false });
                }
            },
            (error) => {
                commit('setIssues', { item: 'errors', data: [error] });
                commit('setIssues', { item: 'isLoading', data: false });
            }
        );
    },
    /**
     * Load post categories from server
     */
    async loadPostCategories({ commit }) {
        commit('setPostCategories', { item: 'isLoading', data: true });
        commit('setPostCategories', { item: 'errors', data: [] });
        await loadOptionPostCategories().then(
            (response) => {
                if (!response.success) {
                    if (response.errors) {
                        commit('setPostCategories', {
                            item: 'errors',
                            data: response.errors
                        });
                        commit('setPostCategories', {
                            item: 'isLoading',
                            data: false
                        });
                    }
                } else {
                    const data = response.postCategories;
                    /*
                    Not applicable to categories since it's a multiple select
                    if (payload && payload.options && payload.options.addPicker) {
                        data.unshift({
                            id: null,
                            value: null,
                            text: 'Please Select'
                        });
                    }
                    */
                    commit('setPostCategories', { item: 'data', data });
                    commit('setPostCategories', {
                        item: 'isLoading',
                        data: false
                    });
                }
            },
            (error) => {
                commit('setPostCategories', { item: 'errors', data: [error] });
                commit('setPostCategories', { item: 'isLoading', data: false });
            }
        );
    },
    /**
     * Load post statuses from server
     */
    async loadPostStatuses({ commit }, payload) {
        commit('setPostStatuses', { item: 'isLoading', data: true });
        commit('setPostStatuses', { item: 'errors', data: [] });
        await loadOptionPostStatuses().then(
            (response) => {
                //response.success = false;
                //response.errors = [ 'test error' ];
                if (!response.success) {
                    if (response.errors) {
                        commit('setPostStatuses', {
                            item: 'errors',
                            data: response.errors
                        });
                        commit('setPostStatuses', {
                            item: 'isLoading',
                            data: false
                        });
                    }
                } else {
                    const data = response.postStatuses;
                    if (
                        payload &&
                        payload.options &&
                        payload.options.addPicker
                    ) {
                        data.unshift({
                            id: null,
                            value: null,
                            text: 'Please Select'
                        });
                    }
                    commit('setPostStatuses', { item: 'data', data });
                    commit('setPostStatuses', {
                        item: 'isLoading',
                        data: false
                    });
                }
            },
            (error) => {
                commit('setPostStatuses', { item: 'errors', data: [error] });
                commit('setPostStatuses', { item: 'isLoading', data: false });
            }
        );
    },
    /**
     * Load post types from server
     */
    async loadPostTypes({ commit }, payload) {
        commit('setPostTypes', { item: 'isLoading', data: true });
        commit('setPostTypes', { item: 'errors', data: [] });
        await loadOptionPostTypes().then(
            (response) => {
                if (!response.success) {
                    if (response.errors) {
                        commit('setPostTypes', {
                            item: 'errors',
                            data: response.errors
                        });
                        commit('setPostTypes', {
                            item: 'isLoading',
                            data: false
                        });
                    }
                } else {
                    const data = response.postTypes;
                    if (
                        payload &&
                        payload.options &&
                        payload.options.addPicker
                    ) {
                        data.unshift({
                            id: null,
                            value: null,
                            text: 'Please Select'
                        });
                    }
                    commit('setPostTypes', { item: 'data', data });
                    commit('setPostTypes', { item: 'isLoading', data: false });
                }
            },
            (error) => {
                commit('setPostTypes', { item: 'errors', data: [error] });
                commit('setPostTypes', { item: 'isLoading', data: false });
            }
        );
    },
    /**
     * Load publications from server
     */
    async loadPublications({ commit }, payload) {
        commit('setPublications', { item: 'isLoading', data: true });
        commit('setPublications', { item: 'errors', data: [] });
        const params = {
            includeOnlyAllowedPublications:
                payload && payload.request
                    ? payload.request.includeOnlyAllowedPublications
                    : false,
            sort:
                payload && payload.request && payload.request.sort
                    ? payload.request.sort
                    : null,
            publicationIds:
                payload && payload.request && payload.request.publicationIds
                    ? payload.request.publicationIds
                    : null,
            filter:
                payload && payload.request && payload.request.filter
                    ? payload.request.filter
                    : null
        };
        await readPublications(params).then(
            (response) => {
                //response.success = false;
                //response.errors = [ 'test error' ];
                if (!response.success) {
                    if (response.errors) {
                        commit('setPublications', {
                            item: 'errors',
                            data: response.errors
                        });
                        commit('setPublications', {
                            item: 'isLoading',
                            data: false
                        });
                    }
                } else {
                    const data = response.publications.map((item) => {
                        return {
                            id: item.id,
                            value: item.id,
                            text: item.name,
                            item
                        };
                    });
                    if (
                        payload &&
                        payload.options &&
                        payload.options.addPicker
                    ) {
                        data.unshift({
                            id: null,
                            value: null,
                            text: 'Please Select'
                        });
                    }
                    commit('setPublications', { item: 'data', data });
                    commit('setPublications', {
                        item: 'isLoading',
                        data: false
                    });
                }
            },
            (error) => {
                commit('setPublications', { item: 'errors', data: [error] });
                commit('setPublications', { item: 'isLoading', data: false });
            }
        );
    },
    /**
     * Load publication from server
     */
    async loadPublication({ commit }, payload) {
        commit('setPublication', { item: 'isLoading', data: true });
        commit('setPublication', { item: 'errors', data: [] });
        await readPublication({ id: payload.request.publicationId }).then(
            (response) => {
                if (!response.success) {
                    if (response.errors) {
                        commit('setPublication', {
                            item: 'errors',
                            data: response.errors
                        });
                        commit('setPublication', {
                            item: 'isLoading',
                            data: false
                        });
                    }
                } else {
                    const data = response.publication;
                    commit('setPublication', { item: 'data', data });
                    commit('setPublication', {
                        item: 'isLoading',
                        data: false
                    });
                }
            },
            (error) => {
                commit('setPublication', { item: 'errors', data: [error] });
                commit('setPublication', { item: 'isLoading', data: false });
            }
        );
    },
    /**
     * Load publish pricing periods from server
     */
    async loadPublishPricingPeriodOptions({ commit }, payload) {
        commit('setPublishPricingPeriodOptions', {
            item: 'isLoading',
            data: true
        });
        commit('setPublishPricingPeriodOptions', { item: 'errors', data: [] });
        try {
            const response = await loadOptionPublishPricingPeriods(
                payload.request
            );
            if (!response.success) {
                if (response.errors) {
                    commit('setPublishPricingPeriodOptions', {
                        item: 'errors',
                        data: response.errors
                    });
                    commit('setPublishPricingPeriodOptions', {
                        item: 'isLoading',
                        data: false
                    });
                }
            } else {
                const data = response.publishPricingPeriods.map((item) => {
                    return { id: item.id, value: item.value, text: item.text };
                });
                if (payload && payload.options && payload.options.addPicker) {
                    data.unshift({
                        id: null,
                        value: null,
                        postTypes: null,
                        text: 'Please Select'
                    });
                }
                commit('setPublishPricingPeriodOptions', {
                    item: 'data',
                    data
                });
                commit('setPublishPricingPeriodOptions', {
                    item: 'isLoading',
                    data: false
                });
            }
        } catch (error) {
            commit('setPublishPricingPeriodOptions', {
                item: 'errors',
                data: [error]
            });
            commit('setPublishPricingPeriodOptions', {
                item: 'isLoading',
                data: false
            });
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
