<!-- @format -->
<template>
    <div>
        <!--
        <div class="na-ad-box-dock has-text-centered">
            <div class="na-ad-box-content">
                <router-link class="is-hidden-mobile" :to="{ name: 'ad' }">
                    <img
                        class="na-link na-ad-image"
                        :src="imageUrl('NewsAtomic-KWAdvertiseNA2020.png')"
                        alt="Advertise on Kingston Wire"
                        title="Advertise on Kingston Wire"
                        loading="lazy"
                    />
                </router-link>
                <router-link class="is-hidden-tablet" :to="{ name: 'ad' }">
                    <img
                        class="na-link na-ad-image"
                        :src="imageUrl('NewsAtomic-KWAdvertiseNA2020.png')"
                        alt="Advertise on Kingston Wire"
                        title="Advertise on Kingston Wire"
                        loading="lazy"
                    />
                </router-link>
            </div>
        </div>
        -->
    </div>
</template>

<script>
import { mediaMixin } from '@/mixins/MediaMixin.js';

export default {
    mixins: [mediaMixin]
};
</script>
