<!-- @format -->
<template>
    <div class="na-sidebar-dock-container">
        <template v-if="isLoaded">
            <p v-if="mostRecentComments.length" class="na-sidebar-new-comments">Recent Comments...</p>
            <single-comment
                v-for="(block, index) in mostRecentComments"
                :key="block.postComment.comment.id"
                :block="block"
                :order="index"
            />
        </template>
        <div v-else class="is-flex is-flex-direction-column" aria-busy="true" aria-label="loading" aria-live="polite">
            <div class="loading-placeholder-comment-author">
                <!-- placeholder -->
            </div>
            <div class="loading-placeholder-comment-text">
                <!-- placeholder -->
            </div>
            <div class="loading-placeholder-comment-author">
                <!-- placeholder -->
            </div>
            <div class="loading-placeholder-comment-text">
                <!-- placeholder -->
            </div>
            <div class="loading-placeholder-comment-author">
                <!-- placeholder -->
            </div>
            <div class="loading-placeholder-comment-text">
                <!-- placeholder -->
            </div>
            <div class="loading-placeholder-comment-author">
                <!-- placeholder -->
            </div>
        </div>
    </div>
</template>

<script>
import SingleComment from './Comment.vue';
import { readRecentComments } from '@/services/post';

export default {
    components: {
        SingleComment
    },

    props: {
        ignorePreferences: {
            type: Boolean,
            required: true
        }
    },

    data() {
        return {
            isLoaded: false,
            mostRecentComments: []
        };
    },

    async created() {
        this.isLoaded = false;
        const response = await readRecentComments({
            ignorePreferences: this.ignorePreferences
        });
        if (response.success) {
            this.mostRecentComments = response.mostRecentComments;
        }
        this.isLoaded = true;
    }
};
</script>
