<!-- @format -->
<template>
    <div>
        <div class="field" :class="hasInputError(errors, 'firstName')">
            <div class="control">
                <input v-model="first_name" class="input" type="text" placeholder="First Name" required />
            </div>
            <input-error field="firstName" :errors="errors" />
        </div>

        <div class="field" :class="hasInputError(errors, 'lastName')">
            <div class="control">
                <input v-model="last_name" class="input" type="text" placeholder="Last Name" required />
            </div>
            <input-error field="lastName" :errors="errors" />
        </div>
    </div>
</template>

<script>
import InputError from '@/components/Common/InputError.vue';

export default {
    components: {
        InputError
    },

    props: {
        formData: {
            type: Object,
            required: true
        },
        errors: {
            type: Array,
            required: true
        },
        defaultAddress: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            first_name:
                this.formData && this.formData.firstName
                    ? this.formData.firstName
                    : this.defaultAddress && this.defaultAddress.user && this.defaultAddress.user.first_name
                    ? this.defaultAddress.user.first_name
                    : null,
            last_name:
                this.formData && this.formData.lastName
                    ? this.formData.lastName
                    : this.defaultAddress && this.defaultAddress.user && this.defaultAddress.user.last_name
                    ? this.defaultAddress.user.last_name
                    : null,
            organization:
                this.formData && this.formData.organization
                    ? this.formData.organization
                    : this.defaultAddress && this.defaultAddress.user && this.defaultAddress.user.organization
                    ? this.defaultAddress.user.organization
                    : null
        };
    },

    watch: {
        first_name() {
            this.updateFormData();
        },
        last_name() {
            this.updateFormData();
        },
        organization() {
            this.updateFormData();
        }
    },

    mounted() {
        this.updateFormData(); // Init
    },

    methods: {
        /**
         * Update form data for parent
         */
        updateFormData() {
            this.$emit('update:formData', {
                firstName: this.first_name,
                lastName: this.last_name,
                organization: this.organization
            });
        }
    }
};
</script>
