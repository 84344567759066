<!-- @format -->
<template>
    <div v-if="index !== null && count !== null && count > 1" class="has-text-centered na-lightbox-nav-buttons">
        <span
            v-for="item in count"
            :key="item"
            class="icon is-clickable"
            :class="{ 'na-lightbox-nav-button-active': index === item - 1 }"
            @click="select(item - 1)"
            ><fa-icon icon="circle" data-toggle="tooltip" data-placement="bottom" :title="tooltip(item)"
        /></span>
    </div>
</template>

<script>
export default {
    props: {
        index: {
            type: Number,
            required: true
        },
        count: {
            type: Number,
            required: true
        }
    },

    methods: {
        /**
         * Return the text for tooltip for each icon
         *
         * @param {Integer} index
         */
        tooltip(index) {
            return 'View Image ' + index;
        },
        /**
         * User selected an index
         *
         * @param {Integer} index
         */
        select(index) {
            this.$emit('index', index);
        }
    }
};
</script>
