<!-- @format -->
<template>
    <article v-if="post">
        <media-lightbox
            v-if="isMediaLightbox && (mediaLightboxImages || mediaLightboxVideos)"
            :post-title="post.title"
            :media-images="mediaLightboxImages"
            :media-videos="mediaLightboxVideos"
            :show-caption="showCaptionCard"
            @close="isMediaLightbox = false"
        />
        <p v-if="adminPreviewPost !== null" class="has-text-centered">ADMIN PREVIEW MODE</p>

        <div ref="postContainer" class="py-2 px-4" :class="{ noselect: !selectAllowed }">
            <div class="is-flex is-justify-content-space-between">
                <div class="is-flex na-post-meta-header is-align-items-center">
                    <span
                        v-if="isPostType(post, 'postTypeEvent')"
                        class="icon na-post-meta-header-post-type"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Event"
                        ><fa-icon :icon="['fac', 'ticket-old']" />
                    </span>
                    <time v-if="isShowContentAt(post)" :datetime="post.content_at" class="na-post-meta-date">
                        <span v-if="isPostType(post, 'postTypePodcast')" class="icon">
                            <fa-icon icon="podcast" />
                        </span>
                        <span v-else-if="isPostType(post, 'postTypeNewscast')" class="icon">
                            <fa-icon icon="headphones" />
                        </span>
                        {{ postContentAt }}
                    </time>
                    <!-- Only if we showed a date above -->
                    <span v-if="isShowPostType(post)" class="na-post-meta-post-type"
                        ><span v-if="isShowContentAt(post)" class="mx-1">&bull;</span>{{ post.post_type.value }}</span
                    >
                    <template v-if="isShowPostCategory(post)">
                        <template
                            v-if="post.post_classifieds && describePostClassifiedCategories(post.post_classifieds)"
                        >
                            <span class="mx-1">&bull;</span>
                            <span>{{ describePostClassifiedCategories(post.post_classifieds) }}</span>
                        </template>
                        <template v-if="post.post_events && describePostEventCategories(post.post_events)">
                            <span class="mx-1">&bull;</span>
                            <span>{{ describePostEventCategories(post.post_events) }}</span>
                        </template>
                        <template
                            v-if="
                                post.post_categories &&
                                !post.post_classifieds &&
                                !post.post_events &&
                                describePostCategories(post.post_categories)
                            "
                        >
                            <span class="mx-1">&bull;</span>
                            <span>{{ describePostCategories(post.post_categories) }}</span>
                        </template>
                    </template>

                    <p v-if="isAd">ADVERTISEMENT</p>

                    <fa-layers
                        v-if="postCommentCount > 0 && isShowPostComments(post) && !isHvnews"
                        class="fa-2x ml-4"
                        full-width
                        data-toggle="tooltip"
                        data-placement="top"
                        :title="commentTooltip"
                    >
                        <fa-icon class="na-post-comment-icon" transform="shrink-2" :icon="['fac', 'comment-old']" />
                        <fa-layers-text
                            class="na-post-comment-count"
                            transform="shrink-9.2 up-1"
                            :value="postCommentCount"
                        />
                    </fa-layers>
                </div>
                <cost-corner
                    v-if="isShowCostCorner(post) && !isHvnews"
                    :post="post"
                    :is-full="true"
                ></cost-corner>
            </div>

            <publication-banner
                v-if="!isAd && isShowPublicationBanner(post) && doesPublicationHaveMediaLogo(post)"
                :post="post"
            />

            <h2 v-if="isPostSponsored(post)" class="na-sponsored">SPONSORED</h2>

            <div v-if="isShowHeadline(post)" class="na-post-headline-wrapper">
                <h1
                    class="na-post-headline"
                    :class="{ 'na-sponsored': isPostSponsored(post) }"
                >
                    {{ post.title }}
                </h1>
            </div>

            <div v-if="post.subtitle && isShowSubhead(post)" class="na-post-subhead-wrapper">
                <h2
                    class="na-post-subhead"
                    :class="{ 'na-sponsored': isPostSponsored(post) }"
                >
                    {{ post.subtitle }}
                </h2>
            </div>

            <div class="is-flex my-2">
                <span v-if="isShowPostTypeLabel" class="post-type-label">{{ post.post_type.value2 }}&nbsp;</span>
                <post-author v-if="post.post_authors && isShowAuthor(post)" :post="post" />
            </div>

            <div v-if="postType === 'postTypeEvent' && hasDataSupplemental" class="na-post-detail-event">
                <post-type-event :post="post" :data-supplemental="dataSupplemental"></post-type-event>
            </div>

            <div class="na-post-share-wrapper">
                <div v-if="showShareIcons">
                    <share-post :post="post" share-to="facebook" />
                    <share-post :post="post" share-to="twitter" />
                    <share-post :post="post" share-to="email" />
                    <share-post :post="post" share-to="clipboard" />
                </div>

                <div v-if="!isAd" class="na-post-media-count-wrapper">
                    <video-count :media="post.post_media_videos" :minus-one="showVideo" @clicked="mediaClicked(post)" />
                    <image-count :media="post.media" :minus-one="showImage" @clicked="mediaClicked(post)" />
                </div>
            </div>

            <post-media v-if="showImage" :post="post" is-full-view @click="mediaClicked(post)" />

            <video-full
                v-if="showVideo"
                :video="post.post_media_videos[0]"
                :is-show-caption="showCaptionCard"
                :show-video="isShowVideo"
            />

            <div v-if="showContent" class="na-post-content-wrapper" :class="postTypeContentClass(post)">
                <div v-if="content" class="na-post-content ql-container">
                    <div class="ql-editor" v-html="content"></div>
                </div>

                <div
                    v-if="isPostType(post, ['postTypeNewscast', 'postTypePodcast']) && postAudio"
                    class="play-newscast-line my-2"
                >
                    <span class="is-clickable" @click="playPodcastClicked">
                        <span class="play-newscast-icon"
                            ><span class="icon is-medium"><fa-icon icon="play" size="lg" /></span
                        ></span>
                        <span class="text"
                            >Play <span v-if="audioDuration">({{ audioDuration }})</span></span
                        >
                    </span>
                </div>

                <p v-if="firstAuthor && authorBio" class="na-post-author-bio">
                    {{ authorBio }}
                </p>
            </div>

            <div
                v-if="isShowPaywallContent"
                class="na-paywall-content"
                :class="{ 'na-hidden': !isVisitorCrawler(crawlerCheck) }"
                aria-hidden="true"
                v-html="postPaywallContent(post)"
            ></div>

            <div v-if="!isAd" class="na-post-meta-footer my-3">
                <p v-if="post.organization">Organization: {{ post.organization }}</p>
                <span v-if="hasPostLocation(post)">{{ describePostLocation(post) }}</span>
                <template v-if="wordCount">
                    <span class="ml-1">&bull;</span>
                    <span class="ml-1">{{ wordCount }}</span>
                </template>
                <template v-if="isShowMediaCount(post) && imageCount">
                    <span class="ml-1">&bull;</span>
                    <span class="ml-1">{{ imageCount }}</span>
                </template>
            </div>

            <!-- Only show again here if there are photos -->
            <span v-if="showShareIcons && post.media && post.media.length" class="is-flex">
                <share-post :post="post" share-to="facebook" />
                <share-post :post="post" share-to="twitter" />
                <share-post :post="post" share-to="email" />
                <share-post :post="post" share-to="clipboard" />
            </span>

            <span
                v-if="isShowPostComments(post) && !isHvnews"
                class="is-clickable is-flex is-align-items-center"
                @click="doShowComments()"
            >
                <span v-if="!postCommentCount">There are currently no comments. Add your own?</span>
                <span v-if="postCommentCount === 1">There is 1 comment</span>
                <span v-if="postCommentCount > 1">There are {{ postCommentCount }} comments</span>
                <span
                    v-if="!showComments"
                    class="icon is-medium na-post-comment-bottom-icon"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Show Comments"
                    ><fa-icon :icon="['fac', 'comment-old']"
                /></span>
                <span
                    v-if="showComments"
                    class="icon is-medium na-post-comment-bottom-icon"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Hide Comments"
                    ><fa-icon :icon="['fac', 'comment-old']" /></span
            ></span>

            <post-comments
                v-if="showComments"
                ref="comments"
                :post="post"
                @comment-count-updated="commentCountUpdated"
            />

            <related-posts
                v-if="isShowRelatedPosts(post) && !isHvnews"
                :related-posts="post._related_posts"
            />
        </div>
    </article>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { formatDuration } from '@/helpers/media';
import mixin from './mixin.js';
import { editorMixin } from '@/mixins/EditorMixin.js';
import { recordPostHit } from '@/services/post.js';
import CostCorner from '@/components/Content/CostCorner/Index.vue';
import ImageCount from '@/components/Content/Images/Count/Index.vue';
import MediaLightbox from '../Media/Lightbox/Index.vue';
import PostComments from '../Comments/Index.vue';
import PostTypeEvent from './PostTypes/Event/Index.vue';
import RelatedPosts from '@/components/Content/Viewer/RelatedPosts/Index.vue';
import SharePost from '@/components/Content/Share/Index.vue';
import VideoCount from '@/components/Content/Videos/Count/Index.vue';
import VideoFull from '@/components/Content/Videos/Full/Index.vue';
import PostMedia from './PostMedia.vue';
import PostAuthor from './PostAuthor.vue';
import PublicationBanner from './PublicationBanner.vue';
import Hammer from 'hammerjs';

export default {
    components: {
        CostCorner,
        ImageCount,
        MediaLightbox,
        PostComments,
        PostTypeEvent,
        RelatedPosts,
        SharePost,
        VideoCount,
        VideoFull,
        PostMedia,
        PostAuthor,
        PublicationBanner
    },

    mixins: [mixin, editorMixin],

    props: {
        post: {
            type: Object,
            required: true
        },
        optionPostTypes: {
            type: Array,
            default: () => []
        },
        adminPreviewPost: {
            type: Object,
            default: function () {
                return null;
            }
        },
        // Open directly to comments?
        openComments: {
            type: Boolean,
            default: false
        },
        isHvnews: {
            type: Boolean,
            default: false
        },
        crawlerCheck: {
            type: [Object, Array],
            default: function () {
                return [];
            }
        },
    },

    data() {
        return {
            isBusy: false,
            errors: [],
            content: '',
            cost: null,
            dataSupplemental: {},
            hasDataSupplemental: false,
            postType: null,
            isMediaLightbox: false,
            mediaLightboxImages: null,
            mediaLightboxVideos: null,
            showComments: false,
            isShowVideo: false
        };
    },

    computed: {
        ...mapGetters({
            postCommentCount: 'viewer/postCommentCount'
        }),
        isAd() {
            return this.isPostType(this.post, 'postTypeNativeAd');
        },
        /**
         * Return tooltip text for comment
         *
         * @return {String} Tooltip text
         */
        commentTooltip() {
            if (this.post) {
                return (
                    this.postCommentCount +
                    ' comment' +
                    (!this.postCommentCount || this.postCommentCount > 1 ? 's' : '')
                );
            } else {
                return '';
            }
        },
        postImage() {
            return this.post?.media?.find((media) => media.media_type_id === 31);
        },
        showImage() {
            return this.postShowImage(this.post);
        },
        postAudio() {
            return this.post.media.find((audio) => audio.audio_type === 'castFull');
        },
        audioDuration() {
            if (!this.postAudio) {
                return '';
            }
            return formatDuration(this.postAudio.duration / 1000);
        },
        firstAuthor() {
            return this.post.post_authors?.[0];
        },
        authorBio() {
            if (!this.firstAuthor) {
                return;
            }
            return this.postAuthorBio(this.firstAuthor.author);
        },
        wordCount() {
            return this.getPostWordCount(this.post);
        },
        imageCount() {
            return this.getPostImageCount(this.post);
        },
        showContent() {
            return this.isShowContent(this.post);
        },
        isShowPaywallContent() {
            return this.postIsShowPaywallContent(this.post);
        },
        showVideo() {
            return this.postShowVideo(this.post, true, false);
        },
        showCaptionCard() {
            return this.isShowCaptionCard(this.post);
        },
        showShareIcons() {
            return this.isShowShareIcons(this.post) && !this.isHvnews;
        }
    },

    created() {
        if (this.adminPreviewPost) {
            this.loadAdminPreview();
        } else {
            this.loadPost();
        }
    },

    mounted() {
        if (this.clientAllowSwipe) {
            const hammer = new Hammer(this.$refs.postContainer, {
                recognizers: [[Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }]]
            });
            hammer.on('swipeleft', this.next);
            hammer.on('swiperight', this.prev);
        }
        if (this.postIsShowPaywallContent(this.post)) {
            this.setStructureData();
        }
    },

    methods: {
        ...mapMutations({
            playPodcast: 'viewer/playPodcast'
        }),

        /**
         * A post needs to be loaded.
         */
        loadPost() {
            this.postType = this.getPostTypeValue(this.optionPostTypes, this.post.post_type_id);
            if (this.postType !== null) {
                const dataSupplemental = this.setSupplementalData(this.post, this.postType);
                if (dataSupplemental !== null) {
                    Object.assign(this.dataSupplemental, dataSupplemental);
                    this.hasDataSupplemental = true;
                }
            }

            if (this.post && (this.post.content_text || this.post.textContent)) {
                if (this.isPostAdvertisement(this.post)) {
                    return;
                }

                let content = this.post.content_html;

                // @todo  2017.07.06  MJL  Might need to do more of this - should have a function or handler to do these
                if (this.postType === 'postTypeBlotter' && this.hasDataSupplemental && this.dataSupplemental.status) {
                    // Add suspect status to content
                    content = this.appendQuillText(content, this.dataSupplemental.status.value);
                }

                if (this.isShowLocationInContent(this.post)) {
                    let location = this.describePostLocation(this.post, false);
                    location = typeof location === 'string' ? location.toUpperCase() : location;
                    content = this.prefixQuillText(content, location + ' - ');
                }

                this.content = content;
                this.cost = this.post.cost;

                this.$nextTick(() => {
                    if (this.post.post_media_videos && this.post.post_media_videos.length) {
                        this.isShowVideo = true;
                    }

                    // Should we open directly to comments?
                    if (this.openComments) {
                        this.doShowComments();
                        setTimeout(() => {
                            this.doScrollComments();
                        }, 1000);
                    }
                });
            }
        },

        /**
         * We're showing an admin preview
         */
        loadAdminPreview() {
            this.post = this.adminPreviewPost;

            let content = this.post.content_html;
            // @todo  2017.07.06  MJL  Might need to do more of this - should have a function or handler to do these
            if (this.postType === 'postTypeBlotter' && this.hasDataSupplemental && this.dataSupplemental.status) {
                // Add suspect status to content
                content = this.appendQuillText(content, this.dataSupplemental.status.value);
            }
            this.content = content;
            this.cost = this.post.cost;
        },

        /**
         * Tell caller to go to previous article
         */
        prev() {
            this.$emit('prev');
        },

        /**
         * Tell caller to go to next article
         */
        next() {
            this.$emit('next');
        },

        /**
         * Allow user to comment
         */
        comment() {
            this.$emit('comment');
        },

        /**
         * Comment count was updated
         */
        commentCountUpdated(newVal) {
            this.$emit('post-comment-count-updated', newVal);
        },

        doScrollComments() {
            this.$nextTick(() => {
                this.$refs.comments.scrollIntoView();
            });
        },

        doShowComments() {
            // Don't allow post comments
            if (!this.isAllowPostComments(this.post)) {
                return;
            }
            if (!this.showComments) {
                this.showComments = true;
                this.doScrollComments();
            } else {
                this.showComments = false;
            }
        },

        /**
         * An image/video has been clicked.  Typically we open the lightbox, but for some post types (currently just native ads), we go to the link specified in the post instead
         */
        mediaClicked(post) {
            if (this.isPostType(post, 'postTypeNativeAd')) {
                const url = post.post_native_ads && post.post_native_ads.length ? post.post_native_ads[0].url : null;
                if (url !== null) {
                    this.logHit();
                    window.open(url, '_blank');
                }
            } else {
                this.mediaLightboxImages = post.media.filter((media) => media.media_type_id === 31);
                this.mediaLightboxVideos = post.post_media_videos;
                this.isMediaLightbox = !this.isMediaLightbox;
            }
        },

        playPodcastClicked() {
            this.playPodcast({
                url: this.postMediaUrl(this.postAudio),
                title: this.post.title
            });
        },

        /**
         * Log post hit
         */
        logHit() {
            const post =
                this.post.post_native_ads && this.post.post_native_ads.length ? this.post.post_native_ads[0] : null;
            if (post) {
                // No, not currently waiting for it or checking if error
                recordPostHit({
                    post_id: post.post_id,
                    subsource: 'NativeAdViewer',
                    url: post.url
                });
            }
        }
    }
};
</script>
