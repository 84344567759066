<!-- @format -->
<template>
    <password-input
        :errors="errors"
        placeholder="Password"
        :password.sync="password"
        :show-password="showPassword"
        :show-password-toggle="true"
        :show-strength="true"
        required
        @show-toggled="showPasswordToggle"
    />
</template>

<script>
import PasswordInput from '@/components/Common/Password.vue';

export default {
    components: {
        PasswordInput
    },

    props: {
        formData: {
            type: Object,
            required: true
        },
        errors: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            password: '',
            showPassword: false
        };
    },

    computed: {
        /**
         * Return the input type for the password field(s)
         */
        inputType() {
            return this.showPassword ? 'text' : 'password'; // If user wants to show password, set type to text, otherwise to password
        }
    },

    watch: {
        password() {
            this.updateFormData();
        }
    },

    created() {
        this.updateFormData(); // Init
    },

    methods: {
        /**
         * Update form data for parent
         */
        updateFormData() {
            this.$emit('update:formData', {
                password: this.password,
                passwordConfirmation: this.password
            });
        },
        /**
         * If the password show/hide is toggled for the main password field
         *
         * @param {Boolean} showPassword
         */
        showPasswordToggle(showPassword) {
            this.showPassword = showPassword;
        }
    }
};
</script>
