/**
 * @format
 * Sharing related API functions.
 * Abstracting these out from DataMixin.js.
 */
import axios from 'axios';

/**
 * Create a post share entry (tracks shares for posts)
 *
 * @param {Object} data Share data
 *
 * @return Promise for resolve, reject
 */
export function postShareLog(data) {
    return axios
        .post(naRoutes.postShareLog, {
            data
        })
        .then((response) => response.data);
}
