/**
 * @format
 * Money mixins
 */

export const moneyMixin = {
    methods: {
        /**
         * Determine if money amount is less than 0
         *
         * @param {String} valueIn Value
         *
         * @return {Boolean}
         */
        isMoneyDeficit(valueIn = null) {
            if (valueIn !== null && !Number.isNaN(Number.parseFloat(valueIn))) {
                return Number.parseFloat(valueIn) < 0;
            } else {
                return false;
            }
        },
        /**
         * Format money if deficit - returns in parens
         *
         * @param {String} valueIn value If value is not less than 0, we simply return the valueIn
         * @param {Boolean} includeSymbol Include currency symbol?  Default is true
         *
         * @return {String} Formatted value; null if not vaid
         */
        formatMoneyDeficit(valueIn = null, includeSymbol = true) {
            if (valueIn !== null && !Number.isNaN(Number.parseFloat(valueIn))) {
                return Number.parseFloat(valueIn) < 0
                    ? '(' +
                          (includeSymbol ? '$' : '') +
                          Number.parseFloat(Math.abs(valueIn)).toFixed(2) +
                          ')'
                    : valueIn;
            } else {
                return valueIn;
            }
        },
        /**
         * Format a cost field (to cents or $)
         *
         * @param {String} valueIn Value
         * @param {mixed}  prefix  Prefix value with
         *
         * @return {mixed} Formatted value; null if none/not valid
         */
        formatCost(valueIn = null, prefix = null) {
            let valueOut = null;
            if (valueIn !== null) {
                if (Math.abs(valueIn) >= 0 && Math.abs(valueIn) < 1) {
                    valueOut =
                        valueIn.toString() === '0'
                            ? null
                            : (
                                  Number.parseFloat(valueIn).toPrecision(12) *
                                  Number.parseFloat(100.0).toPrecision(12)
                              )
                                  .toFixed(0)
                                  .toString() + '&cent;';
                } else {
                    valueOut =
                        '$' +
                        (!Number.isNaN(Number.parseFloat(valueIn))
                            ? Number.parseFloat(valueIn)
                                  .toFixed(2)
                                  .toString()
                                  .replace(/\.00$/, '')
                            : valueIn.toString());
                }
            }

            return valueOut
                ? prefix
                    ? prefix + valueOut
                    : valueOut
                : valueOut;
        },
        /**
         * Format a money field (to cents or $)
         *
         * @param {String}  valueIn       Value
         * @param {mixed}   prefix        Prefix value with
         * @param {Boolean} includeSymbol Include currency symbol?  Default is true
         * @param {Boolean} forceCurrency Force currency format?  That is, always to $m.xx.  Default is false
         * @param {Boolean} formatDeficit Format deficit in parens (this is only applicable when 'forceCurrency' is true)?  Default is false
         *
         * @return {String} Formatted value; null if not valid
         */
        formatMoney(
            valueIn = null,
            prefix = null,
            includeSymbol = true,
            forceCurrency = false,
            formatDeficit = false
        ) {
            let valueOut = null;
            let currencySymbol = includeSymbol ? '$' : '';
            if (valueIn !== null) {
                if (!Number.isNaN(Number.parseFloat(valueIn))) {
                    if (forceCurrency) {
                        if (formatDeficit && this.isMoneyDeficit(valueIn)) {
                            valueOut = this.formatMoneyDeficit(valueIn);
                        } else {
                            valueOut =
                                currencySymbol +
                                Number.parseFloat(valueIn).toFixed(2);
                        }
                    } else {
                        if (Math.abs(valueIn) >= 0 && Math.abs(valueIn) < 1) {
                            valueOut =
                                valueIn.toString() === '0'
                                    ? currencySymbol + '0'
                                    : (
                                          Number.parseFloat(
                                              valueIn
                                          ).toPrecision(12) *
                                          Number.parseFloat(100.0).toPrecision(
                                              12
                                          )
                                      )
                                          .toFixed(0)
                                          .toString() +
                                      ' cent' +
                                      (valueIn != 0.01 ? 's' : '');
                        } else {
                            valueOut =
                                currencySymbol +
                                Number.parseFloat(valueIn).toFixed(2);
                        }
                    }
                } else {
                    valueOut = currencySymbol + '0.00';
                }
            }

            return valueOut
                ? prefix
                    ? prefix + valueOut
                    : valueOut
                : valueOut;
        }
    }
};
