<!-- @format -->
<template>
    <div class="field" :class="fieldClasses">
        <slot />
        <input-error :field="name" :errors="errors" />
    </div>
</template>

<script>
import InputError from './InputError.vue';
export default {
    components: {
        InputError
    },

    props: {
        classes: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            required: true
        },
        errors: {
            type: [Array, Object],
            default: () => []
        }
    },

    computed: {
        fieldClasses() {
            return [this.classes, this.hasInputError(this.errors, this.name)];
        }
    }
};
</script>
