/**
 * Issue related API functions
 * Abstracting these out from DataMixin.js.
 */
import axios from 'axios';
import { dateFormatStandardDateTime } from '@/services/date';  // @todo  2019-03-22  MJL  Start standardizing functions here instead of defining in mixin, mixin can call them instead

/**
 * Load an issue
 *
 * @param {Integer} issueId Issue ID to be read
 *
 * @return Promise for resolve, reject
 */
export const readIssue = function (issueId) {
    return axios.post(naRoutes.readIssue,
        {
            data: {
                id: issueId
            }
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Load issues
 *
 * @param {mixed} publicationId Publication ID to load issues for - may be null for any publisher
 *
 * @return Promise for resolve, reject
 */
export const readIssues = function (publicationId = null) {
    return axios.post(naRoutes.readIssues,
        {
            data: {
                publicationId
            },
            max: 100  // Not implemented yet
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Return a string describing an issue
 *
 * @param {Object} issue Issue
 *
 * @return {String} Description
 */
export const issueDescribe  = function (issue) {
    let name = '';
    name += (issue.volume) ? ' Volume ' + issue.volume : '';
    name += (issue.edition) ? ' Edition ' + issue.edition : '';
    name += (issue.issue_on) ? ' On ' + dateFormatStandardDateTime(issue.issue_on) : '';
    name = name.replace(/^\s+/, '');

    return name.length ? name : 'Issue ID ' + issue.id;
};
