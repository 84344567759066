<!-- @format -->
<template>
    <div class="na-viewer-gradient pt-4">
        <message-bar :initial-message="initialMessage" />
        <div class="container" :class="{ 'is-fluid': !clientIsMobile }">
            <div v-if="!isLoaded" class="columns">
                <!-- @todo kinda ugly to force height because of the sticky audio&#45;player -->
                <div class="column has-text-centered" style="height: 100vh">
                    <busy-loader spinner-type="icon" size="2x" />
                </div>
            </div>

            <template v-if="isLoaded">
                <single-post
                    v-if="activePost && postIndex !== null"
                    :post="activePost"
                    :prev-post-id="prevPostId"
                    :next-post-id="nextPostId"
                    :auto-purchase="autoPurchase"
                    :option-post-types="optionPostTypes"
                    :post-count="posts.length"
                    :post-index="postIndex"
                    :is-adding-subscription="isAddingSubscription"
                    :open-comments="openComments"
                    :ignore-preferences="ignorePreferences"
                    @prev="prev"
                    @next="next"
                    @article-purchased="articlePurchased"
                />

                <not-found
                    v-else
                    :reason="lastResponse && lastResponse.isSectionTypeEvent ? 'event' : 'general'"
                    :render-direct="true"
                />
            </template>
        </div>
    </div>
</template>

<script>
import { dataHelperMixin } from '@/mixins/DataHelperMixin.js';
import { dateMixin } from '@/mixins/DateMixin.js';
import { readPreviewPosts, readPostByIdentifier, readPost } from '@/services/post';
import { buildRouteParams } from '@/helpers/post.js';
import NotFound from '@/components/Content/NotFound/ContentNotFound.vue';
import SinglePost from '@/components/Content/Viewer/Single.vue';
import MessageBar from '@/components/Common/MessageBar/Index.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
    components: {
        NotFound,
        SinglePost,
        MessageBar
    },

    mixins: [dataHelperMixin, dateMixin],

    beforeRouteUpdate(to, _from, next) {
        if (to.name === 'post-viewer-section') {
            this.resetMessageBar();
            this.refresh({
                sectionId: to.params.sectionId
            });
        }
        next();
    },

    beforeRouteLeave(to, _from, next) {
        if (to.name === 'post-viewer-section') {
            this.resetMessageBar();
            this.refresh({
                sectionId: to.params.sectionId
            });
        }
        next();
    },

    props: {
        optionPostTypes: {
            type: Array,
            default: () => []
        },
        initialMessage: {
            type: String,
            default: ''
        },
        reqEventType: {
            // Requested event type - only applies if in an event section
            default: null
        },
        reqEventDate: {
            // Requested event date - only applies if in an event section
            default: null
        }
    },

    data() {
        return {
            errors: [],
            posts: [],
            activePost: null,
            postIndex: null,
            isLoaded: false,
            lastResponse: null,
            lReqEventType: this.eventType, // Event type - only applies to section type of events!
            lReqEventDate: this.eventDate, // Event date - only applies to section type of events!
            openComments: Number(this.$route.query.open_comments) >= 1
        };
    },

    computed: {
        ...mapGetters({
            vuexIgnorePreferences: 'viewer/ignorePreferences'
        }),
        ignorePreferences() {
            return this.$route.query.force_ignore_preferences || this.$route.query.nopref
                ? true
                : this.vuexIgnorePreferences; // Ignore user preferences?
        },
        sectionId() {
            return this.$route.params.sectionId ?? this.$route.query.section_id ?? null;
        },
        sectionRoute() {
            return this.$route.params.sectionRoute ?? this.$route.query.section_route;
        },
        whatsNew() {
            return this.$route.name === 'post-viewer-whats-new' || Number(this.$route.query.whats_new) === 1;
        },
        postIdentifier() {
            return this.$route.params.identifier;
        },
        prevPostId() {
            if (this.posts.length === 0) {
                return null;
            }
            if (this.postIndex > 0 && this.postIndex < this.posts.length) {
                return this.posts[this.postIndex - 1].id;
            }
            return null;
        },
        nextPostId() {
            if (this.posts.length < 2) {
                return null;
            }
            if (this.postIndex === this.posts.length - 1) {
                return null;
            }
            return this.posts[this.postIndex + 1].id;
        },
        autoPurchase() {
            return Number(this.$route.query.open) >= 1;
        },
        isAddingSubscription() {
            return !!this.$route.query.subscribe;
        }
    },

    watch: {
        // When directly navigating to another post, detect if identifier change
        postIdentifier(val) {
            // To prevent duplicate requests
            if (!this.activePost) {
                return;
            }
            const activeIdentifier = this.activePost.post_identifiers.find((ident) => {
                return ident.identifier === val;
            });

            if (!activeIdentifier && val) {
                return this.loadActivePost({ identifier: val });
            }
        },

        postIndex(val) {
            this.$emit('post-index-updated', val);
        },

        /**
         * Preferences flag changed
         */
        ignorePreferences() {
            this.refresh({
                sectionId: this.sectionId,
                sectionRoute: this.sectionRoute
            });
        },

        /**
         * Requested event type
         */
        reqEventType() {
            this.lReqEventType = this.reqEventType;
            this.refresh({
                sectionId: this.sectionId,
                sectionRoute: this.sectionRoute
            });
        },

        /**
         * Requested event date
         */
        reqEventDate() {
            this.lReqEventDate = this.reqEventDate;
            this.refresh({
                sectionId: this.sectionId,
                sectionRoute: this.sectionRoute
            });
        }
    },

    async created() {
        console.time('postviewer-created');
        console.log('DEBUG: PostViewer.vue created');
        this.$root.$on('prev-post', this.prev);
        this.$root.$on('next-post', this.next);
        this.$root.$on('refresh-post', () => {
            this.refresh({
                sectionId: this.sectionId,
                sectionRoute: this.sectionRoute,
                postOnly: true
            });
        });

        console.log('DEBUG: PostViewer.vue loadPreviewList');
        const response = await this.loadPreviewList({
            sectionId: this.sectionId,
            sectionRoute: this.sectionRoute
        });
        console.log('DEBUG: PostViewer.vue done loadPreviewList');
        if (response.eventDate || response.eventType) {
            this.handleEventDateType(response);
        }

        if (response.isSectionTypeWhatsNew) {
            this.handleWhatsNew(response);
        }

        if (this.postIdentifier) {
            console.log('DEBUG: PostViewer.vue loadActivePost for identitiy ', this.postIdentifier);
            await this.loadActivePost({ identifier: this.postIdentifier });
        } else {
            this.postIndex = 0;
            const post = this.posts[this.postIndex];
            if (!post) {
                this.postIndex = null;
                this.isLoaded = true;
            } else {
                console.log('DEBUG: PostViewer.vue loadActivePost for id ', post.id);
                await this.loadActivePost({ id: post.id });
            }
        }

        console.timeLog('postviewer-created');
    },

    beforeDestroy() {
        this.resetMessageBar();
    },

    methods: {
        ...mapMutations({
            resetMessageBar: 'app/clearMessages',
            setMessageBar: 'app/setMessages'
        }),

        async loadPreviewList({ postIds = null, sectionId = null, sectionRoute = null } = {}) {
            console.time('loadPreviewList');
            this.errors = [];
            const data = {
                postIds,
                sectionId,
                sectionRoute,
                whatsNew: this.whatsNew,
                options: {
                    ignorePreferences: this.ignorePreferences,
                    reqEventType: this.reqEventType,
                    reqEventDate: this.reqEventDate,
                    override: false
                }
            };
            try {
                // @todo Update this - it's loading all posts now - should do it in chunks
                
                console.log('DEBUG: PostViewer.vue readPreviewPosts for data', data);
                console.time('readPreviewPosts');
                const response = await readPreviewPosts(data);
                console.timeLog('readPreviewPosts');
                console.log('DEBUG: Postviewer.vue, readPreviewPosts response', response);
                this.lastResponse = response;
                if (!response.success) {
                    if (response.errors) {
                        this.errors = response.errors;
                    }
                } else {
                    this.posts = response.posts;
                    this.$emit('post-count-updated', this.posts.length);
                    console.timeLog('loadPreviewList');
                    return response;
                }
            } catch (error) {
                this.errors = [error.message];
            }
        },

        async loadActivePost({ identifier, id }) {
            this.isLoaded = false;
            let readPostPromise = null;
            const isLogPostView = this.$store.getters['viewer/getIsLogPostView'];
            if (identifier) {
                readPostPromise = readPostByIdentifier(identifier, this.ignorePreferences, isLogPostView);
            } else {
                readPostPromise = readPost(id, this.ignorePreferences, isLogPostView);
            }
            try {
                const response = await readPostPromise;
                if (!response.success) {
                    this.errors = response.errors;
                    return;
                }
                this.activePost = response.post;
                this.$store.commit('user/setRecentPostId', this.activePost.id);
                this.$store.commit('user/setRecentPost', this.activePost);
                this.$store.commit('viewer/setCurrentPost', this.activePost);
                this.$store.commit('viewer/setPostCommentCount', this.activePost ? this.activePost.commentCount : null);
                this.$store.commit('viewer/setIsLogPostView', true);

                this.postLoaded(this.activePost);
            } catch (error) {
                this.errors = [error.message];
            } finally {
                this.isLoaded = true;
            }
        },

        /**
         * Refresh the feed
         *
         * postOnly - refresh only the active post, don't refresh preview list
         */
        async refresh({ postOnly = false, ...params }) {
            if (!postOnly) {
                const response = await this.loadPreviewList({ ...params });
                // If we have event data (e.g. due to event selector usage), we want to display this in the message bar
                if (response.eventDate || response.eventType) {
                    this.handleEventDateType(response);
                }
                // If section is special "what's new" section, we want to display info in the message bar
                if (response.isSectionTypeWhatsNew) {
                    this.handleWhatsNew(response);
                }
            }
            if (this.postIdentifier) {
                await this.loadActivePost({ identifier: this.postIdentifier });
            } else {
                this.postIndex = 0;
                const post = this.posts[this.postIndex];
                if (!post) {
                    this.postIndex = null;
                    this.isLoaded = true;
                } else {
                    await this.loadActivePost({ id: post.id });
                }
            }
        },

        /**
         * Previous post
         */
        async prev() {
            this.openComments = false;
            await this.loadActivePost({ id: this.prevPostId });
            this.$router.push(
                buildRouteParams(this.activePost, this.$route.query.section_id, {
                    query: this.$route.query.query,
                    whatsNew: this.whatsNew
                })
            );
        },

        /**
         * Next post
         */
        async next() {
            this.openComments = false;
            console.log('DEBUG: PostViewer.vue next', this.nextPostId);
            await this.loadActivePost({ id: this.nextPostId });
            this.$router.push(
                buildRouteParams(this.activePost, this.$route.query.section_id, {
                    query: this.$route.query.query,
                    whatsNew: this.whatsNew
                })
            );
        },

        /**
         * An article was purchased, notify parent
         *
         * @param {Object} post Post that was purchased
         */
        articlePurchased(post) {
            this.$emit('article-purchased', post);
        },

        /**
         * Handle display of event date or type selected.  When a user selects event date/type, we want to show the info in the message bar
         *
         * @param {Object} response Response data
         */
        handleEventDateType(response) {
            const eventDate = response.eventDate;
            const eventTypes = response.eventTypes;
            this.setMessageBar(
                eventTypes.display + ' | Dates: ' + eventDate.display + ' - found ' + response.postNoAdsCount
            );
        },
        /**
         * Handle display of what's new section.  When a user selects it, we want to show in the message bar
         *
         * @param {Object} response Response data
         */
        handleWhatsNew(response) {
            this.setMessageBar({
                html:
                    'Everything since last visit <span class="has-text-weight-bold">' +
                    (response.lastVisitAt
                        ? ': ' + this.formatStandardDateTime(response.lastVisitAt, 'human-noseconds')
                        : '') +
                    '</span>'
            });
        },

        async postLoaded(loadedPost) {
            if (!loadedPost) {
                this.postIndex = null;
                return;
            }
            this.postIndex = this.posts.findIndex((post) => post.id === loadedPost.id);
            // When post is not found in preview list and not in specific section view
            // Then show the post regardless. Similar to how direct post works originally.
            if (this.postIndex === -1 && this.sectionId) {
                this.postIndex = 0;
                const post = this.posts[this.postIndex];
                if (!post) {
                    this.postIndex = null;
                    return;
                }
                await this.loadActivePost({ id: post.id });
                this.$router.push(
                    buildRouteParams(this.activePost, this.$route.query.section_id, {
                        query: this.$route.query.query
                    })
                );
                return;
            }

            // Short post link i.e. /post/link/:identifier route is temporary. After loading the post, redirect to post slug link
            if (this.$route.name === 'post-viewer-short') {
                this.$router.replace(
                    buildRouteParams(loadedPost, null, {
                        open: this.autoPurchase,
                        forceIgnorePreferences: true
                    })
                );
            }

            // Section route is temporary. After loading the post, redirect to post slug link
            if (this.$route.name === 'post-viewer-section') {
                this.$router.replace(buildRouteParams(loadedPost, this.sectionId));
            }

            // Whats new is temporary. After loading the post, redirect to post slug link
            if (this.$route.name === 'post-viewer-whats-new') {
                this.$router.replace(
                    buildRouteParams(loadedPost, null, {
                        whatsNew: true
                    })
                );
            }
        }
    }
};
</script>
