<!-- @format -->
<template>
    <div v-show="score !== null" class="na-password-strength">
        <template v-if="showIcons">
            <span
                v-if="score >= 4 || (greenlightMinLength && password && password.length >= minLength)"
                class="icon password-good-icon icon-in-input"
                data-toggle="tooltip"
                data-placement="top"
                title="Password is valid"
                ><fa-icon icon="check"
            /></span>
            <span
                v-else
                class="icon password-bad-icon icon-in-input"
                data-toggle="tooltip"
                data-placement="top"
                title="Password is too short/easy to guess"
                ><fa-icon icon="times"
            /></span>
        </template>
        <progress class="progress" :max="maxScore + 1" :value="score + 1" :class="progressBarClass" />
        {{ scoreNote() }}
    </div>
</template>

<script>
import debounce from 'lodash-es/debounce';
import { scorePassword } from '@/services/user';

export default {
    props: {
        minScore: {
            type: Number,
            default: 0
        },
        maxScore: {
            type: Number,
            default: 4
        },
        badScore: {
            type: Number,
            default: 1
        },
        okayScore: {
            type: Number,
            default: 3
        },
        goodScore: {
            type: Number,
            default: 4
        },
        minLength: {
            type: Number,
            default: 6
        },
        password: {
            type: String,
            default: ''
        },
        // Greenlight when password is at least minLength
        greenlightMinLength: {
            type: Boolean,
            default: false
        },
        showIcons: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            score: null // Current value
        };
    },

    computed: {
        /**
         * Return progress bar class
         *
         * @return {String} Class name
         */
        progressBarClass() {
            switch (this.status()) {
                case 'okay':
                    return 'is-warning';
                case 'good':
                    return 'is-success';
                default:
                    return 'is-danger';
            }
        }
    },

    watch: {
        /**
         * Password has changed, calculate strength, only after 500 miliseconds of inactivity
         *
         * @param {mixed} newVal New value
         * @param {mixed} oldVal Old value
         */
        password: debounce(async function (newVal) {
            if (newVal === null || !String(newVal).length) {
                this.score = null;
            } else {
                const response = await scorePassword(newVal);
                if (response.success) {
                    this.score = response.score;
                }
            }
        }, 500)
    },

    methods: {
        /**
         * Return status of password strength
         *
         * @return {String} 'bad', 'okay' or 'good'
         */
        status() {
            let result = 'bad';

            if (this.password && this.password.length >= this.minLength) {
                if (this.score >= this.okayScore) {
                    result = 'okay';
                }
                if (this.score >= this.goodScore) {
                    result = 'good';
                }
            } else {
                result = 'short';
            }

            return result;
        },
        /**
         * Return a note indicating the score
         *
         * @return {String} Note
         */
        scoreNote() {
            switch (this.status()) {
                case 'short':
                    return (
                        'Password is short, need at least ' +
                        (this.minLength - (this.password ? this.password.length : 0)) +
                        ' more characters'
                    );
                case 'bad':
                    return 'Password is Easy to Guess';
                case 'okay':
                    return 'Password is Okay';
                case 'good':
                    return 'Password is Good';
                default:
                    return '';
            }
        }
    }
};
</script>
