<!-- @format -->
<template>
    <div v-if="isLoaded && isShow" class="is-flex is-flex-direction-column">
        <div v-if="subscriptions && subscriptions.length" class="is-flex is-flex-direction-column">
            <p>Your current subscriptions</p>
            <table class="table is-fullwidth">
                <thead>
                    <tr>
                        <th>publication</th>
                        <th>type</th>
                        <th>length</th>
                        <th>start</th>
                        <th>end</th>
                        <th class="has-text-centered">renews</th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="subscription in subscriptions"
                        :key="subscription.id"
                        :class="{ 'na-subscription-expired': subscription.isExpired }"
                    >
                        <!-- <td>{{ subscription.id }}</td> -->
                        <td>{{ subscription.publication.name }}</td>
                        <td
                            v-if="
                                subscription.publication_subscription_term &&
                                subscription.publication_subscription_term.subscription_product_type
                            "
                        >
                            {{ subscription.publication_subscription_term.subscription_product_type.value }}
                            <span v-if="subscription.is_complimentary">&nbsp;(complimentary)</span>
                        </td>
                        <td v-else>&nbsp;</td>
                        <td
                            v-if="
                                subscription.publication_subscription_term &&
                                subscription.publication_subscription_term.subscription_term
                            "
                        >
                            {{ subscription.publication_subscription_term.subscription_term.value }}
                        </td>
                        <td v-else>&nbsp;</td>
                        <td v-if="subscription.start_on">{{ formatStandardDate(subscription.start_on) }}</td>
                        <td v-if="!subscription.start_on"></td>
                        <td v-if="subscription.expire_on">{{ formatStandardDate(subscription.expire_on) }}</td>
                        <td v-if="!subscription.expire_on">(never)</td>
                        <td v-if="subscription.is_auto_renew" class="has-text-centered">
                            <input type="checkbox" disabled checked />
                        </td>
                        <td v-else-if="!subscription.is_auto_renew" class="has-text-centered">Expiring</td>
                        <td v-else>
                            <!-- Shouldn't really happen, but handle in case -->
                            &nbsp;
                        </td>
                    </tr>
                </tbody>
            </table>
            <p>Call 845-647-9190 for questions or changes to your subscriptions.</p>
        </div>

        <div v-else class="columns">
            <div class="column">
                <p>You currently do not have any subscriptions</p>
                <p>&nbsp;</p>
            </div>
        </div>

        <div v-if="errors && errors.length" class="columns">
            <div class="column">
                <show-error :errors="errors"></show-error>
            </div>
        </div>

        <div v-if="messages && messages.length" class="columns">
            <div class="column">
                <show-success :messages="messages"></show-success>
            </div>
        </div>

        <div class="my-3">
            <hr class="featured-divider" />
        </div>
        <subscription-publications-block />
    </div>
</template>

<script>
import { dateMixin } from '@/mixins/DateMixin.js';
import { postMixin } from '@/mixins/PostMixin.js';
import { readSubscriptions } from '@/services/subscription.js';
import ShowError from '@/components/Common/Error.vue';
import ShowSuccess from '@/components/Common/Success.vue';
import SubscriptionPublicationsBlock from '@/components/Common/Subscription/PublicationsBlock.vue';

export default {
    components: {
        ShowError,
        ShowSuccess,
        SubscriptionPublicationsBlock
    },

    mixins: [dateMixin, postMixin],

    props: {
        level: {
            type: Number,
            default: function () {
                return 0;
            }
        }
    },

    data() {
        return {
            errors: [],
            messages: [],
            isLoaded: false,
            isShow: true,
            subscriptions: []
        };
    },

    watch: {
        /**
         * "level" changed - force a refresh of the list
         */
        level() {
            this.load();
        }
    },

    mounted() {
        this.load();
    },

    methods: {
        /**
         * Load initial data
         */
        async load() {
            this.isLoaded = false;
            this.errors = [];
            this.messages = [];
            this.subscriptions = [];
            try {
                const response = await readSubscriptions();
                if (!response.success) {
                    this.errors = response.errors;
                } else {
                    // Don't show subscriptions that are not auto-renew and are expired
                    this.subscriptions = response.subscriptions.filter(
                        (item) => !(!item.is_auto_renew && item.isExpired)
                    );
                }
                this.isLoaded = true;
            } catch (error) {
                this.isLoaded = true;
            }
        },

        /**
         * User wants to add a subscription
         */
        addSubscription() {
            this.$emit('add-subscription');
        }
    }
};
</script>
