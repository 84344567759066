<!-- @format -->
<template>
    <div class="modal is-active" :class="classMain">
        <div class="modal-background" :class="classBackground"></div>
        <div class="modal-content" :class="classContent">
            <slot />
        </div>
        <slot name="outer-content"></slot>
        <button v-if="showCloseButton" class="modal-close" :class="classButton" @click="$emit('close')"></button>
    </div>
</template>

<script>
export default {
    props: {
        // Additional main class
        classMain: {
            type: [String, Object, Array],
            default: function () {
                return null;
            }
        },
        // Additional background class
        classBackground: {
            type: [String, Object, Array],
            default: function () {
                return null;
            }
        },
        // Additional content class
        classContent: {
            type: [String, Object, Array],
            default: function () {
                return null;
            }
        },
        // Additional button class
        classButton: {
            type: [String, Object, Array],
            default: function () {
                return null;
            }
        },
        showCloseButton: {
            type: Boolean,
            default: true
        }
    }
};
</script>
