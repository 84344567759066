/**
 * @format
 * Media related mixins
 */

export const mediaMixin = {
    methods: {
        /**
         * Return base URL for media in case I want to remap it
         *
         * @returns {String}
         */
        mediaBaseUrl() {
            return import.meta.env.VITE_REHOME_MEDIA_URL
                ? import.meta.env.VITE_REHOME_MEDIA_URL
                : window.naRoutes.root;
        },
        /**
         * Return URL to media file
         *
         * @param {Object} media Media
         *
         * @return {String} Path to media
         */
        mediaUrl(media) {
            return media ? this.mediaBaseUrl() + '/storage/' + media.url : '';
        },

        getPostMedia(media, iType = 'med', excludeMime = ['image/webp']) {
            const generated = (media && media.generated_images)
                ? media.generated_images?.find(
                    (image) =>
                        image.type === iType &&
                        excludeMime.indexOf(image.mime) === -1
                )
                : null;
            if (generated) {
                return {
                    width: generated.width,
                    height: generated.height,
                    url: `${this.mediaBaseUrl()}/storage/${generated.path}`
                };
            }
            return {
                width: media.width,
                height: media.height,
                url: `${this.mediaBaseUrl()}/storage/${media['url_' + iType]}`
            };
        },

        /**
         * Return URL to post media file
         *
         * @param {Object} media       Media
         * @param {mixed}  iType       Image type (e.g. thbs for thumbnail small); null (default) returns full image
         * @param {array}  excludeMime Array of media to be excluded
         *
         * @return {String|null} Path to media
         */
        postMediaUrl(media, iType = null, excludeMime = ['image/webp']) {
            if (media) {
                if (iType && media.generated_images) {
                    const generated = media.generated_images.find(
                        (image) =>
                            image.type === iType &&
                            excludeMime.indexOf(image.mime) === -1
                    );
                    if (generated) {
                        return `${this.mediaBaseUrl()}/storage/${generated.path}`;
                    }
                }
                const check = iType === null ? 'url' : 'url_' + iType;
                const url = media[check] ? media[check] : media.url;
                return this.mediaBaseUrl() + '/storage/' + url;
            } else {
                return null;
            }
        },

        postMediaUrlSet(media, options = {}) {
            const pixelated = options.pixelated ?? false;
            const includeMedium = options.includeMedium ?? true;
            const includeLarge = options.includeLarge ?? false;
            const imageTypes = pixelated ? ['pixs'] : ['sml'];
            if (includeMedium && !pixelated) {
                imageTypes.push('med');
            }
            if (includeLarge && !pixelated) {
                imageTypes.push('lrg');
            }
            const mimeMap = {
                'image/webp': [],
                'image/jpeg': [],
                'image/png': []
            };
            media.generated_images.forEach((image) => {
                if (imageTypes.includes(image.type)) {
                    mimeMap[image.mime].push(
                        `${this.mediaBaseUrl()}/storage/${image.path} ${
                            image.width
                        }w`
                    );
                }
            });
            const imageSet = [];
            for (const mime in mimeMap) {
                if (!mimeMap[mime].length) {
                    continue;
                }
                imageSet.push({
                    mime,
                    srcset: mimeMap[mime].join(', ')
                });
            }
            return imageSet;
        },

        /**
         * Return caption for media
         *
         * @param {Object} media          Media
         * @param {String} defaultCaption Caption to use if media doesn't have one (null)
         *
         * @return {String} Caption
         */
        mediaCaption(media, defaultCaption = null) {
            return media && media.caption ? media.caption : defaultCaption;
        },
        /**
         * Return alt tag value for media
         *
         * @param {Object} media        Media
         * @param {String} defaultValue Default to use
         *
         * @return {String} Caption
         */
        mediaAlt(media, defaultValue = 'Courtesy') {
            return media.alt_text ?? media.caption ?? defaultValue;
        },
        /**
         * Return title tag value for media
         *
         * @param {Object} media        Media
         * @param {String} defaultValue Default to use
         *
         * @return {String} Title
         */
        mediaTitle(media, defaultValue = 'Image') {
            return media && media.caption ? media.caption : defaultValue;
        },
        /**
         * Return credit for media
         *
         * @param {Object} media         Media
         * @param {String} defaultCredit Default credit to use if media doesn't have one (null)
         *
         * @return {String} Credit
         */
        mediaCredit(media, defaultCredit = 'Courtesy') {
            if (media.credit) {
                let result = '';
                result += media.credit.first_name
                    ? ' ' + media.credit.first_name
                    : '';
                result += media.credit.last_name
                    ? ' ' + media.credit.last_name
                    : '';
                if (result.length) {
                    // If we already have part of name, then comma before organization
                    result += media.credit.organization
                        ? ', ' + media.credit.organization
                        : '';
                } else {
                    result += media.credit.organization
                        ? ' ' + media.credit.organization
                        : '';
                }
                return result.replace(/^\s+/, '');
            } else {
                return defaultCredit;
            }
        },
        /**
         * Return URL to image file
         *
         * @param {String} image Image file
         * @param {String} path  Prefix path, default is 'media/images/';
         *
         * @return {String} Path to media
         */
        imageUrl(image, path = 'media/images/') {
            return (
                this.mediaBaseUrl() + '/' + (path !== null ? path : '') + image
            );
        },

        getMediaSourceSet(media) {
            return media.generated_images.map((image) => ({
                url: `${this.mediaBaseUrl()}/storage/${image.path}`,
                mime: image.mime
            }));
        }
    }
};
