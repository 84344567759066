<!-- @format -->
<template>
    <div class="is-flex is-flex-direction-column">
        <what-is-this v-if="currentForm === 'whatIsThis'" @signup="currentForm = 'signup'" />

        <signup
            v-if="currentForm === 'signup'"
            :header-text="headerText"
            :form-data.sync="formData"
            :errors="errors"
            :input-errors="inputErrors"
            :is-busy="isBusy"
            :button-text="buttonText"
            :show-header="showHeader"
            :show-social="showSocial"
            :show-divider="showDivider"
            :show-extra-info="showExtraInfo"
            :align-social="alignSocial"
            @register="doRegister"
            @register-social="doLoginSocial"
            @more-info-click="currentForm = 'whatIsThis'"
        />

        <retry-with-password
            v-if="showPasswordPrompt"
            :password.sync="retryData.password"
            :is-busy="isBusy"
            :email="interimEmail"
            :social-type="interimSocialType"
            :errors="retryErrors"
            @retry="retryLogin"
            @close="showPasswordPrompt = false"
        />

        <retry-with-accept-tc
            v-if="showAcceptTcPrompt"
            :accept-tc.sync="retryData.acceptTc"
            :is-busy="isBusy"
            :errors="retryErrors"
            @retry="retryLogin"
            @close="showAcceptTcPrompt = false"
        />
    </div>
</template>

<script>
import { analyticsEventFireNewReader } from '@/services/analytics_events';
import Signup from './Signup.vue';
import RetryWithPassword from './RetryWithPassword.vue';
import RetryWithAcceptTc from './RetryWithAcceptTc.vue';
import WhatIsThis from './WhatIsThis.vue';
import { userLoginSocial, userRegister } from '@/services/user';

export default {
    components: {
        Signup,
        WhatIsThis,
        RetryWithPassword,
        RetryWithAcceptTc
    },

    props: {
        headerText: {
            type: String,
            default: ''
        },
        initialEmail: {
            type: String,
            default: ''
        },
        buttonText: {
            type: String,
            default: ''
        },
        showHeader: {
            type: Boolean,
            default: true
        },
        showSocial: {
            type: Boolean,
            default: true
        },
        alignSocial: {
            type: String,
            default: 'center'
        },
        showExtraInfo: {
            type: Boolean,
            default: true
        },
        showDivider: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            isBusy: false,
            currentForm: 'signup',
            formData: {
                formDataEmail: {
                    email: this.initialEmail
                }
            },
            errors: [],
            inputErrors: [],
            retryData: {
                password: '',
                acceptTc: false
            },
            retryCallback: null,
            retryErrors: [],
            showPasswordPrompt: false,
            showAcceptTcPrompt: false,
            showRegister: true,
            interimEmail: null,
            interimSocialType: null
        };
    },

    methods: {
        async doRegister() {
            const data = {
                firstName: this.formData.formDataName.firstName,
                lastName: this.formData.formDataName.lastName,
                email: this.formData.formDataEmail.email.toLowerCase(),
                password: this.formData.formDataPassword.password,
                password_confirmation: this.formData.formDataPassword.passwordConfirmation,
                acceptTc: this.formData.formDataTerms.acceptTc,
                registerPublicationId:
                    this.userRecentPost && this.userRecentPost.post ? this.userRecentPost.post.publication_id : null
            };
            this.errors = [];
            this.inputErrors = [];
            this.isBusy = true;
            const response = await userRegister(data);
            this.isBusy = false;
            if (!response.success) {
                this.errors = response.errors ?? [];
                this.inputErrors = response.inputErrors ?? [];
                const existingEmail = this.inputErrors.find(
                    (error) => error === 'email|Email address is already in use'
                );
                if (existingEmail) {
                    this.$emit('email-in-use', this.formData.formDataEmail.email);
                }
            } else {
                analyticsEventFireNewReader();
                this.$emit('loggedIn');
            }
        },

        async doLoginSocial(socialType) {
            this.isBusy = true;
            const data = {
                registerPublicationId:
                    this.userRecentPost && this.userRecentPost.post ? this.userRecentPost.post.publication_id : null
            };
            try {
                const response = await userLoginSocial(socialType, data);
                if (response.passwordRequired) {
                    this.retryCallback = response.retryCallback;
                    this.interimSocialType = response.socialType;
                    this.interimEmail = response.email;
                    this.showPasswordPrompt = true;
                    this.isBusy = false;
                }
                if (response.acceptTcRequired) {
                    this.retryCallback = response.retryCallback;
                    this.interimSocialType = response.socialType;
                    this.showAcceptTcPrompt = true;
                    this.isBusy = false;
                }
                if (!response.success) {
                    this.errors = response.errors ?? [];
                    this.inputErrors = response.inputErrors ?? [];
                } else {
                    this.$emit('loggedIn');
                }
                this.isBusy = false;
            } catch (error) {
                if (error.message === 'cancelled') {
                    this.isBusy = false;
                } else {
                    this.errors = ['There was a problem with the sign up process.'];
                    this.isBusy = false;
                    throw error;
                }
            }
        },

        async retryLogin() {
            this.isBusy = true;
            const response = await this.retryCallback(this.retryData);
            if (!response.success) {
                this.retryErrors = response.errors ?? [];
            } else {
                this.$emit('loggedIn');
            }
            this.isBusy = false;
        },

        close() {
            this.showPasswordPrompt = false;
            this.showRegister = false;
            this.$emit('close');
        }
    }
};
</script>
