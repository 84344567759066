/**
 * @format
 */

import get from 'lodash-es/get';
import { formatDate, toDate } from '@/helpers/date.js';

export function slugify(text) {
    return text
        .trim()
        .toLowerCase()
        .replace(/[^a-z0-9\s]/g, '')
        .replace(/\s/g, '-');
}

export function buildRouteParams(post, sectionId = null, options = {}) {
    const pubDate = toDate(post.content_at);
    const type = post.post_type.key;
    const category = get(post, 'post_categories[0].category.value', 'news');
    if (!post.post_identifiers.length) {
        console.log('DEBUG: buildRouteParams, no identifier found for post ', post);
    }
    const routeParams = {
        name: 'post-viewer',
        params: {
            sectionRoute: post.section_route ? post.section_route : 'article',
            postType: slugify(post.post_type.value),
            postCategory: slugify(category),
            year: formatDate(pubDate, 'YYYY'),
            month: formatDate(pubDate, 'MM'),
            day: formatDate(pubDate, 'DD'),
            titleSlug: type === 'postTypeNativeAd' ? 'ad' : slugify(post.title),
            identifier: (Array.isArray(post.post_identifiers) && post.post_identifiers.length) ? post.post_identifiers[0].identifier : null
        },
        query: {}
    };
    if (sectionId) {
        routeParams.query.section_id = sectionId;
    }

    if (options.openComments) {
        routeParams.query.open_comments = 1;
    }

    if (options.forceIgnorePreferences) {
        routeParams.query.force_ignore_preferences = 1;
    }

    if (options.query) {
        routeParams.query.query = options.query;
    }

    if (options.sectionRoute) {
        routeParams.query.section_route = options.sectionRoute;
    }

    if (options.open) {
        routeParams.query.open = 1;
    }
    if (options.whatsNew) {
        routeParams.query.whats_new = 1;
    }
    if (options.isHvNews) {
    //    routeParams.query.is_hvnews = true;  Not using yet?
    }
    return routeParams;
}
