<!-- @format -->
<template>
    <article v-if="post" ref="postContainer" class="py-2 px-4" :class="{ noselect: !selectAllowed }">
        <div class="is-flex is-align-items-center is-justify-content-space-between">
            <div class="is-flex na-post-meta-header is-align-items-center">
                <span
                    v-if="isPostType(post, 'postTypeEvent')"
                    class="icon na-post-meta-header-post-type"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Event"
                    ><fa-icon :icon="['fac', 'ticket-old']" />
                </span>
                <span v-if="isShowContentAt(post)" class="na-post-meta-date">
                    <time
                        v-if="
                            hasDateInstances(post) &&
                            hasDataSupplemental &&
                            firstUpcomingDateInstance(dataSupplemental.instances)
                        "
                        :datetime="firstUpcomingDateInstance(dataSupplemental.instances).theDate"
                    >
                        {{ postEventInstanceDate(firstUpcomingDateInstance(dataSupplemental.instances)) }}
                    </time>
                    <time v-else :datetime="post.content_at">
                        <span v-if="isPostType(post, 'postTypePodcast')" class="icon">
                            <fa-icon icon="podcast" />
                        </span>
                        <span v-else-if="isPostType(post, 'postTypeNewscast')" class="icon">
                            <fa-icon icon="headphones" />
                        </span>
                        {{ postContentAt }}
                    </time>
                </span>
                <span v-if="isShowPostType(post)" class="na-post-meta-post-type"
                    ><span v-if="isShowContentAt(post)" class="mx-1">&bull;</span>{{ post.post_type.value }}</span
                >
                <template v-if="isShowPostCategory(post)">
                    <template v-if="post.post_classifieds && describePostClassifiedCategories(post.post_classifieds)">
                        <span class="mx-1">&bull;</span>
                        <span>{{ describePostClassifiedCategories(post.post_classifieds) }}</span>
                    </template>
                    <template v-if="post.post_events && describePostEventCategories(post.post_events)">
                        <span class="mx-1">&bull;</span>
                        <span>{{ describePostEventCategories(post.post_events) }}</span>
                    </template>
                    <template
                        v-if="
                            post.post_categories &&
                            !post.post_classifieds &&
                            !post.post_events &&
                            describePostCategories(post.post_categories)
                        "
                    >
                        <span class="mx-1">&bull;</span>
                        <span>{{ describePostCategories(post.post_categories) }}</span>
                    </template>
                </template>
                <fa-layers
                    v-if="postCommentCount > 0"
                    class="fa-2x ml-4"
                    data-toggle="tooltip"
                    data-placement="top"
                    :title="commentTooltip"
                >
                    <fa-icon class="na-post-comment-icon" transform="shrink-2" :icon="['fac', 'comment-old']" />
                    <fa-layers-text
                        class="na-post-comment-count"
                        transform="shrink-9.2 up-1"
                        :value="postCommentCount"
                    />
                </fa-layers>
            </div>
            <cost-corner v-if="userIsLoggedIn" :post="post" @purchase="purchase" />
        </div>

        <publication-banner
            v-if="isShowPublicationBanner(post) && doesPublicationHaveMediaLogo(post)"
            :post="post"
        />

        <h2 v-if="isPostSponsored(post)" class="na-sponsored">SPONSORED</h2>

        <h1 v-if="isShowHeadline(post)" ref="headlineWrapper" class="na-post-headline-wrapper">
            <span
                class="na-post-headline"
                :class="{ 'na-sponsored': isPostSponsored(post) }"
            >
                {{ theHeadline }}
            </span>
            <span v-if="hasPostWhatIsThis(post)">
                &nbsp;<span
                    class="icon na-link na-what-is-this-icon"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="What is this?"
                    @click="showWhatIsThis = !showWhatIsThis"
                    ><fa-icon icon="question-circle"
                /></span>
            </span>
        </h1>

        <show-success
            v-if="showWhatIsThis"
            :message="post.what_is_this"
            notification-class="na-what-is-this-box"
            @closed="showWhatIsThis = false"
        />

        <div v-if="post.subtitle && isShowSubhead(post)" class="na-post-subhead-wrapper">
            <h2
                class="na-post-subhead"
                :class="{ 'na-sponsored': isPostSponsored(post) }"
            >
                {{ theSubhead }}
            </h2>
        </div>

        <div class="is-flex my-2">
            <span v-if="isShowPostTypeLabel" class="post-type-label">{{ post.post_type.value2 }}&nbsp;</span>
            <post-author
                v-if="post.post_authors && isShowAuthor(post)"
                :post="post"
            />
        </div>

        <div class="na-post-share-wrapper">
            <div v-if="isShowShareIcons(post)">
                <share-post :post="post" share-to="facebook" />
                <share-post :post="post" share-to="twitter" />
                <share-post :post="post" share-to="email" />
                <share-post :post="post" share-to="clipboard" />
            </div>

            <div class="na-post-media-count-wrapper">
                <video-count
                    :media="post.post_media_videos"
                    :minus-one="postShowVideo(post, true, false)"
                    @clicked="doScrollReadMore"
                />

                <image-count :media="post.media" :minus-one="showImage" @clicked="doScrollReadMore" />
            </div>
        </div>

        <post-media v-if="showImage" :post="post" />

        <video-preview
            v-if="postShowVideo(post, true, false)"
            :video="post.post_media_videos[0]"
            :is-show-caption="isShowCaptionCard(post)"
            :show-video="isShowVideo"
        />

        <p v-if="theSnippet" class="na-post-preview-snippet py-2" v-html="theSnippet"></p>

        <div
            v-if="postIsShowPaywallContent(post)"
            class="na-paywall-content"
            :class="{ 'na-hidden': !isVisitorCrawler(crawlerCheck) }"
            aria-hidden="true"
            v-html="postPaywallContent(post)"
        ></div>

        <div ref="readMore" class="has-text-centered py-4 is-tablet">
            <p
                v-if="
                    isFlashReadMore && !postCostIsFree(post) && !postCostIsPaid(post) && !postCostIsSubscription(post)
                "
                class="has-text-centered na-post-preview-read-comments"
            >
                To read comments, please purchase the post.
            </p>

            <div v-if="!userIsLoggedIn" class="columns">
                <div class="column na-post-preview-cta">
                    <div class="is-hidden-mobile">
                        <button type="button" class="button na-post-read-more" @click="purchase" alt="Get Your FREE 30-day Trial and Keep Reading">
                            Get Your FREE 30-day Trial & Keep Reading!
                            <img :src="publicFileUrl('media/images/bookicon-trans.png')" alt="Read" title="Read" />
                        </button>

                        <p>
                            Your <strong>FREE Trial</strong> starts with a complimentary
                            <strong>$1 credit</strong> valid for 30 days.
                        </p>
                        <p>Good for all NewsAtomic articles!</p>
                    </div>

                    <div class="is-hidden-tablet">
                        <button type="button" class="button na-post-read-more" @click="purchase" alt="Get Your FREE 30-day Trial and Keep Reading">
                            Get Your FREE 30-day Trial<br />
                            & Keep Reading!
                            <img
                                :src="publicFileUrl('media/images/bookicon-trans.png')"
                                alt="Read the post"
                                title="Read the post"
                            />
                        </button>

                        <p>
                            Your <strong>FREE Trial</strong> starts with a complimentary
                            <strong>$1&nbsp;credit</strong> valid for 30 days. Good for all NewsAtomic articles!
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="userIsLoggedIn" class="columns">
                <div class="column na-post-preview-cta is-offset-1 is-10">
                    <div>
                        <button type="button" class="button is-light is-hidden-mobile na-post-read-more" @click="purchase" alt="Read More">
                            <cost-read-more :post="post" />
                            {{ describePostReadMore(post) }}
                            <span v-if="isPostType(post, 'postTypeNewscast')" class="cta-icon" title="Listen">
                                <fa-icon icon="headphones" size="lg" />
                            </span>
                            <span v-else-if="isPostType(post, 'postTypePodcast')" class="cta-icon" title="Listen">
                                <fa-icon icon="podcast" size="lg" />
                            </span>
                            <img
                                v-else
                                :src="publicFileUrl('media/images/bookicon-trans.png')"
                                alt="Read the full post"
                                title="Read the full post"
                            />
                        </button>

                        <button type="button" class="button is-light is-hidden-tablet na-post-read-more" @click="purchase" alt="Read More">
                            <cost-read-more :post="post" />
                            Read
                            <span v-if="isPostType(post, 'postTypeNewscast')" class="cta-icon" title="Listen">
                                <fa-icon icon="headphones" />
                            </span>
                            <span v-else-if="isPostType(post, 'postTypePodcast')" class="cta-icon" title="Listen">
                                <fa-icon icon="podcast" />
                            </span>
                            <img
                                v-else
                                :src="publicFileUrl('media/images/bookicon-trans.png')"
                                alt="Read the full post"
                                title="Read the full post"
                            />
                        </button>
                    </div>

                    <subscribe-to
                        v-if="canSubscribeToPublication"
                        :post="post"
                        @clicked-subscribe="clickedAddSubscription"
                    />
                </div>
            </div>
        </div>

        <div class="na-post-meta-footer my-3">
            <p v-if="post.organization">Organization: {{ post.organization }}</p>
            <span v-if="hasPostLocation(post)">{{ describePostLocation(post) }}</span>
            <template v-if="getPostWordCount(post) !== null">
                <span class="ml-1">&bull;</span>
                <span class="ml-1">{{ getPostWordCount(post) }}</span>
            </template>
            <template v-if="isShowMediaCount(post) && getPostImageCount(post) !== null">
                <span class="ml-1">&bull;</span>
                <span class="ml-1">{{ getPostImageCount(post) }}</span>
            </template>
        </div>
    </article>
</template>

<script>
import { mapGetters } from 'vuex';
import mixin from './mixin.js';
import { editorMixin } from '@/mixins/EditorMixin.js';
import { miscMixin } from '@/mixins/MiscMixin.js';
import { moneyMixin } from '@/mixins/MoneyMixin.js';
import { publicationMixin } from '@/mixins/PublicationMixin.js';
import CostCorner from '@/components/Content/CostCorner/Index.vue';
import CostReadMore from '@/components/Content/CostReadMore/Index.vue';
import ImageCount from '@/components/Content/Images/Count/Index.vue';
import SharePost from '@/components/Content/Share/Index.vue';
import ShowSuccess from '@/components/Common/Success.vue';
import SubscribeTo from '@/components/Content/SubscribeTo/Index.vue';
import VideoCount from '@/components/Content/Videos/Count/Index.vue';
import VideoPreview from '@/components/Content/Videos/Preview/Index.vue';
import PostMedia from './PostMedia.vue';
import PostAuthor from './PostAuthor.vue';
import PublicationBanner from './PublicationBanner.vue';
import Hammer from 'hammerjs';

export default {
    name: 'PostPreview',

    components: {
        CostCorner,
        CostReadMore,
        ImageCount,
        SharePost,
        ShowSuccess,
        SubscribeTo,
        VideoCount,
        VideoPreview,
        PostMedia,
        PostAuthor,
        PublicationBanner
    },

    mixins: [mixin, editorMixin, miscMixin, moneyMixin, publicationMixin],

    props: {
        post: {
            type: Object,
            default: function () {
                return null;
            }
        },
        optionPostTypes: {
            type: Array,
            default: function () {
                return [];
            }
        },
        isJustPurchased: {
            type: Boolean,
            default: function () {
                return false;
            }
        },
        crawlerCheck: {
            type: [Object, Array],
            default: function () {
                return [];
            }
        },
    },
    data() {
        return {
            cost: null,
            dataSupplemental: {},
            hasDataSupplemental: false,
            postType: null,
            showWhatIsThis: false,
            isFlashReadMore: false,
            showReadMoreTooltip: true,
            isShowVideo: false,
            isShowWhereMoneyGoes: false
        };
    },

    computed: {
        ...mapGetters({
            postCommentCount: 'viewer/postCommentCount'
        }),
        isShowWhereMoneyGoesLink() {
            if (this.post && this.post.publication) {
                return (
                    !this.postCostIsPaid(this.post) &&
                    !this.postCostIsFree(this.post) &&
                    !this.postCostIsSubscription(this.post) &&
                    (this.publicationIsShawangunkJournal(this.post.publication.id) ||
                        this.publicationIsDevilsAdvocate(this.post.publication.id))
                );
            } else {
                return false;
            }
        },
        readMoreTooltip() {
            return this.showReadMoreTooltip ? 'Click to Read More' : null;
        },
        commentTooltip() {
            if (this.post) {
                return (
                    this.postCommentCount +
                    ' comment' +
                    (!this.postCommentCount || this.postCommentCount > 1 ? 's' : '')
                );
            }
            return '';
        },
        theHeadline() {
            return this.post.title;
        },
        theSubhead() {
            return this.post.subtitle;
        },
        theSnippet() {
            if (this.isShowPostSnippet(this.post)) {
                return this.postPreviewSnippet(this.post);
            } else {
                return null;
            }
        },
        canSubscribeToPublication() {
            if (this.userIsLoggedIn && this.post && this.post.publication && this.post.publication.subscription_terms) {
                return (
                    this.post.publication.subscription_terms.findIndex((subscriptionTerm) => {
                        return (
                            subscriptionTerm.subscription_term &&
                            subscriptionTerm.subscription_term.key === 'subscriptionTypeMonthly'
                        );
                    }) >= 0
                );
            } else {
                return false;
            }
        },
        postImage() {
            return this.post?.media?.find((media) => media.media_type_id === 31);
        },
        showImage() {
            return this.postShowImage(this.post);
        }
    },

    mounted() {
        if (this.clientAllowSwipe) {
            const hammer = new Hammer(this.$refs.postContainer, {
                recognizers: [[Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }]]
            });
            hammer.on('swipeleft', this.swipeNext);
            hammer.on('swiperight', this.swipePrev);
        }

        if (this.post) {
            this.postType = this.getPostTypeValue(this.optionPostTypes, this.post.post_type_id);
            if (this.postType !== null) {
                const dataSupplemental = this.setSupplementalData(this.post, this.postType);
                if (dataSupplemental !== null) {
                    Object.assign(this.dataSupplemental, dataSupplemental);
                    this.hasDataSupplemental = true;
                }
            }
            this.$nextTick(() => {
                if (this.post.post_media_videos && this.post.post_media_videos.length) {
                    this.isShowVideo = true;
                }
            });
        }

        if (this.postIsShowPaywallContent(this.post)) {
            this.setStructureData();
        }
    },

    methods: {
        purchase(optionalArg = null) {
            this.$emit('purchase', optionalArg);
        },

        comment() {
            this.$emit('comment');
        },

        doShowComments() {
            this.$nextTick(() => {
                if (this.post._cost === null || this.post._cost === 0) {
                    this.purchase('showComments');
                } else {
                    this.isFlashReadMore = true;
                    this.$refs.readMore.scrollIntoView();
                    this.showReadMoreTooltip = true;
                }
            });
        },

        clickedAddSubscription() {
            this.$emit('clicked-add-subscription', true);
        },

        swipePrev() {
            this.$emit('prev');
        },

        swipeNext() {
            this.$emit('next');
        },

        doScrollReadMore() {
            this.$nextTick(() => {
                this.$refs.readMore.scrollIntoView();
            });
        }
    }
};
</script>
