<!-- @format -->
<template>
    <div class="na-post-video-meta">
        <span v-if="videoDuration"
            >Duration: <span class="na-post-video-meta-duration">{{ videoDuration }}</span></span
        >
    </div>
</template>

<script>
import { videoMixin } from '@/mixins/VideoMixin.js';

export default {
    mixins: [videoMixin],
    props: {
        videoPlayer: {
            default: function () {
                return null;
            }
        }
    },
    data() {
        return {
            duration: null
        };
    },
    computed: {
        videoDuration() {
            return this.videoFormattedDuration(this.duration);
        }
    },
    watch: {
        videoPlayer() {
            if (this.videoPlayer) {
                this.getMeta();
            }
        }
    },
    methods: {
        /**
         * Get video meta data
         */
        getMeta() {
            const self = this;
            if (this.videoPlayer) {
                this.videoPlayer
                    .getDuration()
                    .then((duration) => {
                        self.duration = duration;
                    })
                    .catch((error) => {
                        self.duration = null;
                    });
            } else {
                self.duration = null;
            }
        }
    }
};
</script>
