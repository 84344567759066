<!-- @format -->
<template>
    <post-hv-news v-if="this.$route.name === 'hvnews'"
        :post="post"
        :show-post-type="showPostType"
        :show-authors="false"
        :show-publication-icon="false"
        :show-publication-name="false"
        :show-content-at="false"
        :show-snippet="false"
        @single-post-view="$emit('single-post-view', $event)"
    >
        <div v-for="(instance, index) in postFirstInstance(dataSupplemental.instances)" :key="index">
            <span class="na-intro-post-event-detail">{{ postEventInstanceDateFull(instance) }}</span>
            <span v-if="instance && instance.theTime" class="na-intro-post-event-detail">
                <span>&bull;</span>
                <span>{{ instance.theTime }}</span>
            </span>
        </div>
    </post-hv-news>

    <post v-else
        :post="post"
        :show-post-type="showPostType"
        :show-authors="false"
        :show-publication-icon="false"
        :show-publication-name="false"
        :show-content-at="false"
        :show-snippet="false"
    >
        <div v-for="(instance, index) in postFirstInstance(dataSupplemental.instances)" :key="index">
            <span class="na-intro-post-event-detail">{{ postEventInstanceDateFull(instance) }}</span>
            <span v-if="instance && instance.theTime" class="na-intro-post-event-detail">
                <span>&bull;</span>
                <span>{{ instance.theTime }}</span>
            </span>
        </div>
    </post>
</template>

<script>
import Post from './Post.vue';
import PostHvNews from './PostHvNews.vue';
import { postMixin } from '@/mixins/PostMixin.js';

export default {
    components: {
        Post,
        PostHvNews
    },
    mixins: [postMixin],
    props: {
        post: {
            type: Object,
            required: true
        },
        showPostType: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            dataSupplemental: {}
        };
    },
    mounted() {
        const dataSupplemental = this.setSupplementalData(this.post, 'postTypeEvent');
        if (dataSupplemental !== null) {
            this.dataSupplemental = dataSupplemental;
        }
    }
};
</script>
