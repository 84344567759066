<!-- @format -->
<template>
    <div class="is-flex is-flex-direction-row is-flex-grow-1 is-align-items-center is-justify-content-center">
        <p v-if="messageIsHtml" class="has-text-centered na-message-bar-message" v-html="message"></p>

        <p v-if="!messageIsHtml" class="is-flex-grow-1 has-text-centered na-message-bar-message">
            <span>{{ message }}</span>
        </p>
        <span
            class="icon is-clickable"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Close message bar"
            @click="$emit('close')"
            ><fa-icon icon="times-circle"
        /></span>
    </div>
</template>

<script>
export default {
    props: {
        messages: {
            type: Array,
            default: () => []
        }
    },

    computed: {
        /**
         * Check if current message is HTML
         * This has been designed to allow multiple messages, but until I get a comp for it, we're just displaying the first message.
         *
         * @param {String} message
         */
        messageIsHtml() {
            return this.messages && this.messages.length && this.messages[0].isHtml;
        },
        /**
         * Return current message
         * This has been designed to allow multiple messages, but until I get a comp for it, we're just displaying the first message.
         *
         * @param {String} message
         */
        message() {
            return this.messages && this.messages.length ? this.messages[0].text : null;
        }
    }
};
</script>
