<!-- @format -->
<template>
    <field name="email" :errors="errors">
        <div class="control">
            <input v-model.trim="email" class="input" type="email" placeholder="Email address" required />
            <validate-username :username="email" :verbose="false" :lookup-type="1" />
        </div>
    </field>
</template>

<script>
import InputError from '@/components/Common/InputError.vue';
import ValidateUsername from '@/components/Common/ValidateUsername.vue';

export default {
    components: {
        InputError,
        ValidateUsername
    },

    props: {
        formData: {
            type: Object,
            required: true
        },
        errors: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            email: this.formData.email ?? ''
        };
    },

    watch: {
        email() {
            this.updateFormData();
        }
    },

    created() {
        this.updateFormData(); // Init
    },

    methods: {
        /**
         * Update form data for parent
         */
        updateFormData() {
            this.$emit('update:formData', {
                email: this.email
            });
        },
        /**
         * Return array of errors for given field
         *
         * @param {String} field Field name
         *
         * @return {Array}
         */
        fieldErrors(field) {
            const errors = [];
            this.errors.forEach((item) => {
                const items = item.split('|');
                if (items && items[0] === field) {
                    errors.push(items[1]);
                }
            });

            return errors;
        },
        /**
         * Determine if any errors
         *
         * @return {Boolean}
         */
        hasErrors() {
            return this.fieldErrors('email').length > 0;
        }
    }
};
</script>
