<!-- @format -->
<template>
    <post-hv-news v-if="this.$route.name === 'hvnews'"
        :post="post"
        :is-top-post="isTopPost"
        :show-post-type="showPostType"
        :show-subtitle="false"
        :show-authors="false"
        :show-snippet="$route.name === 'search'"
        default-top-post-image-path="sections/obit.png"
        @single-post-view="$emit('single-post-view', $event)"
    />
    <post v-else
        :post="post"
        :is-top-post="isTopPost"
        :show-post-type="showPostType"
        :show-subtitle="false"
        :show-authors="false"
        :show-snippet="$route.name === 'search'"
        default-top-post-image-path="sections/obit.png"
    />
</template>

<script>
import Post from './Post.vue';
import PostHvNews from './PostHvNews.vue';

export default {
    components: {
        Post,
        PostHvNews
    },
    props: {
        post: {
            type: Object,
            required: true
        },
        isTopPost: {
            type: Boolean,
            required: true
        },
        showPostType: {
            type: Boolean,
            required: true
        }
    }
};
</script>
