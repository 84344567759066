// @format
import moment from 'moment-timezone';

const timeZone = 'America/New_York';

export function getNow(options = {
    isServerTime: true
}) {
    if (!options.isServerTime) {
        return moment();
    }
    return moment().tz(timeZone);
}

export function toDate(dateString, format = null, options = {
    isServerTime: true
}) {
    if (!options.isServerTime) {
        if (format) {
            return moment(dateString, format);
        }
        return moment(dateString);
    }
    if (format) {
        return moment.tz(dateString, format, timeZone);
    }
    return moment.tz(dateString, timeZone);
}

export function formatDateTime(dateString, format = 'YYYY-MM-DD HH:mm:ss') {
    return toDate(dateString).format(format);
}

export function formatDate(dateString, format = 'YYYY-MM-DD') {
    return toDate(dateString).format(format);
}
