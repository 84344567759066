<!-- @format -->
<template>
    <div class="is-flex is-flex-direction-column mt-2">
        <p class="has-text-left is-size-5 has-text-weight-bold">Tags Editor</p>

        <post-tag
            v-if="isLoaded"
            :publication-id="tagPublicationId"
            :tags-selected="postTags"
            :is-busy="isBusy"
            />

        <button type="button" v-if="!autoUpdate && isDirty" class="button is-success" @click="updateTags">Update post tags</button>
    </div>
</template>

<script>
import PostTag from '@/components/Common/TagPicker/Index.vue';
import { updatePostTags } from '@/services/post';

export default {
    components: {
        PostTag
    },

    props: {
        post: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            errors: [],
            isBusy: false,
            isLoaded: false,
            postTags: [],
            postTagsOriginal: [],
            autoUpdate: true
        };
    },

    computed: {
        tagPublicationId() {
            return (this.post.publication_id === 30 || this.post.publication_id === 31)
                ? 30
                : null;
        },

        isDirty() {
            let isDirty = false;
            if (this.postTags.length !== this.postTagsOriginal.length) {
                isDirty = true;
            } else {
                this.postTags.forEach((postTag) => {
                    isDirty =
                        this.postTagsOriginal.find(
                            (postTagOriginal) => postTagOriginal.id === postTag.id && postTagOriginal.tag
                        ) === undefined;
                });
            }

            return isDirty;
        }
    },

    watch: {
        ['post.id'](val, oldVal) {
            if (val && val !== oldVal) {
                this.setTags();
            }
        },
        isDirty(val) {
            if (this.autoUpdate && val) {
                this.updateTags();
            }
        }
    },

    created() {
        this.isLoaded = false;
        this.setTags();
        this.$nextTick(() => {
            this.isLoaded = true;
        });
    },

    methods: {
        setTags() {
            this.postTags = [];
            this.post.post_tags.forEach((item) => {
                if (item && item.tag) {
                    this.postTags.push({
                        id: item.tag.id,
                        tag: item.tag.tag
                    });
                }
            });
            this.postTagsOriginal = this.postTags.slice();
            return this.postTags;
        },
        async updateTags() {
            this.isBusy = true;
            this.errors = [];
            let data = {
                id: this.post.id,
                publicationId: this.post.publication_id,
                postTags: this.postTags
            };

            try {
                const response = await updatePostTags(data);
                if (!response.success) {
                    if (response.errors) {
                        this.errors = response.errors;
                    }
                } else {
                    this.postTagsOriginal = this.postTags.slice();
                }
                this.isBusy = false;
            } catch (error) {
                this.isBusy = false;
            }
        }
    }
};
</script>
