<!-- @format -->
<template>
    <div class="columns is-mobile is-centered my-0 py-0">
        <div class="column is-half">
            <div class="na-subscription-terms-container-term-type">
                <div
                    v-for="termType in termTypes"
                    :key="termType.id"
                    class="columns is-mobile na-subscription-terms-term-type"
                    :class="{ focus: productType.value === 'subscriptionProductTypeDigitalPrint' }"
                >
                    <div class="column">
                        <label class="radio">
                            <input
                                type="radio"
                                name="termType"
                                :value="termType.id"
                                :checked="selectedTermId === termType.id"
                                @change="termTypeSelected"
                            />
                            <span>
                                {{ formatCost(termType.cost) }}/{{ termTypeFriendlyName(termType.termType) }}
                                <span
                                    v-if="
                                        termType.termType.value === 'subscriptionTypeYearly' &&
                                        savingsYearly(terms, productType)
                                    "
                                    class="na-subscription-terms-term-type-savings"
                                >
                                    (saves {{ formatCost(savingsYearly(terms, productType)) }})
                                </span>
                            </span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { moneyMixin } from '@/mixins/MoneyMixin.js';
import { subscriptionHelperMixin } from '../../mixins/SubscriptionHelperMixin.js';

export default {
    mixins: [moneyMixin, subscriptionHelperMixin],

    props: {
        productType: {
            type: Object,
            required: true
        },
        selectedTermId: {
            type: Number,
            required: true
        },
        terms: {
            type: Array,
            required: true
        }
    },

    computed: {
        /**
         * Return term types for product type
         *
         * @return {Array}
         */
        termTypes() {
            return this.terms.filter((term) => term.productType.id === this.productType.id);
        }
    },

    methods: {
        /**
         * User has selected a term type.
         *
         */
        termTypeSelected(event) {
            this.$emit('update:selectedTermId', Number(event.target.value));
        }
    }
};
</script>
