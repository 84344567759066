<!-- @format -->
<template>
    <div class="na-ad-box-dock has-text-centered">
        <router-link :to="{ name: 'gift-subscription', params: { slug: 'kingston-wire' } }"
            ><img
            class="na-link na-ad-image"
            :src="imageUrl('KWGiftSubs-Ad-250.png')"
            alt="Give the gift of local news!"
            title="Gift the gift of local news!"
            width="250"
            height="250"
            loading="lazy"
        /></router-link>
    </div>
</template>

<script>
import { mediaMixin } from '@/mixins/MediaMixin.js';

export default {
    mixins: [mediaMixin]
};
</script>
