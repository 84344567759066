<!-- @format -->
<template>
    <div>
        <div class="is-flex is-justify-content-space-between mb-3">
            <p class="subtitle standout">Choose the subscription that's right for you!</p>
            <slot name="stepNumber" />
        </div>

        <div class="columns" :class="{ 'is-centered': productTypes && productTypes.length < 3 }">
            <div
                v-for="productType in productTypes"
                :key="productType.value"
                class="column has-text-centered na-subscription-terms-product"
                :class="{
                    'is-one-third': productTypes && productTypes.length < 2,
                    'na-subscription-terms-product-focus':
                        productType && productType.value && productType.value === 'subscriptionProductTypeDigitalPrint'
                }"
            >
                <p
                    class="na-subscription-terms-product-header"
                    :class="{ focus: productType.value === 'subscriptionProductTypeDigitalPrint' }"
                >
                    {{ productTypeHeader(productType) }}
                </p>

                <p class="na-subscription-terms-product-type">
                    {{ productType.text }}
                </p>

                <term-type
                    :selected-term-id="selectedTermId"
                    :product-type="productType"
                    :terms="terms"
                    @update:selectedTermId="$emit('update:selectedTermId', $event)"
                />

                <button
                    class="button is-success"
                    :disabled="!selectedTerm || productType.id !== selectedTerm.productType.id"
                    @click="$emit('continue')"
                >
                    JOIN NOW
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { moneyMixin } from '@/mixins/MoneyMixin.js';
import { publicationMixin } from '@/mixins/PublicationMixin.js';
import TermType from './TermType/Index.vue';

export default {
    components: {
        TermType
    },

    mixins: [moneyMixin, publicationMixin],

    props: {
        selectedTermId: {
            type: Number,
            required: true
        },
        terms: {
            type: Array,
            required: true
        }
    },

    computed: {
        /**
         * Return product types
         *
         * @return {Array}
         */
        productTypes() {
            const productTypes = [];
            this.terms.forEach((term) => {
                const matched = productTypes.find((productType) => {
                    return productType.id === term.productType.id;
                });

                // If we don't have the product type, add it to the list
                if (matched === undefined) {
                    productTypes.push(term.productType);
                }
            });
            return productTypes;
        },
        selectedTerm() {
            return this.terms.find((term) => term.id === this.selectedTermId);
        }
    },

    methods: {
        /**
         * Return product type blurb header
         *
         * @param {Object} productType Product type object
         *
         * @return {mixed} Header or null if we couldn't determine
         */
        productTypeHeader(productType) {
            switch (productType.value) {
                case 'subscriptionProductTypeDigital':
                    return 'Introductory Offer';
                case 'subscriptionProductTypePrint':
                    return 'Classic Offer';
                case 'subscriptionProductTypeDigitalPrint':
                    return 'Best Value!';
                default:
                    return null;
            }
        }
    }
};
</script>
