<!-- @format -->
<template>
    <main class="na-viewer-gradient">
        <template v-if="isEnabledCovidSection">
            <info-message
                v-if="$route.params.sectionRoute !== 'covid-19'"
                :link="sectionCovid19Link"
                message="View All The Latest Coronavirus News >"
                :include-button="false"
            />
            <info-message v-else :link="homeLink" message="< Back to regular coverage" :include-button="false" />
        </template>

        <message-bar :initial-message="initialMessage" />

        <div class="container" :class="{ 'is-fluid': !clientIsMobile }">
            <content-intro />
        </div>
    </main>
</template>

<script>
import ContentIntro from '@/components/Content/Intro/Index.vue';
import InfoMessage from '@/components/Content/InfoMessage/Index.vue';
import MessageBar from '@/components/Common/MessageBar/Index.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        ContentIntro,
        InfoMessage,
        MessageBar
    },

    props: {
        initialMessage: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            // Temporary links
            sectionCovid19Link: `${window.naRoutes.root}/covid-19`,
            homeLink: window.naRoutes.root
        };
    },

    computed: {
        ...mapGetters({
            isEnabledCovidSection: 'publication/mainPublicationHasCovid19'
        })
    },

    metaInfo() {
        const mainPub = this.$store.state.publication.mainPublication;
        const title = mainPub && mainPub._title ? mainPub._title : 'Actual News, Locally grown.';
        return {
            title
        };
    }
};
</script>
