<!-- @format -->
<template>
    <component :is="publicationSubdomainComponent" :icon-class="iconClass" @cancel="close" />
</template>

<script>
import { publicationMixin } from '@/mixins/PublicationMixin.js';
import PublicationDefault from './Publication/Default/Index.vue';
import PublicationKingstonwire from './Publication/Kingstonwire/Index.vue';
import PublicationScDemocrat from './Publication/ScDemocrat/Index.vue';
import PublicationShawangunkJournal from './Publication/ShawangunkJournal/Index.vue';

export default {
    components: {
        PublicationDefault,
        PublicationKingstonwire,
        PublicationScDemocrat,
        PublicationShawangunkJournal
    },

    props: {
        iconClass: {
            type: String,
            default: null,
        }
    },

    mixins: [publicationMixin],

    methods: {
        close() {
            if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage('close');
            } else {
                this.$router.push('/');
            }
        }
    }
};
</script>
