<!-- @format -->
<template>
    <fa-icon icon="circle-notch" spin :size="size" />
</template>

<script>
export default {
    props: {
        size: {
            type: String,
            default: '1x'
        }
    }
};
</script>
