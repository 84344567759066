<!-- @format -->
<template>
    <div class="na-sidebar-dock-container">
        <p class="na-sidebar-section">{{ mainPublicationName }} Sections</p>
        <!-- <p v-if="isAllowSectionManagemenet" class="na-sidebar-section-text">Use ours, or <a @click="openSectionManagement()">build your own</a></p> -->
        <div class="na-sidebar-section-items">
            <!-- <p v-for="section in activeSpecialSections(['userSectionSpecialTypeWhatsNew'])" :key="section.id"> -->
            <!--     <router-link -->
            <!--         class="na-sidebar-section-item" -->
            <!--         :to="{ name: 'post-viewer-section', params: { sectionId: section.id } }" -->
            <!--     > -->
            <!--         <fa-icon class="mr-2" icon="chevron-right" />{{ section.name }}</router-link -->
            <!--     > -->
            <!-- </p> -->

            <p v-for="section in sections" :key="section.id">
                <router-link
                    class="na-sidebar-section-item"
                    :to="{ name: 'section', params: { sectionRoute: section.route } }"
                    ><fa-icon class="mr-2" icon="chevron-right" />{{ section.name }}</router-link
                >
            </p>

            <!-- <template v-if="mainPublication === null"> -->
            <!--     <p v-for="section in activeSpecialSections(['userSectionSpecialTypeAtomNews'])" :key="section.id"> -->
            <!--         <router-link -->
            <!--             class="na-sidebar-section-item na-section-atom-news" -->
            <!--             :to="{ name: 'post-viewer-section', params: { sectionId: section.id } }" -->
            <!--         > -->
            <!--             <fa-icon class="mr-2" icon="chevron-right" />{{ section.name }}</router-link -->
            <!--         > -->
            <!--     </p> -->
            <!-- </template> -->
        </div>

        <!-- <manage-sections -->
        <!--     v-if="isManaging" -->
        <!--     @added="sectionChanged" -->
        <!--     @updated="sectionChanged" -->
        <!--     @removed="sectionChanged" -->
        <!--     @sorted="sectionChanged" -->
        <!--     @cancelled="isManaging = false" -->
        <!-- /> -->
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
// import ManageSections from '../Management/Index.vue';

export default {
    components: {
        // ManageSections
    },

    data() {
        return {
            isManaging: false
        };
    },

    computed: {
        ...mapGetters({
            mainPublicationName: 'publication/mainPublicationName'
        }),
        ...mapState({
            sections: (state) => state.user.sections.data,
            sectionsSpecial: (state) => state.user.sectionsSpecial.data,
            mainPublication: (state) => state.publication.mainPublication
        })
    },

    methods: {
        ...mapActions({
            vuexloadSections: 'user/loadSections'
        }),

        /**
         * Load sections
         */
        async loadSections() {
            const data = {
                includeInactive: true
            };
            await this.vuexloadSections(data);
        },
        /**
         * A section was added, updated or removed, refresh
         */
        sectionChanged() {
            this.loadSections();
        },

        activeSpecialSections(includeOnly = []) {
            return this.sectionsSpecial.filter((item) => {
                if (includeOnly === null || (Array.isArray(includeOnly) && !includeOnly.length)) {
                    return item.is_active;
                } else {
                    return (
                        item.is_active &&
                        item.section_special_type &&
                        includeOnly.find((includeKey) => {
                            return includeKey === item.section_special_type.key;
                        }) !== undefined
                    );
                }
            });
        },

        openSectionManagement() {
            this.isManaging = true;
        }
    }
};
</script>
