/** @format */
import moment from 'moment';

const state = {
    parameters: {
        at: moment().format('YYYY-MM-DD HH:mm:ss'),
        code: null,
        source: null,
        param1: null,
        param2: null
    }
};

const getters = {
    parameters: (state) => state.parameters
    //parameterAge: (state) => state.parameters.at ? moment.duration(moment().diff(state.parameters.at)).seconds() : 0
};

const mutations = {
    setParameter(state, payload) {
        state.parameters[payload.item] = payload.data;
        state.parameters.at = moment().format('YYYY-MM-DD HH:mm:ss');
    }
};

const actions = {
    persist({ state }) {
        // If we already have a persistent code and 'at', then don't update the 'at'.  We want to keep track of the first time that the code was used.  If a different code was used, then we will update
        const priorTrackingCode = JSON.parse(
            window.naShared.storage.getItem('trackingCode')
        );
        const priorTrackingAt = JSON.parse(
            window.naShared.storage.getItem('trackingAt')
        );

        if (
            (priorTrackingCode !== null ||
                (typeof priorTrackingCode === 'string' &&
                    priorTrackingCode.trim().length)) &&
            priorTrackingCode === state.parameters.code
        ) {
            // Same code...
            if (
                priorTrackingAt !== null ||
                (typeof priorTrackingAt === 'string' &&
                    priorTrackingAt.trim().length)
            ) {
                // Already have a prior tracking at..., so do nothing - just keep it and the code
            } else {
                // No prior tracking at
                if (state.parameters.at) {
                    window.naShared.storage.setItem(
                        'trackingAt',
                        JSON.stringify(state.parameters.at)
                    );
                }
            }
        } else {
            if (state.parameters.at) {
                window.naShared.storage.setItem(
                    'trackingAt',
                    JSON.stringify(state.parameters.at)
                );
            }
            if (state.parameters.code) {
                window.naShared.storage.setItem(
                    'trackingCode',
                    JSON.stringify(state.parameters.code)
                );
            }
        }

        window.naShared.storage.removeItem('trackingSource');
        window.naShared.storage.removeItem('trackingParam1');
        window.naShared.storage.removeItem('trackingParam2');

        if (state.parameters.source) {
            window.naShared.storage.setItem(
                'trackingSource',
                JSON.stringify(state.parameters.source)
            );
        }
        if (state.parameters.tp1) {
            window.naShared.storage.setItem(
                'trackingParam1',
                JSON.stringify(state.parameters.tp1)
            );
        }
        if (state.parameters.tp2) {
            window.naShared.storage.setItem(
                'trackingParam2',
                JSON.stringify(state.parameters.tp2)
            );
        }
    },
    initParametersFromUrl({ commit, dispatch }) {
        var numberSet = 0;
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('tc')) {
            commit('setParameter', { item: 'code', data: urlParams.get('tc') });
            numberSet++;
        }
        if (urlParams.has('ts')) {
            commit('setParameter', {
                item: 'source',
                data: urlParams.get('ts')
            });
            numberSet++;
        }
        if (urlParams.has('tp1')) {
            commit('setParameter', {
                item: 'param1',
                data: urlParams.get('tp1')
            });
            numberSet++;
        }
        if (urlParams.has('tp2')) {
            commit('setParameter', {
                item: 'param2',
                data: urlParams.get('tp2')
            });
            numberSet++;
        }

        if (numberSet) {
            dispatch('persist');
        }
    }
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
