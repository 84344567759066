/**
 * @format
 * Accounting related API functions
 * Abstracting these out from DataMixin.js.
 */
import axios from 'axios';

/**
 * Load transactions for user
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const readTransactions = function (data) {
    return axios
        .post(naRoutes.readTransactions, {
            data
        })
        .then((response) => response.data);
};

/**
 * Read payment methods for user
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const readPaymentMethods = function (data) {
    return axios
        .post(naRoutes.readPaymentMethods, {
            data
        })
        .then((response) => response.data);
};

/**
 * Add payment method for user
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const addPaymentMethod = function (data) {
    return axios
        .post(naRoutes.addPaymentMethod, {
            data
        })
        .then((response) => response.data);
};

/**
 * Delete payment method for user
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const deletePaymentMethod = function (data) {
    return axios
        .post(naRoutes.deletePaymentMethod, {
            data
        })
        .then((response) => response.data);
};

/**
 * Set payment method as primary for user
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const setPrimaryPaymentMethod = function (data) {
    return axios
        .post(naRoutes.setPrimaryPaymentMethod, {
            data
        })
        .then((response) => response.data);
};

/**
 * Add credit to payment method for user
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const addPaymentCredit = function (data) {
    return axios
        .post(naRoutes.addPaymentCredit, data)
        .then((response) => response.data);
};
