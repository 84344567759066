<!-- @format -->
<template>
    <section-base type="opinion" route="opinions" :posts="posts" />
</template>

<script>
import SectionBase from './SectionBase.vue';

export default {
    components: {
        SectionBase
    },

    props: {
        posts: {
            type: Array,
            required: true
        }
    }
};
</script>
