<!-- @format -->
<template>
    <div>
        <div class="field">
            <div class="control">
                <div class="na-tag-picker-tags-selected">
                    <label class="label na-label-inline">Current Tags:</label>
                    <template v-for="(option, index) in tagsSelectedl">
                        <span :key="index" class="tag is-large na-tag-picker-tag-selected" @click="removeTag(index)">
                            {{ option.tag }}
                            &nbsp;<fa-icon icon="times-circle" class="na-link" />
                        </span>
                    </template>
                    <span v-if="!tagsSelectedl.length"> None Chosen </span>
                </div>
            </div>
        </div>

        <div class="columns">
            <div class="column is-6">
                <div class="field">
                    <label class="label">Search Tags</label>
                    <div class="control">
                        <input
                            v-model="tag"
                            class="input na-tag-picker"
                            type="text"
                            placeholder="Type new tag here"
                            @keyup.esc="cancelTag"
                        />
                    </div>
                </div>

                <div class="field">
                    <a class="button is-success" :disabled="!canAddTypedTag" @click="enteredTag">Add New Tag</a>
                    <span v-if="localIsBusy" class="na-top-5px"><busy-loader spinner-type="icon"></busy-loader></span>
                </div>
            </div>

            <div v-if="includeDropdown" class="column is-6">
                <div class="field">
                    <label class="label">Found Tag List</label>
                    <div class="control">
                        <div class="select">
                            <select
                                v-show="tagsAvailableAnyFiltered.length"
                                v-model="tagSelect"
                                @change="clickedSelect"
                            >
                                <option v-for="option in tagsAvailableAnyFiltered" :key="option.id" :value="option.id">
                                    {{ option.tag }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { readTags } from '@/services/tag';

export default {
    props: {
        pickerTimerLength: {
            type: Number,
            default: 400
        },
        publicationId: {
            // If given, we will only show tags for the given publication id and only add tags for it
            type: Number,
            default: null
        },
        tagsSelected: {
            type: Array,
            required: true
        },
        includeDropdown: {
            // Include drop-down picker options?
            type: Boolean,
            default: true
        },
        minTagLength: {
            type: Number,
            default: 3
        },
        isBusy: {
            type: Boolean,
            default: false
        },
        revision: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            tag: null,
            tagPicked: [],
            tagsSelectedl: this.tagsSelected,
            timer: null,
            tagsAvailable: [],
            tagSelect: null,
            searched: false,
            localIsBusy: this.isBusy
        };
    },
    computed: {
        /*
            ...mapGetters({
                tagsAvailableAny: 'tag/tags'
            }),
            */
        tagsAvailableAnyFiltered() {
            //const matches = this.tagsAvailableAny.data.filter((item) => item.id && (this.tag === null || (typeof this.tag === 'string' && !this.tag.length) || (this.tag !== null && item.tag.toLowerCase().indexOf(this.tag.toLowerCase()) != -1)));
            const matches = this.tagsAvailable; //.filter((item) => item.id && (this.tag === null || (typeof this.tag === 'string' && !this.tag.length) || (this.tag !== null && item.tag.toLowerCase().indexOf(this.tag.toLowerCase()) != -1)));
            //this.tagSelect = null;
            if (matches.length) {
                matches.unshift({ id: null, tag: matches.length + ' tag' + (matches.length === 1 ? '' : 's') });
            }
            return matches.length ? matches : [{ id: null, tag: 'None' }];
        },
        canAddTypedTag() {
            return !this.isTypedTagEmpty;
        },
        isTypedTagEmpty() {
            return this.tag === null || this.tag.replace(/\s/g, '').length < this.minTagLength;
        },
        canDoSearch() {
            return !this.isTypedTagEmpty;
        }
    },
    watch: {
        /**
         * When "revision" changes, force a reload
         */
        revision() {
            this.tagSelect = null;
            this.tagPicked = [];
            //this.loadAllTags();
        },
        /**
         * Tag has changed, check it
         *
         * @param {mixed} newVal New value
         */
        tag(newVal) {
            this.tagSelect = null;
            if (newVal === null) {
                this.hasResult = false;
            } else {
                this.resetTimer(newVal);
            }
        },
        /**
         * Is busy flag changed, update local
         */
        isBusy() {
            this.localIsBusy = this.isBusy;
        },
        /**
         * If no tags are selected, then clear the tag selected
         */
        tagsSelectedl() {
            if (!this.tagsSelectedl.length) {
                this.tagSelect = null;
            }
        }
    },
    mounted() {
        /*
            if (this.includeDropdown) {  // If we're not including the dropdown, then no reason to load
                this.loadAllTags();
            }
            */
    },
    methods: {
        /**
         * Reset timer
         * @todo  2020-04-14  MJL  I'm leaving this code here (along with tagsAvailable related - but it's old functionality that we're not using anymore).  Delete at some point.
         */
        resetTimer() {
            this.clearTimer();
            if (this.timer === null) {
                this.timer = setTimeout(() => {
                    this.doSearch();
                }, this.pickerTimerLength);
            }
        },
        /**
         * Clear the timer
         */
        clearTimer() {
            if (this.timer !== null) {
                clearTimeout(this.timer);
                this.timer = null;
            }
        },
        /**
         * Determine if there is a selected tag with the given id
         *
         * @param {Integer} tag Tag id
         *
         * @return {Boolean}
         */
        hasSelectedTagById(tag) {
            if (tag !== null) {
                let index = this.tagsSelectedl.findIndex((element) => {
                    return element.id === tag;
                });

                return index < 0 ? false : true;
            } else {
                return false;
            }
        },
        /**
         * Determine if there is a selected tag with the given name (case insensitive)
         *
         * @param {String} tag Tag name
         *
         * @return {Boolean}
         */
        hasSelectedTagByName(tag) {
            if (tag !== null) {
                let index = this.tagsSelectedl.findIndex((element) => {
                    return element.tag.trim().toLowerCase() === tag.trim().toLowerCase();
                });

                return index < 0 ? false : true;
            } else {
                return false;
            }
        },
        /**
         * Add tag to selected list (will only be added if tag isn't already there)
         *
         * @param {mixed} tag Tag object (tag id/name) or just a string with the tag name (if a new tag)
         */
        addTagSelected(tag) {
            if (tag !== null) {
                if (typeof tag === 'string') {
                    if (!this.hasSelectedTagByName(tag)) {
                        this.tagsSelectedl.push({
                            id: null,
                            tag: tag.toLowerCase()
                        });
                    }
                } else {
                    // Object
                    if (!this.hasSelectedTagById(tag.id) && !this.hasSelectedTagByName(tag.tag)) {
                        this.tagsSelectedl.push(tag);
                    }
                }
            }
        },
        /**
         * Do search for tag.
         */
        async doSearch() {
            this.tagsAvailable = [];
            if (!this.canDoSearch) {
                return;
            }
            this.searched = false;
            if (this.tag !== null) {
                const tags = this.tag.split(','); // User can comma separate to search for multiple tags
                this.localIsBusy = true;
                const data = {
                    tagsLike: tags,
                };
                if (this.publicationId) {
                    data.publicationId = this.publicationId;
                }

                //console.log('doSearch, data=', data);
                const response = await readTags(data);
                if (response.success) {
                    response.tags.forEach((tagResult) => {
                        if (tagResult.matches.length) {
                            // Multiple tags matches combined into one array
                            this.tagsAvailable = this.tagsAvailable.concat(tagResult.matches);
                            // For every word, see what matches there are.  Any exact match will cause a tag to be automatically added.
                            tagResult.matches.forEach((tag) => {
                                if (
                                    tag.tag &&
                                    tags.find((item) => item.trim().toLowerCase() === tag.tag.trim().toLowerCase())
                                ) {
                                    this.addTagSelected(tag);
                                }
                            });
                        }
                    });
                }

                this.localIsBusy = false;
                this.searched = true;
            }
        },
        /**
         * Load all tags for use in select list
         * 2020-06-04  MJL  Too many tags to realistically load.  Left code here for now, but defunct.
         */
        loadAllTags() {
            this.$store.dispatch('tag/loadTags', { options: { onlyIfEmpty: true } }); // Just call it asynchronously
        },
        /**
         * User clicked on an available tag, add it and clear from the available lsit
         *
         * @param {Object}  tag   Tag
         * @param {Integer} index Index of tag
         */
        clickedTag(tag, index) {
            if (tag !== null) {
                this.addTagSelected(tag);
                this.tagsAvailable.splice(index, 1);
            }
        },
        /**
         * User hit enter on tag, add it and clear
         * Must be at least (n) characters presently
         */
        enteredTag() {
            if (!this.isTypedTagEmpty) {
                this.addTagSelected(this.tag);
                this.tag = null;
            }
        },
        /**
         * User hit escape in tag box, just clear box
         */
        cancelTag() {
            this.tag = null;
        },
        /**
         * Remove a selected tag
         *
         * @param {Integer} index Index of tag to remove
         */
        removeTag(index) {
            this.tagsSelectedl.splice(index, 1);
        },
        /**
         * User selected a tag in the dropdown, add it to the selection
         */
        clickedSelect() {
            if (this.tagSelect !== null) {
                const tag = this.tagsAvailable.find((item) => item.id === this.tagSelect && item.id);
                if (tag !== undefined) {
                    this.addTagSelected(tag);
                }
                //this.tagsAvailable.splice(index, 1);
            }
        }
    }
};
</script>
