<!-- @format -->
<template>
    <div class="columns na-subscription-terms-paylevel">
        <div v-for="level in payLevels" :key="level.name" class="column">
            <span>
                <label class="radio">
                    <input
                        type="radio"
                        name="payLevel"
                        required
                        :checked="payLevel.name === level.name"
                        @click="selectPayLevel(level)"
                    />
                    <span class="has-text-weight-bold">{{ level.name }}</span
                    >&nbsp;
                    <br class="is-hidden-mobile" />
                    <span class="has-text-weight-normal cost">{{ level.costText }}</span>
                </label>
                &nbsp;&nbsp;
            </span>
        </div>

        <div class="column">
            <span class="na-nobreak">
                <label class="radio">
                    <input
                        type="radio"
                        name="payLevel"
                        required
                        :checked="payLevel.name === 'other'"
                        @click="selectPayCustom()"
                    />
                    <span class="has-text-weight-bold">Other</span>
                    <br class="is-hidden-mobile" />
                    <span class="has-text-weight-bold cost">Amount $</span>
                </label>
                <input
                    ref="amountRef"
                    v-model="amount"
                    type="text"
                    class="input"
                    :required="payLevel.name === 'other'"
                    @click="selectPayCustom()"
                />
                / {{ payLevelOtherTerm }}
            </span>
        </div>
    </div>
</template>

<script>
import { moneyMixin } from '@/mixins/MoneyMixin.js';
import { publicationMixin } from '@/mixins/PublicationMixin.js';
import { subscriptionHelperMixin } from '../mixins/SubscriptionHelperMixin.js';

export default {
    mixins: [moneyMixin, publicationMixin, subscriptionHelperMixin],

    props: {
        publication: {
            type: Object,
            required: true
        },
        term: {
            type: Object,
            required: true
        },
        payLevel: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            amount: this.payLevel.name === 'other' ? this.formatCost(this.payLevel.value) : null
        };
    },

    computed: {
        /**
         * Current term type value
         */
        currentTermTypeValue() {
            return this.term.termType.value;
        },
        /**
         * Return pay level term type for other (e.g. mo or yr)
         *
         * @return {String}
         */
        payLevelOtherTerm() {
            return this.term.termType.value === 'subscriptionTypeYearly' ? 'yr' : 'mo';
        },
        /**
         * Return pay level types
         *
         * @return {Array}
         */
        payLevels() {
            // @todo  2018-10-09  MJL  This is hard-coded because at this point in time, Alex doesn't know if we might offer this to other publications or not.  So I have no idea how to formally structure it.
            if (this.term.termType.value === 'subscriptionTypeYearly') {
                switch (this.publication.slug) {
                    case 'shawangunk-journal':
                        return [
                            {
                                costText: this.formatCost(this.term.cost) + '/yr', // Note that this is the "base" cost, so we get the value from the selected term (the other terms are hard-coded - see why above)
                                name: 'Reader',
                                value: this.term.cost,
                                termType: 'subscriptionTypeYearly'
                            },
                            {
                                costText: this.formatCost(120) + '/yr',
                                name: 'Supporter',
                                value: '120',
                                termType: 'subscriptionTypeYearly'
                            },
                            {
                                costText: this.formatCost(300) + '/yr',
                                name: 'Sustainer',
                                value: '300',
                                termType: 'subscriptionTypeYearly'
                            },
                            {
                                costText: this.formatCost(600) + '/yr',
                                name: 'Booster',
                                value: '600',
                                termType: 'subscriptionTypeYearly'
                            }
                        ];
                    default:
                        return [
                            {
                                costText: this.formatCost(this.term.cost) + '/yr', // Note that this is the "base" cost, so we get the value from the selected term (the other terms are hard-coded - see why above)
                                name: 'Reader',
                                value: this.term.cost,
                                termType: 'subscriptionTypeYearly'
                            },
                            {
                                costText: this.formatCost(120) + '/yr',
                                name: 'Supporter',
                                value: '120',
                                termType: 'subscriptionTypeYearly'
                            },
                            {
                                costText: this.formatCost(300) + '/yr',
                                name: 'Sustainer',
                                value: '300',
                                termType: 'subscriptionTypeYearly'
                            },
                            {
                                costText: this.formatCost(600) + '/yr',
                                name: 'Booster',
                                value: '600',
                                termType: 'subscriptionTypeYearly'
                            }
                        ];
                }
            } else {
                switch (this.publication.slug) {
                    case 'shawangunk-journal':
                        return [
                            {
                                costText: this.formatCost(this.term.cost) + '/mo', // Note that this is the "base" cost, so we get the value from the selected term (the other terms are hard-coded - see why above)
                                name: 'Reader',
                                value: this.term.cost,
                                termType: 'subscriptionTypeMonthly'
                            },
                            {
                                costText: this.formatCost(15) + '/mo',
                                name: 'Supporter',
                                value: '15',
                                termType: 'subscriptionTypeMonthly'
                            },
                            {
                                costText: this.formatCost(25) + '/mo',
                                name: 'Sustainer',
                                value: '25',
                                termType: 'subscriptionTypeMonthly'
                            },
                            {
                                costText: this.formatCost(50) + '/mo',
                                name: 'Booster',
                                value: '50',
                                termType: 'subscriptionTypeMonthly'
                            }
                        ];
                    default:
                        return [
                            {
                                costText: this.formatCost(this.term.cost) + '/mo', // Note that this is the "base" cost, so we get the value from the selected term (the other terms are hard-coded - see why above)
                                name: 'Reader',
                                value: this.term.cost,
                                termType: 'subscriptionTypeMonthly'
                            },
                            {
                                costText: this.formatCost(10) + '/mo',
                                name: 'Supporter',
                                value: '10',
                                termType: 'subscriptionTypeMonthly'
                            },
                            {
                                costText: this.formatCost(25) + '/mo',
                                name: 'Sustainer',
                                value: '25',
                                termType: 'subscriptionTypeMonthly'
                            },
                            {
                                costText: this.formatCost(50) + '/mo',
                                name: 'Booster',
                                value: '50',
                                termType: 'subscriptionTypeMonthly'
                            }
                        ];
                }
            }
        }
    },
    watch: {
        /**
         * If amount changes and the custom level is selected, refire it
         */
        amount() {
            if (this.payLevel.name === 'other') {
                this.selectPayCustom();
            }
        },
        /**
         * Make sure the reader payment level is selected when the term type changes
         */
        currentTermTypeValue(val) {
            this.selectPayLevel(this.getPayLevel(val, 'Reader'));
        }
    },
    methods: {
        /**
         * User has selected a pay level type
         *
         * @param {Object} payLevel Pay level object
         */
        selectPayLevel(payLevel) {
            this.$emit('pay-level-selected', payLevel);
        },
        /**
         * Return given pay level for pay level name
         *
         * @param {String} termTypeValue Term type value (e.g. subscriptionTypeMonthly)
         * @param {String} payLevelName  Pay level name (e.g. 'Reader')
         *
         * @return {mixed} Pay level or null if not found
         */
        getPayLevel(termTypeValue, payLevelName) {
            const payLevel = this.payLevels.find((thisPayLevel) => {
                return thisPayLevel.termType === termTypeValue && thisPayLevel.name === payLevelName;
            });

            return payLevel === undefined ? null : payLevel;
        },
        /**
         * Determine if custom pay level should be enabled.  We need a valid amount entered
         *
         * @return {Boolean}
         */
        isCustomEnabled() {
            const amount = this.amount ? Number.parseFloat(this.amount.toString().replace(/[^\d.-]/g, '')) : null;
            if (amount && !Number.isNaN(amount)) {
                return amount > this.term.cost;
            } else {
                return false;
            }
        },
        /**
         * User chose pay level custom type
         */
        selectPayCustom() {
            this.$nextTick(() => {
                this.$refs.amountRef.focus();
            });
            if (this.isCustomEnabled()) {
                const amount = this.amount ? Number.parseFloat(this.amount.toString().replace(/[^\d.-]/g, '')) : null;
                const payLevel = {
                    costText: 'Pay custom',
                    name: 'other',
                    value: amount
                };
                this.$emit('pay-level-selected', payLevel);
            } else {
                const payLevel = {
                    costText: 'Pay custom',
                    name: 'other',
                    value: null
                };
                this.$emit('pay-level-selected', payLevel);
            }
        }
    }
};
</script>
