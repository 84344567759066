<!-- @format -->
<template>
    <div class="is-flex na-sidebar-comment-content" :data-test-id="`recent-comment-${order}`">
        <router-link class="mr-4" :to="routeParams">
            <fa-layers class="fa-2x" full-width data-toggle="tooltip" data-placement="top" :title="commentTooltip">
                <fa-icon class="na-post-comment-icon" :icon="['fac', 'comment-old']" transform="shrink-1.2" />
                <fa-layers-text
                    class="na-post-comment-count"
                    transform="shrink-9.2 up-1"
                    :value="block.post.commentCount"
                />
            </fa-layers>
        </router-link>

        <div class="is-flex is-flex-direction-column">
            <router-link class="na-sidebar-comment-title" :to="routeParams"
                ><publication-icon
                    v-if="publicationIcon"
                    class="is-inline-block"
                    :icon="publicationIcon"
                    :alt-text-fallback="block.post.publication.name"
                />
                <span>{{ commentText }}</span></router-link
            >
            <router-link class="is-block" :to="routeParams"
                ><span class="na-sidebar-comment-commenter">&mdash; {{ user }},</span>
                <span class="na-sidebar-comment-time">{{ time }}</span></router-link
            >
        </div>
    </div>
</template>

<script>
import { postMixin } from '@/mixins/PostMixin';
import { buildRouteParams } from '@/helpers/post';
import PublicationIcon from '@/components/Common/PublicationIcon.vue';

export default {
    components: {
        PublicationIcon
    },

    mixins: [postMixin],

    props: {
        block: {
            type: Object,
            required: true
        },
        order: {
            type: Number,
            default: 0
        }
    },

    computed: {
        havePublicationIcon() {
            return this.doesPublicationHaveMediaIcon(this.block.post);
        },
        publicationUrl() {
            return this.mediaUrl(this.getPublicationMedia(this.block.post, 'mediaTypeIcon')[0]);
        },
        publicationAlt() {
            return this.mediaAlt(
                this.getPublicationMedia(this.block.post, 'mediaTypeIcon')[0],
                this.block.post.publication.name
            );
        },
        publicationTitle() {
            return this.mediaTitle(this.getPublicationMedia(this.block.post, 'mediaTypeIcon')[0]);
        },
        user() {
            return this.postCommentUser(this.block.postComment.comment);
        },
        time() {
            return this.postCommentTime(this.block.postComment.comment, true);
        },
        commentText() {
            return `"${this.postCommentExcerpt(this.block.postComment.comment, 15, null, '...')}"`;
        },
        commentTooltip() {
            return (
                this.block.post.commentCount +
                ' comment' +
                (!this.block.post.commentCount || this.block.post.commentCount > 1 ? 's' : '')
            );
        },
        routeParams() {
            return buildRouteParams(this.block.post, null, {
                openComments: true
            });
        },
        publicationIcon() {
            return this.getPublicationMedia(this.block.post, 'mediaTypeIcon')[0];
        }
    }
};
</script>
