/**
 * @format
 * Author related API functions
 * Abstracting these out from DataMixin.js.
 */
import axios from 'axios';

/**
 * Create a new author
 *
 * @param {Object} data Data to be saved
 *
 * @return Promise for resolve, reject
 */
export const createAuthor = function (data) {
    return axios
        .post(naRoutes.createAuthor, {
            data
        })
        .then((response) => response.data);
};

/**
 * Associate an existing author with given post types
 *
 * @param {Object} data Data to be saved
 *
 * @return Promise for resolve, reject
 */
export const associatePostTypesAuthor = function (data) {
    return axios
        .post(naRoutes.associatePostTypesAuthor, {
            data
        })
        .then((response) => response.data);
};

/**
 * Load an author
 *
 * @param {Integer} authorId Author ID to be loaded
 *
 * @return Promise for resolve, reject
 */
export const readAuthor = function (authorId) {
    return axios
        .post(naRoutes.readAuthor, {
            data: {
                id: authorId
            }
        })
        .then((response) => response.data);
};

/**
 * Update an author
 *
 * @param {Object} data Data to be updated
 *
 * @return Promise for resolve, reject
 */
export const updateAuthor = function (data) {
    return axios
        .post(naRoutes.updateAuthor, {
            data
        })
        .then((response) => response.data);
};

/**
 * Delete an author
 *
 * @param {Integer} authorId Author ID to be deleted
 *
 * @return Promise for resolve, reject
 */
export const deleteAuthor = function (authorId) {
    return axios
        .post(naRoutes.deleteAuthor, {
            data: {
                id: authorId
            }
        })
        .then((response) => response.data);
};

/**
 * Load authors
 *
 * @param {Object} data Data to pass
 *   publicationId - A specific publication ID; may be null
 *   postTypeId - A specific post type ID; may be null
 *   offset - Offset for getting records; may be null
 *   limit - Limit for getting records; may be null
 *
 * @return Promise for resolve, reject
 */
export const readAuthors = function (data) {
    return axios
        .post(naRoutes.readAuthors, {
            data
        })
        .then((response) => response.data);
};

/**
 * Return a string describing an author
 *
 * @param {Object}  author          Author
 * @param {Boolean} includeAuthorId Include author id field with the name?  Default is false
 *
 * @return {String} Description
 */
export const authorDescribe = function (author, includeAuthorId = false) {
    let org = '';
    let name = '';
    org = author.organization ? author.organization : '';
    name = author.first_name ? author.first_name : '';
    name += author.last_name ? ' ' + author.last_name : '';
    //name += (author.organization) ? ' ' + author.organization : '';
    org = org.replace(/^\s+/, '');
    name = name.replace(/^\s+/, '');

    // Want to return org, name if both given
    const authorName = org + (org.length && name.length ? ', ' : '') + name;

    return authorName.length
        ? authorName + (includeAuthorId ? ' (' + author.id + ')' : '')
        : 'Author ID ' + author.id;
};
