<!-- @format -->
<template>
    <standard-modal
        class-background="na-modal-lightbox-background"
        class-content="na-modal-lightbox-content"
        class-button="is-large"
        @close="close"
    >
        <div ref="mediaContainer" class="na-modal-lightbox">
            <figure v-if="activeMedia.type === 'image'" class="image na-post-image-lightbox">
                <picture>
                    <source
                        v-for="set in activeMediaUrlSet"
                        :key="set.mime"
                        :type="set.mime"
                        :srcset="set.srcset"
                        sizes="(max-width: 768px) calc(100vw - 4rem), 1280px"
                    />
                    <img :src="activeMediaUrl" :alt="activeMediaAlt" :title="activeMediaTitle" />
                </picture>
                <figcaption v-if="showCaption" class="p-0">
                    <span v-if="activeMediaCaption" class="na-post-image-caption na-post-image-caption-lightbox"
                        >{{ activeMediaCaption }}&nbsp;</span
                    >
                    <span class="na-post-image-credit na-post-image-credit-lightbox">{{ activeMediaCredit }}</span>
                </figcaption>
            </figure>

            <div v-if="activeMedia.type === 'video'" class="na-post-video-lightbox">
                <video-full :video="activeMedia.media" :is-show-caption="showCaption" show-video />
            </div>
        </div>

        <nav-buttons :index="index" :count="mediaCount" @index="selectIndex" />

        <template #outer-content>
            <fa-icon
                v-if="mediaCount > 1"
                icon="chevron-left"
                size="lg"
                class="na-lightbox-nav-left-arrow is-clickable ml-2"
                alt="Previous Media Navigation Arrow"
                data-toggle="tooltip"
                data-placement="top"
                title="Previous"
                @click="prev"
            />
            <fa-icon
                v-if="mediaCount > 1"
                icon="chevron-right"
                size="lg"
                class="na-lightbox-nav-right-arrow is-clickable mr-2"
                alt="Next Media Navigation Arrow"
                data-toggle="tooltip"
                data-placement="top"
                title="Next"
                @click="next"
            />
        </template>
    </standard-modal>
</template>

<script>
import { mediaMixin } from '@/mixins/MediaMixin.js';
import { miscMixin } from '@/mixins/MiscMixin.js';
import { postMixin } from '@/mixins/PostMixin.js';
import NavButtons from './NavButtons.vue';
import VideoFull from '@/components/Content/Videos/Full/Index.vue';
import Hammer from 'hammerjs';

export default {
    components: {
        NavButtons,
        VideoFull
    },

    mixins: [mediaMixin, miscMixin, postMixin],

    props: {
        postTitle: {
            type: String,
            required: true
        },
        mediaImages: {
            type: Array,
            default: () => []
        },
        mediaVideos: {
            type: Array,
            default: () => []
        },
        showCaption: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            index: 0
        };
    },

    computed: {
        /**
         * Count of media
         */
        mediaCount() {
            return (this.mediaVideos ? this.mediaVideos.length : 0) + (this.mediaImages ? this.mediaImages.length : 0);
        },
        /**
         * Return all media.  Videos first, followed by images
         */
        media() {
            return this.mediaVideos
                .map((video) => ({
                    type: 'video',
                    media: video
                }))
                .concat(
                    this.mediaImages.map((image) => ({
                        type: 'image',
                        media: image
                    }))
                );
        },

        activeMedia() {
            return this.media[this.index];
        },

        activeMediaUrl() {
            return this.postMediaUrl(this.activeMedia.media);
        },
        activeMediaUrlSet() {
            return this.postMediaUrlSet(this.activeMedia.media, { includeLarge: true });
        },
        activeMediaAlt() {
            return this.mediaAlt(this.activeMedia.media, `A photo from "${this.postTitle}"`);
        },
        activeMediaTitle() {
            return this.mediaTitle(this.activeMedia.media);
        },
        activeMediaCaption() {
            return this.mediaCaption(this.activeMedia.media);
        },
        activeMediaCredit() {
            return this.mediaCredit(this.activeMedia.media);
        }
    },

    mounted() {
        const hammer = new Hammer(this.$refs.mediaContainer, {
            recognizers: [[Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }]]
        });
        hammer.on('swipeleft', this.next);
        hammer.on('swiperight', this.prev);
    },

    methods: {
        /**
         * User wants to close
         */
        close() {
            this.$emit('close');
        },
        /**
         * Previous media
         */
        prev() {
            this.index--;
            if (this.index < 0) {
                this.index = this.media.length - 1;
            }
        },
        /**
         * Next media
         */
        next() {
            this.index++;
            if (this.index > this.media.length - 1) {
                this.index = 0;
            }
        },
        /**
         * User selected a specific media index
         *
         * @param {Integer} index
         */
        selectIndex(index) {
            this.index = index;
            // Sanity check to make sure it's in bounds
            if (this.index < 0 || this.index > this.media.length - 1) {
                this.index = 0;
            }
        }
    }
};
</script>
