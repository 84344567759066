<!-- @format -->
<template>
    <div>
        <img
            :src="imageUrl('KingstonWire-subscriberphotologo.jpg')"
            alt="Kingston Wire"
            title="Kingston Wire"
            align="right"
        />
        <p class="title">An Urgent Appeal</p>

        <div>
            <p>
                Opening newsrooms in 2020 is not something rational people do. The economics are brutal, the work is
                hard, and the challenges to reach a point of sustainability are beyond daunting. Yet now more than ever
                the importance of honest journalism is critical, and the people here are just crazy enough to give it a
                whirl.
            </p>

            <p class="pt-4">
                Please choose a price below for the subscription that works for you. We truly appreciate any level of
                support you choose. Thank you!
            </p>

            <!--
            <p class="pt-4">
                <span style="color:#CC0000;">Special Note: October, 2021:</span> The outpouring of concern and
                support in response to our most recent
                <a href="https://kingstonwire.com/opinions/2021/10/26/editorial-the-burning-of-kingston-wire/081HYU" rel="noopener noreferrer" target="_blank">
                    editorial
                </a>
                has been astonishing and truly humbling. Many of you have asked how you can help,
                so if you are ready to subscribe please consider joining at a higher level below. Gift subscriptions
                are also available
                <a href="https://kingstonwire.com/subscribe/gift/kingston-wire" rel="noopener noreferrer" target="_blank">
                    here.
                </a>
            </p>

            <p class="pt-4">
                Any and all contributions, no matter how modest, make a real difference for us, and will help
                Kingston Wire continue to publish for years to come.
            </p>
            -->
        </div>
    </div>
</template>

<script>
import { mediaMixin } from '@/mixins/MediaMixin.js';

export default {
    mixins: [mediaMixin]
};
</script>

<style scoped>
img {
    max-width: 25%;
    padding-left: 2rem;
}

@media screen and (max-width: 768px) {
    img {
        max-width: 65%;
    }
}
</style>
