<!-- @format -->
<template>
    <div class="columns is-mobile">
        <div class="column">
            <tag-picker-picker
                :is-busy="isBusy"
                :min-tag-length="minTagLength"
                :picker-timer-length="pickerTimerLength"
                :publication-id="publicationId"
                :revision="revision"
                :tags-selected.sync="tagsSelected"
            />
        </div>
    </div>
</template>

<script>
import TagPickerPicker from './Picker.vue';

export default {
    components: {
        TagPickerPicker
    },

    props: {
        pickerTimerLength: {
            type: Number,
            default: 400
        },
        publicationId: {
            type: Number,
            default: null
        },
        tagsSelected: {
            type: Array,
            default: () => []
        },
        isBusy: {
            type: Boolean,
            default: false
        },
        revision: {
            type: Number,
            default: 0
        },
        minTagLength: {
            type: Number,
            default: 3
        }
    }
};
</script>
