<!-- @format -->
<template>
    <div>
        <div class="field" :class="hasInputError(errors, 'street_1')">
            <div class="control">
                <input v-model="street_1" class="input" type="text" placeholder="Street and number" required />
            </div>
            <input-error field="street_1" :errors="errors" />
        </div>

        <div class="field" :class="hasInputError(errors, 'street_2')">
            <div class="control">
                <input v-model="street_2" class="input" type="text" placeholder="Apartment, suite" />
            </div>
            <input-error field="street_2" :errors="errors" />
        </div>

        <div class="field" :class="hasInputError(errors, 'city')">
            <div class="control">
                <input v-model="city" class="input" type="text" placeholder="City" required />
            </div>
            <input-error field="city" :errors="errors" />
        </div>

        <div class="field is-grouped is-grouped-multiline" :class="hasInputError(errors, 'state')">
            <div v-if="optionStates.length" class="control na-register-state">
                <div class="select">
                    <select v-model="state" required>
                        <option v-for="option in optionStates" :key="option.id" :value="option.id">
                            {{ option.text }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="control">
                <input v-model="zipcode" class="input" type="text" placeholder="Zipcode" required />
            </div>

            <input-error field="state" :errors="errors" />
            <input-error field="zipcode" :errors="errors" />
        </div>
    </div>
</template>

<script>
import InputError from '@/components/Common/InputError.vue';

export default {
    components: {
        InputError
    },

    props: {
        formData: {
            type: Object,
            required: true
        },
        errors: {
            type: Array,
            required: true
        },
        optionStates: {
            type: Array,
            required: true
        },
        defaultAddress: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            street_1: this.formData.street_1
                ? this.formData.street_1
                : this.defaultAddress && this.defaultAddress.address && this.defaultAddress.address.street_1
                ? this.defaultAddress.address.street_1
                : null,
            street_2: this.formData.street_2
                ? this.formData.street_2
                : this.defaultAddress && this.defaultAddress.address && this.defaultAddress.address.street_2
                ? this.defaultAddress.address.street_2
                : null,
            city: this.formData.city
                ? this.formData.city
                : this.defaultAddress && this.defaultAddress.address && this.defaultAddress.address.city
                ? this.defaultAddress.address.city
                : null,
            state: this.formData.stateId
                ? this.formData.stateId
                : this.defaultAddress && this.defaultAddress.address && this.defaultAddress.address.state_id
                ? this.defaultAddress.address.state_id
                : null,
            zipcode: this.formData.zipcode
                ? this.formData.zipcode
                : this.defaultAddress && this.defaultAddress.address && this.defaultAddress.address.zipcode
                ? this.defaultAddress.address.zipcode
                : null
        };
    },

    watch: {
        street_1() {
            this.updateFormData();
        },
        street_2() {
            this.updateFormData();
        },
        city() {
            this.updateFormData();
        },
        state() {
            this.updateFormData();
        },
        zipcode() {
            this.updateFormData();
        }
    },
    mounted() {
        this.updateFormData(); // Init
    },
    methods: {
        /**
         * Update form data for parent
         */
        updateFormData() {
            this.$emit('update:formData', {
                street_1: this.street_1,
                street_2: this.street_2,
                city: this.city,
                state: this.state,
                zipcode: this.zipcode
            });
        }
    }
};
</script>
