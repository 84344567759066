/**
 * @format
 * General user related API functions
 * Abstracting these out from DataMixin.js.
 */
import axios from 'axios';

/**
 * Check username to determine if it's valid and (optionally) free for use
 *
 * @param {String} username   Username
 * @param {mixed}  lookupType 0 (default) = Must be free for use, 1 = Simply must be a valid email
 *
 * @return Promise for resolve, reject
 */
export const userIsUsernameValid = function (username, lookupType = 0) {
    return axios
        .get(naRoutes.userUsernameValid + '/' + username + '/' + lookupType)
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Check screen name to determine if it's valid and free for use
 *
 * @param {String} screenname Screen name
 *
 * @return Promise for resolve, reject
 */
export const userIsScreennameValid = function (screenname) {
    return axios
        .get(naRoutes.userScreennameValid + '/' + screenname)
        .then((response) => response.data);
};

/**
 * Register
 *
 * @param {Object} data Registration data
 *
 * @return Promise for resolve, reject
 */
export function userRegister(data) {
    return axios
        .post(naRoutes.register, {
            data
        })
        .then((response) => response.data);
}

export function scorePassword(password) {
    return axios
        .post(naRoutes.scorePassword, {
            password
        })
        .then((response) => response.data);
}

export function registerQuick(data) {
    return axios
        .post('/register_quick', data)
        .then((response) => response.data);
}

/**
 * Login
 *
 * @param {String}  email    Email Addresss
 * @param {String}  password New password
 * @param {Boolean} remember Remember me?
 * @param {mixed}   postId   May be a post id.  If given, we'll return the post identifier
 *
 * @return Promise for resolve, reject
 */
export const userLogin = function (
    email,
    password,
    remember = false,
    postId = null
) {
    const data = {
        email,
        password,
        remember,
        postId
    };
    return axios
        .post(naRoutes.login, {
            data
        })
        .then((response) => response.data);
};

/**
 * Login
 *
 * @param {String}  email    Email Addresss
 * @param {Number}  One Time Password
 * @param {Boolean} remember Remember me?
 * @param {mixed}   postId   May be a post id.  If given, we'll return the post identifier
 *
 * @return Promise for resolve, reject
 */
export const userOtpLogin = function (
    email,
    code,
    remember = false,
) {
    const data = {
        email,
        code,
        remember,
    };
    return axios
        .post(naRoutes.otpLogin, {
            data
        })
        .then((response) => response.data);
};

/**
 * Promise-wrapped facebook login
 */
function fbLogin() {
    return new Promise((resolve, reject) => {
        window.FB.login(
            (response) => {
                if (response.authResponse) {
                    window.FB.api(
                        '/me',
                        { fields: 'id,first_name,last_name,email' },
                        function ({ email }) {
                            resolve({
                                accessToken: response.authResponse.accessToken,
                                email
                            });
                        }
                    );
                } else {
                    reject(new Error('Facebook login failed.'));
                }
            },
            { scope: 'public_profile,email', return_scopes: true }
        );
    });
}

/**
 * Login
 *
 * @return Promise for resolve, reject
 */
export async function userLoginSocial(socialType, params = {}) {
    const mergedParams = {
        remember: false,
        postId: null,
        ...params
    };
    const MAGIC_TOKEN = '1jj4j29929jJDHJQH2111aa;3';
    let accessToken = null;
    let email = '';
    let firstName = null;
    let lastName = null;
    if (socialType === 'google') {
        try {
            const googleUser = await window.googleAuthClient.signIn();
            email = googleUser.getBasicProfile().getEmail();
            accessToken = googleUser.getAuthResponse().id_token;
        } catch (error) {
            if (error?.error === 'popup_closed_by_user') {
                throw new Error('cancelled');
            }
            throw error;
        }
    } else if (socialType === 'facebook') {
        const result = await fbLogin();
        email = result.email;
        accessToken = result.accessToken;
    } else if (socialType === 'apple') {
        try {
            const result = await window.AppleID.auth.signIn();
            if (result.user) {
                email = result.user.email;
                if (result.user.name && result.user.name.firstName) {
                    firstName = result.user.name.firstName;
                }
                if (result.user.name && result.user.name.lastName) {
                    lastName = result.user.name.lastName;
                }
            }
            accessToken = result.authorization.id_token;
        } catch (error) {
            if (error?.error === 'popup_closed_by_user') {
                throw new Error('cancelled');
            }
            throw error;
        }
    }

    const data = {
        ...mergedParams,
        accessToken,
        socialType,
        firstName,
        lastName,
        magicToken: MAGIC_TOKEN
    };
    async function retryCallback({ email, password, acceptTc = false }) {
        try {
            const response = await axios.post(naRoutes.loginSocial, {
                data: {
                    ...data,
                    email,
                    password,
                    acceptTc
                }
            });
            if (
                response.data &&
                (response.data.email_required ||
                    response.data.password_required ||
                    response.data.accept_tc_required)
            ) {
                return {
                    retryCallback,
                    email,
                    socialType,
                    emailRequired: response.data.email_required,
                    passwordRequired: response.data.password_required,
                    acceptTcRequired: response.data.accept_tc_required
                };
            }
            return response.data;
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.errors
            ) {
                return {
                    success: false,
                    errors: [error.response.data.errors]
                };
            }
            return {
                success: false,
                errors: [error.message]
            };
        }
    }
    try {
        const response = await axios.post(naRoutes.loginSocial, { data });
        if (
            response.data &&
            (response.data.email_required ||
                response.data.password_required ||
                response.data.accept_tc_required)
        ) {
            return {
                retryCallback,
                email,
                socialType,
                emailRequired: response.data.email_required,
                passwordRequired: response.data.password_required,
                acceptTcRequired: response.data.accept_tc_required
            };
        }
        return response.data;
    } catch (error) {
        if (
            error.response &&
            error.response.data &&
            error.response.data.errors
        ) {
            return {
                success: false,
                errors: [error.response.data.errors]
            };
        }
        return {
            success: false,
            errors: [error.message]
        };
    }
}

/**
 * Send forgot password last
 *
 * @param {String} email Email Addresss
 *
 * @return Promise for resolve, reject
 */
export function userForgotPassword(email) {
    const data = {
        email
    };
    return axios
        .post(naRoutes.forgotPassword, {
            data
        })
        .then((response) => response.data);
}

/**
 * Reset password
 *
 * @param {String} email           Email Addresss
 * @param {String} token           Reset token
 * @param {String} password        New password
 * @param {String} passwordConfirm Password confirmation
 *
 * @return Promise for resolve, reject
 */
export function userResetPassword(email, token, password, passwordConfirm) {
    const data = {
        email,
        token,
        password,
        password_confirmation: passwordConfirm
    };
    return axios
        .post(naRoutes.resetPassword, {
            data
        })
        .then((response) => response.data);
}

/**
 * Confirm registration
 *
 * @param {String} email Email Addresss
 * @param {String} token Confirmation ode
 *
 * @return Promise for resolve, reject
 */
export function userConfirmRegistration(email, token) {
    const data = {
        email,
        token
    };
    return axios
        .post(naRoutes.registerConfirm, {
            data
        })
        .then((response) => response.data);
}

/**
 * Request new confirm registration code
 *
 * @param {String} email Email Addresss
 *
 * @return Promise for resolve, reject
 */
export const userConfirmRegistrationRequest = function (email) {
    const data = {
        email
    };
    return axios
        .post(naRoutes.registerConfirmRequest, {
            data
        })
        .then((response) => response.data);
};

/**
 * Load current user profile data
 *
 * @return Promise for resolve, reject
 */
export const userDataLoadProfile = function () {
    return axios.get(naRoutes.loadProfile).then((response) => response.data);
};

/**
 * Load current user profile data
 *
 * @return Promise for resolve, reject
 */
export const userAccountDelete = function (id) {
    return axios.post(naRoutes.deleteAccount ,{
        id
    }).then((response) => response.data);
};

/**
 * Save user profile data
 *
 * @param {Object} data Data to pass
 *
 * @return Promise for resolve, reject
 */
export const userDataSaveProfile = function (data) {
    return axios
        .post(naRoutes.saveProfile, {
            data
        })
        .then((response) => response.data);
};

/**
 * Save user publications preferences
 *
 * @param {Object} data Data to pass
 *
 * @return Promise for resolve, reject
 */
export const userDataSavePublicationPreferences = function (data) {
    return axios
        .post(naRoutes.savePublicationPreferences, {
            data
        })
        .then((response) => response.data);
};

/**
 * Load current user data object
 *
 * @return Promise for resolve, reject
 */
export function userDataLoadInfo() {
    return axios.get(naRoutes.loadUserData).then((response) => response.data);
}

/**
 * Signup interested (for mailing list)
 *
 * @param {Object} data Signup data
 *
 * @return Promise for resolve, reject
 */
export const userInterested = function (data) {
    return axios
        .post(naRoutes.interested, {
            data
        })
        .then((response) => response.data);
};

/**
 * Save user personal data (e.g. address)
 *
 * @param {Object} data Data to pass
 *
 * @return Promise for resolve, reject
 */
export const userSaveWho = function (data) {
    return axios
        .post(naRoutes.saveWho, {
            data
        })
        .then((response) => response.data);
};

/**
 * Load user personal data (e.g. address)
 *
 * @return Promise for resolve, reject
 */
export const userLoadWho = function () {
    return axios.get(naRoutes.loadWho).then((response) => response.data);
};

/**
 * Load users
 *
 * @param {Object} data Data to pass
 *
 * @return Promise for resolve, reject
 */
export const readUsers = function (data) {
    return axios
        .post(naRoutes.readUsers, {
            data
        })
        .then((response) => response.data);
};

/**
 * Load user
 *
 * @param {Object} data Data to pass
 *
 * @return Promise for resolve, reject
 */
export const readUser = function (data) {
    return axios
        .post(naRoutes.readUser, {
            data
        })
        .then((response) => response.data);
};

/**
 * Send OTP to user
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const sendUserOtp = function (data) {
    return axios
        .post(naRoutes.sendUserOtp, {
            data
        })
        .then((response) => response.data);
};
