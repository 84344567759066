<!-- @format -->
<template>
    <tr>
        <td class="na-related-posts-date na-link has-text-right">
            <router-link class="na-related-posts-date" :to="routeParams">{{ theDate }}</router-link>
        </td>

        <router-link
            v-if="doesPublicationHaveMediaIcon(block)"
            class="na-related-posts-publication"
            :to="routeParams"
            tag="td"
        >
            <a>
                <publication-icon
                    v-if="publicationIcon"
                    :icon="publicationIcon"
                    :alt-text-fallback="block.publication.name"
                />
            </a>
        </router-link>

        <td class="na-related-posts-content">
            <router-link class="na-related-posts-content" :to="routeParams"
                ><span>{{ theHeadline }}</span
                ><span v-if="theSubheadline" class="is-italic">: {{ theSubheadline }}</span></router-link
            >
        </td>
    </tr>
</template>

<script>
import { dateMixin } from '@/mixins/DateMixin.js';
import { mediaMixin } from '@/mixins/MediaMixin.js';
import { postMixin } from '@/mixins/PostMixin.js';
import { buildRouteParams } from '@/helpers/post';
import PublicationIcon from '@/components/Common/PublicationIcon.vue';

export default {
    components: {
        PublicationIcon
    },

    mixins: [dateMixin, mediaMixin, postMixin],

    props: {
        block: {
            type: Object,
            default: function () {
                return {};
            }
        }
    },

    computed: {
        routeParams() {
            return buildRouteParams(this.block);
        },
        /**
         * Headline for post
         *
         * @return {String}
         */
        theHeadline() {
            return this.block.title;
        },
        /**
         * Subheadline for post
         *
         * @return {String}
         */
        theSubheadline() {
            return this.block.subtitle ? this.block.subtitle : null;
        },
        /**
         * Date of post
         *
         * @return {String}
         */
        theDate() {
            return this.formatStandardDateTime(this.block.content_at, 'date-mdyy');
        },

        publicationIcon() {
            return this.getPublicationMedia(this.block, 'mediaTypeIcon')[0];
        }
    }
};
</script>
