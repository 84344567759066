<template>
    <div v-if="relatedPosts.posts.length" class="na-related-posts-container">
        <hr>
        <p class="na-related-posts">Related Coverage</p>
        <table class="table na-related-posts-table">
            <tbody>
                <template v-for="post in relatedPosts.posts">
                    <single-post
                        :block="post"
                    ></single-post>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script>
    import SinglePost from './Related.vue';

    export default {
        components: {
            SinglePost
        },
        props: {
            relatedPosts: {
                default: function () {
                    return null;
                }
            }
        },
        data () {
            return {
            };
        },
    }
</script>
