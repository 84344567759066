/**
 * Post admin
 */
const state = {
    data: {  // Just for storing general data
        publicationId: null,  // @todo  2019-04-04  MJL  Store here?
    },
    currentPostType: null  // I don't think we ever actually used data object above, but just in case - I'm leaving it there for now, new fields not
};

const getters = {
    currentPostType: (state) => state.currentPostType
};

const mutations = {
    setCurrentPostType(state, data) {
        const { postType = null } = data;
        //console.log('setCurrentPostType (post module), data=', data, 'postType=', postType);
        state.currentPostType = postType;
    }
};

const actions = {
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
