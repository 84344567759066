<!-- @format -->
<template>
    <div class="na-sidebar-dock pt-1 pb-2">
        <ad-notification-dock :class="[adNotificationDockClass, 'py-1']" />

        <comment-notifier-dock :ignore-preferences="ignorePreferences" />

        <search-dock />

        <ad-dock />

        <blotter-dock
            v-if="blotters.length && !loadingSections"
            :posts="blotters"
            :is-busy="isBusy && lastActiveType === 'blotter'"
            @fetch-more="$emit('fetch-more', $event)"
        />

        <obituary-dock
            v-if="obituaries.length && !loadingSections"
            :posts="obituaries"
            :is-busy="isBusy && lastActiveType === 'obituary'"
            @fetch-more="$emit('fetch-more', $event)"
        />

        <ad-notification-dock v-if="blotters.length || obituaries.length" class="py-1" />

        <news-dock v-if="news.length" :posts="news" />

        <opinion-dock v-if="opinions.length && !loadingSections" :posts="opinions" />

        <ad-notification-dock v-if="news.length || opinions.length" :class="adNotificationDockClass" />

        <sports-dock v-if="sports.length && !loadingSections" :posts="sports" />

        <events-dock v-if="events.length && !loadingSections" :posts="events" />

        <ad-notification-dock v-if="sports.length || events.length" :class="adNotificationDockClass" />

        <section-dock />

        <quicklinks-dock />

        <ad-notification-dock v-if="blotters.length" :class="adNotificationDockClass" />

        <copyright-dock />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AdDock from '@/components/Content/Ad/Dock/Index.vue';
import AdNotificationDock from '@/components/Content/Ad-Notification/Dock/Index.vue';
import CommentNotifierDock from '@/components/Content/CommentNotifier/Dock/Index.vue';
import CopyrightDock from '@/components/Common/Copyright/Dock/Index.vue';
import QuicklinksDock from '@/components/Common/QuickLinks/Dock/Index.vue';
import SearchDock from '@/components/Content/Search/Dock/Index.vue';
import SectionDock from '@/components/Content/Sections/Dock/Index.vue';
import BlotterDock from './Blotters.vue';
import ObituaryDock from './Obituary.vue';
import NewsDock from './News.vue';
import OpinionDock from './Opinion.vue';
import SportsDock from './Sports.vue';
import EventsDock from './Events.vue';

export default {
    components: {
        AdDock,
        AdNotificationDock,
        CommentNotifierDock,
        CopyrightDock,
        QuicklinksDock,
        SearchDock,
        SectionDock,
        BlotterDock,
        ObituaryDock,
        NewsDock,
        OpinionDock,
        SportsDock,
        EventsDock
    },
    props: {
        blotters: {
            type: Array,
            default: () => []
        },
        obituaries: {
            type: Array,
            default: () => []
        },
        news: {
            type: Array,
            default: () => []
        },
        opinions: {
            type: Array,
            default: () => []
        },
        events: {
            type: Array,
            default: () => []
        },
        sports: {
            type: Array,
            default: () => []
        },
        isBusy: {
            type: Boolean,
            default: false
        },
        lastActiveType: {
            type: String,
            default: ''
        },
        ignorePreferences: {
            type: Boolean,
            required: true
        }
    },

    computed: {
        ...mapState({
            loadingSections: (state) => state.user.sections.loading
        }),
        /**
         * Ugh - need to rework this.  Basically we hide the ad notification dock on mobile when on intro and show it when not on intro
         */
        adNotificationDockClass() {
            return this.$route.name === 'home' ? 'is-hidden-touch' : '';
        }
    }
};
</script>
