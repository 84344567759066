/**
 * Feedback related API functions.
 * Abstracting these out from DataMixin.js.
 */
import axios from 'axios';

/**
 * Send a comment (general feedback, NOT a post comment)
 *
 * @param {Object} data Comment data
 *
 * @return Promise for resolve, reject
 */
export const sendFeedback = function (data) {
    return axios.post(naRoutes.feedback,
        {
            data
        })
        .then((response) => response.data)
};
