<!-- @format -->
<template>
    <div class="columns is-marginless">
        <div class="column is-paddingless">
            <post :post="post" :show-snippet="false" :show-media="false">
                <span slot="mainLabel"
                    ><fa-icon icon="headphones" /> {{ post.post_type.value }}
                    <template v-if="duration">
                        <span>&bull;</span>
                        <span class="intro-post-audio-duration has-text-weight-bold">Listen Now ({{ duration }})</span>
                    </template></span
                >
            </post>
        </div>
    </div>
</template>

<script>
import { mediaMixin } from '@/mixins/MediaMixin.js';
import Post from './Post.vue';
import { formatDuration } from '@/helpers/media';

export default {
    components: {
        Post
    },

    mixins: [mediaMixin],

    props: {
        post: {
            type: Object,
            required: true
        }
    },

    computed: {
        duration() {
            const media = this.post.media.find((audio) => audio.audio_type === 'castFull');
            if (!media) {
                return '';
            }
            return formatDuration(media.duration / 1000);
        }
    }
};
</script>
