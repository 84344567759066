/**
 * Section related API functions
 * Abstracting these out from DataMixin.js.
 */
import axios from 'axios';

/**
 * Create a new section
 *
 * @param {Object} data Data to be saved
 *
 * @return Promise for resolve, reject
 */
export const createSection = function (data) {
    return axios.post(naRoutes.createSection,
        {
            data
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Load a section
 *
 * @param {Integer} sectionId Section ID to be loaded
 *
 * @return Promise for resolve, reject
 */
export const readSection = function (sectionId) {
    return axios.post(naRoutes.readSection,
        {
            data: {
                id: sectionId
            }
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Update a section
 *
 * @param {Object} data Data to be saved
 *
 * @return Promise for resolve, reject
 */
export const updateSection = function (data) {
    return axios.post(naRoutes.updateSection,
        {
            data
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Delete a section
 *
 * @param {Integer} sectionId Section ID to be deleted
 *
 * @return Promise for resolve, reject
 */
export const deleteSection = function (sectionId) {
    return axios.post(naRoutes.deleteSection,
        {
            data: {
                id: sectionId
            }
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Load sections
 *
 * @param {Object} data Data
 *
 * @return Promise for resolve, reject
 */
export const readSections = function (data) {
    return axios.post(naRoutes.readSections,
        {
            data
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Update section sort orders
 *
 * @param {Object} data Data to be saved
 *
 * @return Promise for resolve, reject
 */
export const updateSortSections = function (data) {
    return axios.post(naRoutes.updateSortSections,
        {
            data
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};

/**
 * Try to map system section name to logged in user's id
 *
 * @param {String} name System section name
 *
 * @return Promise for resolve, reject
 */
export const mapSectionIdFromSystemSectionName = function (data) {
    return axios.post(naRoutes.mapSectionIdFromSystemSectionName,
        {
            data
        })
        .then((response) => Promise.resolve(response.data))
        .catch((error) => Promise.reject(error));
};
