<!-- @format -->
<template>
    <div v-if="post && adImage" class="na-nad-content">
        <a :href="!video ? url : undefined" target="_blank" @click="adClick">
            <figure class="image na-nad-image">
                <picture>
                    <source v-for="set in sourceSet" :key="set.mime" :type="set.mime" :srcset="set.url" />
                    <img :src="adMediaUrl" :alt="altText" :title="title" width="250" height="250" loading="lazy" />
                </picture>
            </figure>
        </a>
    </div>
</template>

<script>
import { mediaMixin } from '@/mixins/MediaMixin.js';
import { recordPostHit } from '@/services/post.js';

export default {
    mixins: [mediaMixin],

    props: {
        post: {
            type: Object,
            required: true
        }
    },

    computed: {
        /**
         * Return link url
         *
         * @return {String|null}
         */
        url() {
            return this.post.post_native_ads && this.post.post_native_ads.length
                ? this.post.post_native_ads[0].url
                : null;
        },
        adImage() {
            if (this.post.post_native_ads?.[0]?.ad_type === 'nativeAdTypePremium') {
                return this.post.media.find((media) => media.image_type === 'premiumSquare');
            } else {
                return this.post.media[0];
            }
        },
        adMediaUrl() {
            return this.postMediaUrl(this.adImage, 'natad');
        },
        sourceSet() {
            if (!this.adImage) {
                return [];
            }
            return this.postMediaUrlSet(this.adImage);
        },
        altText() {
            return this.mediaAlt(this.adImage, 'Ad Image');
        },
        title() {
            return this.mediaTitle(this.adImage, 'Ad Image');
        },
        video() {
            if (!this.post) {
                return;
            }
            return this.post.post_media_videos[0];
        }
    },

    methods: {
        adClick() {
            if (this.video) {
                this.$emit('showVideo', {
                    video: this.post.post_media_videos[0],
                    url: this.url
                });
            }
            recordPostHit({
                post_id: this.post.id,
                subsource: 'NativeAd',
                url: this.url
            });
        }
    }
};
</script>
