<!-- @format -->
<template>
    <div>
        <img :src="imageUrl('BKAApleapage.png')" alt="Basha Kill Wetlands" title="Basha Kill Wetlands" />
        <p class="title">Help Protect the Basha Kill Wetlands</p>

        <div>
            <p>
                The Basha Kill Area Association (BKAA) invites you to join them in their mission to preserve the
                unspoiled quality of the beautiful and unique Basha Kill. Your subscription to their publication BKAA
                Guardian gives you membership in the organization, whose efforts go to fight threats such as pollution,
                vandalism and unwise development. Additional donations help pay for water sampling; supplies for the
                annual clean-up; sponsorship of a child to attend D.E.C. Environmental Education Camp for a week; and
                legal expenses.
            </p>
            <p class="shim">
                If you would like to help further the BKAA’s mission, please select one of the contributor options
                below. We thank you for your support!
                <!--
                <p class="has-text-right">
                    &mdash; The Shawangunk Journal Team
                </p>
                -->
            </p>
        </div>
    </div>
</template>

<script>
import { mediaMixin } from '@/mixins/MediaMixin.js';

export default {
    mixins: [mediaMixin]
};
</script>
