<!-- @format -->
<template>
    <div>
        <img
            :src="imageUrl('DHCnewspapers-trans.png')"
            alt="Delaware and Hudson Canvas"
            title="Delaware and Hudson Canvas"
        />
        <p class="title">Help CANVAS Support and Promote the Arts Community</p>

        <div>
            <p>
                We are committed to bringing our readers exciting information about the arts and arts resources in the
                counties we serve. As our community continues to spread its artistic wings, we encourage you to use
                CANVAS as a powerful resource and guide. We need your support to continue expanding our scope and
                service within the community.
            </p>
            <p class="shim">
                We hope you will subscribe today! Please choose a category below for the level that works for you, and
                thank you for your choice to support the arts!
                <!--
                <p class="has-text-right">
                    &mdash; The Shawangunk Journal Team
                </p>
                -->
            </p>
        </div>
    </div>
</template>

<script>
import { mediaMixin } from '@/mixins/MediaMixin.js';

export default {
    mixins: [mediaMixin]
};
</script>
