/**
 * Tag
 */
import { readTags } from '@/services/tag';

const state = {
    tags: {
        data: [],
        isLoading: false,
        errors: []
    }
};

const getters = {
    tags: (state) => state.tags
};

const mutations = {
    setTags(state, payload) {
        state.tags[payload.item] = payload.data;
    }
};

const actions = {
    /**
     * Load tags from server
     *
     * @param {object} context
     * @param {object} payload
     */
    async loadTags({ commit, state }, payload) {
        const setter = 'setTags';
        if (state.tags.isLoading) {
            return;
        }
        if (payload && payload.options && payload.options.onlyIfEmpty && state.tags.data.length) {
            // Only load if no data
            return;
        }
        commit(setter, { item: 'isLoading', data: true });
        commit(setter, { item: 'errors', data: [] });
        await readTags(payload.request)
            .then((response) => {
                if (!response.success) {
                    if (response.errors) {
                        commit(setter, { item: 'errors', data: response.errors });
                        commit(setter, { item: 'isLoading', data: false });
                    }
                } else {
                    const data = response.tags.map(item => { return { id: item.id, tag: item.tag, publicationId: item.publication_id, userId: item.user_id } });
                    if (payload && payload.options && payload.options.addPicker) {
                        data.unshift({
                            id: null,
                            tag: 'Please Select',
                            publicationId: null,
                            userId: null
                        });
                    }
                    commit(setter, { item: 'data', data });
                    commit(setter, { item: 'isLoading', data: false });
                }
            }, (error) => {
                commit(readTags, { item: 'errors', data: [ error ] });
                commit(readTags, { item: 'isLoading', data: false });
            });
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}
