<!-- @format -->
<template>
    <form class="is-flex is-flex-direction-column" @submit.prevent="$emit('register')">
        <div v-if="showHeader" class="mb-6">
            <div class="has-text-centered">
                <h1 class="is-size-4 has-text-weight-bold">
                    {{ headerText || 'Sign Up for a Free Trial' }}
                </h1>
                <p class="is-size-5 is-italic">Read your community news</p>
                <p class="is-size-5 is-italic">Support the people who bring it to you</p>
            </div>

            <div
                v-if="showHeader && mainPublication && mainPublication.media && mainPublication.media.length"
                class="is-flex is-justify-content-center"
            >
                <figure class="image">
                    <img
                        :src="mediaUrl(getPublicationMedia({ publication: mainPublication }, 'mediaTypeImage')[0])"
                        :alt="mainPublication.name"
                        style="height: 64px"
                        height="64"
                    />
                </figure>
            </div>
        </div>

        <social-buttons
            v-if="showSocial"
            class="mb-2"
            :align="alignSocial"
            init-text="Sign up with"
            hide-text-on-mobile
            @click="$emit('register-social', $event)"
        />

        <template v-if="showSocial && showDivider">
            <hr class="divider mb-2" />
            <p class="is-size-6">Or Sign up with email</p>
        </template>

        <register-name :form-data.sync="formDataName" :errors="inputErrors" />

        <register-email :form-data.sync="formDataEmail" :errors="inputErrors" />

        <register-password :form-data.sync="formDataPassword" :errors="inputErrors" />

        <register-terms :form-data.sync="formDataTerms" :errors="inputErrors" />

        <template v-if="errors && errors.length">
            <p v-for="error in errors" :key="error" class="help is-danger">{{ error }}</p>
        </template>

        <div class="is-flex is-justify-content-space-between">
            <button type="submit" class="button is-success" :class="{ 'is-loading': isBusy }" :disabled="isBusy">
                <span>{{ buttonText || 'Sign Up' }}</span> <span class="icon"><fa-icon icon="check" /></span>
            </button>
            <button v-if="showExtraInfo" type="button" class="button is-pulled-right" @click="$emit('more-info-click')">
                <span class="icon"><fa-icon icon="question" /></span> <span>More Info</span>
            </button>
        </div>
    </form>
</template>

<script>
import { postMixin } from '@/mixins/PostMixin.js';
import { mediaMixin } from '@/mixins/MediaMixin.js';
import RegisterEmail from './Email.vue';
import RegisterName from './Name.vue';
import RegisterPassword from './Password.vue';
import RegisterTerms from './Terms.vue';
import SocialButtons from '../Auth/SocialButtons.vue';
import { mapState } from 'vuex';

export default {
    components: {
        RegisterEmail,
        RegisterName,
        RegisterPassword,
        RegisterTerms,
        SocialButtons
    },

    mixins: [mediaMixin, postMixin],

    props: {
        headerText: {
            type: String,
            default: ''
        },
        formData: {
            type: Object,
            required: true
        },
        errors: {
            type: Array,
            default: () => []
        },
        inputErrors: {
            type: Array,
            default: () => []
        },
        isBusy: {
            type: Boolean,
            default: false
        },
        buttonText: {
            type: String,
            default: ''
        },
        showHeader: {
            type: Boolean,
            default: true
        },
        showSocial: {
            type: Boolean,
            default: true
        },
        showExtraInfo: {
            type: Boolean,
            default: true
        },
        showDivider: {
            type: Boolean,
            default: true
        },
        alignSocial: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            formDataEmail: this.formData.formDataEmail,
            formDataName: {},
            formDataPassword: {},
            formDataTerms: {}
        };
    },

    computed: {
        ...mapState({
            mainPublication: (state) => state.publication.mainPublication
        })
    },

    watch: {
        formDataEmail: {
            handler: 'updateFormData',
            deep: true
        },
        formDataName: {
            handler: 'updateFormData',
            deep: true
        },
        formDataPassword: {
            handler: 'updateFormData',
            deep: true
        },
        formDataTerms: {
            handler: 'updateFormData',
            deep: true
        }
    },

    methods: {
        /**
         * Update form data for parent
         */
        updateFormData() {
            this.$emit('update:formData', {
                formDataEmail: this.formDataEmail,
                formDataName: this.formDataName,
                formDataPassword: this.formDataPassword,
                formDataTerms: this.formDataTerms
            });
        }
    }
};
</script>
