/** @format */
// Polyfills
import moment from 'moment';
import axios from 'axios';

window.Laravel = {
    csrfToken: document.head.children['csrf-token'].content
};

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = axios;

window.axios.defaults.headers.common['Accept'] = 'application/json';
window.axios.defaults.headers.common['X-CSRF-TOKEN'] = document.head.children['csrf-token'].content;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.axios.interceptors.request.use(
    function (config) {
        try {
            var numberSet = 0;
            // Pass tracking parameters
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has('tc')) {
                config.headers.natracktc = urlParams.get('tc');
                numberSet++;
            }
            if (urlParams.has('ts')) {
                config.headers.natrackts = urlParams.get('ts');
                numberSet++;
            }
            if (urlParams.has('tp1')) {
                config.headers.natracktp1 = urlParams.get('tp1');
                numberSet++;
            }
            if (urlParams.has('tp2')) {
                config.headers.natracktp2 = urlParams.get('tp2');
                numberSet++;
            }

            // If we had no parameters from above, do we have any unexpired ones in local storage?
            if (!numberSet) {
                const trackingAt = JSON.parse(
                    window.naShared.storage.getItem('trackingAt')
                );
                if (
                    trackingAt !== null ||
                    (typeof trackingAt === 'string' && trackingAt.trim().length)
                ) {
                    var age = moment.duration(
                        moment().diff(moment(trackingAt))
                    );
                    if (age.asDays() <= 365) {
                        // Valid for up to (apx) one year
                        config.headers.natrackat = moment(trackingAt).format(
                            'YYYY-MM-DD HH:mm:ss'
                        );

                        const tc = JSON.parse(
                            window.naShared.storage.getItem('trackingCode')
                        );
                        if (
                            tc !== null ||
                            (typeof tc === 'string' && tc.trim().length)
                        ) {
                            config.headers.natracktc = tc;
                        }
                        const ts = JSON.parse(
                            window.naShared.storage.getItem('trackingSource')
                        );
                        if (
                            ts !== null ||
                            (typeof ts === 'string' && ts.trim().length)
                        ) {
                            config.headers.natrackts = ts;
                        }
                        const tp1 = JSON.parse(
                            window.naShared.storage.getItem('trackingParam1')
                        );
                        if (
                            tp1 !== null ||
                            (typeof tp1 === 'string' && tp1.trim().length)
                        ) {
                            config.headers.natracktp1 = tp1;
                        }
                        const tp2 = JSON.parse(
                            window.naShared.storage.getItem('trackingParam2')
                        );
                        if (
                            tp2 !== null ||
                            (typeof tp2 === 'string' && tp2.trim().length)
                        ) {
                            config.headers.natracktp2 = tp2;
                        }
                    }
                }
            } else {
                // Let server set if no track at
                //config.headers.natrackat = moment().format('YYYY-MM-DD HH:mm:ss');
            }
        } catch (e) {
            // No op
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: 'your-pusher-key'
// });
