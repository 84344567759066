<!-- @format -->
<template>
    <figure class="image is-16x16 mr-1">
        <picture>
            <source v-for="set in sourceSet" :key="set.mime" :type="set.mime" :srcset="set.url" />
            <img :src="url" :alt="altText" width="16" height="16" loading="lazy" />
        </picture>
    </figure>
</template>

<script>
import { mediaMixin } from '@/mixins/MediaMixin.js';

export default {
    mixins: [mediaMixin],

    props: {
        icon: {
            type: Object,
            required: true
        },
        altTextFallback: {
            type: String,
            default: ''
        }
    },

    computed: {
        url() {
            return this.mediaUrl(this.icon);
        },
        sourceSet() {
            return this.getMediaSourceSet(this.icon);
        },
        altText() {
            return this.icon.caption ?? this.altTextFallback;
        }
    }
};
</script>
