<!-- @format -->
<template>
    <span></span>
    <!--
    <p>
        <a :href="facebookLink" target="_blank" rel="noopener noreferrer"
            ><fa-icon class="na-icon-facebook" :icon="['fab', 'facebook-square']" size="2x"
        /></a>
        <a :href="twitterLink" target="_blank" rel="noopener noreferrer"
            ><fa-icon class="na-icon-twitter" :icon="['fab', 'twitter-square']" size="2x"
        /></a>
        <a :href="instagramLink" target="_blank" rel="noopener noreferrer"
            ><fa-icon class="na-icon-instagram" :icon="['fab', 'instagram-square']" size="2x"
        /></a>
    </p>
    -->
</template>

<script>

export default {
    computed: {
        facebookLink() {
            return naVariables.urlNaFacebook;
        },
        twitterLink() {
            return naVariables.urlNaTwitter;
        },
        instagramLink() {
            return naVariables.urlNaInstagram;
        }
    }
};
</script>
