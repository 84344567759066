<!-- @format -->
<template>
    <div class="is-flex is-flex-direction-column">
        <div
            v-if="!groups.length"
            class="is-flex is-flex-direction-column is-align-items-center my-6 has-text-grey-light"
        >
            <fa-icon :icon="['fac', 'newspaper-old']" size="6x" />
            <p class="mt-2">No posts to show.</p>
        </div>

        <template v-for="(group, index) in groups" v-else>
            <hr v-if="index !== 0" :key="index + 'divider'" class="featured-divider" />
            <group-layout
                :key="index + 'group'"
                :group="group"
                :is-emit="isEmit"
                :section="section"
                @single-post-view="singlePostView"
            />
            <div v-if="group.showAdsAfter && clientIsMobile" :key="index + 'ad'" class="my-2">
                <hr class="featured-divider" />
                <article class="py-2">
                    <p class="has-text-gray is-size-8 has-text-weight-normal is-uppercase has-text-centered">
                        Advertisement
                    </p>
                    <ad-multipost />
                </article>
            </div>
        </template>

        <div class="has-text-right">
            <template v-if="!noMore && groups.length">
                <button
                    type="button"
                    v-if="clientIsMobile"
                    class="button is-success is-fullwidth has-text-weight-bold"
                    @click="loadMore"
                >
                    <span v-if="!isBusy">Load More</span>
                    <span v-else><fa-icon icon="circle-notch" spin /></span>
                </button>
                <button type="button" v-else class="button is-success has-text-weight-bold" @click="loadMore">
                    <span v-if="!isBusy">Load More {{ headerName }}</span>
                    <span v-else>Loading... <fa-icon icon="circle-notch" spin /></span>
                </button>
            </template>
            <template v-else>
                <span v-if="clientIsMobile">No more posts to load</span>
                <button type="button" v-else class="button is-success" disabled>No more posts to load</button>
            </template>
        </div>
    </div>
</template>

<script>
import isEmpty from 'lodash-es/isEmpty';
import { mediaMixin } from '@/mixins/MediaMixin.js';
import { postMixin } from '@/mixins/PostMixin.js';
import GroupLayout from './GroupLayout.vue';
import AdMultipost from '@/components/Content/Ad-Notification/Multipost/Index.vue';

// Posts that will never form a group
const groupExcludePostTypes = [
    'postTypeArticle',
    'postTypeNewsShort',
    'postTypePressRelease',
    'postTypeCartoon',
    'postTypePodcast',
    'postTypeNewscast'
];
// Allowed post types that can be top posts i.e. post that shows big image
const topPostIncludePostTypes = [
    'postTypeArticle',
    'postTypeCartoon',
    'postTypeClassified',
    'postTypeColumn',
    'postTypeLetter',
    'postTypeNewsShort',
    'postTypeNewscast',
    'postTypePodcast'
];

export default {
    name: 'LatestPosts',

    components: {
        GroupLayout,
        AdMultipost
    },

    mixins: [mediaMixin, postMixin],

    props: {
        topPost: {
            type: Object,
            default: () => ({})
        },
        posts: {
            type: Array,
            required: true
        },
        section: {
            type: Object,
            default: null
        },
        headerName: {
            type: String,
            required: true
        },
        noMore: {
            type: Boolean,
            required: true
        },
        isBusy: {
            type: Boolean,
            required: true
        },
        isEmit: {  // Emit instead of routing
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            limit: 20,
            offset: 0
        };
    },

    computed: {
        forceNoGroup() {
            return this.$route.name === 'search';
        },
        allPosts() {
            return [...(!isEmpty(this.topPost) ? [this.topPost] : []), ...this.posts];
        },
        groups() {
            if (this.allPosts.length === 0) {
                return [];
            }
            const groups = [
                {
                    isTopPost: true,
                    items: [this.allPosts[0]],
                    showAdsAfter: false
                }
            ];

            if (this.allPosts.length < 2) {
                return groups;
            }

            let group = {
                isTopPost: false,
                items: [this.allPosts[1]],
                showAdsAfter: false
            };
            let currentPostType = this.allPosts[1].post_type.key;
            let topPostCounter = 2;
            let adsPositionCounter = 2;
            for (let i = 2, len = this.allPosts.length; i < len; i++) {
                let post = this.allPosts[i];
                adsPositionCounter++;
                if (adsPositionCounter === 6 && !group.showAdsAfter) {
                    group.showAdsAfter = true;
                    adsPositionCounter = 0;
                }
                const postHasMedia = post.media && post.media[0];
                if (
                    !this.forceNoGroup &&
                    !postHasMedia &&
                    groupExcludePostTypes.indexOf(currentPostType) === -1 &&
                    currentPostType === post.post_type.key &&
                    (!this.section || (this.section && this.section.should_group_post_type))
                ) {
                    topPostCounter = topPostCounter + 1;
                    group.items.push(post);
                } else {
                    topPostCounter = topPostCounter + 1;
                    if (
                        topPostCounter >= 7 &&
                        group.items.length === 1 &&
                        topPostIncludePostTypes.indexOf(group.items[0].post_type.key) !== -1 &&
                        Array.isArray(group.items[0].media) &&
                        group.items[0].media.length
                    ) {
                        group.isTopPost = true;
                        topPostCounter = 0;
                    }
                    groups.push(group);
                    currentPostType = post.post_type.key;
                    group = {
                        isTopPost: false,
                        items: [post],
                        showAdsAfter: false
                    };
                }
            }
            if (group.items.length) {
                groups.push(group);
            }
            return groups;
        }
    },

    methods: {
        loadMore() {
            if (this.isBusy) {
                return;
            }
            if (!this.noMore) {
                if (!this.offset && this.posts.length) {
                    this.offset = this.limit;
                    //this.offset = this.posts.length;
                    // Limit instead?
                }
                this.$emit('fetch-more', { type: 'latest', limit: this.limit, offset: this.offset });
                this.offset += this.limit;
            }
            this.pages = this.pages + 1;
        },

        singlePostView(data) {
            console.log('singlepostview in latest, data=', data);
            this.$emit('single-post-view', data);
        }
    }
};
</script>
