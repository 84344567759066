<!-- @format -->
<template>
    <div class="is-flex is-flex-direction-column">
        <standard-modal v-if="isNotLoggedIn" @close="isNotLoggedIn = false">
            <div class="box">
                <auth @loggedIn="loggedIn" @close="isNotLoggedIn = false" />
            </div>
        </standard-modal>

        <standard-modal v-if="isAddingCredit" class-button="is-large" @close="isAddingCredit = false">
            <add-credit
                :post="post"
                @added="addedCredit"
                @subscribe="subscribeFromCredit"
                @cancelled="isAddingCredit = false"
                @close="isAddingCredit = false"
            />
        </standard-modal>

        <div v-if="isAddingSubscription" class="columns is-centered">
            <div class="column is-two-thirds has-background-white">
                <div class="mx-2">
                    <subscribe-to-add
                        :post="post"
                        @added-subscription="addedSubscription"
                        @cancelled="clickedAddSubscription(false)"
                    />
                </div>
            </div>
        </div>

        <added-subscription
            v-if="isAddedSubscription"
            :cost="addSubscriptionResults.cost"
            :publication="addSubscriptionResults.publication"
            :start-date="addSubscriptionResults.startDate"
            :term-name="addSubscriptionResults.termTypeFriendlyName"
            @close="addedSubscriptionClose"
        />

        <div v-if="!post && isBusy" class="columns mt-2">
            <div class="column has-text-centered">
                <busy-loader spinner-type="icon" />
            </div>
        </div>

        <show-error v-if="errors && errors.length" :errors="errors" />

        <div v-if="post && !isAddingSubscription" class="columns is-gapless">
            <div
                v-if="postCount && postCount > 1 && postIndex && !isShowFullWidth"
                class="column na-post-left-arrow-container is-1"
            >
                <div
                    v-if="!clientIsMobile"
                    ref="navLeftArrow"
                    class="na-post-left-arrow has-text-right is-clickable"
                    @click="prev"
                >
                    <span class="na-post-left-arrow-icon"><fa-icon class="mr-1" icon="chevron-left" /></span> PREV
                </div>
            </div>

            <div
                v-if="(!postCount || (postCount && postCount <= 1) || !postIndex) && !isShowFullWidth"
                class="column na-post-left-arrow-container is-1"
            ></div>

            <div class="column na-main-content" :class="[isShowFullWidth ? 'is-12' : 'is-7']">
                <div v-if="postType !== 'postTypeNativeAd' && !this.isHvnews" class="mx-4 mt-4">
                    <premium-ad />
                </div>

                <view-preview
                    v-if="post && !showFull && adminPreviewPost === null"
                    :post="post"
                    :option-post-types="optionPostTypes"
                    :is-just-purchased="isJustPurchased"
                    :crawler-check="crawlerCheck"
                    @prev="prev"
                    @next="next"
                    @purchase="purchase"
                    @comment="comment()"
                    @clicked-add-subscription="clickedAddSubscription"
                    @post-comment-count-updated="postCommentCountUpdated"
                />

                <view-full
                    v-if="(post && showFull) || adminPreviewPost !== null"
                    :admin-preview-post="adminPreviewPost"
                    :post="post"
                    :option-post-types="optionPostTypes"
                    :open-comments="isFullOpenComments"
                    :is-hvnews="isHvnews"
                    :crawler-check="crawlerCheck"
                    @prev="prev"
                    @next="next"
                    @comment="comment()"
                    @post-comment-count-updated="postCommentCountUpdated"
                />
            </div>

            <div v-if="isShowSidebar" class="column is-3">
                <sidebar-dock :ignore-preferences="!!ignorePreferences" @subscribe="clickedAddSubscription" />
            </div>

            <div
                v-if="postCount && postCount > 1 && postIndex < postCount - 1 && !isShowFullWidth"
                class="column na-post-right-arrow-container is-1"
            >
                <div
                    v-if="!clientIsMobile"
                    ref="navRightArrow"
                    class="na-post-right-arrow has-text-left is-clickable"
                    @click="next"
                >
                    NEXT <span class="na-post-right-arrow-icon"><fa-icon class="ml-1" icon="chevron-right" /></span>
                </div>
            </div>

            <div
                v-if="!postCount || (postCount && postCount <= 1) || postIndex >= postCount - 1"
                class="column na-post-right-arrow-container is-1"
            ></div>
        </div>

        <!-- Note that even if showAdminOptions is true, but user doesn't have admin access, it won't be shown -->
        <admin-options
            v-if="showAdminOptions && !isAddingSubscription && post"
            :post="post"
            @close="showAdminOptions = false"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { editorMixin } from '@/mixins/EditorMixin.js';
import { miscMixin } from '@/mixins/MiscMixin.js';
import { postMixin } from '@/mixins/PostMixin.js';
//import { verifyReceipt } from '@/services/monetization';
import AddedSubscription from '@/components/User/Account/Subscriptions/AddedSubscription/Index.vue';
import AddCredit from './Payment/AddCredit/Index.vue';
import AdminOptions from './Admin/Index.vue';
import PremiumAd from '@/components/Content/Ad-Notification/Premium/Index.vue';
import Auth from '@/components/User/Auth/Auth.vue';
import ShowError from '@/components/Common/Error.vue';
import SidebarDock from '@/components/Content/Sidebar/Dock/Index.vue';
import SubscribeToAdd from '@/components/Content/SubscribeTo/Add.vue';
import ViewFull from './Full.vue';
import ViewPreview from './Preview.vue';
import { sendFeedback } from '@/services/feedback.js';
import { purchasePost } from '@/services/post.js';
import { validateLinkCode } from '@/services/code.js';
import { checkIfCrawler } from '@/services/crawler.js';

export default {
    metaInfo() {
        let title = this.mainPublicationName;
        const metaInfo = { title };
        if (this.post) {
            if (this.post.title) {
                metaInfo.title = `${this.post.title} — ${title}`;
            }
        }

        return metaInfo;
    },

    components: {
        AddedSubscription,
        AddCredit,
        AdminOptions,
        PremiumAd,
        Auth,
        ShowError,
        SidebarDock,
        SubscribeToAdd,
        ViewFull,
        ViewPreview
    },

    mixins: [editorMixin, miscMixin, postMixin],

    props: {
        post: {
            type: Object,
            required: true
        },
        prevPostId: {
            type: Number,
            default: 0
        },
        nextPostId: {
            type: Number,
            default: 0
        },
        optionPostTypes: {
            type: Array,
            default: () => []
        },
        adminPreviewPost: {
            type: Object,
            default: function () {
                return null;
            }
        },
        postCount: {
            default: function () {
                return null;
            }
        },
        postIndex: {
            default: function () {
                return null;
            }
        },
        isAddingSubscription: {
            type: Boolean,
            default: false
        },
        // Request to show full with open comments (note that we also have a local)
        openComments: {
            type: Boolean,
            default: false
        },
        // 2018-12-20  MJL  Ignore user preferences?  We only need for this calling readPost() now because it governs what is returned as most recent comments
        ignorePreferences: {
            type: Boolean,
            default: true
        },
        autoPurchase: {
            type: Boolean,
            default: false
        }
    },

    monetizationProgressHandler: null,
    monetizationStartHandler: null,
    monetizationStopHandler: null,

    data() {
        return {
            isBusy: false,
            errors: [],
            linkCode: [],
            quillContent: null,
            dataSupplemental: {},
            hasDataSupplemental: false,
            showFull: document.monetization ? document.monetization.state === 'started' : false,
            isNotLoggedIn: false,
            isFullOpenComments: this.openComments,
            isAddingCredit: false,
            showAdminOptions: true,
            isAddedSubscription: false,
            addSubscriptionResults: null,
            isJustPurchased: false,
            purchaseTimeout: 2000, // Milliseconds to wait between a purchase - showing a 'thank you' message and then showing the full post
            crawlerCheck: {}
        };
    },

    computed: {
        ...mapGetters({
            mainPublicationName: 'publication/mainPublicationName'
        }),

        postType() {
            if (!this.post) {
                return;
            }
            return this.getPostTypeValue(this.optionPostTypes, this.post.post_type_id);
        },

        isHvnews() {
            return this.$route.name === 'hvnews';
            //return (Number(this.$route.query.is_hvnews) === 1) ? true : false;
        },

        /**
         * Show full width post display?
         * e.g. If mobile, we wouldn't be displaying the arrows (swipe only) and therefore 12 columns, but in any case - if the width is >= 768 (tablet), then always force to 6 columns
         */
        isShowFullWidth() {
            if (this.isHvnews) {
                return true;
            }

            if (this.clientIsMobile) {
                // If mobile, then we should not be displaying the navigation arrows (swipe only), so it will be full width unless screen width >= 768 (then it looks "odd")
                return !(this.clientWidth >= 768);
            } else {
                // If not mobile, then we should be displaying the navigation arrows, so it can't be full width
                return false;
            }
        },

        isShowSidebar() {
            return !this.isHvnews;
        }
    },

    watch: {
        post: {
            handler(val) {
                if (!this.hasPostPreview(val) || this.isPostType(val, 'postTypeObit')) {
                    this.showFull = true;
                } else {
                    // If we've been requested to show comments in props, then try to set to full view mode
                    if (
                        this.openComments &&
                        (this.postCostIsPaid(val) || this.postCostIsFree(val) || this.postCostIsSubscription(val))
                    ) {
                        this.isFullOpenComments = true;
                        this.showFull = true;
                    } else if (Number(this.$route.query.show_full) === 1 && val._has_subscription) {
                        this.showFull = true;
                    } else if (this.isHvnews) {
                        this.showFull = true;
                    } else {
                        this.showFull = false;
                    }
                }
                window.document.scrollingElement.scrollTo(0, 0);
            },
            immediate: true
        },

        postType(val) {
            if (!val) {
                return;
            }
            const dataSupplemental = this.setSupplementalData(this.post, val);
            if (dataSupplemental !== null) {
                Object.assign(this.dataSupplemental, dataSupplemental);
                this.hasDataSupplemental = true;
            }
        },
        /**
         * If open comments flag changes, update local
         */
        openComments() {
            this.isFullOpenComments = this.openComments;
        }
    },

    async created() { 
        // Is the visitor a crawler?
        const crawlerCheck = await checkIfCrawler();
        this.crawlerCheck = crawlerCheck;

        if (1 === 0 && document.monetization) {
            this.$options.monetizationProgressHandler = async (event) => {
                // This runs every few milliseconds so it's probably better to verify the first receipt
                if (this.showFull) {
                    return;
                }
                 this.showFull = true;
                // A payment has been received, now verify in backend
                // const res = await verifyReceipt({
                //     receipt: event.detail.receipt
                // });
                // if (res.success && !this.showFull) {
                //     this.showFull = true;
                // }
            };
            document.monetization.addEventListener('monetizationprogress', this.$options.monetizationProgressHandler);

            let stopTimeoutId = null;
            this.$options.monetizationStartHandler = () => {
                if (stopTimeoutId) {
                    clearTimeout(stopTimeoutId);
                    stopTimeoutId = null;
                }
            };
            document.monetization.addEventListener('monetizationstart', this.$options.monetizationStartHandler);

            this.$options.monetizationStopHandler = () => {
                if (stopTimeoutId) {
                    clearTimeout(stopTimeoutId);
                    stopTimeoutId = null;
                }

                // If tab is not active and monetization stops for about >= 15 secs, then stop showing full post
                if (document.hidden) {
                    stopTimeoutId = setTimeout(() => {
                        this.showFull = false;
                    }, 15000);
                } else {
                    this.showFull = false;
                }
            };
            document.monetization.addEventListener('monetizationstop', this.$options.monetizationStopHandler);
        }

        if (this.userIsLoggedIn && this.autoPurchase) {
            this.purchase();
        }

        if(Object.keys(this.$route.query).length > 0){ 
            const getVar = this.$route.query
            if(getVar.code !== undefined){
                this.checkLinkCode( getVar.code );
            }
        }
    },

    methods: {
        

        /**
         * Tell caller to go to previous article
         */
        prev() {
            this.$emit('prev');
        },

        /**
         * Tell caller to go to next article
         */
        next() {
            this.$emit('next');
        },

        /**
         * Purchase this article
         * If the user is not logged (e.g. they got here via a direct post), then show them and send them to signup
         */
        async processPurchase() {
            if (!this.userIsLoggedIn) {
                this.isNotLoggedIn = true;
                return;
            }

            try {
                const response = await purchasePost(this.post.id);
                this.isBusy = false;
                if (!response.success) {
                    if (response.errors) {
                        if (response.noCredits) {
                            this.isAddingCredit = true;
                        } else {
                            this.errors = response.errors;
                        }
                    }
                } else {
                    this.$emit('article-purchased', this.post);

                    // If the user just paid for this (vs. it's free or already paid for), then allow the preview to show a thank you message
                    if (
                        !this.postCostIsPaid(this.post) &&
                        !this.postCostIsFree(this.post) &&
                        !this.postCostIsSubscription(this.post)
                    ) {
                        this.isJustPurchased = true;
                        this.showFull = true;
                        // 2018-12-18  MJL  Commented-out.  This is meant to show a thank you message after an article is purchased.  Now don't know if we're doing this
                        /*
                                        this.$nextTick(() => {
                                            const timer = setTimeout(() => {
                                                this.showFull = true;
                                                this.isJustPurchased = false;
                                            }, this.purchaseTimeout);
                                        });
                                        */
                    } else {
                        this.showFull = true;
                    }
                }
            } catch (error) {
                this.isBusy = false;
            }
        },

        /**
         * Purchase post
         *
         * @param {mixed} optionalArg Optional argument from purchase
         */
        purchase(optionalArg = null) {
            if (document.monetization?.state === 'pending') {
                this.errors = ['Web Monetization is pending.'];
                return;
            }
            this.isFullOpenComments = typeof optionalArg === 'string' && optionalArg === 'showComments';

            // @todo  If we pay for the post, don't prompt
            // @todo  2017.07.31  MJL  Prompting should eventually be controllable as a profile option

            if (this.isPostPurchased(this.post)) {
                // If the post has previously been "purchased", just show the full version
                this.processPurchase();
                return;
            }

            // @todo  2018-03-28  MJL  Check for available credits before going to server?

            this.processPurchase();
            // @todo  2017.08.02  MJL  Not prompting for now (might be a profile option, but in initial mode, and no real charging will occur)

            return;
            /*
            if (parseInt(this.post.cost) === 0) {
                this.processPurchase();
            } else {
                const self = this;
                this.$swal({
                    title: 'Are you sure you want to purchase this article?',
                    text:
                        "Maybe this is an option that the user can set in their profile?  We can even let them set the flag here so they don't have to keep answering.",
                    type: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: "Yes, I'd like to purchase it",
                    cancelButtonText: 'No, not right now',
                    confirmButtonClass: 'button is-success',
                    cancelButtonClass: 'button is-danger',
                    buttonsStyling: false
                }).then(
                    () => {
                        this.processPurchase();
                    },
                    () => {
                        // Cancelled
                    }
                );
            }
            */
        },

        /**
         * Send feedback on a post (this is NOT a user comment, but rather general feedback to NA)
         */
        comment() {
            this.$swal({
                title: 'Leave us a comment',
                input: 'textarea',
                type: 'info',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Submit',
                cancelButtonText: 'Cancel',
                confirmButtonClass: 'button is-success',
                cancelButtonClass: 'button is-danger',
                buttonsStyling: false
            }).then(
                (comment) => {
                    if (comment && comment.length) {
                        this.sendComment(comment);
                    } else {
                        this.$swal({
                            type: 'error',
                            title: 'Please enter a comment',
                            text: 'Please enter your comment.',
                            showCancelButton: false
                        });
                    }
                },
                () => {
                    // Cancelled
                }
            );
        },

        /**
         * Send comment entered by user
         *
         * @param {String} comment Comment to be sent
         */
        async sendComment(comment) {
            const data = {
                postId: this.post.id,
                comment
            };
            const response = await sendFeedback(data);
            if (!response.success) {
                if (response.errors) {
                    this.$swal({
                        type: 'error',
                        title: 'Error submitting comment',
                        text: "We're sorry, there has been a problem submitting your comment.  Please try again.",
                        showCancelButton: false
                    });
                }
            } else {
                this.$swal({
                    type: 'success',
                    title: 'Thank you!',
                    text: 'Thank you!  We appreciate your comments.',
                    showCancelButton: false
                });
            }
        },

        /**
         * A section was updated
         *
         * @param {Object} data Section data that was updated - this is *NOT* the same as the section object
         */
        sectionUpdated(data) {
            this.$emit('section-updated', data);
        },

        /**
         * A section was removed
         *
         * @param {Object} section Section that was removed
         */
        sectionRemoved(section) {
            this.$emit('section-removed', section);
        },

        /**
         * The user just added a payment method because we couldn't process payment.  We now need to charge for the article.
         */
        async addedPaymentMethod() {
            this.isAddingPaymentMethod = false;
            await this.loadUserInfo();
            this.processPurchase();
        },

        /**
         * A subscription was added, show pop-up message
         * It isn't necessarily the same publication as the current post though, so reload from the server vs. just updating the currently loaded post id.
         *
         * @param {Object} registerResults Results of registering subscription
         *
         * @todo  2018-06-01  MJL  If we ever change it so that the user cannot select other publications to subscribe to (i.e. it's locked to the current posts publication),
         *                         Then we can avoid reloading the post and just set post._has_subscription
         */
        addedSubscription(registerResults) {
            this.showFull = false;
            this.$nextTick(() => {
                this.addSubscriptionResults = registerResults;
                this.isAddedSubscription = true;
            });
        },

        /**
         * A subscription was added, pop-up message was shown, so now hide
         */
        addedSubscriptionClose() {
            this.isAddedSubscription = false;
            this.$router.replace({
                name: 'post-viewer',
                params: {
                    ...this.$route.params
                },
                query: {
                    show_full: 1
                }
            });
            window.location.reload();
        },

        /**
         * Credit was added
         */
        addedCredit(credit) {
            this.$nextTick(() => {
                this.isAddingCredit = false; // Hide add credit form
                this.processPurchase();
            });
        },
        /**
         * A new payment method and credit added
         */
        addedPaymentMethodCredit(data) {
            this.$emit('added-payment-method', {
                paymentMethodId: data.paymentMethodId
            });
            //this.paymentMethod = data.paymentMethodId;
            //this.isAddingPaymentMethod = false;  // Hide add payment form
            this.$nextTick(() => {
                this.isAddingCredit = false; // Hide add credit form
                this.processPurchase();
            });
        },

        /**
         * User logged in
         */
        loggedIn() {
            window.location.href = `/post/link/open/${this.$route.params.identifier}`;
        },

        subscribeFromCredit() {
            this.isAddingCredit = false;
            this.$router.push({
                name: 'post-viewer',
                params: {
                    ...this.$route.params
                },
                query: {
                    subscribe: 1
                }
            });
        },

        /**
         * Add subscription toggled on or off
         *
         * @param {Boolean} flag
         */
        clickedAddSubscription(flag) {
            if (flag && !this.userIsLoggedIn) {
                this.isNotLoggedIn = true;
            }
        },

        /**
         * Post comment count was updated
         *
         * @param {Integer|null} newCount
         */
        postCommentCountUpdated(newCount) {
            this.$store.commit('viewer/setPostCommentCount', newCount);
        },


        async checkLinkCode( code ) {
            
            try { 
                const result = await validateLinkCode(code,this.post.id);
                if (!result.success) {
                    this.showFull = false;
                    if (result.errors) {
                        this.errors = result.errors;
                    }
                } else {
                    this.linkCode = result.linkcode;
                    if(this.linkCode.length){
                        this.showFull = true;
                    }
                    return result;
                }
            } catch (error) {
                this.errors = [error.message];
            }
        },
    }
};
</script>
