<!-- @format -->
<template>
    <p>
        <a :href="facebookLink" target="_blank" rel="noopener noreferrer"
            ><fa-icon class="na-icon-facebook" :class="iconClass" :icon="['fab', 'facebook-square']" size="2x"
        /></a>
        <a :href="twitterLink" target="_blank" rel="noopener noreferrer"
            ><fa-icon class="na-icon-twitter" :class="iconClass" :icon="['fab', 'twitter-square']" size="2x"
        /></a>
        <a :href="instagramLink" target="_blank" rel="noopener noreferrer"
            ><fa-icon class="na-icon-instagram" :class="iconClass" :icon="['fab', 'instagram-square']" size="2x"
        /></a>
    </p>
</template>

<script>

export default {
    props: {
        iconClass: {
            type: String,
            default: null,
        }
    },

    computed: {
        facebookLink() {
            return naVariables.urlSjFacebook;
        },
        twitterLink() {
            return naVariables.urlSjTwitter;
        },
        instagramLink() {
            return naVariables.urlSjInstagram;
        }
    }
};
</script>
