<!-- @format -->
<template>
    <div class="columns is-centered is-gapless my-3">
        <article class="column is-8-tablet" style="z-index: 1">
            <div class="na-intro-featured-container p-4">
                <premium-ad />

                <div class="is-flex is-align-items-center is-flex-wrap-wrap mb-1">
                    <h1 class="has-text-primary has-text-weight-bold is-size-5 is-uppercase mr-3">
                        {{ headerName }} <fa-icon v-if="!isLoaded" icon="circle-notch" spin />
                    </h1>
                    <search-control
                        v-if="$route.name === 'search'"
                        :term="$route.query.q"
                        :sort="$route.query.sort"
                        :count="count"
                    />
                </div>

                <latest
                    v-if="topPost || (latest && latest.length)"
                    :top-post="topPost"
                    :posts="latest"
                    :section="section"
                    :header-name="headerName"
                    :no-more="noMoreLatest"
                    :is-busy="!isLoaded && lastActiveType === 'latest'"
                    @fetch-more="fetchMore"
                />

                <template v-else-if="!isLoaded">
                    <div class="columns">
                        <div class="column">
                            <div class="loading-placeholder-intro-title">
                                <!-- placeholder -->
                            </div>
                            <div class="loading-placeholder-intro-author">
                                <!-- placeholder -->
                            </div>
                            <div class="loading-placeholder-intro-text">
                                <!-- placeholder -->
                            </div>
                        </div>
                        <div class="column">
                            <div class="loading-placeholder-intro-image">
                                <!-- placeholder -->
                            </div>
                        </div>
                    </div>
                    <div class="columns mt-4">
                        <div class="column">
                            <div class="loading-placeholder-intro-title">
                                <!-- placeholder -->
                            </div>
                            <div class="loading-placeholder-intro-author">
                                <!-- placeholder -->
                            </div>
                            <div class="loading-placeholder-intro-text">
                                <!-- placeholder -->
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </article>

        <aside class="column is-narrow px-0 is-3-tablet">
            <sidebar-dock
                :blotters="blotters"
                :obituaries="obituaries"
                :news="news"
                :opinions="opinions"
                :events="events"
                :sports="sports"
                :is-busy="!isLoaded"
                :last-active-type="lastActiveType"
                :ignore-preferences="ignorePreferences"
                @fetch-more="fetchMore"
            />
        </aside>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { miscMixin } from '@/mixins/MiscMixin.js';
import { getNow } from '@/helpers/date.js';
import Latest from './Latest.vue';
import SidebarDock from '@/components/Content/Sidebar/Dock/Index.vue';
import { readPostsIntro } from '@/services/corporate';
import PremiumAd from '@/components/Content/Ad-Notification/Premium/Index.vue';
import SearchControl from './SearchControl.vue';

export default {
    name: 'ContentIntro',

    components: {
        Latest,
        SidebarDock,
        PremiumAd,
        SearchControl
    },

    mixins: [miscMixin],

    data() {
        let showWelcomeSection = window.naShared.storage.getItem('showWelcomeSection');
        if (showWelcomeSection === null) {
            showWelcomeSection = true;
        } else {
            showWelcomeSection = JSON.parse(showWelcomeSection);
        }
        return {
            isLoaded: false,
            lastActiveType: null,
            showWelcomeSection,
            showPublicationPicker: false,
            topPost: null,
            latest: [],
            news: [],
            opinions: [],
            sports: [],
            sponsored: [],
            events: [],
            blotters: [],
            obituaries: [],
            noMoreLatest: false,
            noMoreBlotter: false,
            noMoreObit: false,
            localSelectedPublications: [],
            searchResults: null,
            count: 0
        };
    },

    computed: {
        ...mapGetters({
            ignorePreferences: 'viewer/ignorePreferences',
            isEnabledWelcomeSection: 'publication/mainPublicationHasWelcomeSection',
            mainPublicationName: 'publication/mainPublicationName',
            getSectionByRoute: 'user/getSectionByRoute'
        }),
        /**
         * Return current year
         *
         * @return {String}
         */
        year() {
            return getNow({ isServerTime: false }).format('YYYY');
        },
        /**
         * We don't want images at all in certain cases (e.g. when showing search results)
         *
         * @return {bool}
         */
        section() {
            return this.getSectionByRoute(this.$route.params.sectionRoute);
        },
        headerName() {
            if (this.section) {
                return this.section.name;
            } else if (this.$route.name === 'search') {
                return 'Search Results';
            }
            return 'The Latest';
        }
    },

    watch: {
        showWelcomeSection(val) {
            window.naShared.storage.setItem('showWelcomeSection', JSON.stringify(val));
        },
        '$route.params.sectionRoute'() {
            this.loadPosts();
            this.lastActiveType = 'latest';
        },
        '$route.query.sort'() {
            this.loadPosts();
            this.lastActiveType = 'latest';
        },
        '$route.query.q'() {
            this.loadPosts();
            this.lastActiveType = 'latest';
        },
        /**
         * Preferences flag changed
         */
        ignorePreferences() {
            this.loadPosts();
        }
    },

    async created() {
        this.localSelectedPublications = JSON.parse(window.naShared.storage.getItem('selectedPublications')) ?? [];
        await this.loadPosts();
    },

    methods: {
        /**
         * Read featured intro posts
         */
        async loadPosts() {
            this.isLoaded = false;
            const data = {
                ignorePreferences: this.ignorePreferences
            };

            if (!this.userIsLoggedIn) {
                data.publications = this.localSelectedPublications;
            }
            if (this.$route.params.sectionRoute) {
                data.sectionRoute = this.$route.params.sectionRoute;
            }

            if (this.$route.name === 'search') {
                data.searchTerms = this.$route.query.q
                    .match(/("[^"]*"|'[^']*'|[\S]+)+/g)
                    .map((token) => token.replace(/(^")|("$)/g, ''));
                data.searchSort = this.$route.query.sort;
            }

            try {
                const response = await readPostsIntro(data);
                if (response.success) {
                    const posts = response.posts;

                    const topPosts = posts['top-news'];
                    this.topPost = topPosts && topPosts[0] ? topPosts[0] : null;
                    this.latest = posts.latest;
                    this.news = posts.news;
                    this.events = posts.events;
                    this.opinions = posts.opinions;
                    this.sports = posts.sports;
                    this.sponsored = posts.sponsored;
                    this.blotters = posts.blotters;
                    this.obituaries = posts.obituaries;
                    this.count = response.count ?? 0;
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    this.$router.replace({
                        name: 'not-found',
                        params: { pathMatch: this.$route.path },
                        query: this.$route.query
                    });
                    return;
                } else {
                    throw error;
                }
            }

            this.isLoaded = true;
        },

        async fetchMore({ type, offset, limit = null }) {
            this.isLoaded = false;
            this.lastActiveType = type;
            const data = {
                type,
                offset,
                limit,
                ignorePreferences: this.ignorePreferences
            };
            if (!this.userIsLoggedIn) {
                data.publications = this.localSelectedPublications;
            }
            if (this.$route.params.sectionRoute) {
                data.sectionRoute = this.$route.params.sectionRoute;
            }
            if (this.$route.name === 'search') {
                data.searchTerms = this.$route.query.q
                    .match(/("[^"]*"|'[^']*'|[\S]+)+/g)
                    .map((token) => token.replace(/(^")|("$)/g, ''));
                data.searchSort = this.$route.query.sort;
            }

            const response = await readPostsIntro(data);
            if (response.success) {
                if (type === 'latest') {
                    this.noMoreLatest = !response.posts.latest || !response.posts.latest.length;
                    this.latest = this.latest.concat(response.posts.latest);
                }
                if (type === 'news') {
                    this.noMoreNews = !response.posts.news || !response.posts.news.length;
                    this.news = this.news.concat(response.posts.news);
                }
                if (type === 'opinions') {
                    this.opinions = this.opinions.concat(response.posts.opinions);
                }
                if (type === 'sports') {
                    this.sports = this.sports.concat(response.posts.sports);
                }
                if (type === 'sponsored') {
                    this.sponsored = this.sponsored.concat(response.posts.sponsored);
                }
                if (type === 'event') {
                    this.events = this.events.concat(response.posts.events);
                }
                if (type === 'blotter') {
                    this.noMoreBlotter = !response.posts.blotters || !response.posts.blotters.length;
                    this.blotters = this.blotters.concat(response.posts.blotters);
                }
                if (type === 'obituary') {
                    this.noMoreObit = !response.posts.obituaries || !response.posts.obituaries.length;
                    this.obituaries = this.obituaries.concat(response.posts.obituaries);
                }
            }

            this.isLoaded = true;
        }
    }
};
</script>
