/** @format */
import axios from 'axios';

/**
 * Create a new post
 *
 * @param {Object} data Post data to be saved
 *
 * @return Promise for resolve, reject
 */
export const validateLinkCode = function (code , postId ) {
    return axios
        .post(naRoutes.validateLinkCode, {
            data: {
                post_id : postId,
                code : code
            }
        })
        .then((response) => response.data);
};