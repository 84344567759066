<!-- @format -->
<template>
    <div v-if="isVisible && messages.length" class="is-flex na-message-bar mb-4 py-1">
        <message-bar-list :messages="messages" @close="isVisible = false" />
    </div>
</template>

<script>
import MessageBarList from './List.vue';
import { mapState, mapMutations } from 'vuex';

export default {
    components: {
        MessageBarList
    },

    props: {
        initialMessage: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            isVisible: true
        };
    },

    computed: {
        ...mapState({
            messages: (state) => state.app.messageBar.messages
        })
    },

    mounted() {
        if (this.initialMessage) {
            this.setMessages(this.initialMessage);
        }
    },

    methods: {
        ...mapMutations({
            setMessages: 'app/setMessages'
        }),
        /**
         * Close the message bar
         */
        close() {
            this.isVisible = false;
        }
    }
};
</script>
