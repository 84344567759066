<!-- @format -->
<template>
    <article class="columns" :class="{ 'is-gapless': clientIsMobile }">
        <!-- Placeholder for group image -->
        <div v-if="!clientIsMobile && showMedia" class="column is-one-third">
            <slot name="groupImage" />
        </div>
        <div class="column">
            <p v-if="showGroupTitle" class="is-uppercase is-size-7 has-text-weight-normal">{{ groupTitle }}</p>
            <post-item
                v-for="post in group"
                :key="post.id"
                :post="post"
                :show-title="showTitle"
                :show-subtitle="showSubtitle"
                :show-authors="showAuthors"
                :show-publication-icon="showPublicationIcon"
                :show-publication-name="showPublicationName"
                :show-content-at="showContentAt"
                :show-snippet10="showSnippet10"
            >
                <slot :post="post" />
            </post-item>
        </div>
    </article>
</template>

<script>
import PostItem from './Item.vue';

export default {
    components: {
        PostItem
    },

    props: {
        group: {
            type: Array,
            required: true
        },
        groupTitle: {
            type: String,
            required: true
        },
        showGroupTitle: {
            type: Boolean,
            default: true
        },
        showTitle: {
            type: Boolean,
            default: true
        },
        showSubtitle: {
            type: Boolean,
            default: true
        },
        showAuthors: {
            type: Boolean,
            default: false
        },
        showPublicationIcon: {
            type: Boolean,
            default: true
        },
        showPublicationName: {
            type: Boolean,
            default: true
        },
        showContentAt: {
            type: Boolean,
            default: true
        },
        showSnippet10: {
            type: Boolean,
            default: false
        },
        showMedia: {
            type: Boolean,
            default: true
        }
    }
};
</script>
