<!-- @format -->
<template>
    <div v-if="hasAds" class="columns">
        <div class="column">
            <div class="na-ad-animation is-flex is-justify-content-center">
                <a :href="!video ? url : undefined" target="_blank" rel="noopener noreferrer" @click="adClick">
                    <figure>
                        <picture>
                            <source v-for="set in sourceSet" :key="set.mime" :type="set.mime" :srcset="set.url" />
                            <img
                                :src="adMediaUrl"
                                :alt="altText"
                                :title="title"
                                width="1456"
                                height="180"
                                loading="lazy"
                            />
                        </picture>
                    </figure>
                </a>
            </div>

            <video-modal v-if="showVideo" :video="video" :url="url" @close="closeVideo" @ad-click="logHit" />
        </div>
    </div>
</template>

<script>
import shuffle from 'lodash-es/shuffle';
import { recordPostHit } from '@/services/post.js';
import { mapGetters } from 'vuex';
import { mediaMixin } from '@/mixins/MediaMixin.js';
import VideoModal from '../Core/VideoModal.vue';

export default {
    components: {
        VideoModal
    },

    mixins: [mediaMixin],

    props: {
        shuffleTime: {
            type: Number,
            default: 10000
        }
    },

    data() {
        return {
            ads: [],
            adIndex: 0,
            showVideo: false,
            timer: null
        };
    },

    computed: {
        ...mapGetters({
            nativeAds: 'viewer/premiumNativeAds'
        }),
        adCount() {
            return this.nativeAds.length;
        },
        post() {
            return this.ads[this.adIndex];
        },
        video() {
            if (!this.post) {
                return;
            }
            return this.post.post_media_videos[0];
        },
        hasAds() {
            return this.adCount > 0;
        },
        adImage() {
            if (!this.post) {
                return;
            }
            return this.post?.media.find((media) => media.image_type === 'premiumLong');
        },
        url() {
            return this.post?.post_native_ads && this.post.post_native_ads.length
                ? this.post.post_native_ads[0].url
                : '';
        },
        adMediaUrl() {
            return this.postMediaUrl(this.adImage, 'natad');
        },
        sourceSet() {
            if (!this.adImage) {
                return [];
            }
            return this.postMediaUrlSet(this.adImage);
        },
        altText() {
            return this.mediaAlt(this.adImage, 'Ad Image');
        },
        title() {
            return this.mediaTitle(this.adImage, 'Ad Image');
        }
    },

    watch: {
        nativeAds(val) {
            this.ads = shuffle(val);
        }
    },

    created() {
        this.ads = shuffle(this.nativeAds);
        this.resetTimer();
    },

    methods: {
        resetTimer() {
            this.clearTimer();
            if (this.timer === null) {
                this.timer = setTimeout(() => {
                    this.shuffle();
                }, this.shuffleTime);
            }
        },
        clearTimer() {
            if (this.timer !== null) {
                clearTimeout(this.timer);
                this.timer = null;
            }
        },
        shuffle() {
            if (this.adCount) {
                if (this.adIndex + 1 === this.adCount) {
                    this.adIndex = 0;
                } else {
                    this.adIndex = this.adIndex + 1;
                }
                this.resetTimer();
            }
        },
        adClick() {
            if (this.video) {
                this.clearTimer();
                this.showVideo = true;
            }
            this.logHit();
        },
        closeVideo() {
            this.resetTimer();
            this.showVideo = false;
        },
        logHit() {
            recordPostHit({
                post_id: this.post.id,
                subsource: 'NativeAd',
                url: this.url
            });
        }
    }
};
</script>
