<!-- @format -->
<template>
    <div>
        <p class="na-sidebar-copyright-text">Copyright &copy; {{ year }}. Zelacom Electronic Publishing, LLC</p>
        <p class="na-sidebar-copyright-text">All rights reserved</p>
    </div>
</template>

<script>
export default {
    props: {
        year: {
            type: String,
            default: ''
        }
    }
};
</script>
