<!-- @format -->
<template>
    <post-hv-news v-if="this.$route.name === 'hvnews'"
        :post="post"
        :is-top-post="isTopPost"
        @single-post-view="$emit('single-post-view', $event)"
    />
    <post v-else
        :post="post"
        :is-top-post="isTopPost"
    />
</template>

<script>
import Post from './Post.vue';
import PostHvNews from './PostHvNews.vue';

export default {
    components: {
        Post,
        PostHvNews
    },
    props: {
        post: {
            type: Object,
            required: true
        },
        isTopPost: {
            type: Boolean,
            default: false
        }
    }
};
</script>
