<!-- @format -->
<template>
    <div class="na-sidebar-dock-container" :class="additionalClass">
        <ad-core :shuffle-time="shuffleTime" />
    </div>
</template>

<script>
import AdCore from '../Core/Index.vue';

export default {
    components: {
        AdCore
    },

    props: {
        shuffleTime: {
            type: Number,
            default: 8000
        },
        additionalClass: {
            type: [String, Array, Object],
            default: () => []
        }
    }
};
</script>
