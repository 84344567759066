<!-- @format -->
<template>
    <div class="columns">
        <div class="column">
            <div v-if="includePages && pageNumber && countTotal && countPerPage && totalPages > 1">
                <nav class="pagination is-centered" :class="[navClass, clientIsMobile ? 'is-small' : '']">
                    <a class="pagination-previous" :disabled="pageNumber < 2" @click="changePage('first')">First</a>
                    <a class="pagination-previous" :disabled="pageNumber < 2" @click="changePage('prev')">Previous</a>
                    <a class="pagination-next" :disabled="pageNumber >= totalPages" @click="changePage('next')">Next</a>
                    <a class="pagination-next" :disabled="pageNumber >= totalPages" @click="changePage('last')">Last</a>
                    <ul class="pagination-list">
                        <li v-if="minPage() > 1">
                            <span class="pagination-ellipsis">&hellip;</span>
                        </li>
                        <li v-for="page in pages" :key="page">
                            <a
                                class="pagination-link"
                                :class="{ 'is-current': pageNumber === page }"
                                @click="changePage(page)"
                                >{{ page }}</a
                            >
                        </li>
                        <li v-if="maxPage() < totalPages">
                            <span class="pagination-ellipsis">&hellip;</span>
                        </li>
                    </ul>
                </nav>
            </div>

            <div v-if="includePerPage" class="columns is-mobile">
                <div class="column is-one-third">
                    <field name="itemsPerPageCount">
                        <label class="label">Items per page</label>
                        <div class="control is-expanded">
                            <div class="select">
                                <select v-model="localCountPerPage" @change="countPerPageChange">
                                    <option :value="5">5</option>
                                    <option :value="10">10</option>
                                    <option :value="20">20</option>
                                    <option :value="25">25</option>
                                    <option :value="50">50</option>
                                </select>
                            </div>
                        </div>
                    </field>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// This is just a simple pagination helper - doesn't handle calling for more records, just displays a nice little selector

export default {
    props: {
        pageNumber: {
            type: Number,
            default: 1
        },
        countTotal: {
            type: Number,
            default: 0
        },
        countPerPage: {
            type: Number,
            default: 25
        },
        pageButtons: {
            type: Number,
            default: 15
        },
        navClass: {
            type: String,
            default: ''
        },
        includePerPage: {
            type: Boolean,
            default: true
        },
        includePages: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        localCountPerPage: {
            get() {
                return this.countPerPage;
            },
            set(value) {
                this.$emit('update:countPerPage', Number(value));
            }
        },

        pages() {
            const result = [];
            for (let index = this.minPage(); index <= this.maxPage(); index++) {
                result.push(index);
            }
            return result;
        },

        /**
         * Calculate total number of pages
         *
         * @return {Integer} Total number of pages
         */
        totalPages() {
            const pages = Math.trunc(this.countTotal / this.countPerPage); // Total number of pages truncated
            return this.countTotal % this.countPerPage ? pages + 1 : pages; // Will take another page if a remainder
        }
    },
    methods: {
        /**
         * Page number change
         *
         * @param {mixed} page Page number to change to (or 'first', 'prev', 'next' or 'last')
         */
        changePage(page) {
            let newPage = null;

            if (page) {
                if (typeof page === 'string') {
                    if (page === 'first') {
                        newPage = 1;
                    }
                    if (page === 'prev') {
                        newPage = this.pageNumber - 1;
                    }
                    if (page === 'next') {
                        newPage = this.pageNumber + 1;
                    }
                    if (page === 'last') {
                        newPage = this.totalPages;
                    }
                } else {
                    newPage = page;
                }

                if (newPage >= 1 && newPage <= this.totalPages) {
                    this.$emit('change-page', newPage);
                }
            }
        },
        /**
         * Minimum page
         */
        minPage() {
            return (
                Math.max(Math.min(this.pageNumber - this.pageButtons / 2, this.totalPages - this.pageButtons), 1) | 0
            ); // The "| 0" will truncate the value so we don't end up with fractional page numbers
        },
        /**
         * Maximum page
         */
        maxPage() {
            return Math.min(Math.max(this.pageNumber + this.pageButtons / 2, this.pageButtons), this.totalPages) | 0; // The "| 0" will truncate the value so we don't end up with fractional page numbers
        },
        countPerPageChange(event) {
            this.$emit('countPerPageChange', Number(event.target.value));
        }
    }
};
</script>
