<!-- @format -->
<template>
    <div>
        <img :src="imageUrl('newspapers-trans.png')" alt="Shawangunk Journal" title="Shawangunk Journal" />
        <p class="title">An Urgent Appeal</p>

        <div>
            <p>
                Now more than ever, subscribers are what keep local, independent newspapers going. We depend 100% on the
                support of the community we serve &mdash; <span class="has-text-weight-bold">and that means YOU.</span>
            </p>
            <div class="shim">
                We need your help to continue our mission and remain your trusted news source. Please choose a price
                below for the subscription that works for you. We truly appreciate any level of support you choose.
                Thank you!
                <p class="has-text-right">&mdash; The Shawangunk Journal Team</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mediaMixin } from '@/mixins/MediaMixin.js';

export default {
    mixins: [mediaMixin]
};
</script>
