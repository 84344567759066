<!-- @format -->
<template>
    <div>
        <template v-if="!publication">
            <div v-if="!useOnlySelectedPublication" class="mb-2">
                <p class="subtitle na-profile-subtitle">Add A Subscription</p>
            </div>

            <div
                v-if="!useOnlySelectedPublication || (useOnlySelectedPublication && !selectedPublicationId)"
                class="columns"
            >
                <div class="column">
                    <div class="field">
                        <label class="label">Please select a publication</label>
                        <div class="control">
                            <div class="select">
                                <select v-model="publication">
                                    <option v-for="option in publicationOptions" :key="option.id" :value="option.id">
                                        {{ option.text }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <!-- Not showing publications without a subscription, but code is here in case we do -->
        <p
            v-if="availableSubscriptionTerms && !availableSubscriptionTerms.length && publication"
            class="has-text-weight-bold"
        >
            This publication does not offer subscription plans
        </p>

        <add-guided
            v-if="availableSubscriptionTerms && availableSubscriptionTerms.length && publication"
            :caller="caller"
            :publication="publicationObject(publication)"
            :subscription-terms-description="subscriptionTermsDescription"
            :subscription-terms-what-you-get="subscriptionTermsWhatYouGet"
            :subscription-terms="subscriptionTerms"
            @added="$emit('added-subscription', $event)"
            @cancelled="cancelled"
        />

        <div v-if="errors && errors.length" class="columns">
            <div class="column">
                <show-error :errors="errors" />
            </div>
        </div>

        <div v-if="messages && messages.length" class="columns">
            <div class="column">
                <show-success :messages="messages" />
            </div>
        </div>
    </div>
</template>

<script>
import { accountingMixin } from '@/mixins/AccountingMixin.js';
import { dataHelperMixin } from '@/mixins/DataHelperMixin.js';
import { moneyMixin } from '@/mixins/MoneyMixin.js';
import { postMixin } from '@/mixins/PostMixin.js';
import AddGuided from './Steps/Guided.vue';
import ShowError from '@/components/Common/Error.vue';
import ShowSuccess from '@/components/Common/Success.vue';

export default {
    components: {
        AddGuided,
        ShowError,
        ShowSuccess
    },

    mixins: [accountingMixin, dataHelperMixin, moneyMixin, postMixin],

    props: {
        optionPublications: {
            type: Array,
            default: () => []
        },
        subscriptionTermsDescription: {
            default: function () {
                return null;
            }
        },
        subscriptionTermsWhatYouGet: {
            default: function () {
                return null;
            }
        },
        subscriptionTerms: {
            type: Array,
            default: () => []
        },
        // Preselected publication id (will cause add to be assumed - so user doesn't have to click the 'add subscription button')
        selectedPublicationId: {
            default: function () {
                return null;
            }
        },
        // Use ONLY the preselected publication id above?
        useOnlySelectedPublication: {
            type: Boolean,
            default: false
        },
        // A way of altering behavior slightly depending on "caller" - e.g. We want to center "Subscribe to the Shawangunk Journal" and add a background if being called from 'non-my account'
        caller: {
            type: String,
            default: 'my-account'
        }
    },

    data() {
        return {
            errors: [],
            messages: [],
            publication: this.selectedPublicationId,
            availableSubscriptionTerms: this.subscriptionTerms ? this.subscriptionTerms : null,
            isShowingSuccess: false
        };
    },

    computed: {
        /**
         * Return list of publication options
         *
         * @return {Array}
         */
        publicationOptions() {
            return [
                {
                    id: null,
                    value: null,
                    text: 'Please Select'
                },
                ...this.optionPublications
            ];
        }
    },

    watch: {
        publication() {
            this.paymentMethod = null;
            this.applyBalance = false;
            this.availableSubscriptionTerms = null; // Set to null so we don't show 'this publication does not offer subscription plans' until loaded
            this.agreeTerms = false;
            this.$emit('publication-changed', this.publication);
        },
        /**
         * When subscription terms change, set to local and select
         */
        subscriptionTerms() {
            this.availableSubscriptionTerms = this.subscriptionTerms;
        }
    },

    methods: {
        /**
         * Reset all fields
         */
        reset() {
            this.publication = null;
            this.messages = [];
            this.errors = [];
        },

        /**
         * Cancelled
         */
        cancelled() {
            this.reset();
            this.$emit('cancelled');
        },

        /**
         * Return publication object
         *
         * @param {Integer} publicationId Id to find
         *
         * @return {mixed} Publication object or null if not found
         */
        publicationObject(publicationId) {
            return this.publication
                ? this.optionPublications.find((publication) => publication.id === publicationId)
                : null;
        }
    }
};
</script>
