<!-- @format -->
<template>
    <div v-if="post" ref="box" class="na-post-comment-box">
        <div v-if="isVisible" class="na-post-comment-box-inner">
            <comment-add :post="post" @comment-added="commentAdded"></comment-add>

            <comment-list
                :post="post"
                :comment-list-revision="commentListRevision"
                @comment-count-updated="commentCountUpdated"
            ></comment-list>
        </div>
    </div>
</template>

<script>
import CommentAdd from './Add.vue';
import CommentList from './List/Index.vue';

export default {
    components: {
        CommentAdd,
        CommentList
    },

    props: {
        post: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            isVisible: true,
            commentListRevision: 0
        };
    },

    methods: {
        /**
         * A comment was added.  We'll force a reset on the comment list
         */
        commentAdded() {
            this.commentListRevision++; // Force list to refresh
        },
        /**
         * Comment count has updated - note that unlike the local one in the store, this has been updated from the server
         */
        commentCountUpdated(commentCount) {
            //this.commentCount = commentCount;
            this.$emit('comment-count-updated', commentCount);
        },

        scrollIntoView() {
            this.$refs.box.scrollIntoView();
        }
    }
};
</script>
