<!-- @format -->
<template>
    <field name="email" :errors="errors">
        <label class="label">Email Address</label>
        <p class="control">
            <input
                v-model="emailv"
                class="input"
                type="text"
                placeholder="email"
                autocomplete="email"
                autofocus="autofocus"
            />
        </p>
    </field>
</template>

<script>
import Field from '@/components/Common/BasicField.vue';

export default {
    components: {
        Field
    },
    props: {
        email: {
            type: String,
            default: null
        },
        errors: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            isBusy: false,
            emailv: this.email
        };
    },
    watch: {
        emailv() {
            this.updateFormData();
        }
    },
    methods: {
        /**
         * Update form data for parent
         */
        updateFormData() {
            this.$emit('update:email', this.emailv);
        }
    }
};
</script>
