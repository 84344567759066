<!-- @format -->
<template>
    <standard-modal @close="$emit('close')">
        <div class="box">
            <field name="email" :errors="errors">
                <div class="control">
                    <p>
                        Unable to retrieve email address from {{ socialType }}. Please enter a valid email address to
                        continue.
                    </p>
                    <p>
                        <label class="label">Email address</label>
                        <input
                            :value="email"
                            class="input"
                            type="text"
                            required
                            @input="$emit('update:email', $event.target.value.trim().toLowerCase())"
                        />
                    </p>
                    <p v-for="error in errors" :key="error" class="help is-danger">
                        {{
                            error === 'Unauthorized'
                                ? 'Email address maybe in use by other account or may be invalid.'
                                : error
                        }}
                    </p>
                </div>
            </field>
            <div class="field">
                <div class="control">
                    <button class="button is-success" :class="{ 'is-loading': isBusy }" @click="retry">Connect</button>
                </div>
            </div>
        </div>
    </standard-modal>
</template>

<script>
export default {
    props: {
        email: {
            type: String,
            required: true
        },
        socialType: {
            type: String,
            default: ''
        },
        isBusy: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        retry() {
            if (!this.email) {
                return;
            }
            this.$emit('retry');
        }
    }
};
</script>
