<!-- @format -->
<template>
    <form ref="form" class="is-flex is-flex-direction-column">
        <div class="field">
            <label class="label">Card number</label>
            <div class="control">
                <input
                    v-model="paymentMethod.ccNumber"
                    class="input"
                    :class="{ 'na-input-error': hasInputError(errors, 'ccNumber', '|', true) || chargeErrors.length }"
                    type="tel"
                    inputmode="numeric"
                    pattern="[0-9\s]{13,19}"
                    placeholder="Card number"
                    autocomplete="cc-number"
                    maxlength="19"
                    required
                />
            </div>
            <span class="icon is-large"><fa-icon :icon="['fab', 'cc-visa']" size="2x" /></span>
            <span class="icon is-large"><fa-icon :icon="['fab', 'cc-mastercard']" size="2x" /></span>
            <span class="icon is-large"><fa-icon :icon="['fab', 'cc-discover']" size="2x" /></span>
            <span class="icon is-large"><fa-icon :icon="['fab', 'cc-amex']" size="2x" /></span>
        </div>

        <div class="field is-grouped">
            <div class="control">
                <label class="label">Exp month</label>
                <div
                    class="select"
                    :class="{
                        'na-input-error':
                            hasInputError(errors, ['ccExpMonth', 'ccExpYear'], '|', true) || chargeErrors.length
                    }"
                >
                    <select v-model="paymentMethod.ccExpMonth" required>
                        <option v-for="option in monthOptions" :key="option.id" :value="option.id">
                            {{ option.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="control">
                <label class="label">Exp year</label>
                <div
                    class="select"
                    :class="{
                        'na-input-error':
                            hasInputError(errors, ['ccExpMonth', 'ccExpYear'], '|', true) || chargeErrors.length
                    }"
                >
                    <select v-model="paymentMethod.ccExpYear" required>
                        <option v-for="option in yearOptions" :key="option.id" :value="option.id">
                            {{ option.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="control">
                <label class="label">CVV</label>
                <input
                    v-model="paymentMethod.ccCode"
                    class="input"
                    :class="{
                        'na-input-error': hasInputError(errors, 'ccCode', '|', true) || chargeErrors.length
                    }"
                    type="tel"
                    placeholder="CVV"
                    inputmode="numeric"
                    pattern="[0-9\s]{3,4}"
                    maxlength="4"
                    required
                    autocomplete="cc-csc"
                    style="max-width: 120px"
                />
            </div>
        </div>

        <error-message :errors="errors" />
        <input-error :field="['ccNumber', 'ccExpMonth', 'ccExpYear', 'ccCode']" :errors="errors" />
    </form>
</template>

<script>
import { dateMixin } from '@/mixins/DateMixin';
import { addPaymentMethod } from '@/services/accounting';
import ErrorMessage from '@/components/Common/Error.vue';
import InputError from '@/components/Common/InputError.vue';

export default {
    components: {
        ErrorMessage,
        InputError
    },

    mixins: [dateMixin],

    props: {
        chargeErrors: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            submitting: false,
            paymentMethod: {
                ccName: '',
                ccNumber: '',
                ccExpMonth: null,
                ccExpYear: null,
                ccCode: ''
            },
            errors: []
        };
    },

    computed: {
        monthOptions() {
            return this.dateOptionPaymentMonths(true, 'MM');
        },
        yearOptions() {
            return this.dateOptionPaymentYears(true, 'YYYY');
        }
    },

    methods: {
        async submit() {
            if (!this.$refs.form.reportValidity()) {
                return;
            }
            this.submitting = true;
            this.errors = [];
            const data = {
                paymentType: 'paytypeCreditCard',
                ...this.paymentMethod,
                omitName: true,
                isDefault: true, // Make sure it's set as default method
                overrideDupe: true,
                reloadAmount: 10,
                reloadBelowAmount: 10 // Maybe an option in the future
            };
            const response = await addPaymentMethod(data);
            if (response.success) {
                return response.paymentMethodId;
            } else {
                this.errors = response.errors ?? response.inputErrors ?? [];
            }
            this.submitting = false;
        }
    }
};
</script>
