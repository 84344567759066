<template>
    <div>
        <div>
            <span class="na-post-comment-user">{{ theUser }}</span>
            <span class="na-post-comment-time">{{ theTime }}</span>
        </div>
        <!-- 2018-12-20  MJL  No longer using title -->
        <!-- <div class="na-reset na-post-comment-title">{{ postCommentTitle(comment) }}</div> -->
        <div class="na-reset na-post-comment-content">{{ theComment }}</div>
    </div>
</template>

<script>
    import { postMixin } from '@/mixins/PostMixin.js';

    export default {
        mixins: [postMixin],
        props: {
            comment: {
                default: function () {
                    return {};
                }
            },
            now: {
                default: function () {
                    return 0;
                }
            }
        },
        computed: {
            theUser () {
                return this.postCommentUser(this.comment);
            },
            theTime () {
                let now = this.now;
                return this.postCommentTime(this.comment, true);
                // @todo  2018-12-20  MJL  Not working the way I want yet, so commented-out
                //return moment(this.comment.created_at).fromNow();  //this.postCommentTime(this.comment) + this.now;
            },
            theComment () {
                return this.postComment(this.comment);
            }
        }
    }
</script>
