<!-- @format -->
<template>
    <div>
        <div v-if="!isJustPurchased">
            <template v-if="postCostIsPaid(post)">
                <span
                    v-if="!postCostIsSubscription(post)"
                    class="na-post-cost-read-more na-post-cost-read-more-paid"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Paid For"
                    ><fa-icon :icon="['far', 'smile']" /> PAID</span
                >
                <span
                    v-if="postCostIsSubscription(post)"
                    class="na-post-cost-read-more na-post-cost-read-more-subscription"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Included as part of your subscription"
                    ><fa-icon icon="check" /> SUB</span
                >
            </template>

            <template v-if="!postCostIsPaid(post)">
                <span
                    v-if="postCostIsDebit(post) && !postCostIsSubscription(post)"
                    class="na-post-cost-read-more na-post-cost-read-more-debit"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Purchase"
                    v-html="formatCost(post.cost)"
                ></span>

                <span
                    v-if="postCostIsSubscription(post) && !isPostSponsored(post)"
                    class="na-post-cost-read-more na-post-cost-read-more-subscription"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Included as part of your subscription"
                    ><fa-icon icon="check" /> SUB</span
                >

                <span
                    v-if="postCostIsFree(post)"
                    class="na-post-cost-read-more na-post-cost-read-more-free"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Free!"
                    ><fa-icon :icon="['far', 'smile']" /> FREE</span
                >
            </template>
        </div>

        <p
            v-else
            class="na-post-cost-read-more na-post-cost-read-more-subscription"
            data-toggle="tooltip"
            data-placement="top"
            title="Thank you!"
        >
            <span class="icon"><fa-icon icon="check" /></span>
        </p>
    </div>
</template>

<script>
// Note that we don't need click handlers because this component should be inside of the read more button
import { moneyMixin } from '@/mixins/MoneyMixin.js';
import { postMixin } from '@/mixins/PostMixin.js';

export default {
    mixins: [moneyMixin, postMixin],

    props: {
        post: {
            type: Object,
            default: function () {
                return null;
            }
        },
        // Was post just purchased?
        isJustPurchased: {
            type: Boolean,
            default: false
        }
    }
};
</script>
